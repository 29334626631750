import suprsend from '@suprsend/web-sdk';

export const SUPRSEND_WORKSPACE_KEY = process.env.REACT_APP_SUPRSEND_WORKSPACE_KEY;
export const SUPRSEND_WORKSPACE_SECRET = process.env.REACT_APP_SUPRSEND_WORKSPACE_SECRET;

export function initSuprsend() {
  if (!SUPRSEND_WORKSPACE_KEY) {
    console.error('Invalid Configuration: Missing SUPRSEND_WORKSPACE_KEY');
    return;
  }
  if (!SUPRSEND_WORKSPACE_SECRET) {
    console.error('Invalid Configuration: Missing SUPRSEND_WORKSPACE_SECRET');
    return;
  }

  suprsend.init(SUPRSEND_WORKSPACE_KEY, SUPRSEND_WORKSPACE_SECRET);
}
