import { useEffect, useMemo, useState } from 'react';

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  FormControlLabel,
  Grid,
  Link,
  Typography
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { useSnackbar } from 'notistack';
import numbro from 'numbro';

import {
  AvailableWorkspaceSubscriptionsFromHeadOfficeQuery,
  useAvailableWorkspaceSubscriptionsFromHeadOfficeQuery,
  useUpdateWorkspaceSubscriptionMutation
} from 'generated/graphql';

import DialogTitle from 'components/DialogTitle';
import FeatureComparisonTable from 'components/billing/FeatureComparisonTable';
import CategoryCheckbox from 'components/forms/CategoryCheckbox';

import { useHasuraRoleContext } from 'lib/HasuraRoleContext';
import { APP_CONFIG } from 'lib/app/config';
import useUserContext from 'lib/hooks/useUserContext';
import { sleep } from 'lib/utils/sleep';

export interface UpdateSubscriptionDialogProps {
  open: boolean;
  onClose: (success: boolean) => void;
}

type SubscriptionOption =
  AvailableWorkspaceSubscriptionsFromHeadOfficeQuery['head_office_workspace_subscription_options'][number];

export function UpdateSubscriptionDialog({ open, onClose }: UpdateSubscriptionDialogProps) {
  const { activeWorkspaceId, workspace, isWorkspaceAdmin } = useUserContext();
  const { workspaceMemberContext } = useHasuraRoleContext();
  const { enqueueSnackbar } = useSnackbar();

  const currentSubscriptionLevel = useMemo(
    () => workspace?.subscription_model,
    [workspace?.subscription_model]
  );

  const [chosenSubscriptionLevel, setChosenSubscriptionLevel] = useState(currentSubscriptionLevel);
  const [chosenOption, setChosenOption] = useState<SubscriptionOption | null>(null);

  const [updatingSubscription, setUpdatingSubscription] = useState<boolean>(false);
  const { data, loading } = useAvailableWorkspaceSubscriptionsFromHeadOfficeQuery({
    variables: {
      workspace_id: activeWorkspaceId!
    },
    context: workspaceMemberContext
  });
  const [updateSubscription] = useUpdateWorkspaceSubscriptionMutation({
    context: workspaceMemberContext
  });

  // Can be a delay in workspace data loading and state being initialised
  useEffect(() => {
    setChosenSubscriptionLevel(currentSubscriptionLevel);
  }, [setChosenSubscriptionLevel, currentSubscriptionLevel]);

  const options = useMemo(() => {
    // Sort function mutates array, so we need to copy the immutable response
    const unsorted = Array.from(data?.head_office_workspace_subscription_options ?? []);

    return unsorted.sort(
      (a, b) =>
        (a.stripe_product?.default_price?.unit_amount ?? 0) -
        (b.stripe_product?.default_price?.unit_amount ?? 0)
    );
  }, [data?.head_office_workspace_subscription_options]);

  const paidOptions = useMemo(
    () => options.filter((o) => (o.stripe_product?.default_price?.unit_amount ?? 0) > 0),
    [options]
  );
  const freeOptions = useMemo(
    () => options.filter((o) => (o.stripe_product?.default_price?.unit_amount ?? 0) === 0),
    [options]
  );

  const handleCloseCancel = () => {
    onClose(false);
  };

  const handleClickUpgradeAccount = async () => {
    if (!chosenOption) {
      return enqueueSnackbar('Please choose an option first', { variant: 'error' });
    }

    if (chosenOption.subscription_level === currentSubscriptionLevel) {
      return enqueueSnackbar('This is the same subscription level as you are currently on', {
        variant: 'error'
      });
    }

    try {
      setUpdatingSubscription(true);
      await updateSubscription({
        variables: {
          args: {
            workspace_id: activeWorkspaceId!,
            price_id: chosenOption.stripe_product.default_price?.id!,
            subscription_level: chosenOption.subscription_level,
            head_office_workspace_subscription_option_id: chosenOption.id
          }
        }
      });
      await sleep(2500); // Allow feature flags/etc to be updated in background
      setUpdatingSubscription(false);
      enqueueSnackbar('Subscription updated!', { variant: 'success' });
      onClose(true);
    } catch (error: any) {
      setUpdatingSubscription(false);
      return enqueueSnackbar(error?.message ?? 'Unable to update subscription', {
        variant: 'error'
      });
    }
  };

  const handleChooseSubscription = (option: SubscriptionOption) => {
    setChosenSubscriptionLevel(option.subscription_level);
    setChosenOption(option);
  };

  const buildDescription = (option: SubscriptionOption): string => {
    let price: string = '';
    if (option.stripe_product.default_price) {
      const productPrice = option.stripe_product.default_price!;
      const productPriceCurrency =
        productPrice.currency_options?.find(
          (cur) => cur.currency === workspace?.currency?.toLowerCase()
        ) ?? productPrice;
      price = `${numbro((productPriceCurrency.unit_amount ?? 0) / 100).formatCurrency({
        thousandSeparated: true,
        spaceSeparatedCurrency: false
      })} ${productPriceCurrency.currency.toUpperCase()} per ${productPrice.recurring?.interval}`;

      return price;
    }

    return `Subscription level: ${option.subscription_level}`;
  };

  return (
    <Dialog open={open} maxWidth="lg" fullWidth>
      <DialogTitle title="Upgrade Account" onClose={handleCloseCancel}>
        Upgrade Account
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          Upgrade your workspace to unlock more features and users
        </DialogContentText>

        {loading ? (
          <CircularProgress />
        ) : (
          <Box mt={4}>
            {options.length === 0 ? (
              <DialogContentText>Please contact support to upgrade your account</DialogContentText>
            ) : (
              <>
                <Box mb={2} maxWidth={1000}>
                  <Accordion elevation={0}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <Typography variant="subtitle2">
                        Subscription comparison (click to toggle comparison)
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <FeatureComparisonTable />
                    </AccordionDetails>
                  </Accordion>
                </Box>
                <Grid container spacing={1}>
                  {paidOptions.map((option) => (
                    <Grid item key={option.id}>
                      <CategoryCheckbox
                        title={option.name ?? option.stripe_product.name}
                        description={buildDescription(option)}
                        checked={option.subscription_level === chosenSubscriptionLevel}
                        value={option.subscription_level}
                        onClick={() => handleChooseSubscription(option)}
                        readonly={updatingSubscription}
                      />
                    </Grid>
                  ))}
                </Grid>
                <Box mt={1}>
                  {freeOptions.map((option) => (
                    <FormControlLabel
                      key={option.id}
                      label={`${option.name ?? option.stripe_product.name} (${buildDescription(
                        option
                      )})`}
                      disabled={updatingSubscription}
                      control={
                        <Checkbox
                          checked={option.subscription_level === chosenSubscriptionLevel}
                          value={option.subscription_level}
                          onClick={() => handleChooseSubscription(option)}
                          disabled={updatingSubscription}
                        />
                      }
                    />
                  ))}
                </Box>
              </>
            )}
          </Box>
        )}

        <Box mt={2}>
          <Typography variant="caption" gutterBottom>
            You will be charged immediately upon upgrading your subscription.
            <br />
          </Typography>
          <Typography variant="caption" gutterBottom>
            By subscribing you agree to {APP_CONFIG.SITE_TITLE}'s{' '}
            <Link
              href={APP_CONFIG.SITE_TERMS_URL}
              color="secondary"
              target="_blank"
              rel="noopener noreferrer"
            >
              Terms of Service
            </Link>{' '}
            and its{' '}
            <Link
              href={APP_CONFIG.SITE_PRIVACY_URL}
              color="secondary"
              target="_blank"
              rel="noopener noreferrer"
            >
              Privacy Policy
            </Link>
            .
          </Typography>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseCancel} color="default" disabled={updatingSubscription}>
          Cancel
        </Button>
        <Button
          onClick={handleClickUpgradeAccount}
          color="primary"
          disabled={
            !isWorkspaceAdmin ||
            updatingSubscription ||
            !chosenOption ||
            chosenOption.subscription_level === currentSubscriptionLevel
          }
        >
          {updatingSubscription ? 'Upgrading ... ' : 'Upgrade account'}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
