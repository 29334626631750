import { useCallback, useEffect, useState } from 'react';

import { useLocation, useNavigate } from 'react-router';
import { Link } from 'react-router-dom';

import { Button, ButtonGroup, Menu, MenuItem, Tab, Tabs } from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

import { bindMenu, bindTrigger, usePopupState } from 'material-ui-popup-state/hooks';

import PageHeader from 'components/PageHeader';

import useUserContext from '../../../lib/hooks/useUserContext';
import ListingsTab from './tabs/Listings';

const IS_DEV = process.env.NODE_ENV === 'development';
const hashes = ['#listings'];

const PropertiesPage = () => {
  const [currentTab, setCurrentTab] = useState(0);
  const location = useLocation();
  const navigate = useNavigate();
  const { isWorkspaceAnalyst, workspace } = useUserContext();
  const popupState = usePopupState({ variant: 'popover', popupId: 'campaignMenu' });

  useEffect(() => {
    const hash = location.hash;
    const tab = hashes.findIndex((el: string) => el === hash);

    if (tab >= 0) {
      setCurrentTab(tab);
    }
  }, [location, setCurrentTab]);

  const handleTabChange = useCallback(
    (_e: any, value: number) => {
      const hash = hashes[value];
      navigate(`/properties${hash}`);
    },
    [navigate]
  );

  const workspaceUrlPrefix = workspace?.url_prefix!;

  let ofiLandingPageUrl = '#';
  if (IS_DEV) {
    ofiLandingPageUrl = `http://localhost:3005/p/inspections`;
  } else {
    ofiLandingPageUrl = `https://${workspaceUrlPrefix}.lp.properti.ai/p/inspections`;
  }

  return (
    <div>
      <PageHeader
        title="Properties"
        rightComponent={
          <>
            <ButtonGroup variant="contained" color="secondary" style={{ minWidth: 160 }}>
              <Button
                component={Link}
                to="listing/create"
                size="large"
                disabled={isWorkspaceAnalyst}
              >
                New Listing
              </Button>
              <Button size="small" {...bindTrigger(popupState)}>
                <ArrowDropDownIcon />
              </Button>
            </ButtonGroup>
            <Menu
              {...bindMenu(popupState)}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right'
              }}
            >
              <MenuItem component={Link} to="listing/create" disabled={isWorkspaceAnalyst}>
                Create New Listing
              </MenuItem>
              <MenuItem component="a" href={ofiLandingPageUrl} target="_blank">
                Open Properti OFI Landing Page
              </MenuItem>
            </Menu>
          </>
        }
      />
      <Tabs textColor="secondary" value={currentTab} onChange={handleTabChange}>
        <Tab label="Listings" />
      </Tabs>
      {currentTab === 0 && <ListingsTab />}
    </div>
  );
};

export default PropertiesPage;
