import gql from 'graphql-tag';

export const ListingResponsiveAdDefaultsQuery = gql`
  query ResponsiveDefaults($args: GetResponsiveAdDefaultsInput!) {
    getResponsiveAdDefaults(args: $args) {
      creative_name
      long_title
      url
      descriptions
      titles
    }
  }
`;

export const ListingCarouselAdDefaultsQuery = gql`
  query CarouselDefaults($args: GetCarouselAdDefaultsInput!) {
    getCarouselAdDefaults(args: $args) {
      creative_name
      caption
      link
      message
      __typename
    }
  }
`;

export const ListingCarouselAdImageDefaultsQuery = gql`
  query CarouselImageDefaults($args: GetCarouselAdImageDefaultsInput!) {
    getCarouselAdImageDefaults(args: $args) {
      photo_description
      photo_link
      photo_name
      __typename
    }
  }
`;

export const ListingMessageQuery = gql`
  query ListingMessage($args: GetListingMessageInput!) {
    getListingMessage(args: $args) {
      title
      message
      url
      short_url
      report_url
    }
  }
`;

export const ListingMessageWithLocationQuery = gql`
  query ListingMessageWithLocation($listing_id: uuid!, $args: GetListingMessageInput!) {
    getListingMessage(args: $args) {
      title
      message
      url
      short_url
      report_url
    }
    listing: workspace_listings_by_pk(id: $listing_id) {
      id
      location {
        id
        google_place_id
        geo
        address {
          id
          formatted_address
        }
      }
    }
  }
`;

export const OFIMessageQuery = gql`
  query OFIMessage($args: GetOFIMessageInput!) {
    defaults: getOFIMessage(args: $args) {
      title
      message
      url
    }
  }
`;

export const AuctionMessageQuery = gql`
  query AuctionMessage($args: GetAuctionMessageInput!) {
    defaults: getAuctionMessage(args: $args) {
      title
      message
      url
    }
  }
`;

export const RecentlySoldMessageQuery = gql`
  query RecentlySoldMessage($args: GetRecentlySoldMessageInput!) {
    defaults: getRecentlySoldMessage(args: $args) {
      title
      message
      url
    }
  }
`;

export const CollectionForLeaseMessageQuery = gql`
  query CollectionForLeaseMessage($args: GetCollectionForLeaseMessageInput!) {
    defaults: getCollectionForLeaseMessage(args: $args) {
      title
      message
      url
    }
  }
`;

export const CollectionLeasedMessageQuery = gql`
  query CollectionLeasedMessage($args: GetCollectionLeasedMessageInput!) {
    defaults: getCollectionLeasedMessage(args: $args) {
      title
      message
      url
    }
  }
`;

export const ListingDetailQuery = gql`
  query ListingById($id: uuid!) {
    listing: workspace_listings_by_pk(id: $id) {
      ...workspaceListingFields
    }
  }
`;

export const AgentboxEnquiriesQuery = gql`
  query AgentboxListingEnquiries($limit: Int = 20, $page: Int = 1, $listingId: String) {
    agentbox_enquiries(limit: $limit, page: $page, listingId: $listingId) {
      items
      current
      last
      enquiries {
        id
        comment
        date
        type
        contact {
          id
          contact {
            id
            type
            firstName
            lastName
            email
            mobile
            status
            lastContacted
            communicationRestrictions {
              doNotCall
              doNotMail
              doNotEmail
              doNotSMS
            }
          }
        }
      }
    }
  }
`;
