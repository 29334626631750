import { Form } from 'react-final-form';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import { Box, Button, Grid, Link as MuiLink, Paper, Typography } from '@material-ui/core';

import logdna from '@logdna/browser';

import { FORM_ERROR } from 'final-form';
import { makeValidate, TextField } from 'mui-rff';
import qs from 'query-string';
import * as Yup from 'yup';

import { APP_CONFIG } from 'lib/app/config';
import { auth } from 'lib/auth/hbp';

import useAuthStyles from '../_styles';
import SSOLoginOptions, { ContinueWithSSOButton } from '../components/SSOLoginOptions';
import { findSSODomain } from '../utils/sso';

const schema = Yup.object({
  first_name: Yup.string().trim().required().label('First name'),
  last_name: Yup.string().trim().required().label('Last name'),
  email: Yup.string().trim().email().required().label('Email address'),
  password: Yup.string().required().label('Password')
}).required();

type FormSchema = Yup.InferType<typeof schema>;

const validate = makeValidate(schema);

const RegisterPage = () => {
  const classes = useAuthStyles();
  const location = useLocation();
  const navigate = useNavigate();

  const { email, invite } = qs.parse(location.search);

  const handleAttemptLogin = async (email: string, password: string) => {
    try {
      await auth.login(email, password);
      return true;
    } catch (error) {
      return false;
    }
  };

  const redirectPath = invite ? `/join?invite=${invite}` : `/workspaces`;
  const navigateAfterRegister = () => {
    navigate(redirectPath);
  };

  const handleRegister = async (values: FormSchema) => {
    try {
      logdna.info('User Registration', { email: values.email });

      // Register
      await auth.register(values.email, values.password, {
        display_name: `${values.first_name?.trim()} ${values.last_name?.trim()}`.trim()
      });

      // Can auto sign-in
      await auth.login(values.email, values.password);

      navigateAfterRegister();
    } catch (error: any) {
      let message = 'Unknown Error Occurred';
      if (error.isAxiosError) {
        message = error?.response?.data?.message ?? 'Unknown Error Occurred';

        if (message.includes('Account already exists')) {
          const canLogin = await handleAttemptLogin(values.email, values.password);
          if (canLogin) {
            navigateAfterRegister();
          }
        }
      }
      return { [FORM_ERROR]: message };
    }
  };

  return (
    <div className={classes.content}>
      <Paper elevation={1} className={classes.box}>
        <img
          className={classes.logo}
          src={APP_CONFIG.SITE_LOGO_URL_WIDE}
          alt=""
          style={{ width: 160 }}
        />
        <div className={classes.formWrapper}>
          <Form
            initialValues={{
              email: email
            }}
            onSubmit={handleRegister}
            validate={validate}
            render={({ handleSubmit, submitError, submitting, values, errors }) => {
              const emailDomain = values.email?.split('@').pop();
              const isValidEmail = !Boolean(errors?.email);

              const foundSSOProvider = emailDomain ? findSSODomain(emailDomain) : null;
              const hasSSOProvider = Boolean(foundSSOProvider);
              const ssoRequired = foundSSOProvider?.sso_required === true;
              const showPasswordLoginButton = !hasSSOProvider || !ssoRequired;

              return (
                <form onSubmit={handleSubmit} noValidate>
                  <Typography variant="h3" color="secondary" gutterBottom>
                    Create your account
                  </Typography>
                  <Typography gutterBottom>
                    Dominate your Digital Patch - Real Estate Social Marketing.
                  </Typography>
                  <Grid container spacing={1}>
                    <Grid item xs={12} sm={6}>
                      <TextField style={{ height: 60 }} name="first_name" label="First Name" />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField style={{ height: 60 }} name="last_name" label="Last Name" />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <TextField
                        style={{ height: 60 }}
                        name="email"
                        label="Email"
                        type="email"
                        autoComplete="email"
                      />
                    </Grid>
                    {!ssoRequired && (
                      <Grid item xs={12} sm={12}>
                        <TextField
                          style={{ height: 60 }}
                          name="password"
                          label="Password"
                          type="password"
                        />
                      </Grid>
                    )}
                  </Grid>
                  <Box mb={1}>
                    {showPasswordLoginButton && (
                      <Button
                        type="submit"
                        fullWidth
                        size="large"
                        variant="contained"
                        color="secondary"
                        className={classes.submit}
                        disabled={submitting}
                      >
                        {submitting ? 'Loading ...' : "Let's get started"}
                      </Button>
                    )}
                    {hasSSOProvider && (
                      <ContinueWithSSOButton
                        provider={foundSSOProvider?.provider!}
                        disabled={!isValidEmail}
                        redirectPath={redirectPath}
                      />
                    )}
                  </Box>

                  {submitError && (
                    <Grid container spacing={2}>
                      <Grid item>
                        <Typography color="error" variant="subtitle2">
                          {submitError}
                        </Typography>
                      </Grid>
                    </Grid>
                  )}
                  <Box mt={2} mb={1}>
                    <Typography className={classes.buttonCaption} variant="caption" gutterBottom>
                      By registering you agree to {APP_CONFIG.SITE_TITLE}'s{' '}
                      <MuiLink
                        href={APP_CONFIG.SITE_TERMS_URL}
                        color="secondary"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Terms of Service
                      </MuiLink>{' '}
                      and its{' '}
                      <MuiLink
                        href={APP_CONFIG.SITE_PRIVACY_URL}
                        color="secondary"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Privacy Policy
                      </MuiLink>
                      .
                    </Typography>
                  </Box>
                </form>
              );
            }}
          />
          <SSOLoginOptions redirectPath={redirectPath} />
        </div>
        <Grid container direction="column" justifyContent="space-between" alignItems="center">
          <Grid className={classes.extraItem} item xs>
            <Typography variant="body2" gutterBottom>
              Already have an account?{' '}
              <MuiLink component={Link} color="secondary" to="/login">
                Sign In
              </MuiLink>
            </Typography>
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
};

export default RegisterPage;
