/**
 * Get a value within a nested object
 *
 * @param values The object to read data
 * @param key The key of the desired value, in dot notation with optional array access
 * @returns The value if found, otherwise undefined
 */
export function getPossibleNestedValue(values: Record<string, any> | any[], key: string) {
  // Split the key into an array of nested keys
  const keys = key.split('.');

  // Iterate through the keys to access nested objects
  let value: any = values;
  for (const k of keys) {
    // Remove array access notation from key
    const keyWithoutArrayAccessNotation = k.replace(/\[.*?\]/g, '');

    // Extract the index from array access notation
    const indexMatch = k.match(/\[(\d+)\]/);
    const index = indexMatch ? parseInt(indexMatch[1]) : undefined;

    // Check if value exists
    if (index !== undefined && Array.isArray(value[keyWithoutArrayAccessNotation])) {
      value = value[keyWithoutArrayAccessNotation][index];
    } else if (value && typeof value === 'object' && keyWithoutArrayAccessNotation in value) {
      value = value[keyWithoutArrayAccessNotation];
    } else {
      // Key doesn't exist or value is not an object/array
      return undefined;
    }
  }

  return value;
}
