import logdna from '@logdna/browser';

const LOGDNA_API_KEY = process.env.REACT_APP_LOGDNA_API_KEY as string;

export function initLogdna() {
  if (!LOGDNA_API_KEY) {
    return console.warn('Missing LOGDNA_API_KEY for logging ingestion');
  }

  logdna.init(LOGDNA_API_KEY, {
    app: 'dashboard',
    disabled: typeof window === 'undefined' || process.env.NODE_ENV === 'development',
    env: process.env.NODE_ENV
  });
}
