import { useCallback, useEffect, useState } from 'react';

import { useNavigate } from 'react-router';
import { Virtuoso } from 'react-virtuoso';

import {
  Box,
  Button,
  CircularProgress,
  Divider,
  IconButton,
  List,
  Menu,
  MenuItem,
  Popover,
  Typography
} from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';

import { useNotifications } from '@suprsend/react-inbox';

import { PopupState, bindPopover, bindTrigger } from 'material-ui-popup-state/core';
import { usePopupState } from 'material-ui-popup-state/hooks';

import ListFooter from './NotificationListFooter';
import NotificationListItem from './NotificationListItem';
import NotificationsEmptyState from './NotificationsEmptyState';

interface NotificationsPopoverProps {
  popoverState: PopupState;
}

export default function NotificationsPopover({ popoverState }: NotificationsPopoverProps) {
  const moreMenuState = usePopupState({
    variant: 'popover',
    popupId: 'notificationMoreMenuPopover',
    parentPopupState: popoverState
  });
  const navigate = useNavigate();

  const [loadingBuffer, setLoadingBuffer] = useState<boolean>(true);
  const {
    notifications,
    hasNext,
    markAllRead,
    markAllSeen,
    initialLoading,
    fetchPrevious,
    markClicked
  } = useNotifications();

  const handleOnEndReached = useCallback(() => {
    if (hasNext) {
      fetchPrevious();
    }
  }, [hasNext, fetchPrevious]);

  const handleClearNotifications = useCallback(() => {
    markAllRead();
    markAllSeen();
  }, [markAllRead, markAllSeen]);

  const handleMarkAllAsRead = useCallback(() => {
    moreMenuState.close();
    handleClearNotifications();
  }, [moreMenuState, handleClearNotifications]);

  const handleGoToAllNotifications = useCallback(
    (preferences: boolean = false) => {
      moreMenuState.close();
      popoverState.close();

      if (preferences) {
        navigate('/notifications#preferences');
      } else {
        navigate('/notifications#notifications');
      }
    },
    [moreMenuState, popoverState, navigate]
  );

  useEffect(() => {
    // SuprSend initialLoading starts as false, but needs to load
    // Lets add a one second automatic buffer to avoid showing
    // No notification message at the first render
    const to = setTimeout(() => {
      setLoadingBuffer(false);
    }, 250);

    return () => {
      clearTimeout(to);
    };
  }, [setLoadingBuffer]);

  return (
    <>
      <Popover
        {...bindPopover(popoverState)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
        elevation={10}
        style={{
          marginTop: 0,
          marginBottom: 0,
          marginRight: 2,
          marginLeft: 2
        }}
      >
        <Box
          style={{
            width: 480
          }}
        >
          <Box
            sx={{
              position: 'sticky',
              top: 0,
              bgcolor: '#FFF',
              zIndex: 1000,
              paddingBottom: 0
            }}
          >
            <Box
              pl={2}
              height={60}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography variant="h6">Notifications</Typography>
              <Box sx={{ marginRight: 1 }}>
                <Button variant="text" color="primary" onClick={handleMarkAllAsRead}>
                  Mark all as read
                </Button>
                <IconButton color="primary" size="small" {...bindTrigger(moreMenuState)}>
                  <MoreVertIcon />
                </IconButton>
              </Box>
            </Box>
            <Divider />
          </Box>

          <Box style={{ paddingTop: 0 }}>
            {initialLoading || loadingBuffer ? (
              <Box style={{ height: 650 }}>
                <CircularProgress />
              </Box>
            ) : (
              <List>
                <Virtuoso
                  style={{ paddingTop: 0, height: 650 }}
                  data={notifications}
                  endReached={handleOnEndReached}
                  overscan={200}
                  itemContent={(_index, notification) => {
                    return (
                      <NotificationListItem
                        key={notification.n_id}
                        notification={notification}
                        markClicked={markClicked}
                        closePopoverState={popoverState.close}
                      />
                    );
                  }}
                  components={{
                    EmptyPlaceholder: NotificationsEmptyState,
                    Footer: ListFooter
                  }}
                />
              </List>
            )}
          </Box>
        </Box>
      </Popover>
      <Menu
        {...bindPopover(moreMenuState)}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        <MenuItem onClick={handleMarkAllAsRead}>Mark all as read</MenuItem>
        <MenuItem onClick={() => handleGoToAllNotifications(false)}>Go to notifications</MenuItem>
        <MenuItem onClick={() => handleGoToAllNotifications(true)}>Manage preferences</MenuItem>
      </Menu>
    </>
  );
}
