import { Dispatch, SetStateAction, useCallback, useMemo, useState } from 'react';

import _ from 'lodash';

import {
  AvailableTemplatesQuery,
  CustomTemplateFunctionDynamicParameterInput,
  WorkspaceByIdQuery,
  useAvailableTemplatesQuery
} from 'generated/graphql';

import { useHasuraRoleContext } from 'lib/HasuraRoleContext';

import useUserContext from './useUserContext';

export interface UseDynamicParametersHookResponse {
  hasDynamicParameters: boolean;
  loadingDynamicParameters: boolean;
  dynamicParameters: AvailableTemplatesQuery['getWorkspaceTemplates'][number]['dynamic_parameters'];
  initialFormValuesForDynamicParameters: CustomTemplateFunctionDynamicParameterInput[] | undefined;

  /**
   * Function to check if a value is a valid dynamic parameter form value
   */
  isValidDynamicParameterFormValue: (checkValues?: Record<string, any> | undefined) => boolean;

  /**
   * Boolean indicating whether the UI should be grouped by function name
   * Default: true
   */
  groupByFunctionName: boolean;

  /**
   * Function to toggle "groupByFunctionName" boolean
   */
  setGroupByFunctionName: Dispatch<SetStateAction<boolean>>;

  /**
   * Boolean indicating whether we are in head office mode, and head office merge tags should
   *  be shown in inputs
   */
  isHeadOfficeMode: boolean;
}

type DynamicParameterForInitialValue = Exclude<
  AvailableTemplatesQuery['getWorkspaceTemplates'][number]['dynamic_parameters'][number]['function']['dynamicParameters'],
  null | undefined
>[number];
type WorkspaceOfficeAddress = Exclude<
  WorkspaceByIdQuery['workspace'],
  null | undefined
>['office_address'];

function buildInitialValueFromParameter(
  parameter: DynamicParameterForInitialValue,
  workspaceOfficeAddress: WorkspaceOfficeAddress,
  isHeadOfficeMode: boolean
): string | number | undefined {
  if (!parameter || !workspaceOfficeAddress || isHeadOfficeMode) {
    return undefined;
  }

  // Use Office Address where possible
  if (parameter.name === 'post_code' && workspaceOfficeAddress.postal_code) {
    return workspaceOfficeAddress.postal_code;
  }

  if (parameter.name === 'state' && workspaceOfficeAddress.state_code) {
    return workspaceOfficeAddress.state_code.toLowerCase();
  }

  if (parameter.name === 'suburb' && workspaceOfficeAddress.suburb) {
    return workspaceOfficeAddress.suburb;
  }

  // Some functions have default values that we can use
  if (['type', 'dwelling_type', 'frequency'].includes(parameter.name) && parameter.defaultValue) {
    return parameter.defaultValue;
  }

  return undefined;
}

export function useTemplateDynamicParameters(
  templateId: string | undefined | null
): UseDynamicParametersHookResponse {
  const { activeWorkspaceId, workspace, isHeadOfficeMode } = useUserContext();
  const { workspaceMemberContext, headOfficeUserContext } = useHasuraRoleContext();
  const [groupByFunctionName, setGroupByFunctionName] = useState<boolean>(true);

  const workspaceOfficeAddress = useMemo(
    () => workspace?.office_address,
    [workspace?.office_address]
  );

  const isValidTemplateId = useMemo(
    () => typeof templateId === 'string' && Boolean(templateId),
    [templateId]
  );

  const hasuraContext = useMemo(
    () => (isHeadOfficeMode ? headOfficeUserContext : workspaceMemberContext),
    [isHeadOfficeMode, headOfficeUserContext, workspaceMemberContext]
  );
  const { data, loading } = useAvailableTemplatesQuery({
    variables: {
      args: {
        workspace_id: activeWorkspaceId!
      }
    },
    skip: !activeWorkspaceId || !isValidTemplateId,
    context: hasuraContext!
  });

  const foundTemplate = useMemo(
    () => data?.getWorkspaceTemplates.find((tem) => tem.template_id === templateId),
    [data?.getWorkspaceTemplates, templateId]
  );

  const rawTemplateDynamicParameters = useMemo(
    () => foundTemplate?.dynamic_parameters ?? [],
    [foundTemplate?.dynamic_parameters]
  );

  const templateDynamicParameters = useMemo(() => {
    if (groupByFunctionName) {
      return _.uniqBy(rawTemplateDynamicParameters, 'function.name');
    }

    return rawTemplateDynamicParameters;
  }, [rawTemplateDynamicParameters, groupByFunctionName]);

  const initialFormValuesForDynamicParameters = useMemo(() => {
    if (!isValidTemplateId) {
      return [];
    }

    return templateDynamicParameters.map<CustomTemplateFunctionDynamicParameterInput>((tdp) => ({
      identifiers: tdp.identifiers,
      function_name: tdp.function.name,
      parameters:
        tdp.function.dynamicParameters?.map((dp) => ({
          name: dp.name,
          value: buildInitialValueFromParameter(dp, workspaceOfficeAddress, isHeadOfficeMode)
        })) ?? []
    }));
  }, [isValidTemplateId, templateDynamicParameters, workspaceOfficeAddress, isHeadOfficeMode]);

  const isValidDynamicParameterFormValue = useCallback(
    (checkValues?: Record<string, any>) => {
      if (!checkValues || !isValidTemplateId) {
        return false;
      }

      // Must be in array format to be valid
      if (!Array.isArray(checkValues)) {
        return false;
      }

      // TODO Improve check here, should probably look at identifiers and/or function names
      if (checkValues.length === initialFormValuesForDynamicParameters.length) {
        return true;
      }

      return false;
    },
    [isValidTemplateId, initialFormValuesForDynamicParameters]
  );

  const state = useMemo(() => {
    if (!isValidTemplateId) {
      return {
        hasDynamicParameters: false,
        loadingDynamicParameters: loading,
        dynamicParameters: [],
        initialFormValuesForDynamicParameters,
        isValidDynamicParameterFormValue,
        groupByFunctionName,
        setGroupByFunctionName,
        isHeadOfficeMode
      };
    }

    return {
      hasDynamicParameters: templateDynamicParameters.length > 0,
      loadingDynamicParameters: loading,
      dynamicParameters: templateDynamicParameters,
      initialFormValuesForDynamicParameters,
      isValidDynamicParameterFormValue,
      groupByFunctionName,
      setGroupByFunctionName,
      isHeadOfficeMode
    };
  }, [
    isValidTemplateId,
    initialFormValuesForDynamicParameters,
    isValidDynamicParameterFormValue,
    groupByFunctionName,
    setGroupByFunctionName,
    isHeadOfficeMode,
    templateDynamicParameters,
    loading
  ]);

  return state;
}
