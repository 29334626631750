import { Form } from 'react-final-form';
import { Link, useNavigate } from 'react-router-dom';

import {
  Box,
  Button,
  ButtonGroup,
  CircularProgress,
  Dialog,
  DialogContent,
  Link as MuiLink,
  MenuItem,
  Typography
} from '@material-ui/core';

import { FORM_ERROR } from 'final-form';
import createDecorator from 'final-form-calculate';
import moment from 'moment';
import { Autocomplete, makeValidate, Select } from 'mui-rff';
import { useSnackbar } from 'notistack';
import numbro from 'numbro';
import styled from 'styled-components';
import * as Yup from 'yup';

import {
  CampaignTemplatesQuery,
  useCampaignTemplatesQuery,
  useCreateCampaignFromTemplateMutation,
  useCreativeAutocompleteQuery,
  Workspace_Campaign_Objectives_Enum,
  Workspace_Campaign_Templates_Bool_Exp,
  Workspace_Creatives_Available_Bool_Exp,
  Workspace_Resource_Permission_Modes_Enum
} from 'generated/graphql';

import DialogTitle from 'components/DialogTitle';
import PropertyAutocomplete from 'components/forms/PropertyAutocomplete';

import { useHasuraRoleContext } from 'lib/HasuraRoleContext';
import {
  creativeThumbnailFromCreative,
  creativeTypeTextFromCreative,
  getSupportCreativeTypes
} from 'lib/campaigns/creative';
import { Objective } from 'lib/campaigns/objective';
import { useAgentPermissions } from 'lib/hooks/useAgentPermissions';
import useUserContext from 'lib/hooks/useUserContext';

const schema = Yup.object({
  template_key: Yup.string().required().label('Template Key'),
  listing_id: Yup.string().optional().nullable().label('Listing'),
  creative_id: Yup.string().optional().nullable().label('Creative'),
  publish: Yup.boolean().defined().label('Publish campaign')
}).required();

const validate = makeValidate(schema);

type FormSchema = Yup.InferType<typeof schema>;

interface CampaignFromListingOrCreativeDialogProps {
  open: boolean;
  onClose: () => void;
  listingId?: string;
  creativeId?: string;
  templateKey?: string;
}

// Unselect creative if campaign template changes.
// This is to ensure creative type is supported by platforms/objectives.
const calculator = createDecorator({
  field: 'template_key',
  updates: (_value: string, _name: string, _alLValues: any, _prevValues: any) => {
    return {
      creative_id: null
    };
  }
});

export default function CampaignFromListingOrCreativeDialog({
  open,
  onClose,
  listingId,
  creativeId,
  templateKey
}: CampaignFromListingOrCreativeDialogProps) {
  const { workspaceMemberContext } = useHasuraRoleContext();
  const { activeWorkspaceId, userId, isWorkspaceAgent } = useUserContext();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const [createCampaign] = useCreateCampaignFromTemplateMutation({
    context: workspaceMemberContext
  });

  const where: Workspace_Campaign_Templates_Bool_Exp = {
    _and: [
      {
        workspace_id: { _eq: activeWorkspaceId }
      }
    ]
  };

  if (isWorkspaceAgent) {
    where._and!.push({
      _or: [
        {
          created_by_id: { _eq: userId }
        },
        {
          agent_permission_mode: { _eq: Workspace_Resource_Permission_Modes_Enum.All }
        },
        {
          assigned_users: {
            user_id: { _eq: userId }
          }
        }
      ]
    });
  }

  const { data: templateData, loading: templateLoading } = useCampaignTemplatesQuery({
    variables: {
      where: where
    },
    context: workspaceMemberContext
  });

  const isLoading = templateLoading;

  const handleSubmit = async (formValues: FormSchema) => {
    try {
      const publish = formValues.publish ?? false;

      if (publish) {
        // Check if creative is selected
        if (!formValues.creative_id) {
          return {
            [FORM_ERROR]: 'Creative is required for quick publishing campaigns'
          };
        }
      }

      const resp = await createCampaign({
        variables: {
          args: {
            workspace_id: activeWorkspaceId!,
            listing_id: formValues.listing_id,
            template_key: formValues.template_key,
            creative_id: formValues.creative_id,
            publish: publish
          }
        }
      });

      const campaignId = resp?.data?.createCampaignFromTemplate?.campaign_id;
      if (!campaignId) {
        throw new Error('Unable to create campaign');
      }

      if (publish && resp.data?.createCampaignFromTemplate?.will_publish) {
        navigate('/campaigns');
        onClose();
      } else if (publish) {
        enqueueSnackbar('We were unable to confirm settings to publish, please complete manually', {
          variant: 'warning'
        });
        navigate(`/campaigns/edit/${campaignId}`);
      } else {
        navigate(`/campaigns/edit/${campaignId}`);
      }
    } catch (error: any) {
      return {
        [FORM_ERROR]: error?.message ?? 'Unable to create campaign'
      };
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      aria-labelledby="campaign-template-dialog-title"
    >
      <DialogTitle id="campaign-template-dialog-title" onClose={onClose}>
        Create your campaign
      </DialogTitle>

      <DialogContent>
        {isLoading && <CircularProgress />}
        {!isLoading && (
          <Form
            onSubmit={handleSubmit}
            validate={validate}
            initialValues={{
              publish: false,
              template_key: templateKey,
              listing_id: listingId,
              creative_id: creativeId
            }}
            // @ts-ignore
            decorators={!creativeId ? [calculator] : undefined}
          >
            {({ handleSubmit, form, submitting, submitError, values }) => {
              const chosenTemplate = templateData?.templates?.find(
                (template) => template.template_key === values.template_key
              );

              return (
                <form onSubmit={handleSubmit} noValidate>
                  <Box>
                    <Typography variant="subtitle1">Select your campaign template</Typography>
                    <Select name="template_key" label="Campaign template">
                      {templateData?.templates?.map((template) => (
                        <MenuItem value={template.template_key} key={template.template_key}>
                          {template.template_key
                            .replace('-', ' ')
                            .replace(/\b[a-zA-Z]/g, (match) => match.toUpperCase())}
                        </MenuItem>
                      ))}
                    </Select>
                    <MuiLink component={Link} to="/campaigns/templates" gutterBottom>
                      Don't see any campaign templates? Click here to set them up
                    </MuiLink>
                  </Box>
                  <Box mt={1}>
                    <PropertyAutocomplete
                      name="listing_id"
                      label="Select your Listing"
                      status={['current', 'sold', 'leased']}
                    />

                    <CreativeAutocomplete
                      name="creative_id"
                      label="Select your Creative"
                      listingId={values.listing_id}
                      creativeId={creativeId}
                      chosenTemplate={chosenTemplate}
                    />
                  </Box>

                  <Box my={1}>
                    {chosenTemplate && (
                      <Typography variant="caption">
                        Your campaign will run for <strong>{chosenTemplate?.duration} days</strong>{' '}
                        with a{' '}
                        <strong>
                          {numbro(chosenTemplate.spend_cap ?? 0).formatCurrency({
                            thousandSeparated: true
                          })}{' '}
                          total budget
                        </strong>
                        .
                      </Typography>
                    )}
                  </Box>

                  {submitError && (
                    <Box mt={2}>
                      <Typography variant="caption" color="error">
                        {submitError}
                      </Typography>
                    </Box>
                  )}

                  <Box mt={2} display="flex" justifyContent="flex-end">
                    <ButtonGroup variant="contained" color="secondary" disabled={submitting}>
                      <Button
                        startIcon={
                          submitting ? <CircularProgress size={16} color="inherit" /> : undefined
                        }
                        type="submit"
                        onClick={() => {
                          form.change('publish', false);
                        }}
                      >
                        Edit Campaign
                      </Button>
                      <Button
                        startIcon={
                          submitting ? <CircularProgress size={16} color="inherit" /> : undefined
                        }
                        type="submit"
                        onClick={() => {
                          form.change('publish', true);
                        }}
                      >
                        Publish Campaign
                      </Button>
                    </ButtonGroup>
                  </Box>
                </form>
              );
            }}
          </Form>
        )}
      </DialogContent>
    </Dialog>
  );
}

const Avatar = styled.img`
  width: 60px;
  margin-right: 8px;
`;

const AvatarContainer = styled.div`
  display: flex;
  align-items: center;
`;

// Creative autocomplete function
interface CreativeAutocompleteProps {
  name: string;
  label: string;
  listingId?: string | null;
  creativeId?: string | null;
  chosenTemplate?: CampaignTemplatesQuery['templates'][0];
}

function CreativeAutocomplete({
  name,
  label,
  listingId,
  creativeId,
  chosenTemplate
}: CreativeAutocompleteProps) {
  const { workspaceMemberContext } = useHasuraRoleContext();
  const { activeWorkspaceId, userId, isWorkspaceAgent } = useUserContext();
  const agentPermissions = useAgentPermissions();

  const where: Workspace_Creatives_Available_Bool_Exp = {
    _and: [
      {
        workspace_id: { _eq: activeWorkspaceId }
      },
      {
        ready: { _eq: true }
      }
    ]
  };

  // Filter creatives based on listing if listing is selected.
  if (listingId) {
    where._and!.push({
      listings: { listing_id: { _eq: listingId } }
    });
  }

  // Filter for agents
  if (isWorkspaceAgent) {
    where._and!.push({
      _or: [
        {
          created_by_id: { _eq: userId }
        },
        {
          agent_permission_mode: { _eq: Workspace_Resource_Permission_Modes_Enum.All }
        },
        {
          listings: {
            listing: {
              agents: { user: { display_name: { _in: agentPermissions.allowed_agents } } }
            }
          }
        },
        {
          assigned_users: {
            user_id: { _eq: userId }
          }
        },
        {
          head_office_creative: {}
        }
      ]
    });
  }

  // Filter creatives based on platform and objective of selected campaign template
  const platforms: ('facebook' | 'googleads')[] = chosenTemplate?.ad_platforms ?? [];
  const objective: Workspace_Campaign_Objectives_Enum =
    chosenTemplate?.objective ?? Workspace_Campaign_Objectives_Enum.Reach;

  if (platforms.length && objective) {
    const supportedTypes = getSupportCreativeTypes(objective as unknown as Objective, platforms);
    where._and!.push({
      type: { _in: supportedTypes }
    });
  }

  // Filter based on selected creative
  if (creativeId) {
    where._and!.push({
      id: { _eq: creativeId }
    });
  }

  const { data, loading } = useCreativeAutocompleteQuery({
    variables: {
      where: where
    },
    fetchPolicy: 'cache-and-network',
    context: workspaceMemberContext
  });

  const options = data?.creatives ?? [];

  return (
    <Autocomplete
      name={name}
      label={label}
      options={options}
      loading={loading}
      getOptionValue={(creative: any) => creative.id}
      getOptionLabel={(creative: any) => creative.title ?? creative.id}
      renderOption={(creative: any) => {
        const thumbnailUrl =
          creativeThumbnailFromCreative(creative) ?? '/images/PropertiMasterLogo.png';
        const type = creativeTypeTextFromCreative(creative);
        const date = moment(creative.created_at).fromNow();

        return (
          <AvatarContainer>
            <Avatar alt={creative.title} src={thumbnailUrl} />
            <span>
              <Typography>{creative.title}</Typography>
              <Typography variant="caption" color="textSecondary">
                {type} created {date}
              </Typography>
            </span>
          </AvatarContainer>
        );
      }}
    />
  );
}
