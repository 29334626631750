import React from 'react';

import { MenuItem } from '@material-ui/core';

import { Select } from 'mui-rff';

import { Workspace_Content_Type_Enum } from 'generated/graphql';

interface FinalFormContentTypeSelectProps {
  name?: string;
  label?: string;
  helperText?: string;
  disabled?: boolean;
}

export default function FinalFormContentTypeSelect({
  name = 'content_type',
  label = 'Content Category',
  helperText,
  disabled = false
}: FinalFormContentTypeSelectProps) {
  return (
    <Select
      name={name}
      label={label}
      helperText={helperText}
      defaultValue={Workspace_Content_Type_Enum.Other}
      disabled={disabled}
      fullWidth
    >
      <MenuItem value={Workspace_Content_Type_Enum.AuctionClearanceRates}>
        Auction Clearance Update
      </MenuItem>
      <MenuItem value={Workspace_Content_Type_Enum.AuthorityBuilding}>Authority Building</MenuItem>
      <MenuItem value={Workspace_Content_Type_Enum.CallToAction}>Call to Action</MenuItem>
      <MenuItem value={Workspace_Content_Type_Enum.Listing}>Listing</MenuItem>
      <MenuItem value={Workspace_Content_Type_Enum.MarketData}>Market Data</MenuItem>
      <MenuItem value={Workspace_Content_Type_Enum.NewsUpdate}>News Articles</MenuItem>
      <MenuItem value={Workspace_Content_Type_Enum.Personal}>Personal</MenuItem>
      <MenuItem value={Workspace_Content_Type_Enum.SocialProof}>
        Testimonials & Social Proof
      </MenuItem>
      <MenuItem value={Workspace_Content_Type_Enum.GenericPost}>Generic Post</MenuItem>
      <MenuItem value={Workspace_Content_Type_Enum.Other}>Other</MenuItem>
    </Select>
  );
}
