import { Link } from 'react-router-dom';

import { Box, Button } from '@material-ui/core';
import ReceiptIcon from '@material-ui/icons/Receipt';

import EmptyStatePage from 'components/EmptyStatePage';

import { useAdminModeContext } from 'lib/AdminModeContext';

interface SubscriptionRequiredWarningProps {
  text?: string;
  disabled: boolean;
  onClickIgnore?: () => void;
}
export default function SubscriptionRequiredWarning({
  text = 'You required an active subscription with a valid payment method to view this content. Open your billing portal to add a valid payment method',
  disabled,
  onClickIgnore
}: SubscriptionRequiredWarningProps) {
  const { isAdminMode } = useAdminModeContext();

  return (
    <Box>
      <EmptyStatePage
        title="Active Subscription Required"
        text={text}
        icon={<ReceiptIcon color="secondary" fill="none" style={{ height: 64, width: 64 }} />}
        button={
          <>
            <Button
              variant="contained"
              color="secondary"
              component={Link}
              to="/billing#subscription"
              size="large"
              fullWidth
              disabled={disabled}
            >
              Manage Billing
            </Button>
            {isAdminMode && (
              <Button
                style={{ marginTop: 8 }}
                variant="contained"
                color="primary"
                onClick={onClickIgnore}
                fullWidth
              >
                Ignore as Properti Admin
              </Button>
            )}
          </>
        }
      />
    </Box>
  );
}
