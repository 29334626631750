import { useCallback, useEffect, useMemo, useState } from 'react';

import { useNavigate } from 'react-router-dom';

import { Box, Button, Link, Typography } from '@material-ui/core';

import { useConfirm } from 'material-ui-confirm';

import { useHideOnboardingMutation } from 'generated/graphql';

import {
  WizardPageBody,
  WizardPageContainer,
  WizardPageTopActionContainer
} from 'components/WizardPage/ContentComponents';
import WizardDrawer from 'components/WizardPage/WizardDrawer';
import WizardStepper from 'components/WizardPage/WizardStepper';

import { useHasuraRoleContext } from 'lib/HasuraRoleContext';
import { APP_CONFIG } from 'lib/app/config';
import { getFilePath } from 'lib/auth/hbp';
import { useFeatureFlags } from 'lib/feature-flags';
import useUserContext from 'lib/hooks/useUserContext';

import { AddPaymentMethodSection } from './components/AddPaymentMethodSection';
import ConnectFacebookSection from './components/ConnectFacebookSection';
import KnowledgeBaseSection from './components/KnowledgeBaseSection';
import SelectSubscriptionSection from './components/SelectSubscriptionSection';

export default function QuickStartPage() {
  const navigate = useNavigate();
  const confirm = useConfirm();

  const { activeWorkspaceId, workspace, isWorkspaceAdmin, refetchUserData } = useUserContext();
  const { workspaceMemberContext } = useHasuraRoleContext();
  const { reloadFlags } = useFeatureFlags();

  const [hideOnboarding] = useHideOnboardingMutation({
    context: workspaceMemberContext!
  });

  const hasHeadOffice = useMemo(
    () => (workspace?.head_offices?.length ?? 0) > 0,
    [workspace?.head_offices]
  );
  const logoSrc = useMemo(
    () => getFilePath(workspace?.branding?.logo_url),
    [workspace?.branding?.logo_url]
  );

  const [activeStep, setActiveStep] = useState<number>(0);

  const handleOnPaymentMethodAdded = useCallback(() => {
    setActiveStep((currentStep) => Math.max(currentStep, 1));
  }, [setActiveStep]);

  const handleOnSubscriptionSelected = useCallback(() => {
    setActiveStep((currentStep) => Math.max(currentStep, 2));
  }, [setActiveStep]);

  const handleOnFacebookConnected = useCallback(async () => {
    setActiveStep((currentStep) => Math.max(currentStep, 3));
  }, [setActiveStep]);

  const handleFinishQuickStart = useCallback(async () => {
    // Hide Onboarding Prompt from ever showing again
    try {
      await hideOnboarding({
        variables: {
          workspace_id: activeWorkspaceId!,
          hide_onboarding: true
        }
      });
      await refetchUserData();
      await reloadFlags();
    } catch (error: any) {
      console.error(error);
    }

    // Navigate to dashboard
    navigate('/dashboard');
  }, [hideOnboarding, activeWorkspaceId, refetchUserData, reloadFlags, navigate]);

  const handleExitQuickStart = useCallback(async () => {
    try {
      await confirm({
        title: 'Exit quick start',
        description: 'Are you sure you want to exit this quick start guide?'
      });

      await refetchUserData();
      await reloadFlags();

      navigate('/dashboard');
    } catch (error: any) {
      // Noop
    }
  }, [confirm, refetchUserData, reloadFlags, navigate]);

  useEffect(() => {
    if (workspace?.hide_onboarding || !isWorkspaceAdmin) {
      navigate('/dashboard', { replace: true });
    }
  }, [workspace?.hide_onboarding, isWorkspaceAdmin, navigate]);

  return (
    <WizardPageContainer>
      <WizardDrawer>
        <Typography variant="h6" style={{ paddingLeft: 24 }}>
          {workspace?.name}
        </Typography>
        <Typography variant="subtitle1" style={{ paddingLeft: 24 }}>
          Quick Start
        </Typography>
        <Box mt={2}>
          <WizardStepper
            activeStep={activeStep}
            steps={[
              'Payment Method',
              'Subscription and Features',
              'Facebook Connection',
              'Helpful Links'
            ]}
          />
        </Box>
      </WizardDrawer>
      <WizardPageBody>
        <WizardPageTopActionContainer>
          <Button
            color="inherit"
            style={{ minWidth: 160 }}
            variant="contained"
            size="large"
            onClick={handleExitQuickStart}
          >
            Exit Quick Start
          </Button>
        </WizardPageTopActionContainer>

        <Box maxWidth={800}>
          <Typography variant="h4" gutterBottom>
            Let's get started
          </Typography>

          {hasHeadOffice ? (
            <>
              <Typography gutterBottom>
                Your head office has selected{' '}
                <Link href="https://properti.ai" target="_blank">
                  Properti
                </Link>{' '}
                as your social media distribution platform. By connecting your social accounts, your
                head office will be able to post marketing material on your behalf. You may also
                upgrade your account to unlock more marketing power.
              </Typography>
              <Box
                mt={2}
                mb={2}
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-around'
                }}
              >
                <img width={200} src={logoSrc} alt="Brand logo" />
                <img width={200} src={APP_CONFIG.SITE_LOGO_URL_WIDE} alt="Properti Logo" />
              </Box>
            </>
          ) : (
            <Typography gutterBottom>
              Welcome to Properti, we are excited to get you started and streamlining your social
              media marketing efforts!
            </Typography>
          )}
        </Box>

        {activeStep >= 0 && (
          <Box mt={4}>
            <AddPaymentMethodSection onPaymentMethodAdded={handleOnPaymentMethodAdded} />
          </Box>
        )}
        {activeStep >= 1 && (
          <Box mt={4}>
            <SelectSubscriptionSection onSubscriptionSelected={handleOnSubscriptionSelected} />
          </Box>
        )}
        {activeStep >= 2 && (
          <Box mt={4}>
            <ConnectFacebookSection onFacebookConnected={handleOnFacebookConnected} />
          </Box>
        )}
        {activeStep >= 3 && (
          <Box mt={4}>
            <KnowledgeBaseSection onFinish={handleFinishQuickStart} />
          </Box>
        )}
      </WizardPageBody>
    </WizardPageContainer>
  );
}
