import styled from 'styled-components';

interface AddonContainerProps {
  hasError: boolean;
  hasPostingFromHeader: boolean;
  children: React.ReactNode;
}

export const AddonContainer: React.FC<AddonContainerProps> = ({
  hasError,
  hasPostingFromHeader,
  children
}) => {
  let top = 50;

  if (hasError) {
    top += 24;
  }

  if (hasPostingFromHeader) {
    top += 30;
  }

  return <AddonContainerDiv top={`-${top}px`}>{children}</AddonContainerDiv>;
};

const AddonContainerDiv = styled.div.attrs((props: any) => ({
  top: props.top || '-80px'
}))`
  position: relative;
  top: ${(props) => props.top};
  height: 35px;
  display: flex;
  justify-content: space-between;
  align-content: center;
  align-items: center;
  margin-left: 8px;
  margin-right: 8px;
  border-top: 1px solid #ebeff2;
`;
