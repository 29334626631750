import { ApolloClient, ApolloLink, HttpLink, InMemoryCache, split } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { getMainDefinition } from '@apollo/client/utilities';

import logdna from '@logdna/browser';

import { createClient } from 'graphql-ws';

import { auth } from '../auth/hbp';

const { REACT_APP_HASURA_GRAPHQL_ENDPOINT, REACT_APP_HASURA_GRAPHQL_ENDPOINT_WS } = process.env;

export const buildApolloClient = () => {
  const cache = new InMemoryCache();

  if (!REACT_APP_HASURA_GRAPHQL_ENDPOINT_WS) {
    throw new Error('REACT_APP_HASURA_GRAPHQL_ENDPOINT_WS must be configured.');
  }

  const httpLink = new HttpLink({
    uri: REACT_APP_HASURA_GRAPHQL_ENDPOINT,
    credentials: 'include'
  });

  const webSocketLink = new GraphQLWsLink(
    createClient({
      url: REACT_APP_HASURA_GRAPHQL_ENDPOINT_WS,
      connectionParams: () => ({
        headers: {
          authorization: `Bearer ${auth.getJWTToken()}`
        }
      })
    })
  );

  const middlewareLink = new ApolloLink((operation, forward) => {
    const context = operation.getContext();
    const headers = context.headers;
    operation.setContext(() => {
      return {
        headers: {
          authorization: `Bearer ${auth.getJWTToken()}`,
          ...headers
        }
      };
    });

    return forward(operation);
  });

  const authHttpLink = middlewareLink.concat(httpLink);

  const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
      graphQLErrors.forEach(({ message, locations, path, ...other }) => {
        console.error(
          `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
        );
        logdna.debug('GraphQL Error', {
          message,
          locations,
          path,
          ...other
        });
      });
    }

    if (networkError) {
      console.error(`[Network error]`, networkError.name, networkError.message, networkError.stack);
      logdna.warn('Network Error', {
        ...networkError
      });
    }
  });

  const link = split(
    ({ query }) => {
      const definition = getMainDefinition(query);
      return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
    },
    webSocketLink,
    authHttpLink
  );

  const client = new ApolloClient({
    link: errorLink.concat(link),
    cache
  });

  return client;
};
