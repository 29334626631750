import { useCallback, useState } from 'react';

export default function useAssignAgentsAsset(refetch?: () => any) {
  const [assignAssetId, setAssignAssetId] = useState<string | null>(null);
  const [assignAgentAssetDialogOpen, setAssignAgentAssetDialogOpen] = useState<boolean>(false);

  const handleOpenAssignAgentsAssetDialog = useCallback(
    (assetId: string) => {
      setAssignAssetId(assetId);
      setAssignAgentAssetDialogOpen(true);
    },
    [setAssignAgentAssetDialogOpen, setAssignAssetId]
  );

  const handleCloseAssignAgentsAssetDialog = useCallback(() => {
    setAssignAgentAssetDialogOpen(false);
    setAssignAssetId(null);
    if (refetch) {
      refetch();
    }
  }, [setAssignAgentAssetDialogOpen, setAssignAssetId, refetch]);

  return {
    assignAssetId,
    assignAgentAssetDialogOpen,
    handleOpenAssignAgentsAssetDialog,
    handleCloseAssignAgentsAssetDialog
  };
}
