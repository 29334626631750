import { Form } from 'react-final-form';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import { Box, Button, Grid, Link as MuiLink, Paper, Typography } from '@material-ui/core';

import { FORM_ERROR } from 'final-form';
import { makeValidate, TextField } from 'mui-rff';
import qs from 'query-string';
import * as Yup from 'yup';

import { APP_CONFIG } from 'lib/app/config';
import { auth } from 'lib/auth/hbp';

import useAuthStyles from '../_styles';
import SSOLoginOptions, { ContinueWithSSOButton } from '../components/SSOLoginOptions';
import { findSSODomain } from '../utils/sso';

const schema = Yup.object({
  email: Yup.string().email().trim().required().label('Email address'),
  password: Yup.string().required().label('Password')
}).required();

type FormSchema = Yup.InferType<typeof schema>;

const validate = makeValidate(schema);

const LoginPage = () => {
  const classes = useAuthStyles();
  const navigate = useNavigate();
  const location = useLocation();

  const { back_url } = qs.parse(location.search);

  const from = location.state?.from;
  const search = location.state?.search;

  let navigateUrl = back_url ?? '/dashboard';
  if (from) {
    navigateUrl = `${from}${search ?? ''}`;
  }
  const backUrl = Array.isArray(navigateUrl) ? navigateUrl[0] : navigateUrl;

  const handleFormSubmit = async (values: FormSchema) => {
    try {
      await auth.login(values?.email, values?.password);
      navigate(backUrl ?? '/dashboard');
    } catch (error: any) {
      const message = error?.response?.data?.message ?? 'Unknown Error Occurred';
      return { [FORM_ERROR]: message };
    }
  };

  return (
    <div className={classes.content}>
      <Paper elevation={1} className={classes.box}>
        <img
          className={classes.logo}
          src={APP_CONFIG.SITE_LOGO_URL_WIDE}
          alt=""
          style={{ width: 160 }}
        />
        <div className={classes.formWrapper}>
          <Form
            onSubmit={handleFormSubmit}
            validate={validate}
            render={({ handleSubmit, submitting, submitError, values, errors }) => {
              const emailDomain = values.email?.split('@').pop();
              const isValidEmail = !Boolean(errors?.email);

              const foundSSOProvider = emailDomain ? findSSODomain(emailDomain) : null;
              const hasSSOProvider = Boolean(foundSSOProvider);
              const ssoRequired = foundSSOProvider?.sso_required === true;
              const showPasswordLoginButton = !hasSSOProvider || !ssoRequired;

              return (
                <form onSubmit={handleSubmit} noValidate>
                  <Typography variant="h3" color="secondary" gutterBottom>
                    Sign In
                  </Typography>
                  <Typography gutterBottom>
                    Welcome back to your Real Estate marketing machine.
                  </Typography>
                  <Grid container spacing={1}>
                    <Grid item xs={12} sm={12}>
                      <TextField
                        style={{ height: 60 }}
                        name="email"
                        label="Email"
                        type="email"
                        autoComplete="email"
                      />
                    </Grid>
                    {!ssoRequired && (
                      <Grid item xs={12} sm={12}>
                        <TextField
                          style={{ height: 60 }}
                          name="password"
                          label="Password"
                          type="password"
                        />
                      </Grid>
                    )}
                  </Grid>
                  <Box mb={1}>
                    {showPasswordLoginButton && (
                      <Button
                        type="submit"
                        fullWidth
                        size="large"
                        variant="contained"
                        color="secondary"
                        className={classes.submit}
                        disabled={submitting}
                      >
                        {submitting ? 'Loading ...' : 'Sign In'}
                      </Button>
                    )}
                    {hasSSOProvider && (
                      <ContinueWithSSOButton
                        provider={foundSSOProvider?.provider!}
                        disabled={!isValidEmail}
                        redirectPath={backUrl}
                      />
                    )}
                  </Box>
                  {submitError && (
                    <Grid container spacing={2}>
                      <Grid item>
                        <Typography color="error" variant="subtitle2">
                          {submitError}
                        </Typography>
                      </Grid>
                    </Grid>
                  )}
                  <Box mt={2} mb={1}>
                    <Typography className={classes.buttonCaption} variant="caption" gutterBottom>
                      By signing in you agree to {APP_CONFIG.SITE_TITLE}'s{' '}
                      <MuiLink
                        href={APP_CONFIG.SITE_TERMS_URL}
                        color="secondary"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Terms of Service
                      </MuiLink>{' '}
                      and its{' '}
                      <MuiLink
                        href={APP_CONFIG.SITE_PRIVACY_URL}
                        color="secondary"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Privacy Policy
                      </MuiLink>
                      .
                    </Typography>
                  </Box>
                </form>
              );
            }}
          />
          <SSOLoginOptions redirectPath={backUrl} />
        </div>
        <Grid container direction="column" justifyContent="space-between" alignItems="center">
          <Grid className={classes.extraItem} item xs>
            <Typography variant="body2" gutterBottom>
              Forgot your password?{' '}
              <MuiLink
                component={Link}
                color="secondary"
                to="/forgot-password"
              >{`Recover account`}</MuiLink>
            </Typography>{' '}
          </Grid>
          <Grid className={classes.extraItem} item xs>
            <Typography variant="body2" gutterBottom>
              Don't have an account?{' '}
              <MuiLink component={Link} color="secondary" to="/register">{`Sign up`}</MuiLink>
            </Typography>{' '}
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
};

export default LoginPage;
