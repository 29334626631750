import { useEffect } from 'react';

import { useForm, useFormState } from 'react-final-form';

import intersection from 'lodash/intersection';

import { Objective } from './objective';

export const platform = {
  facebook: {
    objectives: [
      Objective.REACH,
      Objective.ENGAGEMENT,
      Objective.VIDEO_VIEWS,
      Objective.WEBSITE_VISITS,
      Objective.MESSAGES,
      Objective.CONVERSIONS,
      Objective.LEADGEN,
      Objective.PHONE_CALL
    ]
  },
  googleads: {
    objectives: [Objective.REACH, Objective.WEBSITE_VISITS, Objective.CONVERSIONS]
  }
};

export const platformMap = {
  facebook: 'Facebook',
  googleads: 'Google'
};

export function mapPlatforms(platform: keyof typeof platformMap) {
  return platformMap[platform];
}

export function useSelectedPlatforms() {
  const formState = useFormState();
  const platforms = (formState.values.ad_platforms ?? []) as (keyof typeof platform)[];

  function isSelected(name: keyof typeof platform) {
    return platforms.includes(name);
  }

  return [platforms, isSelected] as const;
}

export function useSelectedObjective(): Objective {
  const formState = useFormState();
  return formState.values.objective ?? Objective.REACH;
}

export function useSupportedObjectives() {
  const form = useForm();
  const objective = useSelectedObjective();
  const [platforms] = useSelectedPlatforms();
  const supported = intersection(...platforms.map((key) => platform[key].objectives));

  function isSupported(objective: Objective) {
    return supported.includes(objective);
  }

  useEffect(() => {
    // If current value is not supported, default to first supported value
    if (!isSupported(objective)) {
      form.change('objective', supported[0]);
    }
  });

  return [isSupported] as const;
}
