import { useMemo, useState } from 'react';

import {
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
  IconButton,
  Tooltip,
  Typography
} from '@material-ui/core';
import GroupIcon from '@material-ui/icons/Group';

import BullhornIcon from 'mdi-material-ui/BullhornOutline';
import CommentEditIcon from 'mdi-material-ui/CommentEditOutline';

import {
  AttachmentFieldsFragment,
  DynamicCreativeFieldsFragment,
  Workspace_Creative_Types_Enum
} from 'generated/graphql';

import CampaignFromListingOrCreativeDialog from 'components/campaigns/templates/CampaignFromListingOrCreativeDialog';
import AssignAgentsAssetDialog from 'components/workflows/AssignAgentsAssetDialog';

import { getFilePath } from 'lib/auth/hbp';
import useAssignAgentsAsset from 'lib/hooks/useAssignAgentsAsset';
import useUserContext from 'lib/hooks/useUserContext';

import DynamicCreativePreview from '../DynamicCreativePreview';
import EditCreativeDrawer from '../EditCreativeDrawer';
import TagHolder, { Tag } from '../TagHolder';
import useImageCreativeCardStyles from './_styles';

interface DynamicCreativeCardProps {
  id: string;
  title: string;
  ready: boolean;
  date: string;
  tags: Tag[];
  attachments: AttachmentFieldsFragment[];
  dynamic: DynamicCreativeFieldsFragment;
  type: Workspace_Creative_Types_Enum.Dynamic;
  onRefetchRequired?: () => void;
  disableActionButtons?: boolean;
  createdByUserId?: string | null;
}

export default function DynamicCreativeCard({
  id,
  title,
  ready,
  date,
  tags,
  attachments,
  dynamic,
  onRefetchRequired,
  disableActionButtons = false,
  createdByUserId
}: DynamicCreativeCardProps) {
  const { userId, isWorkspaceAdmin } = useUserContext();
  const classes = useImageCreativeCardStyles();

  const [previewOpen, setPreviewOpen] = useState<boolean>(false);
  const [editOpen, setEditOpen] = useState<boolean>(false);
  const [boostModelOpen, setBoostModelOpen] = useState<boolean>(false);

  const {
    assignAgentAssetDialogOpen,
    handleOpenAssignAgentsAssetDialog,
    handleCloseAssignAgentsAssetDialog
  } = useAssignAgentsAsset(onRefetchRequired);

  const handleClickCard = () => {
    if (!attachments || attachments.length === 0) {
      handleOpenEditDrawer();
    } else {
      setPreviewOpen(true);
    }
  };

  const handleOpenEditDrawer = () => {
    setEditOpen(true);
  };

  const handleCloseEditDrawer = () => {
    setEditOpen(false);
    if (onRefetchRequired) {
      onRefetchRequired();
    }
  };

  const handleClosePreview = () => {
    setPreviewOpen(false);
  };

  const handleOpenCreateFromTemplateModal = () => {
    setBoostModelOpen(true);
  };

  const handleCloseCreateFromTemplateModal = () => {
    setBoostModelOpen(false);
  };

  const mainImageUrl = useMemo(() => {
    if (!ready) {
      return '/videos/loading.jpg';
    }

    const images =
      attachments?.map((attachment) =>
        attachment.content_type?.includes('video/')
          ? getFilePath(attachment.generated_thumbnail_path)
          : getFilePath(attachment.path)
      ) ?? [];
    let mainImageUrl = images[0];

    if (!mainImageUrl) {
      mainImageUrl = '/images/PropertiMasterLogo.png';
    }

    return mainImageUrl;
  }, [attachments, ready]);

  const allAssetsReady = useMemo(() => {
    const hasVideos = dynamic.all_attachments.some((attachments) =>
      attachments.content_type.includes('video/')
    );

    // No videos to check
    if (!hasVideos) {
      return true;
    }

    const someVideosPending = dynamic.all_attachments.some(
      (attachment) => attachment.content_type.includes('video/') && !attachment.facebook_asset_id
    );

    if (someVideosPending) {
      return false;
    }

    return true;
  }, [dynamic.all_attachments]);

  return (
    <>
      <Card className={classes.root} elevation={2}>
        <CardActionArea disabled={!ready} onClick={handleClickCard}>
          <CardMedia className={classes.media} title={title} image={mainImageUrl}>
            <TagHolder tags={tags} />
          </CardMedia>
        </CardActionArea>
        <CardContent>
          <Typography variant="overline">{date}</Typography>
          <Typography variant="h6">{title}</Typography>
        </CardContent>
        <CardActions>
          <Tooltip title="Boost Creative">
            <IconButton
              aria-label="boost creative"
              onClick={handleOpenCreateFromTemplateModal}
              disabled={disableActionButtons || !ready}
            >
              <BullhornIcon />
            </IconButton>
          </Tooltip>
          <Tooltip style={{ marginLeft: 'auto' }} title="Assign Agents">
            <IconButton
              aria-label="Assign Agents"
              onClick={() => handleOpenAssignAgentsAssetDialog(id)}
              disabled={disableActionButtons || (createdByUserId !== userId && !isWorkspaceAdmin)}
            >
              <GroupIcon />
            </IconButton>
          </Tooltip>

          <Tooltip title="Edit Ad Copy">
            <IconButton
              onClick={handleOpenEditDrawer}
              aria-label="Edit Ad Copy"
              disabled={disableActionButtons}
            >
              <CommentEditIcon />
            </IconButton>
          </Tooltip>
        </CardActions>
      </Card>
      <EditCreativeDrawer creativeId={id} open={editOpen} onClose={handleCloseEditDrawer} />
      <CampaignFromListingOrCreativeDialog
        open={boostModelOpen}
        onClose={handleCloseCreateFromTemplateModal}
        creativeId={id}
      />
      <DynamicCreativePreview
        open={previewOpen}
        onClose={handleClosePreview}
        creativeId={id}
        title={title}
        allAssetsReady={allAssetsReady}
      />
      <AssignAgentsAssetDialog
        open={assignAgentAssetDialogOpen}
        onClose={handleCloseAssignAgentsAssetDialog}
        assetId={id}
        assetType="creative"
      />
    </>
  );
}
