import { useRef } from 'react';

import { Portal } from '@material-ui/core';

import '@uppy/core/dist/style.css';
import '@uppy/dashboard/dist/style.css';
import '@uppy/image-editor/dist/style.css';
import { DashboardModal } from '@uppy/react';
import { DashboardModalProps } from '@uppy/react/src/DashboardModal';
import '@uppy/url/dist/style.css';

import { FileAttachment } from 'components/types';

import { useUppyInstance } from './hook';
import {
  DEFAULT_MAX_IMAGE_SIZE,
  DEFAULT_MAX_VIDEO_SIZE,
  UppyFileCheckOptions,
  UppyImageCompressionOptions,
  UppyImageEditorActions,
  UppyRestrictions
} from './utils';

export interface UppyUploaderProps {
  workspaceId: string;
  allowMultipleFileTypes?: boolean;
  baseFileUploadPath: string;
  onUppyCompleteSuccess?: (files: FileAttachment[]) => void;
  uppyRestrictions?: UppyRestrictions;
  uppyDashboardProps: Omit<DashboardModalProps, 'uppy' | 'plugins'>;
  uppyImageCompressionOptions?: UppyImageCompressionOptions;
  uppyImageEditorActions?: UppyImageEditorActions;
  uppyFileCheckOptions?: UppyFileCheckOptions;
}

export function UppyUploader({
  baseFileUploadPath,
  uppyRestrictions,
  onUppyCompleteSuccess,
  uppyDashboardProps,
  allowMultipleFileTypes = false,
  uppyImageCompressionOptions,
  uppyImageEditorActions,
  uppyFileCheckOptions = {
    maxImageSize: DEFAULT_MAX_IMAGE_SIZE,
    maxVideoSize: DEFAULT_MAX_VIDEO_SIZE
  }
}: UppyUploaderProps) {
  const container = useRef(null);
  const uppy = useUppyInstance({
    baseFileUploadPath,
    uppyRestrictions,
    onUppyCompleteSuccess,
    allowMultipleFileTypes,
    uppyFileCheckOptions,
    uppyImageCompressionOptions,
    uppyImageEditorActions
  });

  return (
    <Portal container={container.current}>
      <div style={{ zIndex: 2000, position: 'fixed' }}>
        <DashboardModal
          uppy={uppy}
          plugins={['ImageEditor', 'Url', 'Dropbox', 'GoogleDrive', 'Canva']}
          showProgressDetails
          proudlyDisplayPoweredByUppy={false}
          autoOpenFileEditor
          note="Image max size 30MB. Video max size 1GB."
          {...uppyDashboardProps}
        />
      </div>
    </Portal>
  );
}
