import jwt_decode from 'jwt-decode';
import isEqual from 'lodash/isEqual';

export default class JWTMemory {
  private jwt_token: string | undefined;
  private claims: any;
  private claims_changed_functions: Function[];

  constructor() {
    this.claims_changed_functions = [];
  }

  public setJWT(jwt_token: string) {
    this.jwt_token = jwt_token;
    const jwt_token_encoded = jwt_decode(jwt_token) as any;

    const oldClaims = this.claims;
    const newClaims = jwt_token_encoded['https://hasura.io/jwt/claims'];
    this.claims = newClaims;

    if (!isEqual(oldClaims, newClaims)) {
      localStorage.setItem('active_workspace_id', this.getClaim('x-hasura-active-workspace-id'));
      const activeAdminWorkspaceId = this.getClaim('x-hasura-active-admin-workspace-id');

      if (activeAdminWorkspaceId !== 'null') {
        localStorage.setItem(
          'active_admin_workspace_id',
          this.getClaim('x-hasura-active-admin-workspace-id')
        );
      } else {
        localStorage.removeItem('active_admin_workspace_id');
      }

      this.claims_changed_functions.forEach((fn) => {
        fn();
      });
    }
  }

  public getJWT(): string {
    if (!this.jwt_token) {
      throw new Error('JWT Token not Set');
    }

    return this.jwt_token;
  }

  public getClaim(claim: string): string {
    return this.claims[claim];
  }

  public clearJWT(): void {
    this.jwt_token = undefined;
  }

  public onClaimsChanged(fn: Function): void {
    this.claims_changed_functions.push(fn);
  }
}
