import { useEffect, useMemo, useState } from 'react';

import { Outlet } from 'react-router';
import { Link } from 'react-router-dom';

import { Box, Button } from '@material-ui/core';
import ErrorIcon from '@material-ui/icons/Error';
import LockIcon from '@material-ui/icons/Lock';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';

import EmptyStatePage from 'components/EmptyStatePage';
import LoadingStatePage from 'components/LoadingStatePage';

import { useAdminModeContext } from 'lib/AdminModeContext';
import useUserContext from 'lib/hooks/useUserContext';

import useFeatureFlags from './useFeatureFlagsContext';
import { useIsFeatureFlagEnabled } from './useIsFeatureFlagEnabled';

interface FeatureFlagOutletProps {
  featureFlagName: string;
  continueAsAdmin: boolean;
  onClickIgnore?: () => void;
}

export default function FeatureFlagOutlet({
  featureFlagName,
  continueAsAdmin,
  onClickIgnore
}: FeatureFlagOutletProps) {
  const { isWorkspaceAdmin } = useUserContext();
  const { isAdminMode } = useAdminModeContext();
  const { flagsLoading, flagsError } = useFeatureFlags();
  const isFeatureEnabled = useIsFeatureFlagEnabled(featureFlagName);

  const isBillingDisabled = useMemo(() => !Boolean(isWorkspaceAdmin), [isWorkspaceAdmin]);

  const [showLoadingIndicator, setShowLoadingIndicator] = useState<boolean>(false);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setShowLoadingIndicator(!flagsLoading);
    }, 500);

    return () => {
      clearTimeout(timeout);
    };
  }, [flagsLoading, setShowLoadingIndicator]);

  if (isFeatureEnabled) {
    return <Outlet />;
  }

  if (continueAsAdmin) {
    return (
      <>
        <Box mb={2}>
          <Alert severity="warning">
            <AlertTitle>Feature not enabled in workspace, viewing as admin</AlertTitle>
          </Alert>
        </Box>
        <Outlet />
      </>
    );
  }

  // Show loading indicator whilst loading
  // This should only occur on the initial page load
  if (flagsLoading && !showLoadingIndicator) {
    return null;
  } else if (flagsLoading && showLoadingIndicator) {
    // The show loading indicator is delayed 0.5 seconds to provide a less "jerky" experience
    return <LoadingStatePage />;
  }

  if (flagsError) {
    return (
      <Box>
        <EmptyStatePage
          title="Error reaching server"
          text="Please try refreshing your web browser"
          icon={<ErrorIcon color="secondary" fill="none" style={{ height: 64, width: 64 }} />}
          button={
            <Button
              variant="contained"
              color="secondary"
              href="https://status.properti.ai"
              size="large"
              fullWidth
              target="_blank"
              startIcon={<OpenInNewIcon />}
            >
              Open System Status
            </Button>
          }
        />
      </Box>
    );
  }

  return (
    <Box>
      <EmptyStatePage
        title="Feature not enabled"
        text="Upgrade your account to unlock this feature"
        icon={<LockIcon color="secondary" fill="none" style={{ height: 64, width: 64 }} />}
        button={
          <>
            <Button
              variant="contained"
              color="secondary"
              component={Link}
              to="/upgrade"
              size="large"
              fullWidth
              disabled={isBillingDisabled}
            >
              Upgrade to unlock
            </Button>
            {isAdminMode && (
              <Button
                style={{ marginTop: 8 }}
                variant="contained"
                color="primary"
                onClick={onClickIgnore}
                fullWidth
              >
                Ignore as Properti Admin
              </Button>
            )}
          </>
        }
      />
    </Box>
  );
}
