import { ChangeEvent, useContext, useEffect, useState } from 'react';

import { Box, IconButton, MenuItem, Select } from '@material-ui/core';
import RefreshIcon from '@material-ui/icons/Refresh';
import Pagination from '@material-ui/lab/Pagination';

import { useCreativePreviewListQuery, Workspace_Creative_Types_Enum } from 'generated/graphql';

import { useHasuraRoleContext } from 'lib/HasuraRoleContext';
import { UserContext } from 'lib/UserContext';

import SelectGoogleAdsPreview from './SelectGoogleAdsPreview';
import { previewTypes } from './google';
import { usePreviewStyles } from './styles';
import { GoogleAdsPreviewContext } from './utils';

export interface GoogleAdPreviewProps {
  readonly platform: 'googleads';
  creativeIds: string[];
  forceRefreshCount?: number;
}

export default function GoogleAdsPreview({ forceRefreshCount, creativeIds }: GoogleAdPreviewProps) {
  const { workspaceMemberContext } = useHasuraRoleContext();

  const classes = usePreviewStyles();
  const [previewType, setPreviewType] = useState(0);
  const [page, setPage] = useState(0);
  const [refreshCount, setRefreshCount] = useState<number>(0);
  const user = useContext(UserContext);
  const { data, refetch } = useCreativePreviewListQuery({
    variables: {
      workspaceId: user.activeWorkspaceId!,
      ids: creativeIds
    },
    context: workspaceMemberContext,
    partialRefetch: true
  });

  useEffect(() => {
    return () => void refetch();
  }, [forceRefreshCount, refreshCount, refetch]);

  const count = data?.creative.length ?? 0;
  // Handle case where use deselects an ad. Reset the pagination
  useEffect(() => {
    if (page >= count) {
      setPage(0);
    }
  }, [count, page, setPage]);

  if (!data?.creative) {
    return <div>Could not generate preview.</div>;
  }

  const creative = data.creative[page];
  const branding = data.workspace?.branding;

  function handleChange(event: ChangeEvent<any>) {
    setPreviewType(event.target.value as number);
  }

  return (
    <div>
      <Box display="flex" alignContent="center" justifyContent="space-between">
        <div className={classes.controls}>
          {count > 1 && (
            <Pagination
              color="primary"
              page={page + 1}
              count={count}
              onChange={(_, page) => setPage(page - 1)}
              hideNextButton
              hidePrevButton
            />
          )}
        </div>
        {creative?.type === 'responsive' && (
          <div>
            <Select value={previewType} onChange={handleChange}>
              {previewTypes.map((type, index) => (
                <MenuItem value={index} key={index}>
                  {type.label}
                </MenuItem>
              ))}
            </Select>
            <IconButton
              onClick={() => {
                setRefreshCount(refreshCount + 1);
              }}
            >
              <RefreshIcon />
            </IconButton>
          </div>
        )}
      </Box>
      {creative && (
        <GoogleAdsPreviewContext.Provider value={{ creative, branding }}>
          <SelectGoogleAdsPreview
            type={creative.type! as Workspace_Creative_Types_Enum}
            previewType={previewType}
          />
        </GoogleAdsPreviewContext.Provider>
      )}
    </div>
  );
}
