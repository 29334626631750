import { Restrictions, UppyFile } from '@uppy/core';

export const COMPANION_URL = process.env.REACT_APP_UPPY_COMPANION_ENDPOINT!;

// DEFAULT_MAX_IMAGE_SIZE 30MB
export const DEFAULT_MAX_IMAGE_SIZE = 30000000;

// DEFAULT_MAX_VIDEO_SIZE 1GB
export const DEFAULT_MAX_VIDEO_SIZE = 1100000000;

// MAX_REMOTE_URL_FILE_SIZE 8MB
export const MAX_REMOTE_URL_FILE_SIZE = 8000000;

// CONTENT TYPES
export const IMAGE_CONTENT_TYPE = 'image';
export const VIDEO_CONTENT_TYPE = 'video';

export interface UppyImageCompressionOptions {
  quality?: number;
  maxWidth?: number;
  maxHeight?: number;
  type?: 'jpeg' | 'png' | 'auto';
  convertTypes?: string[];
}

export interface UppyFileCheckOptions {
  maxImageSize?: number;
  maxVideoSize?: number;
}

export type UppyRestrictions = Restrictions;

export interface UppyFiles {
  [key: string]: UppyFile<Record<string, unknown>, Record<string, unknown>>;
}

export interface UppyImageEditorActions {
  revert: boolean;
  rotate: boolean;
  granularRotate: boolean;
  flip: boolean;
  zoomIn: boolean;
  zoomOut: boolean;
  cropSquare: boolean;
  cropWidescreen: boolean;
  cropWidescreenVertical: boolean;
}
