import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { useSnackbar } from 'notistack';

import { useSetActiveHeadOfficeIdMutation } from 'generated/graphql';

import LoadingBackdrop from 'components/LoadingBackdrop';

import { HasuraContext, meHasuraContext } from 'lib/HasuraRoleContext';
import useUserContext from 'lib/hooks/useUserContext';

import { auth } from '../auth/hbp';

type HeadOfficeModeContextType = {
  activeHeadOfficeId: string | null;
  isHeadOfficeMode: boolean;
  isHeadOfficeModeAvailable: boolean;
  setHeadOfficeMode: (headOfficeId: string | null, context?: HasuraContext) => void;
};

export const HEAD_OFFICE_MODE_SESSION_STORAGE_KEY = 'properti-head-office-mode';
const defaultContext: HeadOfficeModeContextType = {
  activeHeadOfficeId: null,
  isHeadOfficeMode: false,
  isHeadOfficeModeAvailable: false
} as HeadOfficeModeContextType;

const HeadOfficeModeContext = createContext(defaultContext);

function isHeadOfficeModeAvailable() {
  const allowedRoles = auth.getClaim('x-hasura-allowed-roles');
  return allowedRoles.includes('head-office-user');
}

function hydrateHeadOfficeMode(activeHeadOfficeId: string | null) {
  const isAvailable = isHeadOfficeModeAvailable();

  if (!isAvailable) {
    return false;
  }

  if (activeHeadOfficeId) {
    return true;
  }

  return false;
}

interface HeadOfficeModeContextProviderProps {
  children: React.ReactNode;
}

function HeadOfficeModeContextProvider({ children }: HeadOfficeModeContextProviderProps) {
  const { enqueueSnackbar } = useSnackbar();

  const [backdropLoading, setBackdropLoading] = useState<boolean>(false);
  const [backdropTitle, setBackdropTitle] = useState<string>(`Entering Head Office Mode`);

  const { activeHeadOfficeId, userId } = useUserContext();
  const [setActiveHeadOffice] = useSetActiveHeadOfficeIdMutation({
    context: meHasuraContext
  });

  const [isHeadOfficeMode] = useState<boolean>(() => hydrateHeadOfficeMode(activeHeadOfficeId));
  const [headOfficeModeAvailable, setHeadOfficeModeAvailable] = useState<boolean>(
    isHeadOfficeModeAvailable()
  );

  useEffect(() => {
    auth.onAuthStateChanged((state: boolean) => {
      if (!state) {
        setHeadOfficeModeAvailable(false);
      }

      setHeadOfficeModeAvailable(isHeadOfficeModeAvailable());
    });
  }, [setHeadOfficeModeAvailable]);

  useEffect(() => {
    if (window.sessionStorage) {
      if (isHeadOfficeMode) {
        window.sessionStorage.setItem(HEAD_OFFICE_MODE_SESSION_STORAGE_KEY, 'true');
      } else {
        window.sessionStorage.removeItem(HEAD_OFFICE_MODE_SESSION_STORAGE_KEY);
      }
    }
  }, [isHeadOfficeMode]);

  const setHeadOfficeMode = useCallback(
    async (headOfficeId: string | null, context: HasuraContext = meHasuraContext) => {
      if (!headOfficeModeAvailable && headOfficeId) {
        return;
      }

      setBackdropLoading(true);
      setBackdropTitle(`${headOfficeId ? 'Entering' : 'Leaving'} Head Office Mode`);

      try {
        await setActiveHeadOffice({
          variables: {
            user_id: userId!,
            head_office_id: headOfficeId!
          },
          context: context
        });

        // Let settings propagate
        await new Promise((resolve) => setTimeout(resolve, 2000));
        // Complete refresh window to reload JWT token
        if (headOfficeId) {
          window.location.pathname = '/head-office/dashboard';
        } else {
          window.location.pathname = '/workspaces';
        }
      } catch (error: any) {
        console.error(error);
        enqueueSnackbar('Unable to switch head office mode', { variant: 'error' });
      } finally {
        setBackdropLoading(false);
      }
    },
    [
      setActiveHeadOffice,
      headOfficeModeAvailable,
      userId,
      enqueueSnackbar,
      setBackdropLoading,
      setBackdropTitle
    ]
  );

  const value = useMemo<HeadOfficeModeContextType>(
    () => ({
      activeHeadOfficeId,
      isHeadOfficeMode,
      isHeadOfficeModeAvailable: headOfficeModeAvailable,
      setHeadOfficeMode
    }),
    [activeHeadOfficeId, isHeadOfficeMode, headOfficeModeAvailable, setHeadOfficeMode]
  );

  return (
    <HeadOfficeModeContext.Provider value={value}>
      <>{children}</>
      <LoadingBackdrop title={backdropTitle} open={backdropLoading} />
    </HeadOfficeModeContext.Provider>
  );
}

const useHeadOfficeModeContext = () => useContext(HeadOfficeModeContext);

export { HeadOfficeModeContextProvider, useHeadOfficeModeContext };
