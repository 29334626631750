import { useMemo } from 'react';

import {
  Workspace_User_Roles_Enum,
  Workspace_Users_Bool_Exp,
  useWorkspaceTeamMembersQuery
} from 'generated/graphql';

import { useHasuraRoleContext } from 'lib/HasuraRoleContext';
import { getFilePath } from 'lib/auth/hbp';
import { useAgentPermissions } from 'lib/hooks/useAgentPermissions';
import useUserContext from 'lib/hooks/useUserContext';

export function useTeamMemberList(
  role: 'all' | Workspace_User_Roles_Enum,
  ignoreAgentUserAllocatedAgentNames: boolean = false
) {
  const { workspaceMemberContext } = useHasuraRoleContext();
  const { userId, activeWorkspaceId, isWorkspaceAgent } = useUserContext();
  const agentPermissions = useAgentPermissions();

  const where: Workspace_Users_Bool_Exp = {
    _and: [{ workspace_id: { _eq: activeWorkspaceId! } }]
  };

  if (role !== 'all') {
    where._and!.push({
      role: { _eq: role }
    });
  }

  if (isWorkspaceAgent && !ignoreAgentUserAllocatedAgentNames) {
    where._and!.push({
      _or: [
        {
          user: { display_name: { _in: agentPermissions.allowed_agents } }
        },
        {
          agent_workspace_mappings: {
            listing_agent_name: { _in: agentPermissions.allowed_agents }
          }
        },
        {
          user_id: { _eq: userId! }
        }
      ]
    });
  }

  const { data, loading, error } = useWorkspaceTeamMembersQuery({
    variables: {
      where: where,
      workspace_id: activeWorkspaceId!
    },
    context: workspaceMemberContext
  });

  const options = useMemo(
    () =>
      data?.workspace_users?.map((agent) => ({
        user_id: agent.user_id,
        value: agent.user_id,
        primary: agent.user?.display_name ?? 'Agent Name',
        secondary: `${agent.user?.job_title ?? ''} @ ${agent.user.contact_email ?? 'N/A'} # ${
          agent.user.contact_number ?? 'NA'
        }`,
        email: agent.user.account?.email ?? agent.user.contact_email,
        thumbnailUrl: getFilePath(agent.user.avatar_url)
      })) ?? [],
    [data?.workspace_users]
  );

  return {
    teamMembers: options,
    data,
    loading,
    error
  };
}
