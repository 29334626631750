import { useCallback, useMemo, useState } from 'react';

import { CircularProgress, Typography } from '@material-ui/core';

import _ from 'lodash';
import { Autocomplete } from 'mui-rff';

import {
  ListingsSlimQuery,
  Workspace_Listings_Bool_Exp,
  useListingsSlimQuery
} from 'generated/graphql';

import { useHasuraRoleContext } from 'lib/HasuraRoleContext';
import { useAgentPermissions } from 'lib/hooks/useAgentPermissions';
import useUserContext from 'lib/hooks/useUserContext';

interface PropertyAutocompleteProps {
  name: string;
  label: string;
  disabled?: boolean;
  multiple?: boolean;
  status?: string[];
  showUnderOffer?: boolean;
}

export default function PropertyAutocomplete({
  name,
  label,
  disabled = false,
  multiple = false,
  status = ['sold', 'leased'],
  showUnderOffer = true
}: PropertyAutocompleteProps) {
  const { workspaceMemberContext } = useHasuraRoleContext();
  const { activeWorkspaceId, isWorkspaceAgent } = useUserContext();
  const agentPermissions = useAgentPermissions();

  const [loadingMore, setLoadingMore] = useState<boolean>(false);
  const [additionalFetches, setAdditionalFetches] = useState<ListingsSlimQuery['listings']>([]);

  const where = useMemo<Workspace_Listings_Bool_Exp>(() => {
    const or: Workspace_Listings_Bool_Exp[] = [
      {
        status: { _in: status }
      }
    ];

    if (showUnderOffer) {
      or.push({
        under_offer: { _eq: true }
      });
    }

    const where: Workspace_Listings_Bool_Exp = {
      _and: [
        {
          _or: or
        },
        {
          workspace_id: { _eq: activeWorkspaceId }
        }
      ]
    };

    if (isWorkspaceAgent) {
      where._and!.push({
        agents: { user: { display_name: { _in: agentPermissions.allowed_agents } } }
      });
    }

    return where;
  }, [
    status,
    showUnderOffer,
    activeWorkspaceId,
    isWorkspaceAgent,
    agentPermissions.allowed_agents
  ]);

  const { data, loading, error, fetchMore } = useListingsSlimQuery({
    variables: {
      limit: 500,
      where: where
    },
    context: workspaceMemberContext
  });

  const initialListings = useMemo(() => data?.listings ?? [], [data?.listings]);

  const handleInputSearchChange = useCallback(
    async (_event: any, newValue: string) => {
      if (newValue && newValue.trim() !== '') {
        const pgSearch = `%${newValue.trim()}%`;

        const or: Workspace_Listings_Bool_Exp[] = [
          {
            status: { _in: status }
          }
        ];

        if (showUnderOffer) {
          or.push({
            under_offer: { _eq: true }
          });
        }

        const moreWhere: Workspace_Listings_Bool_Exp = {
          _and: [
            {
              _or: or
            },
            {
              workspace_id: { _eq: activeWorkspaceId }
            },
            {
              _or: [
                { location: { address: { formatted_address: { _ilike: pgSearch } } } },
                { location: { address: { suburb: { _ilike: pgSearch } } } },
                { location: { address: { street: { _ilike: pgSearch } } } },
                { location: { address: { state: { _ilike: pgSearch } } } },
                { location: { address: { street_number: { _ilike: pgSearch } } } }
              ]
            }
          ]
        };

        if (isWorkspaceAgent) {
          moreWhere._and!.push({
            agents: { user: { display_name: { _in: agentPermissions.allowed_agents } } }
          });
        }

        try {
          setLoadingMore(true);
          const resp = await fetchMore({
            variables: {
              limit: 25,
              where: moreWhere
            }
          });

          const newListings = resp.data.listings ?? [];
          if (newListings.length) {
            setAdditionalFetches((cur) => _.uniqBy([...cur, ...newListings], 'id'));
          }
        } catch (error: any) {
          console.warn(error);
        } finally {
          setLoadingMore(false);
        }
      }
    },
    [
      setAdditionalFetches,
      fetchMore,
      agentPermissions.allowed_agents,
      activeWorkspaceId,
      isWorkspaceAgent,
      status,
      showUnderOffer,
      setLoadingMore
    ]
  );

  // TODO: Proper fix, multiple + searching doesn't work well
  const debouncedHandleInputSearchChange = useMemo(
    () => (multiple ? undefined : _.debounce(handleInputSearchChange, 500)),
    [multiple, handleInputSearchChange]
  );

  const options = useMemo<ListingsSlimQuery['listings']>(() => {
    return _.uniqBy([...initialListings, ...additionalFetches], 'id');
  }, [initialListings, additionalFetches]);

  if (error) {
    return (
      <Typography variant="caption" color="error">
        Could not load listings
      </Typography>
    );
  }

  // Autocomplete component will error if multiple is true and options have not
  // finished loading. Return progress indicator to stop app crashing.
  if (!options.length && multiple) {
    return <CircularProgress />;
  }

  return (
    <Autocomplete
      name={name}
      label={label}
      options={options}
      loading={loading || loadingMore}
      disabled={disabled}
      multiple={multiple}
      onInputChange={debouncedHandleInputSearchChange}
      getOptionValue={(listing: any) => listing.id}
      getOptionLabel={(listing: any) => {
        // Fallback to ID if we can't do anything else
        let addressPart = listing.unique_id ?? listing.id;

        if (listing.location.address.full_address) {
          addressPart = listing.location.address.full_address;
        } else if (listing.location.address.suburb && listing.location.address.street) {
          addressPart = `${listing.location.address.street} ${listing.location.address.suburb}`;
        }

        if (listing.type && listing.status) {
          return `${addressPart} (${listing.type} - ${listing.status})`;
        }

        return addressPart;
      }}
    />
  );
}
