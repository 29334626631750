import { useCallback, useEffect, useMemo, useState } from 'react';

import { Form } from 'react-final-form';
import { Link as ReactRouterLink } from 'react-router-dom';

import { Avatar, Box, Button, CircularProgress, Link, Paper, Typography } from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';

import { FORM_ERROR } from 'final-form';
import createDecorator from 'final-form-calculate';
import { Autocomplete, makeValidate } from 'mui-rff';
import { useSnackbar } from 'notistack';
import * as Yup from 'yup';

import {
  FacebookUserAccountsQuery,
  useFacebookAvailableBusinessesQuery,
  useFacebookOAuthMutation,
  useFacebookQuery,
  useFacebookUserAccountsQuery,
  useSetupFacebookBusinessMutation
} from 'generated/graphql';

import AccountConnection from 'components/AccountConnection';

import { useHasuraRoleContext } from 'lib/HasuraRoleContext';
import useUserContext from 'lib/hooks/useUserContext';

interface ConnectFacebookSectionProps {
  onFacebookConnected: () => void;
}

const FB_LOGIN_USER_TOKEN_CONFIGURATION_ID = 1053910955759535;

interface FacebookResponse {
  authResponse: {
    accessToken: string;
    expiresIn: number;
    userID: string;
    signedRequest: string;
  };
  status: 'connected' | 'not_authorized' | 'unknown';
}

export default function ConnectFacebookSection({
  onFacebookConnected
}: ConnectFacebookSectionProps) {
  const { activeWorkspaceId } = useUserContext();
  const { workspaceMemberContext } = useHasuraRoleContext();

  const [metaConnected, setMetaConnected] = useState<boolean>(false);
  const { enqueueSnackbar } = useSnackbar();

  const [installFacebook, { loading: installing }] = useFacebookOAuthMutation({
    context: workspaceMemberContext
  });
  const { data, loading, refetch, fetchMore } = useFacebookQuery({
    variables: {
      workspace_id: activeWorkspaceId!
    },
    context: workspaceMemberContext
  });

  const facebook = useMemo(
    () => data?.workspace?.apps?.facebook,
    [data?.workspace?.apps?.facebook]
  );
  const connected = useMemo(() => Boolean(facebook?.id), [facebook?.id]);
  const hasPageAttached = useMemo(
    () => (facebook?.allowed_pages?.length ?? 0) > 0,
    [facebook?.allowed_pages?.length]
  );

  const [installKey, setInstallKey] = useState<string>();

  useEffect(() => {
    if (hasPageAttached) {
      setMetaConnected(true);
    }
  }, [hasPageAttached, setMetaConnected]);

  useEffect(() => {
    if (metaConnected) {
      onFacebookConnected();
    }
  }, [metaConnected, onFacebookConnected]);

  const handleFacebookConnectionSuccess = useCallback(() => {
    setMetaConnected(true);
    refetch();
  }, [setMetaConnected, refetch]);

  const handleFacebookLoginForBusinessWithConfiguration = useCallback(async () => {
    try {
      const loginForBusinessCallback = async (response: FacebookResponse) => {
        if (response.status !== 'connected') {
          return enqueueSnackbar(
            'Unable to complete Facebook login, please reload the page and try again',
            {
              variant: 'error'
            }
          );
        }

        setInstallKey(response.authResponse.signedRequest);

        try {
          await installFacebook({
            variables: {
              object: {
                workspaceId: activeWorkspaceId!,
                accessToken: response.authResponse.accessToken,
                signedRequest: response.authResponse.signedRequest,
                userID: response.authResponse.userID
              }
            }
          });
          await refetch({ workspace_id: activeWorkspaceId! });

          // Refetch not working sometimes??
          await fetchMore({ variables: { workspace_id: activeWorkspaceId! } });
        } catch (error: any) {
          enqueueSnackbar('Unable to confirm connection', { variant: 'error' });
        }
      };

      // @ts-ignore
      FB.login((response) => loginForBusinessCallback(response), {
        config_id: FB_LOGIN_USER_TOKEN_CONFIGURATION_ID,
        enable_profile_selector: true
      });
    } catch (error: any) {
      console.warn(error);
      enqueueSnackbar('Unable to complete Facebook login, please reload the page and try again', {
        variant: 'error'
      });
    }
  }, [enqueueSnackbar, activeWorkspaceId, installFacebook, refetch, fetchMore, setInstallKey]);

  if (loading) {
    return <CircularProgress />;
  }

  if (metaConnected) {
    return (
      <Box maxWidth={1000}>
        <Typography variant="h6">Connect your Facebook account</Typography>
        <Alert severity="success">
          <AlertTitle>Facebook successfully connected</AlertTitle>
        </Alert>
      </Box>
    );
  }

  return (
    <Box maxWidth={1000}>
      <Typography variant="h6">Connect your Facebook account</Typography>

      <Alert severity="info">
        <AlertTitle>Required permissions</AlertTitle>
        We require you to have ADMIN permissions on both your Facebook page, and the Facebook
        Business Manager that owns to page. Please ensure you have correct permissions. <br />
        <Link
          href="https://support.properti.ai/meta-facebook-instagram-account-wont-connect-to-properti"
          target="_blank"
        >
          Which permissions do I need?
        </Link>
      </Alert>

      <Paper elevation={0}>
        <AccountConnection
          title="Facebook"
          platform="facebook"
          details={connected ? 'Confirm page to finish' : 'No account connected'}
          buttonText={'Continue with Facebook'}
          loading={loading}
          connected={connected}
          elevation={0}
          buttonOnClick={handleFacebookLoginForBusinessWithConfiguration}
        />

        <Box padding={2} style={{ display: 'flex', placeContent: 'center' }}>
          <Box maxWidth={600}>
            {installing ? (
              <CircularProgress />
            ) : connected ? (
              <AssetSelectionForm key={installKey} onSuccess={handleFacebookConnectionSuccess} />
            ) : (
              <Alert severity="warning">
                <AlertTitle>Press the "Continue with Facebook" button to proceed</AlertTitle>
              </Alert>
            )}
          </Box>
        </Box>
      </Paper>
    </Box>
  );
}

interface AssetSelectionFormProps {
  onSuccess: () => void;
}

const schema = Yup.object({
  business_account_id: Yup.string().required().label('Your FB Business Manager'),
  page_id: Yup.string().label('Facebook Page').required()
}).required();

const validate = makeValidate(schema);

type FormSchema = Yup.InferType<typeof schema>;

function useBusinessPageDecorator(facebookPages: FacebookUserAccountsQuery['pages']) {
  const businessCalculator = useMemo(
    () =>
      createDecorator({
        field: 'page_id',
        updates: (value) => {
          const page = facebookPages?.find((p) => p.id === value);
          return {
            business_account_id: page?.business?.id ?? undefined
          };
        }
      }),
    [facebookPages]
  );

  return businessCalculator;
}

function AssetSelectionForm({ onSuccess }: AssetSelectionFormProps) {
  const { activeWorkspaceId, workspace } = useUserContext();
  const { workspaceMemberContext } = useHasuraRoleContext();

  const { enqueueSnackbar } = useSnackbar();

  const { data, loading: fbLoading } = useFacebookUserAccountsQuery({
    context: workspaceMemberContext
  });

  const { data: businessData, loading: businessesLoading } = useFacebookAvailableBusinessesQuery({
    context: workspaceMemberContext
  });
  const [setupBusiness] = useSetupFacebookBusinessMutation({
    context: workspaceMemberContext
  });

  const pages = useMemo(
    () => data?.pages?.filter((page) => page.tasks?.includes('MANAGE')) ?? [],
    [data?.pages]
  );

  const businesses = useMemo(
    () =>
      businessData?.businesses
        ?.map((bm) => ({
          value: bm.id,
          label: bm.name,
          pp: bm.profile_picture_uri
        }))
        .sort((a, b) => a.label.localeCompare(b.label)) ?? [],
    [businessData?.businesses]
  );

  const pageData = useMemo(
    () =>
      pages.sort((a, b) =>
        (a.name_with_location_descriptor ?? a.name).localeCompare(
          b.name_with_location_descriptor ?? b.name
        )
      ),
    [pages]
  );

  const formDecorator = useBusinessPageDecorator(pages);

  const handleFormSubmit = useCallback(
    async (formValues: FormSchema) => {
      try {
        await setupBusiness({
          variables: {
            args: {
              workspace_id: activeWorkspaceId!,
              name: `Properti ${workspace?.name.trim()}`,
              use_client_ad_account: false,
              business_id: formValues.business_account_id,
              page_id: formValues.page_id,
              currency: workspace?.currency,
              timezone_id: 15 // TODO currently hardcoded to Sydney
            }
          }
        });

        enqueueSnackbar('Connection successful', { variant: 'success' });
        onSuccess();
      } catch (error: any) {
        return {
          [FORM_ERROR]: error?.message ?? 'Unable to create connection'
        };
      }
    },
    [
      activeWorkspaceId,
      workspace?.name,
      workspace?.currency,
      setupBusiness,
      enqueueSnackbar,
      onSuccess
    ]
  );

  if (fbLoading || businessesLoading) {
    return <CircularProgress />;
  }

  return (
    <Form
      onSubmit={handleFormSubmit}
      validate={validate}
      // @ts-ignore
      decorators={[formDecorator]}
      render={({ handleSubmit, submitting, submitError, values }) => {
        const selectedBusinessManagerId = values.business_account_id;
        const hasAccessToPageBusiness =
          businesses.findIndex((business) => business.value === selectedBusinessManagerId) !== -1;

        const showBusinessManagerWarning =
          Boolean(selectedBusinessManagerId) && !hasAccessToPageBusiness;

        return (
          <form onSubmit={handleSubmit}>
            <Autocomplete
              name="business_account_id"
              label="Select your Business Account"
              options={businesses}
              getOptionValue={(business: typeof businesses[0]) => business.value}
              getOptionLabel={(business: typeof businesses[0]) => business.label}
              renderOption={(business: typeof businesses[0]) => (
                <Box style={{ display: 'flex', alignItems: 'center', gap: 8 }} key={business.value}>
                  <Avatar alt={business.label} src={business.pp ?? undefined} />
                  {business.label} (ID: {business.value})
                </Box>
              )}
              blurOnSelect
              disabled={submitting}
            />
            <Autocomplete
              name="page_id"
              label="Select your Facebook Page"
              options={pageData}
              getOptionValue={(page: typeof pageData[0]) => page.id}
              getOptionLabel={(page: typeof pageData[0]) =>
                page.name_with_location_descriptor ?? page.name
              }
              renderOption={(page: typeof pageData[0]) => (
                <Box style={{ display: 'flex', alignItems: 'center', gap: 8 }} key={page.id}>
                  <Avatar alt={page.name} src={page.picture?.data?.url ?? undefined} />
                  {page.name_with_location_descriptor ?? page.name} (ID: {page.id})
                </Box>
              )}
              multiple={false}
              disableCloseOnSelect={false}
              blurOnSelect
              disabled={submitting}
            />
            <Box>
              <Typography variant="caption" color="textPrimary">
                Please ensure you have <strong>"MANAGE"</strong> permissions on the pages you are
                trying to add. You must also be an <strong>Admin</strong> on the business manager
                that owns the page.
              </Typography>
            </Box>
            {showBusinessManagerWarning && (
              <Box mt={1}>
                <Alert
                  severity="error"
                  action={
                    <Button
                      component={ReactRouterLink}
                      variant="contained"
                      color="primary"
                      to="/apps/facebook/audit"
                      target="_blank"
                      style={{ width: 130 }}
                    >
                      View audit
                    </Button>
                  }
                >
                  <AlertTitle>Missing business manager access</AlertTitle>
                  You do not appear to have access to this page's Business Manager. Please ensure
                  correct permissions to continue.
                </Alert>
              </Box>
            )}
            <Box mt={2}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={submitting || showBusinessManagerWarning}
                startIcon={submitting ? <CircularProgress size={12} /> : undefined}
              >
                {'Create Properti <> Facebook Connection'}
              </Button>
              {submitError && (
                <Box>
                  <Typography variant="caption" color="error">
                    {submitError}
                  </Typography>
                </Box>
              )}
            </Box>
          </form>
        );
      }}
    />
  );
}
