import { useCallback, useMemo } from 'react';

import { Form } from 'react-final-form';

import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Typography
} from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';

import { FORM_ERROR } from 'final-form';
import { makeValidate, Select } from 'mui-rff';
import { useSnackbar } from 'notistack';
import * as Yup from 'yup';

import {
  useAutomationTriggerAssignedAgentsQuery,
  useAutomationScheduleAssignedAgentsQuery,
  useInsertAutomationTriggerAssignedAgentsMutation,
  useInsertAutomationScheduleAssignedAgentsMutation,
  Workspace_User_Roles_Enum,
  useInsertCustomerReviewAssignedAgentsMutation,
  useCustomerReviewAssignedAgentsQuery,
  useInsertCreativeAssignedAgentsMutation,
  useCreativeAssignedAgentsQuery,
  Workspace_Resource_Permission_Modes_Enum,
  useInsertAudienceAssignedAgentsMutation,
  useAudienceAssignedAgentsQuery,
  useLeadgenFormAssignedAgentsQuery,
  useInsertLeadgenFormAssignedAgentsMutation,
  usePostAssignedAgentsQuery,
  useInsertPostAssignedAgentsMutation,
  useInsertCampaignAssignedAgentsMutation,
  useCampaignAssignedAgentsQuery,
  useInsertCampaignTemplateAssignedAgentsMutation,
  useCampaignTemplateAssignedAgentsQuery,
  useCampaignPromotionAssignedAgentsQuery,
  useInsertCampaignPromotionAssignedAgentsMutation
} from 'generated/graphql';

import DialogTitle from 'components/DialogTitle';
import AgentSelectInput from 'components/forms/AgentSelectInput';
import FinalFormCondition from 'components/forms/FinalFormCondition';

import { useHasuraRoleContext } from 'lib/HasuraRoleContext';

type AssetType =
  | 'automation-trigger'
  | 'automation-schedule'
  | 'post'
  | 'creative'
  | 'campaign'
  | 'campaign-template'
  | 'review'
  | 'audience'
  | 'leadgen-form'
  | 'campaign-promotion';

interface AssignAgentsAssetDialogProps {
  assetId?: string | null;
  assetType: AssetType;
  open: boolean;
  onClose: () => void;
}

function getAssetName(assetType: AssetType): string {
  switch (assetType) {
    case 'automation-trigger':
      return 'Automation Trigger';
    case 'automation-schedule':
      return 'Automation Schedule';
    case 'campaign':
      return 'Campaign';
    case 'campaign-template':
      return 'Campaign Template';
    case 'creative':
      return 'Creative';
    case 'post':
      return 'Post';
    case 'review':
      return 'Review';
    case 'audience':
      return 'Custom Audience';
    case 'leadgen-form':
      return 'Leadgen Form';
    case 'campaign-promotion':
      return 'Promotion';
    default:
      return '';
  }
}

function getAssetAllowedActions(assetType: AssetType): string[] {
  switch (assetType) {
    case 'automation-trigger':
    case 'automation-schedule':
      return ['edit', 'pause/unpause', 'delete'];

    case 'campaign':
      return ['edit', 'publish', 'pause/unpause', 'archive'];

    case 'campaign-template':
      return ['clone', 'create campaigns'];

    case 'post':
      return ['edit', 'publish', 'delete'];

    case 'creative':
      return ['edit', 'share', 'delete'];

    case 'review':
      return ['edit', 'build creatives', 'delete'];

    case 'audience':
      return ['create lookalike audiences', 'build campaigns', 'delete'];

    case 'leadgen-form':
      return ['create campaign', 'clone', 'edit', 'delete'];

    case 'campaign-promotion':
      return ['create campaigns', 'edit', 'delete'];

    default: {
      return [];
    }
  }
}

function getAdditionalText(assetType: AssetType): string | null {
  switch (assetType) {
    case 'automation-trigger':
    case 'automation-schedule':
      return 'Agents will also have access to any new creatives, posts, and campaigns built by this automation.';

    case 'review':
      return 'Agents will always be granted access to reviews tagged on their own listings.';

    case 'creative':
      return "Agents will always be granted access to their listing's creatives, creatives built by themselves, and head office creatives.";

    case 'leadgen-form':
      return 'This access is in addition to the leadgen form\'s "Owner" Agents will be sent email and in-app notifications for leads acquired via this leadgen form.';
  }

  return null;
}

const schema = Yup.object({
  mode: Yup.string()
    .oneOf([
      Workspace_Resource_Permission_Modes_Enum.All,
      Workspace_Resource_Permission_Modes_Enum.SelectUsers
    ])
    .required(),
  selected_agent_ids: Yup.array().of(Yup.string().required()).defined()
}).required();

type FormSchema = Yup.InferType<typeof schema>;
const validate = makeValidate(schema);

export default function AssignAgentsAssetDialog({
  assetId,
  assetType,
  open,
  onClose
}: AssignAgentsAssetDialogProps) {
  const { enqueueSnackbar } = useSnackbar();

  const { selectedAgentIds, permissionMode, loading, refetchAll } = useCurrentlySelectedAgents(
    open,
    assetType,
    assetId
  );
  const updateAssignedAgents = useUpdateAssignedAgents(assetType, assetId);

  const handleSubmitForm = useCallback(
    async (formValues: FormSchema) => {
      try {
        await updateAssignedAgents(formValues.mode, formValues.selected_agent_ids);
        await refetchAll();
        enqueueSnackbar('Access updated', { variant: 'success' });
        onClose();
      } catch (error: any) {
        console.error('Error updating assets assigned agents', error);
        return {
          [FORM_ERROR]: error?.message ?? 'Unable to save changes'
        };
      }
    },
    [updateAssignedAgents, refetchAll, enqueueSnackbar, onClose]
  );

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="md"
      PaperProps={{ style: { minHeight: 800 } }}
    >
      <DialogTitle onClose={onClose}>Assign Agents {getAssetName(assetType)}</DialogTitle>
      <DialogContent style={{ display: 'flex', flexDirection: 'column' }}>
        <DialogContentText>
          Granting access to {getAssetName(assetType).toLowerCase()}. Once granted access, agents
          will be able to: {getAssetAllowedActions(assetType).join(', ')}. <br />
          {getAdditionalText(assetType)}
        </DialogContentText>

        {loading ? (
          <CircularProgress />
        ) : (
          <Box display="flex" flexDirection="column" flexGrow={1}>
            <Form
              initialValues={{
                mode: permissionMode,
                selected_agent_ids: selectedAgentIds
              }}
              keepDirtyOnReinitialize // Stops checkboxes flashing when submitting
              validate={validate}
              onSubmit={handleSubmitForm}
              render={({ handleSubmit, submitError, submitting }) => {
                return (
                  <form
                    onSubmit={handleSubmit}
                    noValidate
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      flexGrow: 1
                    }}
                  >
                    <Box flexGrow={1}>
                      <Select
                        name="mode"
                        label="How would you like to control access?"
                        data={[
                          {
                            value: 'select_users',
                            label: 'Manually select agents'
                          },
                          {
                            value: 'all',
                            label: 'Grant access to all agents'
                          }
                        ]}
                      />
                      <FinalFormCondition
                        when="mode"
                        equals={Workspace_Resource_Permission_Modes_Enum.SelectUsers}
                      >
                        <AgentSelectInput
                          name="selected_agent_ids"
                          multiple={true}
                          role={Workspace_User_Roles_Enum.Agent}
                          dense
                          disabled={submitting}
                          // Allow agent users to allocated other agent users individually
                          ignoreAgentUserAllocatedAgentNames={true}
                        />
                      </FinalFormCondition>
                      <FinalFormCondition when="mode" equals="all">
                        <Alert severity="info">
                          <AlertTitle>Granting access to all agents</AlertTitle>
                          All agent users iin your workspace will have access to this{' '}
                          {getAssetName(assetType).toLowerCase()}.
                        </Alert>
                      </FinalFormCondition>
                      {submitError && (
                        <Box>
                          <Typography variant="caption" color="error">
                            {submitError}
                          </Typography>
                        </Box>
                      )}
                    </Box>

                    <DialogActions>
                      <Button onClick={onClose} disabled={submitting}>
                        Cancel
                      </Button>
                      <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        disabled={submitting}
                      >
                        {submitting ? 'Saving ...' : 'Save Changes'}
                      </Button>
                    </DialogActions>
                  </form>
                );
              }}
            />
          </Box>
        )}
      </DialogContent>
    </Dialog>
  );
}

interface UseCurrentlySelectedAgentsData {
  permissionMode: Workspace_Resource_Permission_Modes_Enum;
  selectedAgentIds: string[];
  loading: boolean;
  refetchAll: () => Promise<void>;
}

const DEFAULT_PERMISSION_MODE = Workspace_Resource_Permission_Modes_Enum.SelectUsers;

function useCurrentlySelectedAgents(
  open: boolean,
  assetType: AssetType,
  assetId?: string | null
): UseCurrentlySelectedAgentsData {
  const { workspaceMemberContext } = useHasuraRoleContext();

  // Triggers
  const automationTriggerResp = useAutomationTriggerAssignedAgentsQuery({
    variables: {
      trigger_id: assetId!
    },
    skip: !open || !assetId || assetType !== 'automation-trigger',
    context: workspaceMemberContext
  });

  // Schedules
  const automationScheduleResp = useAutomationScheduleAssignedAgentsQuery({
    variables: {
      schedule_id: assetId!
    },
    skip: !open || !assetId || assetType !== 'automation-schedule',
    context: workspaceMemberContext
  });

  // Reviews
  const customerReviewResp = useCustomerReviewAssignedAgentsQuery({
    variables: {
      review_id: assetId!
    },
    skip: !open || !assetId || assetType !== 'review',
    context: workspaceMemberContext
  });

  // Creatives
  const creativeResp = useCreativeAssignedAgentsQuery({
    variables: {
      creative_id: assetId!
    },
    skip: !open || !assetId || assetType !== 'creative',
    context: workspaceMemberContext
  });

  // Audiences
  const audienceResp = useAudienceAssignedAgentsQuery({
    variables: {
      audience_id: assetId!
    },
    skip: !open || !assetId || assetType !== 'audience',
    context: workspaceMemberContext
  });

  // Leadgen Forms
  const leadgenFormResp = useLeadgenFormAssignedAgentsQuery({
    variables: {
      form_id: assetId!
    },
    skip: !open || !assetId || assetType !== 'leadgen-form',
    context: workspaceMemberContext
  });

  // Posts
  const postResp = usePostAssignedAgentsQuery({
    variables: {
      post_id: assetId!
    },
    skip: !open || !assetId || assetType !== 'post',
    context: workspaceMemberContext
  });

  // Campaigns
  const campaignResp = useCampaignAssignedAgentsQuery({
    variables: {
      campaign_id: assetId!
    },
    skip: !open || !assetId || assetType !== 'campaign',
    context: workspaceMemberContext
  });

  // Campaign Templates
  const campaignTemplateResp = useCampaignTemplateAssignedAgentsQuery({
    variables: {
      campaign_template_id: assetId!
    },
    skip: !open || !assetId || assetType !== 'campaign-template',
    context: workspaceMemberContext
  });

  // Campaign Promotions
  const campaignPromotionResp = useCampaignPromotionAssignedAgentsQuery({
    variables: {
      campaign_promotion_id: assetId!
    },
    skip: !open || !assetId || assetType !== 'campaign-promotion',
    context: workspaceMemberContext
  });

  const refetchAll = useCallback(async () => {
    if (assetType === 'automation-trigger') {
      await automationTriggerResp.refetch();
    } else if (assetType === 'automation-schedule') {
      await automationScheduleResp.refetch();
    } else if (assetType === 'review') {
      await customerReviewResp.refetch();
    } else if (assetType === 'creative') {
      await creativeResp.refetch();
    } else if (assetType === 'audience') {
      await audienceResp.refetch();
    } else if (assetType === 'leadgen-form') {
      await leadgenFormResp.refetch();
    } else if (assetType === 'post') {
      await postResp.refetch();
    } else if (assetType === 'campaign') {
      await campaignResp.refetch();
    } else if (assetType === 'campaign-template') {
      await campaignTemplateResp.refetch();
    } else if (assetType === 'campaign-promotion') {
      await campaignPromotionResp.refetch();
    }
  }, [
    assetType,
    automationTriggerResp,
    automationScheduleResp,
    customerReviewResp,
    creativeResp,
    audienceResp,
    leadgenFormResp,
    postResp,
    campaignResp,
    campaignTemplateResp,
    campaignPromotionResp
  ]);

  const { permissionMode, selectedAgentIds, loading } = useMemo(() => {
    if (!assetId) {
      return {
        permissionMode: DEFAULT_PERMISSION_MODE,
        selectedAgentIds: [],
        loading: true
      };
    }

    let selectedAgentIds: string[] = [];
    let loading: boolean = true;
    let permissionMode: Workspace_Resource_Permission_Modes_Enum = DEFAULT_PERMISSION_MODE;

    // Handle Automation Triggers
    if (assetType === 'automation-trigger') {
      permissionMode =
        automationTriggerResp.data?.workspace_automation_triggers_by_pk?.agent_permission_mode ??
        DEFAULT_PERMISSION_MODE;
      selectedAgentIds =
        automationTriggerResp.data?.workspace_automation_triggers_assigned_users?.map(
          (assign) => assign.user_id
        ) ?? [];
      loading = automationTriggerResp.loading;
    } else if (assetType === 'automation-schedule') {
      permissionMode =
        automationScheduleResp.data?.workspace_automation_rules_by_pk?.agent_permission_mode ??
        DEFAULT_PERMISSION_MODE;
      selectedAgentIds =
        automationScheduleResp.data?.workspace_automation_schedules_assigned_users?.map(
          (assign) => assign.user_id
        ) ?? [];
      loading = automationScheduleResp.loading;
    } else if (assetType === 'review') {
      permissionMode =
        customerReviewResp.data?.workspace_reviews_by_pk?.agent_permission_mode ??
        DEFAULT_PERMISSION_MODE;
      selectedAgentIds =
        customerReviewResp.data?.workspace_review_assigned_users.map((assign) => assign.user_id) ??
        [];
      loading = customerReviewResp.loading;
    } else if (assetType === 'creative') {
      permissionMode =
        creativeResp.data?.workspace_creatives_by_pk?.agent_permission_mode ??
        DEFAULT_PERMISSION_MODE;
      selectedAgentIds =
        creativeResp.data?.workspace_creative_assigned_users.map((assign) => assign.user_id) ?? [];
      loading = customerReviewResp.loading;
    } else if (assetType === 'audience') {
      permissionMode =
        audienceResp.data?.workspace_audiences_by_pk?.agent_permission_mode ??
        DEFAULT_PERMISSION_MODE;
      selectedAgentIds =
        audienceResp.data?.workspace_audience_assigned_users.map((assign) => assign.user_id) ?? [];
      loading = audienceResp.loading;
    } else if (assetType === 'leadgen-form') {
      permissionMode =
        leadgenFormResp.data?.workspace_lead_forms_by_pk?.agent_permission_mode ??
        DEFAULT_PERMISSION_MODE;
      selectedAgentIds =
        leadgenFormResp.data?.workspace_lead_form_assigned_users.map((assign) => assign.user_id) ??
        [];
      loading = leadgenFormResp.loading;
    } else if (assetType === 'post') {
      permissionMode =
        postResp.data?.workspace_posts_by_pk?.agent_permission_mode ?? DEFAULT_PERMISSION_MODE;
      selectedAgentIds =
        postResp.data?.workspace_post_assigned_users.map((assign) => assign.user_id) ?? [];
      loading = postResp.loading;
    } else if (assetType === 'campaign') {
      permissionMode =
        campaignResp.data?.workspace_campaigns_by_pk?.agent_permission_mode ??
        DEFAULT_PERMISSION_MODE;
      selectedAgentIds =
        campaignResp.data?.workspace_campaign_assigned_users.map((assign) => assign.user_id) ?? [];
      loading = campaignResp.loading;
    } else if (assetType === 'campaign-template') {
      permissionMode =
        campaignTemplateResp.data?.workspace_campaign_templates?.[0]?.agent_permission_mode ??
        DEFAULT_PERMISSION_MODE;
      selectedAgentIds =
        campaignTemplateResp.data?.workspace_campaign_template_assigned_users.map(
          (assign) => assign.user_id
        ) ?? [];
      loading = campaignTemplateResp.loading;
    } else if (assetType === 'campaign-promotion') {
      permissionMode =
        campaignPromotionResp.data?.workspace_campaign_promotions?.[0]?.agent_permission_mode ??
        DEFAULT_PERMISSION_MODE;
      selectedAgentIds =
        campaignPromotionResp.data?.workspace_campaign_promotion_assigned_users.map(
          (assign) => assign.user_id
        ) ?? [];
      loading = campaignPromotionResp.loading;
    }

    return {
      permissionMode,
      selectedAgentIds,
      loading
    };
  }, [
    assetId,
    assetType,
    audienceResp.data?.workspace_audience_assigned_users,
    audienceResp.data?.workspace_audiences_by_pk?.agent_permission_mode,
    audienceResp.loading,
    automationScheduleResp.data?.workspace_automation_rules_by_pk?.agent_permission_mode,
    automationScheduleResp.data?.workspace_automation_schedules_assigned_users,
    automationScheduleResp.loading,
    automationTriggerResp.data?.workspace_automation_triggers_assigned_users,
    automationTriggerResp.data?.workspace_automation_triggers_by_pk?.agent_permission_mode,
    automationTriggerResp.loading,
    campaignResp.data?.workspace_campaign_assigned_users,
    campaignResp.data?.workspace_campaigns_by_pk?.agent_permission_mode,
    campaignResp.loading,
    campaignTemplateResp.data?.workspace_campaign_template_assigned_users,
    campaignTemplateResp.data?.workspace_campaign_templates,
    campaignTemplateResp.loading,
    campaignPromotionResp.data?.workspace_campaign_promotion_assigned_users,
    campaignPromotionResp.data?.workspace_campaign_promotions,
    campaignPromotionResp.loading,
    creativeResp.data?.workspace_creative_assigned_users,
    creativeResp.data?.workspace_creatives_by_pk?.agent_permission_mode,
    customerReviewResp.data?.workspace_review_assigned_users,
    customerReviewResp.data?.workspace_reviews_by_pk?.agent_permission_mode,
    customerReviewResp.loading,
    leadgenFormResp.data?.workspace_lead_form_assigned_users,
    leadgenFormResp.data?.workspace_lead_forms_by_pk?.agent_permission_mode,
    leadgenFormResp.loading,
    postResp.data?.workspace_post_assigned_users,
    postResp.data?.workspace_posts_by_pk?.agent_permission_mode,
    postResp.loading
  ]);

  return {
    permissionMode,
    selectedAgentIds,
    loading,
    refetchAll
  };
}

function useUpdateAssignedAgents(assetType: AssetType, assetId?: string | null) {
  const { workspaceMemberContext } = useHasuraRoleContext();

  const [updateAutomationTriggerAssignedAgents] = useInsertAutomationTriggerAssignedAgentsMutation({
    context: workspaceMemberContext
  });
  const [updateAutomationScheduleAssignedAgents] =
    useInsertAutomationScheduleAssignedAgentsMutation({
      context: workspaceMemberContext
    });
  const [updateCustomerReviewAssignedAgents] = useInsertCustomerReviewAssignedAgentsMutation({
    context: workspaceMemberContext
  });
  const [updateCreativeAssignedAgents] = useInsertCreativeAssignedAgentsMutation({
    context: workspaceMemberContext
  });
  const [updateAudienceAssignedAgents] = useInsertAudienceAssignedAgentsMutation({
    context: workspaceMemberContext
  });
  const [updateLeadgenFormAssignedAgents] = useInsertLeadgenFormAssignedAgentsMutation({
    context: workspaceMemberContext
  });
  const [updatePostAssignedAgents] = useInsertPostAssignedAgentsMutation({
    context: workspaceMemberContext
  });
  const [updateCampaignAssignedAgents] = useInsertCampaignAssignedAgentsMutation({
    context: workspaceMemberContext
  });
  const [updateCampaignTemplateAssignedAgents] = useInsertCampaignTemplateAssignedAgentsMutation({
    context: workspaceMemberContext
  });
  const [updateCampaignPromotionAssignedAgents] = useInsertCampaignPromotionAssignedAgentsMutation({
    context: workspaceMemberContext
  });

  const updateAssignedAgents = useCallback(
    async (mode: Workspace_Resource_Permission_Modes_Enum, selectedAgentIds: string[]) => {
      if (!assetId) {
        throw new Error('Missing assert ID, refresh and try again');
      }

      if (assetType === 'automation-trigger') {
        return updateAutomationTriggerAssignedAgents({
          variables: {
            trigger_id: assetId,
            selected_user_ids: selectedAgentIds,
            permission_mode: mode,
            objects: selectedAgentIds.map((userId) => ({
              trigger_id: assetId,
              user_id: userId
            }))
          }
        });
      } else if (assetType === 'automation-schedule') {
        return updateAutomationScheduleAssignedAgents({
          variables: {
            schedule_id: assetId,
            selected_user_ids: selectedAgentIds,
            permission_mode: mode,
            objects: selectedAgentIds.map((userId) => ({
              schedule_id: assetId,
              user_id: userId
            }))
          }
        });
      } else if (assetType === 'review') {
        return updateCustomerReviewAssignedAgents({
          variables: {
            review_id: assetId,
            selected_user_ids: selectedAgentIds,
            permission_mode: mode,
            objects: selectedAgentIds.map((userId) => ({
              review_id: assetId,
              user_id: userId
            }))
          }
        });
      } else if (assetType === 'creative') {
        return updateCreativeAssignedAgents({
          variables: {
            creative_id: assetId,
            selected_user_ids: selectedAgentIds,
            permission_mode: mode,
            objects: selectedAgentIds.map((userId) => ({
              creative_id: assetId,
              user_id: userId
            }))
          }
        });
      } else if (assetType === 'audience') {
        return updateAudienceAssignedAgents({
          variables: {
            audience_id: assetId,
            selected_user_ids: selectedAgentIds,
            permission_mode: mode,
            objects: selectedAgentIds.map((userId) => ({
              audience_id: assetId,
              user_id: userId
            }))
          }
        });
      } else if (assetType === 'leadgen-form') {
        return updateLeadgenFormAssignedAgents({
          variables: {
            form_id: assetId,
            selected_user_ids: selectedAgentIds,
            permission_mode: mode,
            objects: selectedAgentIds.map((userId) => ({
              form_id: assetId,
              user_id: userId
            }))
          }
        });
      } else if (assetType === 'post') {
        return updatePostAssignedAgents({
          variables: {
            post_id: assetId,
            selected_user_ids: selectedAgentIds,
            permission_mode: mode,
            objects: selectedAgentIds.map((userId) => ({
              post_id: assetId,
              user_id: userId
            }))
          }
        });
      } else if (assetType === 'campaign') {
        return updateCampaignAssignedAgents({
          variables: {
            campaign_id: assetId,
            selected_user_ids: selectedAgentIds,
            permission_mode: mode,
            objects: selectedAgentIds.map((userId) => ({
              campaign_id: assetId,
              user_id: userId
            }))
          }
        });
      } else if (assetType === 'campaign-template') {
        return updateCampaignTemplateAssignedAgents({
          variables: {
            campaign_template_id: assetId,
            selected_user_ids: selectedAgentIds,
            permission_mode: mode,
            objects: selectedAgentIds.map((userId) => ({
              campaign_template_id: assetId,
              user_id: userId
            }))
          }
        });
      } else if (assetType === 'campaign-promotion') {
        return updateCampaignPromotionAssignedAgents({
          variables: {
            campaign_promotion_id: assetId,
            selected_user_ids: selectedAgentIds,
            permission_mode: mode,
            objects: selectedAgentIds.map((userId) => ({
              campaign_promotion_id: assetId,
              user_id: userId
            }))
          }
        });
      }

      throw new Error(`Invalid asset type "${assetType}", refresh and try again`);
    },
    [
      assetType,
      assetId,
      updateAutomationTriggerAssignedAgents,
      updateAutomationScheduleAssignedAgents,
      updateCustomerReviewAssignedAgents,
      updateCreativeAssignedAgents,
      updateAudienceAssignedAgents,
      updateLeadgenFormAssignedAgents,
      updatePostAssignedAgents,
      updateCampaignAssignedAgents,
      updateCampaignTemplateAssignedAgents,
      updateCampaignPromotionAssignedAgents
    ]
  );

  return updateAssignedAgents;
}
