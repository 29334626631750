import React from 'react';

import { Link } from 'react-router-dom';

import { Avatar, Badge, Link as MuiLink } from '@material-ui/core';
import {
  Box,
  CardActions,
  CardContent,
  Chip,
  Divider,
  IconButton,
  Tooltip,
  Typography
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import ViewIcon from '@material-ui/icons/Visibility';
import { AvatarGroup } from '@material-ui/lab';

import logdna from '@logdna/browser';

import { useConfirm } from 'material-ui-confirm';
import moment from 'moment';
import { useSnackbar } from 'notistack';

import {
  Workspace_Content_Type_Enum,
  useDeleteAutomationRuleMutation,
  useDeleteHeadOfficePostMutation,
  useDeletePostMutation
} from 'generated/graphql';

import FacebookAvatar from 'components/avatars/Facebook';
import GoogleMyBusinessAvatar from 'components/avatars/GoogleMyBusiness';
import InstagramAvatar from 'components/avatars/Instagram';
import LinkedInAvatar from 'components/avatars/LinkedIn';
import YouTubeAvatar from 'components/avatars/YouTube';

import { useHasuraRoleContext } from 'lib/HasuraRoleContext';
import { getFilePath } from 'lib/auth/hbp';
import useUserContext from 'lib/hooks/useUserContext';
import { contentTypeColorMapping } from 'lib/utils/contentTypeColorMappings';

interface SchedulerTooltipProps {
  appointmentData: any;
  onHide: () => void;
  handleEditPost: (postId: string) => void;
  handleDelete?: () => void;
  refetch?: () => void;
}

export default function SchedulerTooltip({
  appointmentData,
  onHide,
  handleEditPost,
  refetch
}: SchedulerTooltipProps) {
  let viewButton;
  let deleteButton;
  let editButton;

  const { activeHeadOfficeId } = useUserContext();
  const { headOfficeUserContext } = useHasuraRoleContext();
  const confirm = useConfirm();
  const { enqueueSnackbar } = useSnackbar();

  function getBackgroundURL() {
    if (!appointmentData || !appointmentData.metadata) {
      return null;
    }
    const args = appointmentData.metadata.args ?? undefined;
    const postType = appointmentData.type ?? undefined;

    if (postType !== 'post' && postType !== 'headOfficePost') {
      return null;
    }

    const platform = [
      'facebook',
      'instagram',
      'linkedin',
      'linkedin_personal',
      'youtube',
      'gmb'
    ].find((platform) => args.hasOwnProperty(platform));

    if (!platform) return null;

    const attachments = args[platform].attachments;

    if (
      !attachments ||
      attachments.length === 0 ||
      (!attachments[0].file && !attachments[0].entry && !attachments[0].video) ||
      (attachments[0].file && attachments[0].file.contenttype === 'video/quicktime') ||
      (attachments[0].entry && attachments[0].entry.contenttype === 'video/quicktime')
    ) {
      return null;
    }

    const file = attachments[0].file;
    const entry = attachments[0].entry;
    const isFileJpeg = file && file.contenttype === 'image/jpeg';
    const isEntryJpeg = entry && entry.content_type === 'image/jpeg';

    if (isFileJpeg || isEntryJpeg) {
      const key = file?.key ?? entry?.path;
      const token = file?.token ?? entry?.token;

      if (!key || !token) {
        return null;
      }

      const url = getFilePath(key, token);

      return url;
    } else if (['REALSHORTZ_VIDEO', 'CREATIVE_VIDEO'].includes(attachments[0].type)) {
      const url = attachments[0].video.thumbnail_url || null;
      return url;
    }

    return null;
  }

  const backgroundImageURL = getBackgroundURL();

  const [deletePost] = useDeleteHeadOfficePostMutation({
    context: headOfficeUserContext!
  });

  const handleConfirmDeleteDraft = async (headOfficePostId: string) => {
    try {
      await confirm({
        title: 'Are you sure you want to delete this post?',
        description:
          'Posts and drafts scheduled on workspaces will be deleted.\nWorkspace posts that have already been published will not be deleted.\n\nThis cannot be undone and recovered.'
      });
    } catch (error: any) {
      // Noop, user cancelled
      return;
    }

    try {
      await deletePost({
        variables: {
          args: {
            head_office_post_id: headOfficePostId,
            head_office_id: activeHeadOfficeId!,
            delete_all: false
          }
        }
      });
      await refetch!();

      enqueueSnackbar('Draft post deleted', { variant: 'success' });
    } catch (error: any) {
      console.error(error);
      enqueueSnackbar('Could not delete post', { variant: 'error' });
    }
  };

  const contentTypeMapping: {
    [key in Workspace_Content_Type_Enum]: string;
  } = {
    [Workspace_Content_Type_Enum.Listing]: 'Listing',
    [Workspace_Content_Type_Enum.CallToAction]: 'Call to Action',
    [Workspace_Content_Type_Enum.MarketData]: 'Market Data',
    [Workspace_Content_Type_Enum.AuthorityBuilding]: 'Authority Building',
    [Workspace_Content_Type_Enum.SocialProof]: 'Testimonial & Social Proof',
    [Workspace_Content_Type_Enum.Personal]: 'Personal',
    [Workspace_Content_Type_Enum.NewsUpdate]: 'News Article',
    [Workspace_Content_Type_Enum.AuctionClearanceRates]: 'Auction Clearances',
    [Workspace_Content_Type_Enum.GenericPost]: 'Generic',
    [Workspace_Content_Type_Enum.Other]: 'Other'
  };

  const contentTag =
    appointmentData && appointmentData.metadata
      ? contentTypeMapping[appointmentData.metadata.content_type as Workspace_Content_Type_Enum]
      : null;

  if (appointmentData.type === 'post' || appointmentData.type === 'headOfficePost') {
    const {
      draft: isDraft,
      args = {},
      facebook_post_id: fbId,
      facebook_permalink: fbPermalink,
      instagram_permalink: igPermalink,
      linkedin_post_id: liUrn,
      linkedin_personal_post_id: lipUrn,
      youtube_post_id: ytId,
      gmb_permalink: gmbHref
    } = appointmentData.metadata;

    const hasFacebook = Boolean(args?.facebook);
    const hasInstagram = Boolean(args?.instagram);
    const hasLinkedIn = Boolean(args?.linkedin);
    const hasLinkedInPersonal = Boolean(args?.linkedin_personal);
    const hasYouTube = Boolean(args?.youtube);
    const hasGoogleMyBusiness = Boolean(args?.gmb);

    const generateHref = (base: string, id: string): string | undefined =>
      id ? `${base}${id}` : undefined;

    const [fbPageId, fbPostId] = fbId?.split('_') || [];
    const fbHref = fbPermalink || generateHref(`https://facebook.com/${fbPageId}/posts/`, fbPostId);
    const fbDisabled = isDraft || !fbHref;

    const igHref = igPermalink;
    const igDisabled = isDraft || !igHref;

    const liHref = generateHref('https://linkedin.com/feed/update/', liUrn);
    const liDisabled = isDraft || !liHref;

    const lipHref = generateHref('https://linkedin.com/feed/update/', lipUrn);
    const lipDisabled = isDraft || !lipHref;

    const ytHref = generateHref('https://youtu.be/', ytId);
    const ytDisabled = isDraft || !ytHref;

    const gmbDisabled = isDraft || !gmbHref;

    viewButton = (
      <AvatarGroup color="primary" max={4}>
        {hasFacebook && (
          <MuiLink style={{ border: 'none' }} href={fbHref} target="_blank">
            <FacebookAvatar disabled={fbDisabled} />
          </MuiLink>
        )}
        {hasInstagram && (
          <MuiLink style={{ border: 'none' }} href={igHref} target="_blank">
            <InstagramAvatar disabled={igDisabled} />
          </MuiLink>
        )}
        {hasLinkedIn && (
          <MuiLink style={{ border: 'none' }} href={liHref} target="_blank">
            <LinkedInAvatar disabled={liDisabled} />
          </MuiLink>
        )}
        {hasLinkedInPersonal && (
          <Badge
            overlap="circular"
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            badgeContent={<Avatar style={{ width: 20, height: 20 }} />}
          >
            <MuiLink style={{ border: 'none' }} href={liHref} target="_blank">
              <LinkedInAvatar disabled={lipDisabled} />
            </MuiLink>
          </Badge>
        )}
        {hasYouTube && (
          <MuiLink style={{ border: 'none' }} href={ytHref} target="_blank">
            <YouTubeAvatar disabled={ytDisabled} />
          </MuiLink>
        )}
        {hasGoogleMyBusiness && (
          <MuiLink style={{ border: 'none' }} href={gmbHref} target="_blank">
            <GoogleMyBusinessAvatar disabled={gmbDisabled} />
          </MuiLink>
        )}
      </AvatarGroup>
    );
    if (appointmentData.draft || !appointmentData.posted) {
      deleteButton = (
        <DeletePostButton
          id={appointmentData.id}
          refetch={appointmentData.refetch}
          onHide={onHide}
        />
      );
      editButton = (
        <Tooltip title="Edit post">
          <IconButton onClick={() => handleEditPost(appointmentData.id)} aria-label="Edit Post">
            <EditIcon />
          </IconButton>
        </Tooltip>
      );
    }
  } else {
    switch (appointmentData.type) {
      case 'headOfficePost':
        deleteButton = (
          <IconButton onClick={() => handleConfirmDeleteDraft(appointmentData.id)}>
            <DeleteIcon />
          </IconButton>
        );
        editButton = (
          <Tooltip title="Edit post">
            <IconButton onClick={() => handleEditPost(appointmentData.id)} aria-label="Edit Post">
              <EditIcon />
            </IconButton>
          </Tooltip>
        );

        const platforms = appointmentData?.metadata.platforms || [];

        viewButton = (
          <AvatarGroup color="primary" max={4}>
            {platforms.includes('facebook') && <FacebookAvatar disabled={true} />}
            {platforms.includes('instagram') && <InstagramAvatar disabled={true} />}
            {platforms.includes('linkedin') && <LinkedInAvatar disabled={true} />}
            {platforms.includes('linkedin_personal') && (
              <Badge
                overlap="circular"
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                badgeContent={<Avatar style={{ width: 20, height: 20 }} />}
              >
                <LinkedInAvatar disabled={true} />
              </Badge>
            )}
            {platforms.includes('youtube') && <YouTubeAvatar disabled={true} />}
            {platforms.includes('gmb') && <GoogleMyBusinessAvatar disabled={true} />}
          </AvatarGroup>
        );
        break;

      case 'campaign':
        viewButton = (
          <IconButton component={Link} to={`/campaigns/view/${appointmentData.metadata?.id}`}>
            <ViewIcon />
          </IconButton>
        );
        break;

      case 'rule':
        viewButton = (
          <IconButton component={Link} to={'/automation#schedule'}>
            <ViewIcon />
          </IconButton>
        );
        deleteButton = (
          <DeleteAutomationButton
            id={appointmentData.id}
            refetch={appointmentData.refetch}
            onHide={onHide}
          />
        );
        break;

      default:
        viewButton = null;
        deleteButton = null;
        editButton = null;
        break;
    }
  }

  return (
    <Box>
      <Box
        style={{
          position: 'relative',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-end',
          background: `url(${backgroundImageURL}) no-repeat center center`,
          backgroundSize: 'cover',
          height: backgroundImageURL ? '260px' : '30px',
          margin: 0
        }}
      >
        {appointmentData?.metadata?.content_type && (
          <Chip
            key={appointmentData.metadata.content_type}
            label={contentTag}
            size="small"
            style={{
              position: 'absolute',
              top: '8px',
              left: '8px',
              color: '#fff',
              backgroundColor:
                contentTypeColorMapping[
                  appointmentData.metadata.content_type as keyof typeof contentTypeColorMapping
                ]
            }}
          />
        )}
      </Box>
      <CardContent style={{ display: 'flex', justifyContent: 'space-between', paddingBottom: 0 }}>
        <Box>
          <Typography variant="h6">
            {appointmentData.type === 'rule'
              ? appointmentData.title
              : appointmentData.metadata.name}
          </Typography>
          {appointmentData.workspaceName && (
            <Box>
              <Typography variant="overline">Workspace: {appointmentData.workspaceName}</Typography>
            </Box>
          )}
          {appointmentData.type === 'post' || 'rule' ? (
            <Typography variant="overline">
              Post date:{' '}
              {moment(
                appointmentData.type === 'rule'
                  ? appointmentData.created_at
                  : appointmentData.metadata.scheduled_at
              ).format('D MMM YYYY, h:mm a')}
            </Typography>
          ) : (
            <>
              <Typography variant="overline">
                Start date:
                {moment(appointmentData.metadata.start_date).format('D MMM YYYY, h:mm a')}
              </Typography>
              <Box>
                <Typography variant="overline">
                  End date: {moment(appointmentData.metadata.end_date).format('D MMM YYYY, h:mm a')}
                </Typography>
              </Box>
            </>
          )}

          {appointmentData?.type !== 'rule' && (
            <Box>
              <Typography variant="overline">Status: {appointmentData.status}</Typography>
            </Box>
          )}
          <Box>
            <Typography variant="overline">
              {`Type: ${
                appointmentData.type === 'rule'
                  ? 'automation'
                  : appointmentData.type === 'headOfficePost'
                  ? 'head office post'
                  : appointmentData.type
              }`}
            </Typography>
          </Box>
          <Box>
            <Typography variant="overline">
              Created by: {appointmentData.created_by ?? null}
            </Typography>
          </Box>
        </Box>
      </CardContent>
      <CardActions>
        <Box style={{ display: 'flex', flex: '1', justifyContent: 'space-between' }}>
          <Box style={{ display: 'flex' }}>
            {deleteButton}
            {deleteButton && editButton && (
              <Divider orientation="vertical" flexItem style={{ marginTop: 8, marginBottom: 8 }} />
            )}
            {editButton}
          </Box>
          <Box>{viewButton}</Box>
        </Box>
      </CardActions>
    </Box>
  );
}

interface DeleteAutomationButtonProps {
  id: string;
  refetch?: () => void;
  onHide?: () => void;
}

const DeleteAutomationButton: React.FC<DeleteAutomationButtonProps> = ({ id, refetch, onHide }) => {
  const { workspaceMemberContext } = useHasuraRoleContext();
  const { enqueueSnackbar } = useSnackbar();
  const [deleteRule] = useDeleteAutomationRuleMutation({
    variables: {
      rule_id: id
    },
    context: workspaceMemberContext
  });

  const handleDelete = async () => {
    try {
      const resp = await deleteRule();
      const deletedId = resp?.data?.delete_workspace_automation_rules_by_pk?.id;

      if (!deletedId) {
        throw new Error('Failed to delete automation rule');
      }

      if (refetch) {
        refetch();
      }
      if (onHide) {
        onHide();
      }

      enqueueSnackbar('Automation rule deleted', { variant: 'success' });
    } catch (error: any) {
      logdna.error(error?.message, { error });
      enqueueSnackbar('Failed to delete automation rule', { variant: 'error' });
    }
  };

  return (
    <IconButton onClick={handleDelete}>
      <DeleteIcon />
    </IconButton>
  );
};

interface DeletePostButtonProps {
  id: string;
  refetch?: () => void;
  onHide?: () => void;
}

const DeletePostButton: React.FC<DeletePostButtonProps> = ({ id, refetch, onHide }) => {
  const { workspaceMemberContext } = useHasuraRoleContext();
  const { enqueueSnackbar } = useSnackbar();
  const [deletePost] = useDeletePostMutation({
    variables: {
      post_id: id
    },
    context: workspaceMemberContext
  });

  const handleDelete = async () => {
    try {
      const resp = await deletePost();
      const deletedId = resp?.data?.delete_workspace_posts_by_pk?.id;

      if (!deletedId) {
        throw new Error('Failed to delete scheduled post');
      }

      if (refetch) {
        refetch();
      }
      if (onHide) {
        onHide();
      }

      enqueueSnackbar('Scheduled post deleted', { variant: 'success' });
    } catch (error: any) {
      logdna.error(error?.message, { error });
      enqueueSnackbar('Failed to scheduled post', { variant: 'error' });
    }
  };

  return (
    <IconButton onClick={handleDelete}>
      <DeleteIcon />
    </IconButton>
  );
};
