import React, { useState } from 'react';

import { Form } from 'react-final-form';

import { Button, Dialog, DialogActions, DialogContent, DialogContentText } from '@material-ui/core';

import { makeValidate, TextField } from 'mui-rff';
import * as Yup from 'yup';

import {
  useCreateHeadOfficeShortLinkMutation,
  useCreateShortLinkMutation
} from 'generated/graphql';

import DialogTitle from 'components/DialogTitle';

import { useHasuraRoleContext } from 'lib/HasuraRoleContext';
import useUserContext from 'lib/hooks/useUserContext';

interface ShortLinkDialogProps {
  verb?: string;
  open: boolean;
  onClose: () => void;
  onSave: (url: string) => void;
  listingId?: string;
}

const schema = Yup.object({
  url: Yup.string().url().required().label('Target URL')
}).required();

const validate = makeValidate(schema);

type ShortLinkDialogFormSchema = Yup.InferType<typeof schema>;

const ShortLinkDialog: React.FC<ShortLinkDialogProps> = ({
  verb = 'Insert',
  open,
  onClose,
  onSave,
  listingId
}) => {
  const { workspaceMemberContext, headOfficeUserContext } = useHasuraRoleContext();
  const { activeWorkspaceId, activeHeadOfficeId } = useUserContext();

  const [loading, setLoading] = useState(false);
  const [createShortLink] = useCreateShortLinkMutation({
    context: workspaceMemberContext
  });
  const [createHeadOfficeShortLink] = useCreateHeadOfficeShortLinkMutation({
    context: headOfficeUserContext!
  });

  const handleSubmit = async (values: ShortLinkDialogFormSchema) => {
    setLoading(true);
    // Create Short Link
    try {
      let shortLink: string | undefined | null = null;
      if (headOfficeUserContext && activeHeadOfficeId) {
        const resp = await createHeadOfficeShortLink({
          variables: {
            args: {
              head_office_id: activeHeadOfficeId!,
              target_url: values.url,
              use_existing_link: true
            }
          }
        });

        shortLink = resp.data?.createHeadOfficeShortLink?.head_office_short_link?.short_url;
      } else {
        const resp = await createShortLink({
          variables: {
            args: {
              workspace_id: activeWorkspaceId!,
              target_url: values.url,
              listing_id: listingId
            }
          }
        });

        shortLink = resp.data?.createShortLink?.link?.short_url;
      }

      setLoading(false);
      if (shortLink) {
        onSave(shortLink);
      }
    } catch (error) {
      // Noop
      setLoading(false);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="attachment-dialog-title"
      disableBackdropClick={loading}
    >
      <DialogTitle id="attachment-dialog-title" onClose={onClose}>
        {verb} Short Link
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          Enter your destination url below, and we will create a short link for you.
        </DialogContentText>
        <Form
          validate={validate}
          onSubmit={handleSubmit}
          render={({ handleSubmit }) => {
            return (
              <form onSubmit={handleSubmit} noValidate>
                <TextField
                  name="url"
                  type="url"
                  placeholder="https://www.yourwebsite.com/amazing-property-for-sale"
                />
                <DialogActions>
                  <Button onClick={onClose} color="primary" disabled={loading}>
                    Cancel
                  </Button>
                  <Button type="submit" color="primary" disabled={loading}>
                    {loading ? 'Loading ...' : `${verb} Link`}
                  </Button>
                </DialogActions>
              </form>
            );
          }}
        />
      </DialogContent>
    </Dialog>
  );
};

export default ShortLinkDialog;
