import React, { createContext, useCallback, useMemo } from 'react';

import { ApolloError } from '@apollo/client';

import { GetWorkspaceFeatureFlagsQuery, useGetWorkspaceFeatureFlagsQuery } from 'generated/graphql';

import { useHasuraRoleContext } from 'lib/HasuraRoleContext';
import useUserContext from 'lib/hooks/useUserContext';

interface FeatureFlagWorkspaceContextProps {
  children: React.ReactElement;
}

interface FeatureFlagContextType {
  flags: GetWorkspaceFeatureFlagsQuery['flags']['flags'];
  flagsLoading: boolean;
  flagsError?: ApolloError;
  isFlagEnabled: (flagName: string) => boolean;
  reloadFlags: () => void;
}

export const FeatureFlagContext = createContext<FeatureFlagContextType>({
  flags: [],
  flagsLoading: false,
  isFlagEnabled: () => false,
  reloadFlags: () => {}
});

export function FeatureFlagWorkspaceContext({ children }: FeatureFlagWorkspaceContextProps) {
  const { activeWorkspaceId } = useUserContext();
  const { workspaceMemberContext } = useHasuraRoleContext();

  const { data, loading, error, refetch } = useGetWorkspaceFeatureFlagsQuery({
    variables: {
      workspace_id: activeWorkspaceId!
    },
    pollInterval: 300000,
    context: workspaceMemberContext!,
    skip: !activeWorkspaceId || !workspaceMemberContext
  });

  const flags = useMemo<GetWorkspaceFeatureFlagsQuery['flags']['flags']>(() => {
    if (data?.flags?.flags) {
      return data?.flags?.flags;
    }

    return [];
  }, [data?.flags?.flags]);

  const isFlagEnabled = useCallback(
    (flagName: string) => {
      const enabled = flags.find((flag) => flag.flag_name === flagName)?.enabled ?? false;
      return enabled;
    },
    [flags]
  );

  const value = useMemo<FeatureFlagContextType>(
    () => ({
      flags: flags,
      flagsLoading: loading,
      isFlagEnabled: isFlagEnabled,
      reloadFlags: refetch,
      flagsError: error
    }),
    [flags, loading, isFlagEnabled, refetch, error]
  );

  return <FeatureFlagContext.Provider value={value}>{children}</FeatureFlagContext.Provider>;
}
