import { StepConnector, withStyles } from '@material-ui/core';

const StepperConnector = withStyles((theme) => ({
  lineVertical: {
    borderLeftColor: theme.palette.secondary.main,
    borderLeftStyle: 'dashed',
    borderLeftWidth: 1,
    minHeight: 40
  },
  active: {
    color: theme.palette.secondary.main
  }
}))(StepConnector);

export default StepperConnector;
