import { Box, Typography } from '@material-ui/core';

export default function NotificationsEmptyState() {
  return (
    <Box
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        gap: 40,
        height: '100%'
      }}
    >
      <Box style={{ width: '100%', textAlign: 'center' }}>
        <Typography variant="h6">Inbox Empty</Typography>
        <Typography variant="caption">Great work clearing your notifications!</Typography>
      </Box>
      <img style={{ maxHeight: 300 }} src="/illustrations/no-data.svg" alt="" />
    </Box>
  );
}
