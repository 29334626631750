import { useEffect, useState } from 'react';

import { Link, useNavigate } from 'react-router-dom';

import { Box, Button, Paper } from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';

import PageHeader from 'components/PageHeader';
import FeatureComparisonTable from 'components/billing/FeatureComparisonTable';
import { UpdateSubscriptionDialog } from 'components/billing/UpdateSubscriptionDialog';

import { useFeatureFlags } from 'lib/feature-flags';
import useUserContext from 'lib/hooks/useUserContext';

export default function UpgradeWorkspaceSubscriptionPage() {
  const navigate = useNavigate();

  const { workspace, isWorkspaceAdmin, refetchUserData } = useUserContext();
  const { reloadFlags } = useFeatureFlags();

  const [upgradeModalOpen, setUpgradeModalOpen] = useState<boolean>(false);

  // Redirect workspaces that are upgraded per user
  useEffect(() => {
    if (workspace?.billing_subscription_per_user && isWorkspaceAdmin) {
      navigate('/billing#subscription', { replace: true });
    }
  }, [workspace?.billing_subscription_per_user, isWorkspaceAdmin, navigate]);

  const handleOpenUpgradeSubscriptionModal = () => {
    setUpgradeModalOpen(true);
  };

  const handleCloseUpgradeSubscriptionModal = (success: boolean) => {
    setUpgradeModalOpen(false);
    refetchUserData();
    reloadFlags();

    if (success) {
      navigate('/dashboard');
    }
  };

  const showUpgradeSubscription =
    workspace?.billing_subscription_per_user === false && Boolean(workspace.head_offices?.length);

  return (
    <>
      <div>
        <PageHeader
          title="Upgrade workspace"
          subtitle="Upgrade your workspace to get the most out of Properti"
        />
        <Box>
          <Alert severity="info">
            <AlertTitle title="Feature comparison">Subscription comparison</AlertTitle>
            Upgrading you subscription unlocks more features to streamline your social media
            workflows.
          </Alert>
          <Paper style={{ paddingTop: 4, paddingBottom: 4 }}>
            <Box>
              <FeatureComparisonTable />
            </Box>
            <Box marginY={4} style={{ display: 'flex', placeContent: 'center' }}>
              {showUpgradeSubscription ? (
                <Button
                  variant="contained"
                  size="large"
                  color="secondary"
                  onClick={handleOpenUpgradeSubscriptionModal}
                  disabled={!isWorkspaceAdmin}
                >
                  Choose subscription level
                </Button>
              ) : (
                <Button
                  component={Link}
                  to="/support"
                  variant="contained"
                  size="large"
                  color="secondary"
                  disabled={!isWorkspaceAdmin}
                >
                  Contact support to upgrade
                </Button>
              )}
            </Box>
          </Paper>
        </Box>
      </div>
      {showUpgradeSubscription && (
        <UpdateSubscriptionDialog
          open={upgradeModalOpen}
          onClose={handleCloseUpgradeSubscriptionModal}
        />
      )}
    </>
  );
}
