import React, { useCallback, useMemo } from 'react';

import {
  Select,
  FormControl,
  MenuItem,
  InputLabel,
  Grid,
  Badge,
  Tooltip,
  IconButton,
  TextField
} from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import FilterIcon from '@material-ui/icons/FilterList';
import Autocomplete from '@material-ui/lab/Autocomplete';

import { capitalize } from 'lodash';

import StackedItems from 'components/StackedItems';
import SearchInput from 'components/forms/SearchInput';

function setIfDefined<T>(value: T, fn?: (value: T) => void) {
  if (fn) {
    fn(value);
  }
}

type Primitive = string | number;

type FilterSelectOption = {
  value: string;
  text: string;
};
type FilterOption = FilterSelectOption | string;

interface FilterDropdownProps {
  name: string;
  options: FilterOption[];
  value: string | undefined;
  setValue: (value?: string) => void;
  hasAll?: boolean;
  hidden?: boolean;
  autocomplete?: boolean;
}

function FilterDropdown({
  name,
  options,
  value,
  setValue,
  hasAll,
  hidden = false,
  autocomplete = false
}: FilterDropdownProps) {
  const onChangeHandler = useCallback(
    (event: React.ChangeEvent<{ value: unknown }>) => {
      setValue(event.target.value as string | undefined);
    },
    [setValue]
  );

  if (hidden) {
    return null;
  }

  const isSelected = (optionValue: Primitive | Primitive[]) => {
    if (Array.isArray(value)) {
      // Allows for a value-based comparison, enabling the correct menu item selection to be identified when 'value' is an array.
      return JSON.stringify(value) === JSON.stringify(optionValue);
    }
    return value === optionValue;
  };

  if (autocomplete) {
    if (typeof options[0] === 'string') {
      throw new Error('Autocomplete does not support string options');
    }

    const autocompleteOptions: FilterSelectOption[] = options as FilterSelectOption[];
    const currentValue = autocompleteOptions.find((x) => x.value === value);

    return (
      <Autocomplete
        fullWidth
        value={currentValue}
        onChange={(_, option: FilterSelectOption | null, _reason) => {
          if (option?.value) {
            setValue(option.value as any);
          } else {
            setValue(undefined);
          }
        }}
        options={autocompleteOptions}
        placeholder="Search options ..."
        getOptionSelected={(option, value) => option.value === value.value}
        getOptionLabel={(option) => option.text}
        renderInput={(params) => (
          <TextField {...params} fullWidth label={name} variant="standard" />
        )}
        disableClearable={!hasAll}
        multiple={false}
        freeSolo={false}
      />
    );
  }

  return (
    <FormControl style={{ width: '100%' }}>
      <InputLabel>{name}</InputLabel>
      <Select
        variant="standard"
        autoWidth
        value={value}
        onChange={onChangeHandler}
        renderValue={(selected) => {
          // Find the option text corresponding to the selected value
          const selectedOption = options.find((option) => {
            if (typeof option === 'object' && 'value' in option) {
              return isSelected(option.value);
            }
            return false;
          });
          return selectedOption && typeof selectedOption === 'object' ? selectedOption.text : '';
        }}
      >
        {hasAll && (
          <MenuItem value={undefined}>
            <em>All</em>
          </MenuItem>
        )}
        {options.map((option, idx) => {
          if (typeof option === 'string') {
            return (
              <MenuItem value={option} key={`${option}-${idx}`}>
                {option
                  .split('_')
                  .map((text) => capitalize(text))
                  .join(' ')}
              </MenuItem>
            );
          }
          return (
            <MenuItem value={option.value} key={`${option.value}-${idx}`}>
              {option.text}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
}

interface MultiFilterDropdownProps {
  menuItems: FilterDropdownProps[];
  filtersOpen?: boolean;
  search: boolean;
  searchValue?: string;
  setSearch?: (val: string) => void;
  onToggleFiltersOpen?: () => void;
  hideClearButton?: boolean;
  customActionButton?: React.ReactNode;
  hideToggleFiltersButton?: boolean;
}

export default function MultiFilterDropdown({
  menuItems,
  filtersOpen,
  onToggleFiltersOpen,
  hideClearButton = false,
  hideToggleFiltersButton = false,
  customActionButton,
  search,
  searchValue,
  setSearch
}: MultiFilterDropdownProps) {
  const activeFilters = useMemo(() => {
    return menuItems?.reduce((num, item) => {
      if (item.value !== undefined) {
        num++;
      }

      return num;
    }, 0);
  }, [menuItems]);

  const handleToggleFilter = () => {
    setIfDefined(undefined, onToggleFiltersOpen);
  };

  const handleChangeSearch = (event: any) => {
    setIfDefined(event.target.value, setSearch);
  };

  const handleClickSearch = () => {};

  const handleClearSearch = () => {
    setIfDefined('', setSearch);
  };

  const clearActiveFilters = () => menuItems?.forEach((item) => item.setValue(undefined));

  return (
    <>
      <StackedItems containerProps={{ style: { padding: 8 } }} justifyContent="flex-end">
        {!!search && (
          <SearchInput
            variant="standard"
            onClickClear={handleClearSearch}
            onClickSearch={handleClickSearch}
            placeholder="Search"
            value={searchValue}
            onChange={handleChangeSearch}
            autoFocus
          />
        )}
        {menuItems?.length > 0 && !hideToggleFiltersButton && (
          <Badge
            badgeContent={activeFilters}
            invisible={filtersOpen || activeFilters === 0}
            color="primary"
            overlap="circular"
          >
            <Tooltip
              title={
                activeFilters === 0
                  ? 'Custom Filters'
                  : `Custom Filters (${activeFilters} Active Filter${activeFilters > 1 ? 's' : ''})`
              }
            >
              <IconButton onClick={handleToggleFilter}>
                <FilterIcon />
              </IconButton>
            </Tooltip>
          </Badge>
        )}
      </StackedItems>

      {filtersOpen && menuItems?.length > 0 && (
        <StackedItems
          containerProps={{ style: { paddingTop: 4, paddingRight: 8 } }}
          alignItems="flex-end"
          wrap="nowrap"
        >
          <Grid item xs style={{ flexGrow: 1, width: '100%' }}>
            <StackedItems spacing={1} justifyContent="flex-end">
              {menuItems.map((menuItem, idx) => (
                <Grid item xs={6} sm={4} md={3} lg={2} xl={2} key={menuItem.name}>
                  <FilterDropdown key={idx} {...menuItem} />
                </Grid>
              ))}
            </StackedItems>
          </Grid>
          {!hideClearButton && (
            <Tooltip title={activeFilters === 0 ? 'No active filters' : 'Clear active filters'}>
              <div>
                <IconButton disabled={activeFilters === 0} onClick={clearActiveFilters}>
                  <ClearIcon />
                </IconButton>
              </div>
            </Tooltip>
          )}
          {customActionButton}
        </StackedItems>
      )}
    </>
  );
}
