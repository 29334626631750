import React, { useMemo } from 'react';

import { Form } from 'react-final-form';

import { Button, Dialog, DialogActions, DialogContent, DialogContentText } from '@material-ui/core';

import { makeValidate, TextField } from 'mui-rff';
import * as Yup from 'yup';

import DialogTitle from 'components/DialogTitle';

interface AttachmentDialogProps {
  mode?: 'workspace' | 'headoffice';
  open: boolean;
  onClose: () => void;
  onRemoveAttachment: () => void;
  onSaveAttachment: (url?: string | null) => void;
  attachmentUrl?: string | null;
}

const workspaceSchema = Yup.object({
  url: Yup.string().url()
}).required();

const workspaceValidate = makeValidate(workspaceSchema);

const headOfficeSchema = Yup.object({
  url: Yup.string()
    .label('Landing page URL')
    .nullable()
    .test('is-url-or-merge-tag', 'Invalid URL or merge tag', (value: string) => {
      if (!value) {
        return true; // Allow null or empty values if nullable
      }

      if (
        value.includes('{{ WORKSPACE_URL_SLUG }}') ||
        value.includes('{{ WORKSPACE_URL_PREFIX }}') ||
        value.includes('{{ WORKSPACE_WEBSITE }}') ||
        value.includes('{{ WORKSPACE_APPRAISAL_LANDING_PAGE }}')
      ) {
        // Replace merge tag with a valid domain to validate the rest of the URL
        const testUrl = value
          .replace('{{ WORKSPACE_URL_PREFIX }}', 'https://example.lp.properti.ai')
          .replace('{{ WORKSPACE_WEBSITE }}', 'https://example.com')
          .replace('{{ WORKSPACE_URL_SLUG }}', 'example')
          .replace(
            '{{ WORKSPACE_APPRAISAL_LANDING_PAGE }}',
            'https://example.lp.properti.ai/p/appraisal'
          );
        return Yup.string().url().isValidSync(testUrl);
      }

      // If no merge tag, use standard URL validation
      return Yup.string().url().isValidSync(value);
    })
}).required();

const headOfficeValidate = makeValidate(headOfficeSchema);

type AttachmentDialogFormSchema = Yup.InferType<typeof workspaceSchema>;

const AttachmentDialog: React.FC<AttachmentDialogProps> = ({
  open,
  onClose,
  onRemoveAttachment,
  onSaveAttachment,
  attachmentUrl,
  mode = 'workspace'
}) => {
  const handleSubmit = (values: AttachmentDialogFormSchema) => {
    onSaveAttachment(values?.url);
  };

  const validate = useMemo(() => {
    if (mode === 'headoffice') {
      return headOfficeValidate;
    }

    return workspaceValidate;
  }, [mode]);

  return (
    <Dialog open={open} onClose={onClose} aria-labelledby="attachment-dialog-title">
      <DialogTitle onClose={onClose}>Attach Webpage</DialogTitle>
      <DialogContent>
        <DialogContentText>
          To attach an article or website to this post, enter the url below.
        </DialogContentText>
        <Form
          initialValues={{
            url: attachmentUrl
          }}
          validate={validate}
          onSubmit={handleSubmit}
          render={({ handleSubmit }) => {
            return (
              <form onSubmit={handleSubmit} noValidate>
                <TextField
                  name="url"
                  type="url"
                  placeholder="https://wwww.yourwebsite.com/article"
                />
                <DialogActions>
                  <Button onClick={onClose} color="primary">
                    Cancel
                  </Button>
                  <Button onClick={onRemoveAttachment} color="primary">
                    Remove
                  </Button>
                  <Button type="submit" color="primary">
                    Save
                  </Button>
                </DialogActions>
              </form>
            );
          }}
        />
      </DialogContent>
    </Dialog>
  );
};

export default AttachmentDialog;
