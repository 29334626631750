import { useCallback, useEffect, useMemo, useState } from 'react';

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Grid,
  Link,
  Typography
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Alert, AlertTitle } from '@material-ui/lab';

import { useSnackbar } from 'notistack';
import numbro from 'numbro';

import {
  AvailableWorkspaceSubscriptionsFromHeadOfficeQuery,
  Workspace_Subscription_Model_Enum,
  useAvailableWorkspaceSubscriptionsFromHeadOfficeQuery,
  useUpdateWorkspaceSubscriptionMutation
} from 'generated/graphql';

import FeatureComparisonTable, {
  useFeaturesAccordionStyles
} from 'components/billing/FeatureComparisonTable';
import CategoryCheckbox from 'components/forms/CategoryCheckbox';

import { useHasuraRoleContext } from 'lib/HasuraRoleContext';
import { APP_CONFIG } from 'lib/app/config';
import { getNextSubscriptionLevel, isMaxSubscriptionLevel } from 'lib/billing/subscriptions';
import { useFeatureFlags } from 'lib/feature-flags';
import useUserContext from 'lib/hooks/useUserContext';
import { sleep } from 'lib/utils/sleep';

interface SelectSubscriptionSectionProps {
  onSubscriptionSelected: () => void;
}

type SubscriptionOption =
  AvailableWorkspaceSubscriptionsFromHeadOfficeQuery['head_office_workspace_subscription_options'][number];

export default function SelectSubscriptionSection({
  onSubscriptionSelected
}: SelectSubscriptionSectionProps) {
  const styles = useFeaturesAccordionStyles();

  const { activeWorkspaceId, workspace } = useUserContext();
  const { workspaceMemberContext } = useHasuraRoleContext();
  const { reloadFlags } = useFeatureFlags();

  const { enqueueSnackbar } = useSnackbar();

  const [hasConfirmedSubscription, setHasConfirmedSubscription] = useState<boolean>(false);

  const billsPerUser = useMemo(
    () => workspace?.billing_subscription_per_user ?? false,
    [workspace?.billing_subscription_per_user]
  );
  const currentSubscriptionLevel = useMemo(
    () => workspace?.subscription_model,
    [workspace?.subscription_model]
  );

  const [chosenSubscriptionLevel, setChosenSubscriptionLevel] =
    useState<Workspace_Subscription_Model_Enum>(
      currentSubscriptionLevel ?? Workspace_Subscription_Model_Enum.Basic
    );
  const [chosenOption, setChosenOption] = useState<SubscriptionOption | null>(null);

  const { data, loading } = useAvailableWorkspaceSubscriptionsFromHeadOfficeQuery({
    variables: {
      workspace_id: activeWorkspaceId!
    },
    context: workspaceMemberContext
  });
  const [updateSubscription, { loading: updatingSubscription }] =
    useUpdateWorkspaceSubscriptionMutation({
      context: workspaceMemberContext
    });

  // Can be a delay in workspace data loading and state being initialised
  // Init to next level, to prompt the upgrade
  useEffect(() => {
    if (currentSubscriptionLevel) {
      setChosenSubscriptionLevel(getNextSubscriptionLevel(currentSubscriptionLevel));
    }
  }, [setChosenSubscriptionLevel, currentSubscriptionLevel]);

  useEffect(() => {
    if (billsPerUser) {
      setHasConfirmedSubscription(billsPerUser);
    }
  }, [billsPerUser, setHasConfirmedSubscription]);

  const handleChooseSubscription = useCallback(
    (option: SubscriptionOption) => {
      setChosenSubscriptionLevel(option.subscription_level);
      setChosenOption(option);
    },
    [setChosenSubscriptionLevel, setChosenOption]
  );

  const handleConfirmSubscription = useCallback(async () => {
    if (chosenSubscriptionLevel === currentSubscriptionLevel) {
      return setHasConfirmedSubscription(true);
    }

    if (!chosenOption) {
      return enqueueSnackbar('Please choose an option first', { variant: 'error' });
    }

    try {
      await updateSubscription({
        variables: {
          args: {
            workspace_id: activeWorkspaceId!,
            price_id: chosenOption.stripe_product.default_price?.id!,
            subscription_level: chosenOption.subscription_level!,
            head_office_workspace_subscription_option_id: chosenOption.id
          }
        }
      });

      enqueueSnackbar('Subscription confirmed', { variant: 'success' });

      // Reload flags in background after background tasks have had a chance to run
      void sleep(2500).then(reloadFlags);
    } catch (error: any) {
      return enqueueSnackbar(error?.message ?? 'Unable to confirm subscription', {
        variant: 'error'
      });
    }

    setHasConfirmedSubscription(true);
  }, [
    setHasConfirmedSubscription,
    chosenSubscriptionLevel,
    currentSubscriptionLevel,
    activeWorkspaceId,
    chosenOption,
    enqueueSnackbar,
    updateSubscription,
    reloadFlags
  ]);

  const options = useMemo(() => {
    // Sort function mutates array, so we need to copy the immutable response
    const unsorted = Array.from(data?.head_office_workspace_subscription_options ?? []);

    return unsorted.sort(
      (a, b) =>
        (a.stripe_product?.default_price?.unit_amount ?? 0) -
        (b.stripe_product?.default_price?.unit_amount ?? 0)
    );
  }, [data?.head_office_workspace_subscription_options]);

  const paidOptions = useMemo(
    () => options.filter((o) => (o.stripe_product?.default_price?.unit_amount ?? 0) > 0),
    [options]
  );
  const freeOptions = useMemo(
    () => options.filter((o) => (o.stripe_product?.default_price?.unit_amount ?? 0) === 0),
    [options]
  );

  // Preset chosen option, so user can just hit confirm and subscribe.
  useEffect(() => {
    if (chosenSubscriptionLevel) {
      const option = options.find(
        (option) => option.subscription_level === chosenSubscriptionLevel
      );
      if (option) {
        setChosenOption(option);
      }
    }
  }, [setChosenOption, options, chosenSubscriptionLevel]);

  // Set as complete if current level is one of the paid options
  // Show options if still on free plan
  useEffect(() => {
    if (currentSubscriptionLevel) {
      const found = paidOptions.find(
        (option) => option.subscription_level === currentSubscriptionLevel
      );

      const isMaxLevel = isMaxSubscriptionLevel(currentSubscriptionLevel);
      if (found || isMaxLevel) {
        setHasConfirmedSubscription(true);
      }
    }
  }, [currentSubscriptionLevel, paidOptions, setHasConfirmedSubscription]);

  useEffect(() => {
    if (hasConfirmedSubscription) {
      onSubscriptionSelected();
    }
  }, [onSubscriptionSelected, hasConfirmedSubscription]);

  if (loading) {
    return <CircularProgress />;
  }

  if (hasConfirmedSubscription) {
    return (
      <Box maxWidth={1000}>
        <Typography variant="h6">Select your subscription level</Typography>
        <Alert severity="success">
          {billsPerUser ? (
            <>
              <AlertTitle>Subscription based on user count</AlertTitle>
              Simply add more team members to your workspaces to unlock more features
            </>
          ) : (
            <AlertTitle>Subscription chosen</AlertTitle>
          )}
        </Alert>
      </Box>
    );
  }

  if (options.length === 0) {
    return (
      <Box maxWidth={1000}>
        <Typography variant="h6">Select your subscription level</Typography>
        <Box mb={1}>
          <Alert severity="info">
            <Accordion classes={{ root: styles.root }} elevation={0}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <AlertTitle>Subscription comparison (click to toggle comparison)</AlertTitle>
              </AccordionSummary>
              <AccordionDetails>
                <FeatureComparisonTable />
              </AccordionDetails>
            </Accordion>
          </Alert>
        </Box>

        <Box>
          <Alert severity="warning">
            <AlertTitle>Contact support to change your subscription level</AlertTitle>
          </Alert>
        </Box>
      </Box>
    );
  }

  return (
    <Box maxWidth={1000}>
      <Typography variant="h6">Select your subscription level</Typography>

      <Box mb={1}>
        <Alert severity="info">
          <Accordion classes={{ root: styles.root }} elevation={0}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <AlertTitle>Subscription comparison (click to toggle comparison)</AlertTitle>
            </AccordionSummary>
            <AccordionDetails>
              <FeatureComparisonTable />
            </AccordionDetails>
          </Accordion>
        </Alert>
      </Box>

      <Grid container spacing={1}>
        {paidOptions.map((option) => (
          <Grid item key={option.id}>
            <CategoryCheckbox
              title={option.name ?? option.stripe_product.name}
              description={buildDescription(option, workspace?.currency)}
              checked={option.subscription_level === chosenSubscriptionLevel}
              value={option.subscription_level}
              onClick={() => handleChooseSubscription(option)}
              readonly={updatingSubscription}
            />
          </Grid>
        ))}
      </Grid>

      <Box mt={1}>
        {freeOptions.map((option) => (
          <FormControlLabel
            key={option.id}
            label={`${option.name ?? option.stripe_product.name} (${buildDescription(
              option,
              workspace?.currency
            )})`}
            disabled={updatingSubscription}
            control={
              <Checkbox
                checked={option.subscription_level === chosenSubscriptionLevel}
                value={option.subscription_level}
                onClick={() => handleChooseSubscription(option)}
                disabled={updatingSubscription}
              />
            }
          />
        ))}
      </Box>

      <Box mt={2} mb={1}>
        <Typography variant="caption" gutterBottom>
          By subscribing you agree to {APP_CONFIG.SITE_TITLE}'s{' '}
          <Link
            href={APP_CONFIG.SITE_TERMS_URL}
            color="secondary"
            target="_blank"
            rel="noopener noreferrer"
          >
            Terms of Service
          </Link>{' '}
          and its{' '}
          <Link
            href={APP_CONFIG.SITE_PRIVACY_URL}
            color="secondary"
            target="_blank"
            rel="noopener noreferrer"
          >
            Privacy Policy
          </Link>
          .
        </Typography>
      </Box>

      <Button
        variant="contained"
        color="primary"
        size="large"
        onClick={handleConfirmSubscription}
        disabled={updatingSubscription}
      >
        {updatingSubscription ? 'Loading ...' : 'Confirm subscription'}
      </Button>
    </Box>
  );
}

const buildDescription = (option: SubscriptionOption, workspaceCurrency?: string): string => {
  let price: string = option.stripe_product.description ?? '';

  if (option.stripe_product.default_price) {
    const productPrice = option.stripe_product.default_price!;
    const productPriceCurrency =
      productPrice.currency_options?.find(
        (cur) => cur.currency === workspaceCurrency?.toLowerCase()
      ) ?? productPrice;
    price = price.concat(
      '\n\n',
      `${numbro((productPriceCurrency.unit_amount ?? 0) / 100).formatCurrency({
        thousandSeparated: true,
        spaceSeparatedCurrency: false
      })} ${productPriceCurrency.currency.toUpperCase()} per ${productPrice.recurring?.interval}`
    );
  }

  if (price !== '') {
    return price.trim();
  }

  return `Subscription level: ${option.subscription_level}`;
};
