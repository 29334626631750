import React, { useMemo } from 'react';

import { SuprSendProvider } from '@suprsend/react-inbox';

import useUserContext from 'lib/hooks/useUserContext';

const SUPRSEND_WORKSPACE_KEY = process.env.REACT_APP_SUPRSEND_WORKSPACE_KEY;

interface SuprSendNotificationsProviderProps {
  children: React.ReactElement;
}

export default function SuprSendNotificationsProvider({
  children
}: SuprSendNotificationsProviderProps) {
  const { userId, info } = useUserContext();

  const storedUserId = useMemo(() => userId, [userId]);
  const suprsendSubscriberId = useMemo(
    () => info?.suprsend_subscriber_id,
    [info?.suprsend_subscriber_id]
  );

  if (!storedUserId || !SUPRSEND_WORKSPACE_KEY) {
    return children;
  }

  return (
    <SuprSendProvider
      workspaceKey={SUPRSEND_WORKSPACE_KEY!}
      distinctId={storedUserId}
      subscriberId={suprsendSubscriberId ?? undefined}
    >
      {children}
    </SuprSendProvider>
  );
}
