import { useCallback, useEffect, useState } from 'react';

import Zoom from 'react-medium-image-zoom';
import useCopyToClipboard from 'react-use/lib/useCopyToClipboard';

import { Link as MuiLink } from '@material-ui/core';

import MaterialTable from '@material-table/core';

import moment from 'moment';
import { useSnackbar } from 'notistack';

import {
  useWorkspaceRssContentItemsQuery,
  Workspace_Rss_Content_Items_Bool_Exp
} from 'generated/graphql';

import ShareDrawer from 'components/ShareDrawer';
import TableContainer from 'components/TableContainer';
import { ShareLogicVariants } from 'components/types';

import { useHasuraRoleContext } from 'lib/HasuraRoleContext';
import { usePagination } from 'lib/hooks/useFiltersAndPagination';
import useUserContext from 'lib/hooks/useUserContext';

export default function RSSFeedTab() {
  const { workspaceMemberContext } = useHasuraRoleContext();
  const { activeWorkspaceId } = useUserContext();

  const { enqueueSnackbar } = useSnackbar();
  const [, copyToClipboard] = useCopyToClipboard();

  const { limit, setLimit, page, setPage } = usePagination(10);
  const [search, setSearch] = useState<string>();
  const [shareOpen, setShareOpen] = useState<boolean>(false);
  const [shareId, setShareId] = useState<string | null>();

  const searchFilter = search?.length ? `%${search.replace(/\s+/, '%')}%` : undefined;
  const filters: Workspace_Rss_Content_Items_Bool_Exp[] | undefined = searchFilter
    ? [
        {
          title: { _ilike: searchFilter }
        },
        { channel: { _ilike: searchFilter } },
        {
          author: { _ilike: searchFilter }
        }
      ]
    : undefined;

  const where: Workspace_Rss_Content_Items_Bool_Exp = {
    _and: [
      {
        workspace_id: { _eq: activeWorkspaceId! }
      },
      {
        _or: filters
      }
    ]
  };

  const { data, loading, refetch } = useWorkspaceRssContentItemsQuery({
    variables: {
      where: where,
      limit: limit,
      offset: page * limit
    },
    context: workspaceMemberContext
  });

  // Reload when page enters view
  // As we have a SPA, pages enter and leave view without full reloads
  useEffect(() => {
    refetch();
  }, [refetch]);

  const handleCopyLink = useCallback(
    (_event: any, row: any) => {
      copyToClipboard(row.link);
      enqueueSnackbar('Link copied to clipboard!', { variant: 'success' });
    },
    [copyToClipboard, enqueueSnackbar]
  );

  const handleOpenShareDrawer = useCallback(
    (_event: any, row: any) => {
      setShareOpen(true);
      setShareId(row.id);
    },
    [setShareOpen]
  );

  const handleCloseShareDrawer = useCallback(() => {
    setShareOpen(false);
    setShareId(null);
  }, [setShareOpen, setShareId]);

  const count = data?.items_aggregate?.aggregate?.count ?? 0;

  // https://github.com/mbrn/material-table/issues/1979
  const items = data?.items?.map((i) => ({ ...i })) ?? [];

  return (
    <>
      <MaterialTable
        title="Content Feed"
        columns={[
          {
            title: 'Title',
            field: 'title',
            render: (row) => (
              <span
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'flex-start'
                }}
              >
                <Zoom>
                  <img src={row.image ?? '/brands/rss.png'} style={{ width: 100 }} alt="" />
                </Zoom>
                <MuiLink style={{ marginLeft: 16 }} href={row.link} target="_blank">
                  {row.title}
                </MuiLink>
              </span>
            )
          },
          {
            title: 'Created',
            field: 'created_at',
            render: (row) => moment(row.created_at).format('D MMM YYYY h:mm A')
          },
          {
            title: 'Channel',
            field: 'channel'
          },
          {
            title: 'Author',
            field: 'author'
          },
          {
            title: 'Post Date',
            field: 'posts',
            render: (row) => {
              if (!row.posts || row.posts.length === 0) {
                return 'Never';
              } else if (row.posts.length === 1) {
                const post = row.posts[0];
                return moment(post.scheduled_at).format('D MMM YYYY h:mm A');
              } else {
                const post = row.posts[0];
                return `Multiple from ${moment(post.scheduled_at).format('D MMM YYYY')}`;
              }
            }
          }
        ]}
        data={items}
        totalCount={count}
        page={page}
        isLoading={loading}
        options={{
          search: true,
          debounceInterval: 500,
          toolbar: true,
          sorting: false,
          draggable: false,
          filtering: false,
          actionsColumnIndex: -1,
          pageSize: limit
        }}
        actions={[
          {
            icon: 'open_in_new',
            tooltip: 'Visit',
            onClick: (_, rowData: any) => {
              if (window.open) {
                window.open(rowData.link, '_blank');
              }
            }
          },
          {
            icon: 'file_copy',
            tooltip: 'Copy Link',
            onClick: handleCopyLink
          },
          {
            icon: 'share',
            tooltip: 'Share',
            onClick: handleOpenShareDrawer
          }
        ]}
        onRowsPerPageChange={(rowsPerPage) => setLimit(rowsPerPage)}
        onPageChange={(newPage) => setPage(newPage)}
        onSearchChange={(newSearch) => setSearch(newSearch)}
        components={{
          Container: TableContainer
        }}
      />
      <ShareDrawer
        open={shareOpen}
        onClose={handleCloseShareDrawer}
        title={'Share RSS Content'}
        variant={ShareLogicVariants.CONTENT_RSS}
        rssContentId={shareId!}
      />
    </>
  );
}
