import React from 'react';

import { Box, Typography } from '@material-ui/core';

import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 280px;
  margin-right: auto;
  margin-left: auto;
  height: 60vh;
  justify-content: center;
  align-items: center;
`;

const Center = styled.div`
  text-align: center;
`;

interface EmptyStatePageProps {
  icon?: React.ReactNode;
  title: string;
  text: string;
  button?: React.ReactNode;
  containerStyle?: React.CSSProperties;
  error?: boolean;
}

const EmptyStatePage: React.FC<EmptyStatePageProps> = ({
  icon,
  title,
  text,
  button,
  containerStyle,
  error = false
}) => {
  return (
    <Container style={containerStyle}>
      <Center>
        <Box>{icon}</Box>
        <Box mt={2}>
          <Typography variant="h3" color={error ? 'error' : 'secondary'} gutterBottom>
            {title}
          </Typography>
          <Typography variant="body2">{text}</Typography>
        </Box>
        <Box mt={3}>{button}</Box>
      </Center>
    </Container>
  );
};

export default EmptyStatePage;
