import { useCallback, useRef } from 'react';

import { Form } from 'react-final-form';

import {
  Button,
  ButtonGroup,
  Drawer,
  IconButton,
  MenuItem,
  Typography,
  capitalize,
  Grid,
  LinearProgress
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Clear';

import { FORM_ERROR } from 'final-form';
import { CheckboxData, makeValidate, TextField, Checkboxes } from 'mui-rff';
import { useSnackbar } from 'notistack';
import styled from 'styled-components';
import * as Yup from 'yup';

import {
  CreativePostScheduleEnum,
  CreativeTemplateTypeEnum,
  TemplateTypeEnum,
  useCreateCustomerReviewMutation,
  useCreateImageCreativeMutation,
  Workspace_Review_Source_Enum,
  Workspace_Review_Types_Enum
} from 'generated/graphql';

import { dynamicParameterSchema } from 'components/creative/dynamicParameters';
import AgentAutocomplete from 'components/forms/AgentAutocomplete';
import FinalFormCondition from 'components/forms/FinalFormCondition';
import Rating from 'components/forms/FinalFormRating';
import { FinalFormTemplateSelector } from 'components/forms/FinalFormTemplateSelector';
import PropertyAutocomplete from 'components/forms/PropertyAutocomplete';

import { useHasuraRoleContext } from 'lib/HasuraRoleContext';
import useUserContext from 'lib/hooks/useUserContext';

const creativeCreativeCheckbox: CheckboxData = {
  label: 'Create a creative with this review?',
  value: 'create_creative'
};

interface AddCustomerReviewDrawerProps {
  open: boolean;
  onClose: () => void;
}

const schema = Yup.object({
  name: Yup.string().required().label('Customer Name').required("Please enter the customer's name"),
  title: Yup.string().optional().label('Review title'),
  review: Yup.string().required().label('Customer Review').required('Please enter a review'),
  rating: Yup.number().min(0).max(5).label('Rating').required('Please select a review rating'),
  agent_user_id: Yup.string().label('Select Listing Agent').nullable(),
  listing_id: Yup.string().label('Listing').nullable(),
  type: Yup.string()
    .oneOf([
      Workspace_Review_Types_Enum.Buyer,
      Workspace_Review_Types_Enum.Seller,
      Workspace_Review_Types_Enum.Landlord,
      Workspace_Review_Types_Enum.Renter
    ])
    .required('Please select a review type'),
  create_creative: Yup.bool(),
  template_id: Yup.string().when('create_creative', {
    is: true,
    then: Yup.string().required('You must select a template')
  }),
  dynamic_parameters: dynamicParameterSchema.nullable().notRequired()
}).required();

const validate = makeValidate(schema);

type FormValues = Yup.InferType<typeof schema>;

const reviewTypes: Workspace_Review_Types_Enum[] = [
  Workspace_Review_Types_Enum.Buyer,
  Workspace_Review_Types_Enum.Landlord,
  Workspace_Review_Types_Enum.Renter,
  Workspace_Review_Types_Enum.Seller
];

const useStyles = makeStyles((theme) => ({
  templatePadding: {
    padding: theme.spacing(1)
  }
}));

function AddCustomerReviewDrawer({ open, onClose }: AddCustomerReviewDrawerProps) {
  const { workspaceMemberContext } = useHasuraRoleContext();
  const { activeWorkspaceId, workspace } = useUserContext();

  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [createReview] = useCreateCustomerReviewMutation({
    context: workspaceMemberContext
  });
  const [createCreative] = useCreateImageCreativeMutation({
    context: workspaceMemberContext
  });

  const submitRef = useRef<any>();
  const onSubmit = useCallback(
    async (values: FormValues) => {
      try {
        if (!values) {
          throw new Error('Values not present');
        }

        const res = await createReview({
          variables: {
            object: {
              workspace_id: activeWorkspaceId!,
              name: values.name,
              title: values.title,
              review: values.review,
              listing_id: values.listing_id,
              type: values.type,
              agent_user_id: values.agent_user_id,
              rating: Number(values.rating),
              source: Workspace_Review_Source_Enum.Manual
            }
          }
        });

        const { id: reviewId, name, review } = res.data?.insert_workspace_reviews_one ?? {};

        let isCreativeCreated = false;

        if (reviewId && name && review && values.create_creative) {
          // NOTE inner try/catch because if review creates successfully we don't
          // want to give the impression that the whole operation failed. So we will give an error
          // message but still show that the review created successfully
          try {
            const urlPrefix = workspace?.url_prefix;
            const appraisalUrl = workspace?.branding?.landing_pages?.appraisal?.trim();

            const creativeRes = await createCreative({
              variables: {
                args: {
                  template_id: values.template_id!,
                  dynamic_parameters: values.dynamic_parameters ?? undefined,
                  workspace_id: activeWorkspaceId!,
                  title: `Creative created from review - ${name}`,
                  message: review,
                  url: appraisalUrl
                    ? appraisalUrl
                    : `https://${urlPrefix}.lp.properti.ai/p/appraisal`,
                  params: {
                    listing_id: values.listing_id,
                    num_images: 1,
                    review_id: reviewId
                  },
                  schedule: CreativePostScheduleEnum.Never
                }
              }
            });

            if (creativeRes.data?.createImageCreative.creative_id) {
              isCreativeCreated = true;
            } else {
              enqueueSnackbar(`Failed to create creative`, { variant: 'error' });
            }
          } catch (error: any) {
            enqueueSnackbar(
              `Failed to create creative${error.message ? ' - ' + error.message : ''}`,
              { variant: 'error' }
            );
          }
        }

        enqueueSnackbar(`Customer Review Saved`, { variant: 'success' });
        if (isCreativeCreated) {
          enqueueSnackbar(`Creative created successfully`, { variant: 'success' });
        }
        onClose();
      } catch (error) {
        return {
          [FORM_ERROR]: 'Unable to save review'
        };
      }
    },
    [
      createReview,
      enqueueSnackbar,
      onClose,
      activeWorkspaceId,
      createCreative,
      workspace?.branding?.landing_pages?.appraisal,
      workspace?.url_prefix
    ]
  );

  const onClickSubmit = useCallback(() => {
    if (submitRef.current) {
      submitRef.current();
    }
  }, []);

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={onClose}
      elevation={2}
      ModalProps={{
        disableEnforceFocus: true
      }}
      BackdropProps={{
        style: {
          backgroundColor: '#333333',
          opacity: 0.9
        }
      }}
    >
      <Container>
        <Top>
          <Typography variant="h5">Add Customer Review</Typography>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Top>
        <Middle>
          <Form
            onSubmit={onSubmit}
            validate={validate}
            initialValues={{
              rating: '5'
            }}
            render={({ handleSubmit, submitting }) => {
              submitRef.current = handleSubmit;

              if (submitting) {
                return <LinearProgress />;
              }

              return (
                <form onSubmit={handleSubmit} noValidate>
                  <div>
                    <TextField name="name" label="Customer Name" />
                    <TextField name="title" label="Review title (optional)" />
                    <TextField name="type" label="Review Type" select style={{ textAlign: 'left' }}>
                      {reviewTypes.map((type) => (
                        <MenuItem key={type} value={type}>
                          {capitalize(type)}
                        </MenuItem>
                      ))}
                    </TextField>
                    <TextField name="review" label="Review" multiline minRows={4} />
                    <Rating name="rating" precision={0.5} size="large" />

                    <FinalFormCondition when="rating" lte={2.5}>
                      <Typography variant="h6" color="secondary">
                        Low rating, are you sure you want to post this review?
                      </Typography>
                    </FinalFormCondition>
                    <AgentAutocomplete name="agent_user_id" multiple={false} limitAgentUsers />
                    <PropertyAutocomplete name="listing_id" label="Listing" multiple={false} />
                    <Checkboxes name="create_creative" data={creativeCreativeCheckbox} />
                    <FinalFormCondition when="create_creative" equals={true}>
                      {/* NOTE fixes horizontal scroll - https://v4.mui.com/components/grid/#negative-margin */}
                      <div className={classes.templatePadding}>
                        <Grid container alignItems="center" spacing={1}>
                          <Grid item xs={12}>
                            <FinalFormTemplateSelector
                              name="template_id"
                              label="Select Template"
                              creativeType={CreativeTemplateTypeEnum.Image}
                              templateType={TemplateTypeEnum.Review}
                            />
                          </Grid>
                        </Grid>
                      </div>
                    </FinalFormCondition>
                  </div>
                </form>
              );
            }}
          />
        </Middle>
        <Bottom>
          <ButtonGroup>
            <Button variant="text" size="large" onClick={onClose}>
              Cancel
            </Button>
            <Button
              variant="contained"
              size="large"
              color="secondary"
              type="submit"
              onClick={onClickSubmit}
            >
              Save
            </Button>
          </ButtonGroup>
        </Bottom>
      </Container>
    </Drawer>
  );
}

const Container = styled.div`
  min-width: 560px;
  width: 640px;
  max-width: 640px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const Top = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 16px;
`;

const Middle = styled.div`
  padding: 20px 8px 8px 10px;
  flex-grow: 1;
  text-align: center;
  background-color: #f5f6f8;
  overflow-y: auto;
`;

const Bottom = styled.div`
  padding: 16px;
  display: flex;
  flex-direction: row-reverse;
`;

export default AddCustomerReviewDrawer;
