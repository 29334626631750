import React, { useCallback, useMemo, useState } from 'react';

import { FormSpy, useForm } from 'react-final-form';

import { Button, ButtonGroup, CircularProgress, Typography } from '@material-ui/core';
import Pagination from '@material-ui/lab/Pagination';

import { Workspace_User_Roles_Enum } from 'generated/graphql';

import PaginationContainer from 'components/PaginationContainer';
import FinalFormListCheckboxes from 'components/forms/FinalFormListCheckboxes';

import { useTeamMemberList } from 'lib/hooks/useTeamMemberList';

import SearchInput from '../SearchInput';

interface AgentSelectInputProps {
  name: string;
  multiple?: boolean;
  role?: 'all' | Workspace_User_Roles_Enum;
  dense?: boolean;
  ignoreAgentUserAllocatedAgentNames?: boolean;
  disabled?: boolean;
}

const PAGE_SIZE = 5;

const AgentSelectInput: React.FC<AgentSelectInputProps> = ({
  name,
  multiple = false,
  role = 'all',
  dense = false,
  ignoreAgentUserAllocatedAgentNames = false,
  disabled = false
}) => {
  const [pagination, setPagination] = useState(1);
  const [search, setSearchQuery] = useState('');

  const form = useForm();

  const { teamMembers, loading, error } = useTeamMemberList(
    role,
    ignoreAgentUserAllocatedAgentNames
  );

  const filteredOptions = useMemo(
    () =>
      teamMembers.filter((user) => {
        if (!search || !search.trim().length) {
          return true;
        }

        const trimmedSearch = search.trim().toLowerCase();

        return (
          user.primary.toLowerCase().includes(trimmedSearch) ||
          user.secondary.toLowerCase().includes(trimmedSearch) ||
          user.email?.toLowerCase().includes(trimmedSearch)
        );
      }),
    [teamMembers, search]
  );

  const handleChangePagination = useCallback(
    (_event: any, page: number) => {
      setPagination(page);
    },
    [setPagination]
  );

  const handleSearch = useCallback(() => {
    // Noop, filtering is local not DB based
    // Just need to function to pass to the component
  }, []);

  const handleSearchChange = useCallback(
    (event: any) => {
      setSearchQuery(event?.target.value);
      setPagination(1);
    },
    [setPagination]
  );

  const handleClearSearch = useCallback(() => {
    setSearchQuery('');
  }, []);

  const handleSelectAll = useCallback(() => {
    const all = filteredOptions.map((option) => option.value);
    form.change(name, all);
  }, [form, name, filteredOptions]);

  const handleClearSelection = useCallback(() => {
    form.change(name, []);
  }, [form, name]);

  // Query returns all data, we just want to paginate frontend
  const count = filteredOptions?.length ?? 0;
  const totalCount = teamMembers?.length ?? 0;
  const pages = Math.max(1, Math.ceil(count / PAGE_SIZE));
  const offset = (pagination - 1) * PAGE_SIZE;

  if (loading) {
    return <CircularProgress />;
  }

  if (error) {
    return (
      <div>
        <Typography variant="caption" color="error">
          Unable to load agents
        </Typography>
      </div>
    );
  }

  return (
    <div>
      <SearchInput
        name="agent_search"
        label={'Search agents'}
        placeholder="Start typing to search agents ..."
        value={search}
        style={{ width: '100%' }}
        onClickSearch={handleSearch}
        onClickClear={handleClearSearch}
        onChange={handleSearchChange}
      />

      <PaginationContainer>
        <Typography style={{ textAlign: 'center' }} variant="caption" gutterBottom>
          Showing {Math.min(PAGE_SIZE, count)} of {totalCount}
        </Typography>
      </PaginationContainer>
      <PaginationContainer>
        <FormSpy subscription={{ values: true }}>
          {(props) => {
            if (multiple) {
              return (
                <Typography style={{ textAlign: 'center' }} variant="caption" gutterBottom>
                  {props.values[name]?.length} Selected
                </Typography>
              );
            } else if (props.values[name]) {
              return (
                <Typography style={{ textAlign: 'center' }} variant="caption" gutterBottom>
                  1 Selected
                </Typography>
              );
            }
            return null;
          }}
        </FormSpy>
      </PaginationContainer>
      {multiple && (
        <PaginationContainer>
          <ButtonGroup variant="text" color="primary">
            <Button onClick={handleSelectAll}>Select All</Button>
            <Button onClick={handleClearSelection}>Clear Selection</Button>
          </ButtonGroup>
        </PaginationContainer>
      )}

      <FinalFormListCheckboxes
        name={name}
        multiple={multiple}
        loading={loading}
        options={filteredOptions.slice(offset, offset + PAGE_SIZE)}
        dense={dense}
        disabled={disabled}
        icon="person"
      />
      <PaginationContainer>
        <Pagination count={pages} page={pagination} onChange={handleChangePagination} />
      </PaginationContainer>
    </div>
  );
};

export default AgentSelectInput;
