import {
  Box,
  Button,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography
} from '@material-ui/core';
import LaunchIcon from '@material-ui/icons/Launch';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';

interface KnowledgeBaseSectionProps {
  onFinish: () => void;
}

export default function KnowledgeBaseSection({ onFinish }: KnowledgeBaseSectionProps) {
  return (
    <>
      <Box maxWidth={1000}>
        <Typography variant="h6">Helpful Links and Info</Typography>
        <Alert severity="info">
          <AlertTitle>Knowledge base and other helpful links</AlertTitle>
          <List>
            <ListItem button component="a" href="https://support.properti.ai" target="_blank">
              <ListItemIcon>
                <LaunchIcon />
              </ListItemIcon>
              <ListItemText primary="Knowledge base" />
            </ListItem>
            <ListItem button component="a" href="https://status.properti.ai" target="_blank">
              <ListItemIcon>
                <LaunchIcon />
              </ListItemIcon>
              <ListItemText primary="System status" />
            </ListItem>
          </List>
        </Alert>
      </Box>
      <Box maxWidth={1000} mt={4}>
        <Button variant="contained" color="primary" size="large" onClick={onFinish}>
          Finish quick start
        </Button>
      </Box>
    </>
  );
}
