import { useCallback, useEffect, useMemo, useState } from 'react';

import { Outlet } from 'react-router-dom';

import { Box } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';

import {
  useStripeCustomActiveSubscriptionQuery,
  Workspace_Billing_Model_Enum
} from 'generated/graphql';

import SubscriptionRequiredWarning from 'components/SubscriptionRequiredWarning';

import { useHasuraRoleContext } from 'lib/HasuraRoleContext';
import FeatureFlagOutlet from 'lib/feature-flags/FeatureFlagOutlet';
import useUserContext from 'lib/hooks/useUserContext';

interface ActiveSubscriptionOutletProps {
  featureFlagName?: string;
}

/**
 * React Router Outlet that checks for active subscriptions.
 * Active subscriptions are required if the workspace has a stripe customer ID.
 *
 * We will check if subscription is active and a payment method is attached to the stripe account
 *
 */
export default function ActiveSubscriptionOutlet({
  featureFlagName
}: ActiveSubscriptionOutletProps) {
  const { workspace, loading: infoLoading, isWorkspaceAdmin, stripeCustomerId } = useUserContext();
  const { workspaceMemberContext } = useHasuraRoleContext();

  const [continueAsAdmin, setContinueAsAdmin] = useState<boolean>(false);

  const billingModel = useMemo(() => workspace?.billing_model, [workspace?.billing_model]);

  const skip = !stripeCustomerId;
  const {
    data,
    loading: statusLoading,
    error
  } = useStripeCustomActiveSubscriptionQuery({
    variables: {
      customerId: stripeCustomerId!
    },
    context: workspaceMemberContext,
    skip: skip
  });

  useEffect(() => {
    // Reset continue as admin when stripe customer changes
    setContinueAsAdmin(false);
  }, [stripeCustomerId, setContinueAsAdmin]);

  const loading = useMemo(() => infoLoading || statusLoading, [infoLoading, statusLoading]);

  // Allow Properti Admin Users to Ignore message and use app
  const handleClickIgnoreAsAdmin = useCallback(() => {
    setContinueAsAdmin(true);
  }, [setContinueAsAdmin]);

  const stripeCustomerStatus = data?.stripe_customer_status;

  const hasDefaultSource = useMemo(
    () => stripeCustomerStatus?.has_default_source ?? false,
    [stripeCustomerStatus]
  );
  const activeSubscriptions = useMemo(
    () => stripeCustomerStatus?.active_subscriptions ?? 0,
    [stripeCustomerStatus]
  );
  const numberSubscription = useMemo(
    () => stripeCustomerStatus?.number_subscriptions ?? 0,
    [stripeCustomerStatus]
  );

  const isActive = useMemo(() => Boolean(activeSubscriptions), [activeSubscriptions]);

  // Customers managed outside of stripe can continue to use application
  if (!stripeCustomerId) {
    return (
      <ChooseOutlet
        featureFlagName={featureFlagName}
        continueAsAdmin={continueAsAdmin}
        onClickIgnore={handleClickIgnoreAsAdmin}
      />
    );
  }

  // Whilst checking subscription let customers use app
  // In case of errors on our behalf let customer continue using app
  if (loading || !data || error) {
    return (
      <ChooseOutlet
        featureFlagName={featureFlagName}
        continueAsAdmin={continueAsAdmin}
        onClickIgnore={handleClickIgnoreAsAdmin}
      />
    );
  }

  // Workspaces on standard billing model without any subscriptions are
  // most likely managed differently. We will allow them to continue.
  if (billingModel === Workspace_Billing_Model_Enum.Standard && numberSubscription === 0) {
    return (
      <ChooseOutlet
        featureFlagName={featureFlagName}
        continueAsAdmin={continueAsAdmin}
        onClickIgnore={handleClickIgnoreAsAdmin}
      />
    );
  }

  if (isActive) {
    return (
      <ChooseOutlet
        featureFlagName={featureFlagName}
        continueAsAdmin={continueAsAdmin}
        onClickIgnore={handleClickIgnoreAsAdmin}
      />
    );
  }

  if (continueAsAdmin) {
    return (
      <Box mb={2}>
        <Alert severity="warning">
          <AlertTitle>Subscription missing, viewing as admin</AlertTitle>
        </Alert>
        <ChooseOutlet
          featureFlagName={featureFlagName}
          continueAsAdmin={continueAsAdmin}
          onClickIgnore={handleClickIgnoreAsAdmin}
        />
      </Box>
    );
  }

  const message =
    !hasDefaultSource && activeSubscriptions
      ? 'A payment method required, open your billing portal to add a payment method.'
      : undefined;

  return (
    <SubscriptionRequiredWarning
      text={message}
      disabled={!isWorkspaceAdmin}
      onClickIgnore={handleClickIgnoreAsAdmin}
    />
  );
}

interface ChooseOutletProps {
  featureFlagName?: string;
  continueAsAdmin: boolean;
  onClickIgnore?: () => void;
}

function ChooseOutlet({ featureFlagName, continueAsAdmin, onClickIgnore }: ChooseOutletProps) {
  if (!featureFlagName) {
    return <Outlet />;
  }

  return (
    <FeatureFlagOutlet
      featureFlagName={featureFlagName}
      continueAsAdmin={continueAsAdmin}
      onClickIgnore={onClickIgnore}
    />
  );
}
