import React from 'react';

import { default as MuiStepLabel } from '@material-ui/core/StepLabel';

import StepIcon from './StepIcon';

export interface StepLabelProps {
  label: string;
}

const StepLabel: React.FC<StepLabelProps> = ({ label, ...other }) => (
  <MuiStepLabel StepIconComponent={StepIcon} {...other}>
    {label}
  </MuiStepLabel>
);

export default StepLabel;
