import { useState } from 'react';

import {
  AppBar,
  Container,
  DialogContent,
  Drawer,
  IconButton,
  Tab,
  Tabs,
  Typography
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Clear';
import { TabContext, TabPanel } from '@material-ui/lab';

import styled from 'styled-components';

import { SelectPreview } from 'pages/workspace/Campaigns/Create/AdPreview';

import { ImageCarousel } from '../ImageCreativePreviewDialog';

const platforms = [
  {
    name: 'Meta',
    label: 'facebook'
  },
  {
    name: 'Google',
    label: 'googleads'
  }
] as const;

interface ResponsiveCreativePreviewDialogProps {
  id: string;
  open: boolean;
  onClose: () => void;
  title: string;
  ignoredPlatforms?: Array<typeof platforms[number]['label']>;
  imageUrls?: string[];
}

export default function ResponsiveCreativePreviewDialog({
  id,
  open,
  title,
  onClose,
  ignoredPlatforms = [],
  imageUrls = []
}: ResponsiveCreativePreviewDialogProps) {
  const availablePlatforms = platforms.filter(
    (platform) => !ignoredPlatforms.includes(platform.label)
  );

  const shouldShowImagePreview = imageUrls.length !== 0;

  const [selected, setSelected] = useState<typeof platforms[number]['label'] | 'image'>(
    shouldShowImagePreview ? 'image' : availablePlatforms[0].label
  );

  function handleChange(e: unknown, value: typeof platforms[number]['label']) {
    setSelected(value);
  }

  const preview = {
    facebook: {
      platform: 'facebook' as const,
      creativeIds: [id],
      videoIds: []
    },
    googleads: {
      platform: 'googleads' as const,
      creativeIds: [id]
    }
  };

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={onClose}
      elevation={2}
      ModalProps={{
        disableEnforceFocus: true
      }}
      BackdropProps={{
        style: {
          backgroundColor: '#333333',
          opacity: 0.9
        }
      }}
    >
      <Top>
        <Typography variant="h5" gutterBottom>
          Previewing {title}
        </Typography>
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </Top>
      <Container style={{ width: '48rem' }}>
        <DialogContent>
          <TabContext value={selected}>
            <AppBar position="static">
              <Tabs value={selected} onChange={handleChange}>
                {shouldShowImagePreview && <Tab label="Image" value="image" />}
                {availablePlatforms.map((platform, index) => (
                  <Tab key={index} label={platform.name} value={platform.label} />
                ))}
              </Tabs>
            </AppBar>
            {shouldShowImagePreview && (
              <TabPanel value="image">
                <ImageCarousel imageUrls={imageUrls} title={title} width={600} minHeight={300} />
              </TabPanel>
            )}
            {availablePlatforms.map((platform, index) => (
              <TabPanel key={index} value={platform.label}>
                <SelectPreview preview={preview[platform.label]} forceRefreshCount={0} />
              </TabPanel>
            ))}
          </TabContext>
        </DialogContent>
      </Container>
    </Drawer>
  );
}

const Top = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 16px;
`;
