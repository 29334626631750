import React, { useCallback, useMemo, useState } from 'react';

import {
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
  IconButton,
  Tooltip,
  Typography
} from '@material-ui/core';
import DownloadIcon from '@material-ui/icons/CloudDownload';
import GroupIcon from '@material-ui/icons/Group';
import RefreshIcon from '@material-ui/icons/Refresh';
import ShareIcon from '@material-ui/icons/ShareOutlined';

import BullhornIcon from 'mdi-material-ui/BullhornOutline';
import CommentEditIcon from 'mdi-material-ui/CommentEditOutline';
import { useSnackbar } from 'notistack';

import {
  useCreateCanvaDesignReturnRedirectUrlMutation,
  useRefreshCreativeStatusLazyQuery,
  Workspace_Template_Engine_Providers_Enum
} from 'generated/graphql';

import LoadingBackdrop from 'components/LoadingBackdrop';
import ShareDrawer from 'components/ShareDrawer';
import CampaignFromListingOrCreativeDialog from 'components/campaigns/templates/CampaignFromListingOrCreativeDialog';
import CanvaIcon from 'components/icons/CanvaIcon';
import { ShareLogicVariants } from 'components/types';
import AssignAgentsAssetDialog from 'components/workflows/AssignAgentsAssetDialog';

import { useHasuraRoleContext } from 'lib/HasuraRoleContext';
import useAssignAgentsAsset from 'lib/hooks/useAssignAgentsAsset';
import useUserContext from 'lib/hooks/useUserContext';

import CreativePreviewDialog from '../CreativePreviewDialog';
import EditCreativeDrawer from '../EditCreativeDrawer';
import TagHolder, { Tag } from '../TagHolder';
import useVideoCreativeCardStyles from './_styles';

interface VideoCreativeCardProps {
  id: string;
  title: string;
  date: string;
  tags?: Tag[];
  src?: string;
  ready?: boolean;
  downloadUrl?: string;
  disableActionButtons?: boolean;
  draft?: boolean;
  listingId?: string;
  hasBeenPosted?: boolean;
  onRefetchRequired?: () => void;
  createdByUserId?: string | null;
  engine?: Workspace_Template_Engine_Providers_Enum | null;
  engineOwnerUrn: string | null;
}

const VideoCreativeCard: React.FC<VideoCreativeCardProps> = ({
  id,
  title,
  date,
  tags,
  onRefetchRequired,
  src,
  downloadUrl,
  ready = false,
  disableActionButtons = false,
  draft,
  listingId,
  createdByUserId,
  engine,
  engineOwnerUrn
}) => {
  const { userId, isWorkspaceAdmin, activeWorkspaceId } = useUserContext();
  const { workspaceMemberContext } = useHasuraRoleContext();

  const classes = useVideoCreativeCardStyles();
  const [shareOpen, setShareOpen] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const [previewOpen, setPreviewOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const [createCanvaDesignRedirectUrl] = useCreateCanvaDesignReturnRedirectUrlMutation({
    context: workspaceMemberContext
  });
  const [refresh, { loading: loadingRefresh }] = useRefreshCreativeStatusLazyQuery({
    context: workspaceMemberContext
  });

  const {
    assignAgentAssetDialogOpen,
    handleOpenAssignAgentsAssetDialog,
    handleCloseAssignAgentsAssetDialog
  } = useAssignAgentsAsset(onRefetchRequired);

  const [boostModelOpen, setBoostModelOpen] = useState(false);

  let image = src;
  if (draft) {
    image = '/videos/draft.jpg';
  } else if (!ready) {
    image = '/videos/loading.jpg';
  }

  const handleRefreshPendingVideo = useCallback(() => {
    try {
      refresh({ variables: { id } });
    } catch (error) {
      console.error(error);
      enqueueSnackbar('Unable to get creative status', { variant: 'error' });
    }
  }, [id, refresh, enqueueSnackbar]);

  const handleClickCard = useCallback(() => {
    setPreviewOpen(true);
  }, [setPreviewOpen]);

  const handleOpenEditDialog = useCallback(() => {
    setEditOpen(true);
  }, [setEditOpen]);

  const handleCloseEdit = useCallback(() => {
    setEditOpen(false);
    if (onRefetchRequired) {
      onRefetchRequired();
    }
  }, [setEditOpen, onRefetchRequired]);

  const handleOpenShareDrawer = () => {
    setShareOpen(true);
  };

  const handleCloseShareDrawer = () => {
    setShareOpen(false);
  };

  const handleOpenCreateFromTemplateModal = () => {
    setBoostModelOpen(true);
  };

  const handleCloseCreateFromTemplateModal = () => {
    setBoostModelOpen(false);
  };

  const currentWorkspaceUrn = useMemo(() => `workspace:${activeWorkspaceId}`, [activeWorkspaceId]);

  // Advanced images may have images rendered by different images.
  // Lets only enable return navigation on full Canva engine images.
  const isCanvaEditingAvailable =
    engine === Workspace_Template_Engine_Providers_Enum.Canva &&
    // Workspace must be using their own connection, not the head office connection
    engineOwnerUrn === currentWorkspaceUrn;

  const handleCanvaReturnNavigation = async () => {
    try {
      const { data } = await createCanvaDesignRedirectUrl({
        variables: {
          args: { creative_id: id }
        }
      });
      if (data?.createCanvaDesignReturnNavigationRedirectUrl?.design_redirect_url) {
        window.location.href =
          data?.createCanvaDesignReturnNavigationRedirectUrl?.design_redirect_url;
      } else {
        throw new Error('Unable to build edit URL');
      }
    } catch (error: any) {
      enqueueSnackbar(error?.message ?? 'Unable to build Canva edit URL, please try again', {
        variant: 'error'
      });
    }
  };

  return (
    <>
      <Card className={classes.root} elevation={2}>
        <CardActionArea disabled={!ready} onClick={handleClickCard}>
          <CardMedia className={classes.media} title={title} image={image}>
            <TagHolder tags={tags} />
          </CardMedia>
          <CardContent>
            <Typography variant="overline">{date}</Typography>
            <Typography variant="h6">{title}</Typography>
          </CardContent>
        </CardActionArea>
        <CardActions>
          {ready && (
            <Tooltip title="Share Post">
              <IconButton
                aria-label="share post"
                onClick={handleOpenShareDrawer}
                disabled={!ready || disableActionButtons}
              >
                <ShareIcon />
              </IconButton>
            </Tooltip>
          )}
          <Tooltip title="Boost Creative">
            <IconButton
              aria-label="boost creative"
              onClick={handleOpenCreateFromTemplateModal}
              disabled={disableActionButtons || !ready}
            >
              <BullhornIcon />
            </IconButton>
          </Tooltip>
          {!draft && !ready && (
            <Tooltip title="Recheck Video">
              <IconButton
                onClick={handleRefreshPendingVideo}
                aria-label="Recheck Video"
                disabled={ready || disableActionButtons}
              >
                <RefreshIcon />
              </IconButton>
            </Tooltip>
          )}
          {ready && downloadUrl && (
            <Tooltip title="Download Video">
              <IconButton
                component="a"
                href={downloadUrl}
                target="_blank"
                download={`${title.replace(' ', '-').replace(',', '-')}`}
              >
                <DownloadIcon />
              </IconButton>
            </Tooltip>
          )}
          {ready && isCanvaEditingAvailable && (
            <Tooltip title="Edit on Canva">
              <IconButton
                aria-label="Edit on Canva"
                disabled={!isCanvaEditingAvailable}
                onClick={handleCanvaReturnNavigation}
              >
                <CanvaIcon />
              </IconButton>
            </Tooltip>
          )}

          <Tooltip style={{ marginLeft: 'auto' }} title="Assign Agents">
            <IconButton
              aria-label="Assign Agents"
              onClick={() => handleOpenAssignAgentsAssetDialog(id)}
              disabled={disableActionButtons || (createdByUserId !== userId && !isWorkspaceAdmin)}
            >
              <GroupIcon />
            </IconButton>
          </Tooltip>

          <Tooltip title="Edit Ad Copy">
            <IconButton
              onClick={handleOpenEditDialog}
              aria-label="Edit Ad Copy"
              disabled={disableActionButtons}
            >
              <CommentEditIcon />
            </IconButton>
          </Tooltip>
        </CardActions>
      </Card>
      {ready && !disableActionButtons && (
        <ShareDrawer
          open={shareOpen}
          onClose={handleCloseShareDrawer}
          title={title}
          variant={ShareLogicVariants.CREATIVE}
          listingId={listingId}
          creativeId={id}
          disabled={disableActionButtons}
        />
      )}
      {ready && (
        <CreativePreviewDialog
          open={previewOpen}
          handleClose={() => {
            setPreviewOpen(false);
          }}
          title={title}
          src={downloadUrl}
        />
      )}
      <LoadingBackdrop open={loadingRefresh} />
      <EditCreativeDrawer
        creativeId={id}
        open={editOpen}
        onClose={handleCloseEdit}
        isVideoCreative
      />
      <CampaignFromListingOrCreativeDialog
        open={boostModelOpen}
        onClose={handleCloseCreateFromTemplateModal}
        creativeId={id}
      />
      <AssignAgentsAssetDialog
        open={assignAgentAssetDialogOpen}
        onClose={handleCloseAssignAgentsAssetDialog}
        assetId={id}
        assetType="creative"
      />
    </>
  );
};

export default VideoCreativeCard;
