import { Form, useForm } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';

import {
  Box,
  Button,
  ButtonGroup,
  Dialog,
  DialogActions,
  DialogContent,
  MenuItem,
  Typography
} from '@material-ui/core';

import { FORM_ERROR } from 'final-form';
import arrayMutators from 'final-form-arrays';
import moment from 'moment';
import { Checkboxes, makeValidate, Select, TextField } from 'mui-rff';
import { useSnackbar } from 'notistack';
import * as Yup from 'yup';

import {
  useCreateLeadGenFormMutation,
  useSavedLeadGenFormQuery,
  Workspace_Lead_Types_Enum
} from 'generated/graphql';

import DialogTitle from 'components/DialogTitle';
import AgentAutocomplete from 'components/forms/AgentAutocomplete';
import FinalFormCondition from 'components/forms/FinalFormCondition';

import { useHasuraRoleContext } from 'lib/HasuraRoleContext';
import usePlatforms from 'lib/hooks/usePlatforms';
import useUserContext from 'lib/hooks/useUserContext';

import PreviewModal from './LeadGenPreviewModal';

interface CreateLeadGenFormDialogProps {
  open: boolean;
  onClose: (id?: string) => void;
  formId: any;
}

const schema = Yup.object({
  name: Yup.string().required().label('Form name'),
  fields: Yup.array(Yup.string().required()).required().label('Customer data fields'),
  facebook_page_id: Yup.string().required().label('Facebook page ID'),
  context_title: Yup.string().required().label('Context title'),
  context_message: Yup.string().required().label('Context message'),
  context_button_text: Yup.string().required().label('Context button text'),
  privacy_policy_url: Yup.string().url().required().label('Privacy policy URL'),
  follow_up_action_url: Yup.string().url().required().label('Follow up action url'),
  call_to_action_text: Yup.string()
    .oneOf(['APPLY_NOW', 'GET_QUOTE', 'LEARN_MORE'])
    .required()
    .label('Call to Action Button Text'),
  thank_you_title: Yup.string().required().label('Title of the thank you page'),
  thank_you_body: Yup.string().required().label('Body of the thank you page'),
  thank_you_button_text: Yup.string().required().label('Text of the thank you page button'),
  optimise_for_quality: Yup.boolean().label('Optimise for lead quality'),
  preview_open: Yup.boolean(),
  lead_type: Yup.string()
    .oneOf([
      Workspace_Lead_Types_Enum.General,
      Workspace_Lead_Types_Enum.Listing,
      Workspace_Lead_Types_Enum.Appraisal
    ])
    .required()
    .label('Lead type'),
  owner_id: Yup.string().optional().nullable().label('Lead owner'),
  multi_choice: Yup.boolean().defined().default(false).label('Multiple Choice Questions'),
  multi_choice_questions: Yup.array()
    .of(
      Yup.object()
        .shape({
          label: Yup.string().required('Multiple Choice Question Label'),
          options: Yup.array()
            .of(
              Yup.object()
                .shape({
                  value: Yup.string().required().label('Multiple Choice Question Option')
                })
                .required()
            )
            .min(2)
            .required()
        })
        .required()
    )
    .optional()
    .nullable()
}).required();

const validate = makeValidate(schema);

type FormSchema = Yup.InferType<typeof schema>;

export default function CreateLeadGenFormDialog({
  open,
  onClose,
  formId
}: CreateLeadGenFormDialogProps) {
  const { enqueueSnackbar } = useSnackbar();

  const { activeWorkspaceId } = useUserContext();
  const { workspaceMemberContext } = useHasuraRoleContext();
  const [, , { facebook }] = usePlatforms();
  const [createForm] = useCreateLeadGenFormMutation({
    context: workspaceMemberContext
  });

  const { data } = useSavedLeadGenFormQuery({
    variables: {
      id: formId
    },
    context: workspaceMemberContext,
    skip: !formId
  });

  const handleCreateLeadGenForm = async (values: FormSchema) => {
    if (values.multi_choice && !values.multi_choice_questions?.length) {
      return {
        [FORM_ERROR]:
          'You must add at least one multiple choice question, or uncheck the "Include multiple choice questions" option'
      };
    }

    try {
      await createForm({
        variables: {
          args: {
            name: values.name,
            workspace_id: activeWorkspaceId!,
            page_id: values.facebook_page_id,
            fields: values.fields,
            context_title: values.context_title,
            context_message: values.context_message,
            context_button_text: values.context_button_text,
            follow_up_action_url: values.follow_up_action_url!,
            privacy_policy_url: values.privacy_policy_url,
            call_to_action_text: values.call_to_action_text,
            optimize_for_quality: values.optimise_for_quality ?? false,
            thank_you_title: values.thank_you_title,
            thank_you_body: values.thank_you_body,
            thank_you_button_text: values.thank_you_button_text,
            lead_type: values.lead_type ?? Workspace_Lead_Types_Enum.General,
            owner_id: values.owner_id,
            multi_choice: values.multi_choice ?? false,
            multi_choice_questions: values.multi_choice_questions
          }
        }
      });
      onClose();
      enqueueSnackbar('Leadgen form created', { variant: 'success' });
    } catch (error: any) {
      return {
        [FORM_ERROR]: error?.message ?? 'Unable to create leadgen form'
      };
    }
  };

  const handleClose = () => {
    onClose();
  };

  const savedForm = data?.workspace_lead_forms_by_pk;
  const facebookConfig = savedForm?.facebook_config;

  const leadType = savedForm?.lead_type ?? Workspace_Lead_Types_Enum.General;
  const formName = savedForm?.name
    ? `${savedForm.name} ${moment().format('DD-MM-YYYY')}-copy`
    : undefined;
  const ownerId = savedForm?.owner_id ?? undefined;

  const facebookPageId = facebookConfig?.facebookPageId ?? undefined;
  const fields = facebookConfig?.fields ?? ['FULL_NAME', 'EMAIL', 'PHONE', 'STREET_ADDRESS'];
  const adCallToActionButtonText = facebookConfig?.adCallToActionButtonText ?? 'APPLY_NOW';
  const contextTitle = facebookConfig?.contextTitle ?? 'Request information';
  const contextMessage =
    facebookConfig?.contextMessage ?? `Fill out your details below and we'll get back to you`;
  const contextButtonText = facebookConfig?.contextButtonText ?? 'Submit request';
  const privacyUrl = facebookConfig?.privacyUrl ?? undefined;
  const followUpActionUrl = facebookConfig?.followUpActionUrl ?? undefined;
  const optimiseForQuality = facebookConfig?.optimiseForQuality ?? true;
  const thankYouTitle = facebookConfig?.thankYouTitle ?? "Thanks, you're all set.";
  const bodyText =
    facebookConfig?.thankYouBody ?? 'You can visit our website or exit the form now.';
  const thankYouButtonText = facebookConfig?.thankYouButtonText ?? 'View website';
  const multiChoice = facebookConfig?.multiChoice ?? false;
  const multiChoiceQuestions = facebookConfig?.multiChoiceQuestions ?? null;

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
      <DialogTitle onClose={handleClose}>Create your leadgen form</DialogTitle>
      <Form
        onSubmit={handleCreateLeadGenForm}
        mutators={{
          ...arrayMutators
        }}
        keepDirtyOnReinitialize
        validate={validate}
        initialValues={{
          name: formName,
          facebook_page_id: facebookPageId,
          fields: fields,
          call_to_action_text: adCallToActionButtonText,
          context_title: contextTitle,
          context_message: contextMessage,
          thank_you_title: thankYouTitle,
          thank_you_body: bodyText,
          thank_you_button_text: thankYouButtonText,
          context_button_text: contextButtonText,
          optimise_for_quality: optimiseForQuality,
          privacy_policy_url: privacyUrl,
          follow_up_action_url: followUpActionUrl,
          preview_open: false,
          lead_type: leadType,
          owner_id: ownerId,
          multi_choice: multiChoice,
          multi_choice_questions: multiChoiceQuestions
        }}
        render={({
          handleSubmit,
          submitting,
          submitError,
          form: {
            mutators: { push, pop }
          }
        }) => {
          return (
            <form onSubmit={handleSubmit}>
              <DialogContent>
                <Box>
                  <TextField name="name" label="Form name" required autoFocus />
                  <AgentAutocomplete name="owner_id" label="Lead owner" limitAgentUsers />
                  <Select name="lead_type" label="Lead type" required>
                    <MenuItem value={Workspace_Lead_Types_Enum.General}>General</MenuItem>
                    <MenuItem value={Workspace_Lead_Types_Enum.Listing}>Listing</MenuItem>
                    <MenuItem value={Workspace_Lead_Types_Enum.Appraisal}>Appraisal</MenuItem>
                  </Select>
                  <Select name="facebook_page_id" label="Facebook page" required>
                    {facebook?.allowedPages?.length ? (
                      facebook?.allowedPages?.map((page) => (
                        <MenuItem key={page.id} value={page.id}>
                          {page.display_name}
                        </MenuItem>
                      ))
                    ) : (
                      <MenuItem value={undefined} disabled>
                        No options
                      </MenuItem>
                    )}
                  </Select>
                  <Checkboxes
                    name="fields"
                    data={[
                      {
                        label: 'Customer name',
                        value: 'FULL_NAME',
                        disabled: true
                      },
                      {
                        label: 'Customer email',
                        value: 'EMAIL',
                        disabled: true
                      },
                      {
                        label: 'Customer phone number',
                        value: 'PHONE',
                        disabled: true
                      },
                      {
                        label: 'Customer address',
                        value: 'STREET_ADDRESS'
                      },
                      {
                        label: 'Custom message',
                        value: 'MESSAGE'
                      }
                    ]}
                  />
                  <Box mb={2} style={{ display: 'flex', flexDirection: 'column' }}>
                    <Checkboxes
                      name="multi_choice"
                      data={[
                        {
                          label: 'Include multiple choice questions',
                          value: true
                        }
                      ]}
                    />
                    <FinalFormCondition when={'multi_choice'} equals={true}>
                      <ButtonGroup>
                        <Button onClick={() => push('multi_choice_questions', undefined)}>
                          Add Multiple Choice Question
                        </Button>
                        <Button onClick={() => pop('multi_choice_questions', undefined)}>
                          Remove Last Question
                        </Button>
                      </ButtonGroup>
                      <FieldArray name="multi_choice_questions">
                        {({ fields }) =>
                          fields.map((name, idx) => (
                            <Box>
                              <TextField
                                name={`${name}.label`}
                                label={`Multiple Choice Question ${idx + 1}`}
                              />
                              <LeadGenMultiChoiceQuestions
                                name={`${name}.options`}
                                push={push}
                                pop={pop}
                              />
                            </Box>
                          ))
                        }
                      </FieldArray>
                    </FinalFormCondition>
                  </Box>
                  <Select name="call_to_action_text" label="Ad Call to Action Button Text" required>
                    <MenuItem value="APPLY_NOW">APPLY_NOW</MenuItem>
                    <MenuItem value="GET_QUOTE">GET_QUOTE</MenuItem>
                    <MenuItem value="LEARN_MORE">LEARN_MORE</MenuItem>
                  </Select>
                  <TextField
                    name="context_title"
                    label="Context title"
                    helperText="Title shown to user to provide context"
                    required
                  />
                  <TextField
                    name="context_message"
                    label="Context message"
                    helperText="Message shown to user to provide context"
                    multiline
                    minRows={4}
                    maxRows={8}
                    required
                  />
                  <TextField
                    name="context_button_text"
                    label="Context button text"
                    helperText="Button text shown to user to provide context"
                    required
                  />
                  <TextField
                    name="thank_you_title"
                    label="Thank you page title"
                    helperText="Title shown to user to on the final thank you page"
                    required
                  />
                  <TextField
                    name="thank_you_body"
                    label="Thank you page body text"
                    helperText="Text shown to user on the final thank you page"
                    multiline
                    minRows={4}
                    maxRows={8}
                  />
                  <TextField
                    name="thank_you_button_text"
                    label="Thank you page button text"
                    helperText="Button text shown to user to on the thank you page"
                    required
                  />
                  <TextField
                    name="privacy_policy_url"
                    label="Privacy policy URL"
                    helperText="Link to your privacy policy"
                    required
                  />
                  <TextField
                    name="follow_up_action_url"
                    label="Follow up action URL"
                    helperText="Link to send users after submission"
                    required
                  />
                  <Checkboxes
                    name="optimise_for_quality"
                    data={[
                      {
                        label: 'Optimise for lead quality',
                        value: true
                      }
                    ]}
                  />
                </Box>
                {submitError && (
                  <Typography variant="caption" color="error">
                    {submitError}
                  </Typography>
                )}
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose} disabled={submitting}>
                  Cancel
                </Button>
                <PreviewButton />
                <Button type="submit" variant="contained" color="secondary" disabled={submitting}>
                  {submitting ? 'Creating ...' : 'Create leadgen form'}
                </Button>
              </DialogActions>
              <PreviewModal />
            </form>
          );
        }}
      />
    </Dialog>
  );
}

interface LeadGenMultiChoiceQuestionsProps {
  name: string;
  push: (...args: any[]) => any;
  pop: (...args: any[]) => any;
}

function LeadGenMultiChoiceQuestions({ name, push, pop }: LeadGenMultiChoiceQuestionsProps) {
  return (
    <Box>
      <ButtonGroup>
        <Button onClick={() => push(name, undefined)}>Add Response Option</Button>
        <Button onClick={() => pop(name, undefined)}>Remove Last Option</Button>
      </ButtonGroup>

      <FieldArray name={name}>
        {({ fields }) =>
          fields.map((name, idx) => (
            <TextField name={`${name}.value`} label={`Response Option ${idx + 1}`} />
          ))
        }
      </FieldArray>
    </Box>
  );
}

function PreviewButton() {
  const form = useForm();

  const changePreviewOpen = () => {
    form.change('preview_open', true);
  };

  return (
    <Box style={{ display: 'flex', alignItems: 'center' }}>
      <Button style={{ padding: 4 }} variant="text" onClick={changePreviewOpen}>
        Preview
      </Button>
    </Box>
  );
}
