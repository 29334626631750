import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Typography
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import suprsend from '@suprsend/web-sdk';

import NotificationChannelIcon from '../NotificationChannelIcon';
import { ChannelLevelPreferenceOptions, ChannelPreference, PreferenceData } from '../types';
import { getChannelName } from './utils';

const handleOverallChannelPreferenceChange = (
  channel: ChannelPreference,
  status: ChannelLevelPreferenceOptions,
  setPreferenceData: (newData: PreferenceData) => void
) => {
  const resp = suprsend.user.preferences.update_overall_channel_preference(channel.channel, status);
  if (resp.error) {
    console.warn(resp.message);
  } else {
    setPreferenceData({ ...resp });
  }
};

export interface ChannelLevelPreferencesProps {
  preferenceData: PreferenceData;
  setPreferenceData: (newData: PreferenceData) => void;
}

export default function ChannelLevelPreferences({
  preferenceData,
  setPreferenceData
}: ChannelLevelPreferencesProps) {
  return (
    <Box>
      <Typography variant="h6" gutterBottom>
        Which notifications to allow for channels?
      </Typography>
      <Box>
        {preferenceData.channel_preferences?.map((cp) => (
          <ChannelLevelPreferenceAccordion
            key={cp.channel}
            channel={cp}
            setPreferenceData={setPreferenceData}
          />
        ))}
      </Box>
    </Box>
  );
}

export interface ChannelLevelPreferenceAccordionProps {
  channel: ChannelPreference;
  setPreferenceData: (newData: PreferenceData) => void;
}

function ChannelLevelPreferenceAccordion({
  channel,
  setPreferenceData
}: ChannelLevelPreferenceAccordionProps) {
  const handleChangePreference = (_event: any, value: string) => {
    handleOverallChannelPreferenceChange(
      channel,
      value as ChannelLevelPreferenceOptions,
      setPreferenceData
    );
  };

  return (
    <Accordion variant="outlined">
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls={`${channel.channel}-content`}
        id={`${channel.channel}-header`}
      >
        <Box style={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
          <Box style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start' }}>
            <NotificationChannelIcon channel={channel.channel} />
            <Typography style={{ marginLeft: 4 }}>{getChannelName(channel.channel)}</Typography>
          </Box>
          <Typography variant="overline">
            {channel.is_restricted
              ? 'Allow required notifications only'
              : 'Allow all notifications'}
          </Typography>
        </Box>
      </AccordionSummary>
      <AccordionDetails id={`${channel.channel}-content`}>
        <Box>
          <FormControl component="fieldset">
            <FormLabel component="legend">{getChannelName(channel.channel)} preferences</FormLabel>
            <RadioGroup
              aria-label={`${channel.channel} preferences`}
              name={`${channel.channel} preferences`}
              value={
                channel.is_restricted
                  ? ChannelLevelPreferenceOptions.REQUIRED
                  : ChannelLevelPreferenceOptions.ALL
              }
              onChange={handleChangePreference}
            >
              <FormControlLabel
                value={ChannelLevelPreferenceOptions.ALL}
                control={<Radio />}
                label="All - Allow all notifications, except the ones I have turned off"
              />
              <FormControlLabel
                value={ChannelLevelPreferenceOptions.REQUIRED}
                control={<Radio />}
                label="Required - Allow only important notifications related to account and security"
              />
            </RadioGroup>
          </FormControl>
        </Box>
      </AccordionDetails>
    </Accordion>
  );
}
