import React, { useEffect, useState } from 'react';

import { Link } from 'react-router-dom';

import {
  Box,
  Button,
  Checkbox,
  Grid,
  IconButton,
  LinearProgress,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Tooltip,
  Tab,
  Tabs,
  ButtonGroup
} from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import FilterListIcon from '@material-ui/icons/FilterList';
import Lock from '@material-ui/icons/Lock';
import RefreshIcon from '@material-ui/icons/Refresh';
import Pagination from '@material-ui/lab/Pagination';

import { bindMenu, bindTrigger, usePopupState } from 'material-ui-popup-state/hooks';
import { useDebounce } from 'use-debounce';

import {
  useCreativesQuery,
  Workspace_Creatives_Available_Bool_Exp,
  Workspace_Resource_Permission_Modes_Enum
} from 'generated/graphql';

import EmptyStatePage from 'components/EmptyStatePage';
import PageHeader from 'components/PageHeader';
import PaginationContainer from 'components/PaginationContainer';
import CreativeCard from 'components/creative/CreativeCard';
import ManualCreativeDrawer from 'components/creative/ManualCreativeDrawer';
import SearchInput from 'components/forms/SearchInput';
import PenPaintBrush from 'components/icons/PenPaintBrush';

import { useHasuraRoleContext } from 'lib/HasuraRoleContext';
import { FeatureFlags, useIsFeatureFlagEnabled } from 'lib/feature-flags';
import { useAgentPermissions } from 'lib/hooks/useAgentPermissions';
import { usePagination } from 'lib/hooks/useFiltersAndPagination';
import useUserContext from 'lib/hooks/useUserContext';

const CreativePage = () => {
  const { workspaceMemberContext } = useHasuraRoleContext();
  const { userId, activeWorkspaceId, isWorkspaceAnalyst, isWorkspaceAgent, isWorkspaceAdmin } =
    useUserContext();
  const agentPermissions = useAgentPermissions();

  const popupState = usePopupState({ variant: 'popover', popupId: 'filterMenu' });
  const actionsPopupState = usePopupState({ variant: 'popover', popupId: 'actionsMenu' });

  const [manualUploadDrawerOpen, setManualUploadDrawerOpen] = useState<boolean>(false);

  const [search, setSearch] = useState<string>();
  const [searchValue] = useDebounce(search ?? '', 650);
  const { page, setPage, limit, offset } = usePagination(8, 0);

  const [filteredTags, setFilteredTags] = useState<string[]>([]);
  const [tab, setTab] = useState(0);

  const createCreativeFlagEnabled = useIsFeatureFlagEnabled(FeatureFlags.create_creatives);

  const filterSearch = searchValue.length > 0 ? `%${searchValue}%` : undefined;
  const filterTags = filteredTags.length > 0 ? filteredTags : undefined;
  const where: Workspace_Creatives_Available_Bool_Exp = {
    _and: [
      { workspace_id: { _eq: activeWorkspaceId! } },
      { title: filterSearch ? { _ilike: filterSearch } : undefined }
    ]
  };

  if (filterTags) {
    where._and!.push({
      tags: { tag: { _in: filterTags } }
    });
  }

  if (isWorkspaceAgent) {
    where._and!.push({
      _or: [
        {
          created_by_id: { _eq: userId }
        },
        {
          agent_permission_mode: { _eq: Workspace_Resource_Permission_Modes_Enum.All }
        },
        {
          listings: {
            listing: {
              agents: { user: { display_name: { _in: agentPermissions.allowed_agents } } }
            }
          }
        },
        {
          assigned_users: {
            user_id: { _eq: userId }
          }
        },
        {
          head_office_creative: {}
        }
      ]
    });
  }

  const { data, loading, refetch } = useCreativesQuery({
    variables: {
      workspace_id: activeWorkspaceId!,
      offset: offset,
      limit: limit,
      where: where
    },
    context: workspaceMemberContext
  });

  const tags = data?.workspace_creative_tags?.map((tag) => tag.tag);
  const pageCount = Math.max(
    1,
    Math.ceil((data?.workspace_creatives_aggregate?.aggregate?.count ?? 0) / limit)
  );

  const handleFilterTag = (tag: string) => () => {
    if (filteredTags.includes(tag)) {
      // remove
      const newTags = filteredTags.filter((t) => t !== tag);
      setFilteredTags(newTags);
    } else {
      // add
      setFilteredTags([...filteredTags, tag]);
    }

    setPage(0);
  };

  const handleTabChange = (_event: React.ChangeEvent<{}>, newTab: number) => {
    if (tab !== newTab) {
      setTab(newTab);
    }
  };

  const handleToggleManualCreativeDrawer = () => {
    actionsPopupState.close();
    setManualUploadDrawerOpen((open) => !open);
  };

  const handleSaveManualCreative = () => {
    handleToggleManualCreativeDrawer();
    refetch();
  };

  useEffect(() => {
    if (tab === 0) {
      setFilteredTags([]);
    }
    if (tab === 1) {
      setFilteredTags(['image']);
    }
    if (tab === 2) {
      setFilteredTags(['video']);
    }
    if (tab === 3) {
      setFilteredTags(['ofi']);
    }
    if (tab === 4) {
      setFilteredTags(['head office']);
    }
    setPage(0);
  }, [tab, setFilteredTags, setPage]);

  // Reload when page enters view
  // As we have a SPA, pages enter and leave view without full reloads
  useEffect(() => {
    refetch();
  }, [refetch]);

  if (
    !loading &&
    data?.creatives?.length === 0 &&
    searchValue.length === 0 &&
    filteredTags.length === 0
  ) {
    return (
      <EmptyStatePage
        title="Creative"
        text="Easily generate and automate the creation for you marketing efforts here."
        icon={<PenPaintBrush color="secondary" fill="none" style={{ height: 64, width: 64 }} />}
        button={
          createCreativeFlagEnabled ? (
            <Button
              component={Link}
              to="create"
              variant="contained"
              color="secondary"
              size="large"
              disabled={isWorkspaceAnalyst}
              fullWidth
            >
              New Creative
            </Button>
          ) : (
            <Button
              component={Link}
              to="/upgrade"
              variant="contained"
              size="large"
              disabled={!isWorkspaceAdmin}
              fullWidth
              startIcon={<Lock />}
            >
              Upgrade to unlock creatives
            </Button>
          )
        }
      />
    );
  }

  return (
    <>
      <div>
        <PageHeader
          title="Creative"
          rightComponent={
            createCreativeFlagEnabled ? (
              <>
                <ButtonGroup
                  variant="contained"
                  color="secondary"
                  disabled={isWorkspaceAnalyst}
                  style={{ minWidth: 160 }}
                >
                  <Button component={Link} to="create" size="large">
                    New Creative
                  </Button>
                  <Button size="small" {...bindTrigger(actionsPopupState)}>
                    <ArrowDropDownIcon />
                  </Button>
                </ButtonGroup>
                <Menu
                  {...bindMenu(actionsPopupState)}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                  }}
                >
                  <MenuItem
                    onClick={handleToggleManualCreativeDrawer}
                    disabled={isWorkspaceAnalyst}
                  >
                    Upload media
                  </MenuItem>
                  <MenuItem component={Link} to="create#advanced" disabled={isWorkspaceAnalyst}>
                    New advanced creative
                  </MenuItem>
                  <MenuItem component={Link} to="create#image" disabled={isWorkspaceAnalyst}>
                    New image creative
                  </MenuItem>
                  <MenuItem component={Link} to="create#video" disabled={isWorkspaceAnalyst}>
                    New video creative
                  </MenuItem>
                </Menu>
              </>
            ) : (
              <Button
                component={Link}
                to="/upgrade"
                variant="contained"
                size="large"
                disabled={!isWorkspaceAdmin}
                style={{ minWidth: 160 }}
                startIcon={<Lock />}
              >
                Upgrade to unlock creatives
              </Button>
            )
          }
        />
        <Box
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'flex-end'
          }}
        >
          <Tabs value={tab} onChange={handleTabChange}>
            <Tab label="All" value={0} />
            <Tab label="Image" value={1} />
            <Tab label="Video" value={2} />
            <Tab label="OFI" value={3} />
            <Tab label="Head Office" value={4} />
          </Tabs>
          <Box
            style={{
              display: 'flex',
              justifyContent: 'middle',
              alignItems: 'center'
            }}
          >
            <SearchInput
              value={search}
              onChange={(event) => {
                setSearch(event.target.value);
              }}
              placeholder="Search Creatives"
              variant="outlined"
              onClickClear={() => {
                setSearch('');
                setPage(0);
              }}
              onClickSearch={() => {
                refetch();
              }}
            />
            <Tooltip title="Filter Tags">
              <IconButton {...bindTrigger(popupState)}>
                <FilterListIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Refresh Creative">
              <IconButton onClick={() => refetch()}>
                <RefreshIcon />
              </IconButton>
            </Tooltip>
          </Box>
        </Box>

        <Box mb={0.5} height={2}>
          {loading && Boolean(data) && <LinearProgress />}
        </Box>

        {loading && !data && (
          <Grid container spacing={2}>
            {Array.from(Array(8).keys()).map((key) => (
              <Grid key={key} item xs={12} sm={6} md={4} lg={3} xl={3}>
                <CreativeCard key={key} />
              </Grid>
            ))}
          </Grid>
        )}

        <Grid container spacing={2}>
          {data?.creatives?.map((creative) => (
            <Grid
              key={creative.id}
              item
              xs={12}
              sm={6}
              md={4}
              lg={3}
              xl={3}
              style={{
                paddingTop: 2
              }}
            >
              <CreativeCard id={creative.id!} onRefetchRequired={refetch} />
            </Grid>
          ))}
        </Grid>

        <PaginationContainer
          style={{
            marginTop: 16
          }}
        >
          <Pagination
            count={pageCount}
            page={page + 1}
            onChange={(_, page) => {
              setPage(Math.max(0, page - 1));
            }}
            showFirstButton={pageCount >= 5}
            showLastButton={pageCount >= 5}
          />
        </PaginationContainer>
        <Menu
          {...bindMenu(popupState)}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
          style={{ marginTop: 20 }}
          PaperProps={{
            style: {
              maxHeight: 700,
              width: 275
            }
          }}
        >
          {tags?.map((tag) => (
            <MenuItem key={tag} onClick={handleFilterTag(tag)}>
              <ListItemIcon>
                <Checkbox
                  edge="start"
                  checked={filteredTags.includes(tag)}
                  onClick={handleFilterTag(tag)}
                  disableRipple
                />
              </ListItemIcon>
              <ListItemText primary={tag} />
            </MenuItem>
          ))}
        </Menu>
      </div>
      <ManualCreativeDrawer
        open={manualUploadDrawerOpen}
        onClose={handleToggleManualCreativeDrawer}
        onSaved={handleSaveManualCreative}
      />
    </>
  );
};

export default CreativePage;
