import { Cloudinary } from '@cloudinary/url-gen';
import { thumbnail, limitFit } from '@cloudinary/url-gen/actions/resize';

// Create and configure your Cloudinary instance.
const cloudinary = new Cloudinary({
  cloud: {
    cloudName: 'properti'
  },
  url: {
    secure: true
  }
});

export function buildThumbnailUrl(imageUrl: string, width: number = 400, height?: number) {
  //  Don't try to load localhost images remotely
  if (imageUrl.includes('localhost')) {
    return imageUrl;
  }

  // Load Properti CDN if possible
  if (imageUrl.includes('cdn.properti.ai')) {
    return imageUrl;
  }

  const resize = height ? thumbnail().width(width).height(height) : thumbnail().width(width);
  const image = cloudinary.image(imageUrl).resize(resize).setDeliveryType('fetch').format('jpg');

  return image.toURL();
}

export function getCloudinaryThumbnailFromVideoPath(path: string) {
  const url = cloudinary
    .video(path)
    .resize(limitFit().width(720).height(720))
    .format('jpg')
    .toURL();

  return url;
}

export { cloudinary };
