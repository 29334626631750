import React from 'react';

import { Field, FieldInputProps } from 'react-final-form';

import {
  Avatar,
  Box,
  Checkbox,
  FormHelperText,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  Tooltip,
  Typography
} from '@material-ui/core';
import BrushIcon from '@material-ui/icons/Brush';
import HouseIcon from '@material-ui/icons/Home';
import PersonIcon from '@material-ui/icons/Person';
import OfficeIcon from '@material-ui/icons/StoreMallDirectory';
import { Skeleton } from '@material-ui/lab';

import CommentEditIcon from 'mdi-material-ui/CommentEditOutline';

import { useFieldForErrors } from 'components/forms/FinalFormSlider';

interface ListOption {
  // Value
  value: string | number;

  // Primary text
  primary: string;

  // Secondary text line (optional)
  secondary?: string | React.ReactNode | null;

  // Thumbnail URL
  thumbnailUrl?: string | null;
}

interface FinalFormListCheckboxesProps {
  name: string;
  multiple?: boolean;
  options?: ListOption[];
  loading?: boolean;
  emptyListText?: string;
  icon?: 'house' | 'person' | 'creative' | 'workspace';
  onEditItem?: (id: string | number) => void;
  editItemTooltip?: string;
  onRealShortzVideoClick?: (videoId: string) => void;
  dense?: boolean;
  disabled?: boolean;
}

interface FinalFormListItemProps {
  secondary?: string | React.ReactNode | null;
  primary: string;
  thumbnailUrl?: string | null;
  input: FieldInputProps<any, HTMLElement>;
  multiple: boolean;
  icon?: 'house' | 'person' | 'creative' | 'workspace';
  onEditItem?: (id: string | number) => void;
  editItemTooltip: string;
  onRealShortzVideoClick?: (videoId: string) => void;
  dense?: boolean;
  disabled?: boolean;
}

const FinalFormListItem: React.FC<FinalFormListItemProps> = ({
  secondary,
  primary,
  thumbnailUrl,
  input,
  multiple,
  icon = 'house',
  onEditItem,
  editItemTooltip,
  onRealShortzVideoClick,
  dense = false,
  disabled = false
}) => {
  const { onChange, checked, name, value, ...restInput } = input;

  const handleOnClick = () => {
    const type = multiple ? 'checkbox' : 'radio';
    onChange({
      target: { type, value: value, checked: !checked }
    });
    if (onRealShortzVideoClick) {
      onRealShortzVideoClick(value);
    }
  };

  const handleOnEdit = () => {
    if (onEditItem) {
      onEditItem(value);
    }
  };

  const avatarDimension = dense ? 55 : 75;
  const avatarMargin = dense ? 6 : 8;

  return (
    <ListItem button onClick={handleOnClick} dense={false} disabled={disabled}>
      <ListItemAvatar>
        <Avatar
          alt={primary}
          src={thumbnailUrl ?? void 0}
          variant="rounded"
          style={{
            width: avatarDimension,
            height: avatarDimension,
            margin: avatarMargin
          }}
        >
          {icon === 'house' && <HouseIcon />}
          {icon === 'person' && <PersonIcon />}
          {icon === 'creative' && <BrushIcon />}
          {icon === 'workspace' && <OfficeIcon />}
        </Avatar>
      </ListItemAvatar>
      <ListItemText
        style={{ paddingLeft: 4 }}
        onClick={handleOnClick}
        primary={primary}
        secondary={secondary}
      />

      <ListItemSecondaryAction>
        {onEditItem && (
          <Tooltip title={editItemTooltip}>
            <IconButton edge="end" aria-label={editItemTooltip} onClick={handleOnEdit}>
              <CommentEditIcon />
            </IconButton>
          </Tooltip>
        )}
        <Checkbox
          name={name}
          value={value}
          edge="end"
          onChange={(e) => {
            handleOnClick();
            onChange(e);
          }}
          checked={checked}
          disabled={disabled}
          {...restInput}
        />
      </ListItemSecondaryAction>
    </ListItem>
  );
};

const loaders = [0, 1, 2, 3, 4];

const FinalFormListCheckboxes: React.FC<FinalFormListCheckboxesProps> = ({
  name,
  options,
  multiple = false,
  loading = false,
  disabled = false,
  emptyListText = 'No results found',
  icon = 'house',
  onEditItem,
  editItemTooltip = 'Edit Item',
  dense = false,
  onRealShortzVideoClick
}) => {
  const field = useFieldForErrors(name);
  const meta = field.meta;
  const showError = (meta.error || meta.submitError) && meta.touched;

  const type = multiple ? 'checkbox' : 'radio';

  const loaderDimension = dense ? 55 : 75;
  const loaderMargin = dense ? 6 : 8;

  return (
    <div>
      <List dense={dense}>
        {loading
          ? loaders.map((i: number) => (
              <ListItem key={i} dense={dense}>
                <Skeleton
                  variant="rect"
                  style={{
                    height: loaderDimension,
                    width: loaderDimension,
                    margin: loaderMargin,
                    borderRadius: 4
                  }}
                />
                <span
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '100%',
                    paddingLeft: 4
                  }}
                >
                  <Skeleton variant="text" style={{ width: '60%' }} />
                  <Skeleton variant="text" style={{ width: '20%' }} />
                </span>
              </ListItem>
            ))
          : options?.map((option, idx) => (
              <Field
                key={idx}
                type={type}
                name={name}
                value={option.value}
                multiple={multiple}
                disabled={disabled}
                render={({ input }) => (
                  <FinalFormListItem
                    multiple={multiple}
                    icon={icon}
                    onEditItem={onEditItem}
                    editItemTooltip={editItemTooltip}
                    onRealShortzVideoClick={onRealShortzVideoClick}
                    dense={dense}
                    disabled={disabled}
                    {...option}
                    input={input}
                  />
                )}
              />
            ))}
      </List>
      {options?.length === 0 && (
        <Box pl={1}>
          <Typography>{emptyListText}</Typography>
        </Box>
      )}
      {showError && <FormHelperText error>{meta.error || meta.submitError}</FormHelperText>}
    </div>
  );
};

export default FinalFormListCheckboxes;
