import { Box } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

export const WizardPageNavigateButtonHolder = withStyles({ root: { width: 140 } })(Box);

export const WizardPageContainer = withStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100vh'
  }
})(Box);

export const WizardPageBody = withStyles((theme) => ({
  root: {
    width: 'calc(100% - 300px)',
    marginLeft: '300px',
    textAlign: 'left',
    paddingLeft: theme.spacing(4),
    paddingTop: theme.spacing(3),
    flexGrow: 1,
    maxHeight: '100%',
    overflowY: 'auto',
    marginBottom: theme.spacing(8),
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      marginLeft: 0,
      paddingLeft: theme.spacing(1),
      paddingTop: theme.spacing(2),
      marginBottom: theme.spacing(4),
      paddingRight: theme.spacing(1)
    }
  }
}))(Box);

export const WizardPageTopActionContainer = withStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(1),
    marginRight: theme.spacing(3),
    display: 'flex',
    flexDirection: 'row-reverse'
  }
}))(Box);

export const WizardPageButtonContainer = withStyles((theme) => ({
  root: {
    background: '#ffffff',
    boxShadow: '0px 6px 18px rgba(0, 0, 0, 0.06)',
    height: '64px',
    width: 'calc(100% - 300px)',
    marginLeft: '300px',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(10),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    alignSelf: 'flex-end',
    position: 'absolute',
    top: 'calc(100% - 64px)',
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    }
  }
}))(Box);
