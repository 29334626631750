export const SSO_EMAIL_DOMAINS: {
  domain: string;
  provider: string;
  provider_label: string;
  sso_required: boolean;
}[] = [
  {
    domain: 'atlas.com.au',
    provider: 'ljhooker',
    provider_label: 'LJ Hooker',
    sso_required: false
  },
  {
    domain: 'harveys.co.nz',
    provider: 'ljhooker',
    provider_label: 'LJ Hooker',
    sso_required: false
  },
  {
    domain: 'ljhooker.com',
    provider: 'ljhooker',
    provider_label: 'LJ Hooker',
    sso_required: false
  },
  { domain: 'ljh.nz', provider: 'ljhooker', provider_label: 'LJ Hooker', sso_required: false },
  { domain: 'ljha.com.au', provider: 'ljhooker', provider_label: 'LJ Hooker', sso_required: false },
  {
    domain: 'ljhadelaidemetro.com.au',
    provider: 'ljhooker',
    provider_label: 'LJ Hooker',
    sso_required: false
  },
  {
    domain: 'ljhalicesprings.com.au',
    provider: 'ljhooker',
    provider_label: 'LJ Hooker',
    sso_required: false
  },
  {
    domain: 'ljhashburton.co.nz',
    provider: 'ljhooker',
    provider_label: 'LJ Hooker',
    sso_required: false
  },
  {
    domain: 'ljhatherton.com',
    provider: 'ljhooker',
    provider_label: 'LJ Hooker',
    sso_required: false
  },
  {
    domain: 'ljhavalon.com.au',
    provider: 'ljhooker',
    provider_label: 'LJ Hooker',
    sso_required: false
  },
  { domain: 'ljhb.com.au', provider: 'ljhooker', provider_label: 'LJ Hooker', sso_required: false },
  {
    domain: 'ljhbeenleigh.com.au',
    provider: 'ljhooker',
    provider_label: 'LJ Hooker',
    sso_required: false
  },
  {
    domain: 'ljhbega.com.au',
    provider: 'ljhooker',
    provider_label: 'LJ Hooker',
    sso_required: false
  },
  {
    domain: 'ljhbelconnen.com.au',
    provider: 'ljhooker',
    provider_label: 'LJ Hooker',
    sso_required: false
  },
  {
    domain: 'ljhbl.com.au',
    provider: 'ljhooker',
    provider_label: 'LJ Hooker',
    sso_required: false
  },
  {
    domain: 'ljhbls.com.au',
    provider: 'ljhooker',
    provider_label: 'LJ Hooker',
    sso_required: false
  },
  {
    domain: 'ljhbowen.com.au',
    provider: 'ljhooker',
    provider_label: 'LJ Hooker',
    sso_required: false
  },
  {
    domain: 'ljhbroadwater.com.au',
    provider: 'ljhooker',
    provider_label: 'LJ Hooker',
    sso_required: false
  },
  {
    domain: 'ljhburwood.com.au',
    provider: 'ljhooker',
    provider_label: 'LJ Hooker',
    sso_required: false
  },
  {
    domain: 'ljhcabramatta.com.au',
    provider: 'ljhooker',
    provider_label: 'LJ Hooker',
    sso_required: false
  },
  {
    domain: 'ljhcala.com.au',
    provider: 'ljhooker',
    provider_label: 'LJ Hooker',
    sso_required: false
  },
  {
    domain: 'ljhcampsie.com.au',
    provider: 'ljhooker',
    provider_label: 'LJ Hooker',
    sso_required: false
  },
  {
    domain: 'ljhcanberracity.com.au',
    provider: 'ljhooker',
    provider_label: 'LJ Hooker',
    sso_required: false
  },
  {
    domain: 'ljhcasey.com.au',
    provider: 'ljhooker',
    provider_label: 'LJ Hooker',
    sso_required: false
  },
  {
    domain: 'ljhcb.com.au',
    provider: 'ljhooker',
    provider_label: 'LJ Hooker',
    sso_required: false
  },
  {
    domain: 'ljhces.com.au',
    provider: 'ljhooker',
    provider_label: 'LJ Hooker',
    sso_required: false
  },
  {
    domain: 'ljhch.com.au',
    provider: 'ljhooker',
    provider_label: 'LJ Hooker',
    sso_required: false
  },
  {
    domain: 'ljhclayfield.com.au',
    provider: 'ljhooker',
    provider_label: 'LJ Hooker',
    sso_required: false
  },
  {
    domain: 'ljhcoffs.com',
    provider: 'ljhooker',
    provider_label: 'LJ Hooker',
    sso_required: false
  },
  {
    domain: 'ljhcomplete.com.au',
    provider: 'ljhooker',
    provider_label: 'LJ Hooker',
    sso_required: false
  },
  {
    domain: 'ljhdickson.com.au',
    provider: 'ljhooker',
    provider_label: 'LJ Hooker',
    sso_required: false
  },
  {
    domain: 'ljhdoublebay.com',
    provider: 'ljhooker',
    provider_label: 'LJ Hooker',
    sso_required: false
  },
  {
    domain: 'ljhdunedin.co.nz',
    provider: 'ljhooker',
    provider_label: 'LJ Hooker',
    sso_required: false
  },
  {
    domain: 'ljhdural.com.au',
    provider: 'ljhooker',
    provider_label: 'LJ Hooker',
    sso_required: false
  },
  {
    domain: 'ljheh.com.au',
    provider: 'ljhooker',
    provider_label: 'LJ Hooker',
    sso_required: false
  },
  {
    domain: 'ljhfairfield.com.au',
    provider: 'ljhooker',
    provider_label: 'LJ Hooker',
    sso_required: false
  },
  {
    domain: 'ljhff.com.au',
    provider: 'ljhooker',
    provider_label: 'LJ Hooker',
    sso_required: false
  },
  {
    domain: 'ljhforster.com.au',
    provider: 'ljhooker',
    provider_label: 'LJ Hooker',
    sso_required: false
  },
  {
    domain: 'ljhfp.com.au',
    provider: 'ljhooker',
    provider_label: 'LJ Hooker',
    sso_required: false
  },
  {
    domain: 'ljhgc.com.au',
    provider: 'ljhooker',
    provider_label: 'LJ Hooker',
    sso_required: false
  },
  {
    domain: 'ljhglenelgbrighton.com.au',
    provider: 'ljhooker',
    provider_label: 'LJ Hooker',
    sso_required: false
  },
  {
    domain: 'ljhgleninnes.com.au',
    provider: 'ljhooker',
    provider_label: 'LJ Hooker',
    sso_required: false
  },
  {
    domain: 'ljhgungahlin.com.au',
    provider: 'ljhooker',
    provider_label: 'LJ Hooker',
    sso_required: false
  },
  {
    domain: 'ljhgunghalin.com.au',
    provider: 'ljhooker',
    provider_label: 'LJ Hooker',
    sso_required: false
  },
  {
    domain: 'ljhhv.com.au',
    provider: 'ljhooker',
    provider_label: 'LJ Hooker',
    sso_required: false
  },
  {
    domain: 'ljh-inverloch.com.au',
    provider: 'ljhooker',
    provider_label: 'LJ Hooker',
    sso_required: false
  },
  {
    domain: 'ljhkala.com.au',
    provider: 'ljhooker',
    provider_label: 'LJ Hooker',
    sso_required: false
  },
  {
    domain: 'ljhkaleen.com.au',
    provider: 'ljhooker',
    provider_label: 'LJ Hooker',
    sso_required: false
  },
  {
    domain: 'ljh-kallangur.com.au',
    provider: 'ljhooker',
    provider_label: 'LJ Hooker',
    sso_required: false
  },
  {
    domain: 'ljhkatherine.com.au',
    provider: 'ljhooker',
    provider_label: 'LJ Hooker',
    sso_required: false
  },
  {
    domain: 'ljhkensingtonunley.com.au',
    provider: 'ljhooker',
    provider_label: 'LJ Hooker',
    sso_required: false
  },
  {
    domain: 'ljhkingaroy.com.au',
    provider: 'ljhooker',
    provider_label: 'LJ Hooker',
    sso_required: false
  },
  {
    domain: 'ljhkingscliff.com.au',
    provider: 'ljhooker',
    provider_label: 'LJ Hooker',
    sso_required: false
  },
  {
    domain: 'ljhkippax.com.au',
    provider: 'ljhooker',
    provider_label: 'LJ Hooker',
    sso_required: false
  },
  {
    domain: 'ljhkwinana.com.au',
    provider: 'ljhooker',
    provider_label: 'LJ Hooker',
    sso_required: false
  },
  {
    domain: 'ljhlaurieton.com.au',
    provider: 'ljhooker',
    provider_label: 'LJ Hooker',
    sso_required: false
  },
  {
    domain: 'ljhlc.com.au',
    provider: 'ljhooker',
    provider_label: 'LJ Hooker',
    sso_required: false
  },
  {
    domain: 'ljhlithgow.com.au',
    provider: 'ljhooker',
    provider_label: 'LJ Hooker',
    sso_required: false
  },
  {
    domain: 'ljhmanuka.com.au',
    provider: 'ljhooker',
    provider_label: 'LJ Hooker',
    sso_required: false
  },
  {
    domain: 'ljhmareeba.com',
    provider: 'ljhooker',
    provider_label: 'LJ Hooker',
    sso_required: false
  },
  {
    domain: 'ljhmelton.com.au',
    provider: 'ljhooker',
    provider_label: 'LJ Hooker',
    sso_required: false
  },
  { domain: 'ljhmo.co.nz', provider: 'ljhooker', provider_label: 'LJ Hooker', sso_required: false },
  {
    domain: 'ljhmosgiel.co.nz',
    provider: 'ljhooker',
    provider_label: 'LJ Hooker',
    sso_required: false
  },
  {
    domain: 'ljhmtbarker.com.au',
    provider: 'ljhooker',
    provider_label: 'LJ Hooker',
    sso_required: false
  },
  {
    domain: 'ljhmuswellbrook.com.au',
    provider: 'ljhooker',
    provider_label: 'LJ Hooker',
    sso_required: false
  },
  {
    domain: 'ljhmv.com.au',
    provider: 'ljhooker',
    provider_label: 'LJ Hooker',
    sso_required: false
  },
  {
    domain: 'ljhnarrabeen.com.au',
    provider: 'ljhooker',
    provider_label: 'LJ Hooker',
    sso_required: false
  },
  {
    domain: 'ljhnewport.com.au',
    provider: 'ljhooker',
    provider_label: 'LJ Hooker',
    sso_required: false
  },
  {
    domain: 'ljhoamaru.co.nz',
    provider: 'ljhooker',
    provider_label: 'LJ Hooker',
    sso_required: false
  },
  { domain: 'ljhooker.au', provider: 'ljhooker', provider_label: 'LJ Hooker', sso_required: false },
  {
    domain: 'ljhooker.co.nz',
    provider: 'ljhooker',
    provider_label: 'LJ Hooker',
    sso_required: false
  },
  {
    domain: 'ljhooker.com.au',
    provider: 'ljhooker',
    provider_label: 'LJ Hooker',
    sso_required: false
  },
  { domain: 'ljhooker.me', provider: 'ljhooker', provider_label: 'LJ Hooker', sso_required: false },
  {
    domain: 'ljhookerbairnsdale.com.au',
    provider: 'ljhooker',
    provider_label: 'LJ Hooker',
    sso_required: false
  },
  {
    domain: 'ljhookerbelmont.com.au',
    provider: 'ljhooker',
    provider_label: 'LJ Hooker',
    sso_required: false
  },
  {
    domain: 'ljhookerbm.com.au',
    provider: 'ljhooker',
    provider_label: 'LJ Hooker',
    sso_required: false
  },
  {
    domain: 'ljhookerbundaberg.com.au',
    provider: 'ljhooker',
    provider_label: 'LJ Hooker',
    sso_required: false
  },
  {
    domain: 'ljhookercoastal.com.au',
    provider: 'ljhooker',
    provider_label: 'LJ Hooker',
    sso_required: false
  },
  {
    domain: 'ljhookercom.au',
    provider: 'ljhooker',
    provider_label: 'LJ Hooker',
    sso_required: false
  },
  {
    domain: 'ljhookerdarwin.com.au',
    provider: 'ljhooker',
    provider_label: 'LJ Hooker',
    sso_required: false
  },
  {
    domain: 'ljhookerdulwichhill.com.au',
    provider: 'ljhooker',
    provider_label: 'LJ Hooker',
    sso_required: false
  },
  {
    domain: 'ljhookereast.com.au',
    provider: 'ljhooker',
    provider_label: 'LJ Hooker',
    sso_required: false
  },
  {
    domain: 'ljhookergatton.com.au',
    provider: 'ljhooker',
    provider_label: 'LJ Hooker',
    sso_required: false
  },
  {
    domain: 'ljhookergordon.com.au',
    provider: 'ljhooker',
    provider_label: 'LJ Hooker',
    sso_required: false
  },
  {
    domain: 'ljhookerhay.com',
    provider: 'ljhooker',
    provider_label: 'LJ Hooker',
    sso_required: false
  },
  {
    domain: 'ljhookermmc.com.au',
    provider: 'ljhooker',
    provider_label: 'LJ Hooker',
    sso_required: false
  },
  {
    domain: 'ljhookermooroolbark.com.au',
    provider: 'ljhooker',
    provider_label: 'LJ Hooker',
    sso_required: false
  },
  {
    domain: 'ljhookerpinnacle.com',
    provider: 'ljhooker',
    provider_label: 'LJ Hooker',
    sso_required: false
  },
  {
    domain: 'ljhookerpn.co.nz',
    provider: 'ljhooker',
    provider_label: 'LJ Hooker',
    sso_required: false
  },
  {
    domain: 'ljhookerprojects.com.au',
    provider: 'ljhooker',
    provider_label: 'LJ Hooker',
    sso_required: false
  },
  {
    domain: 'ljhookerproperty.com.au',
    provider: 'ljhooker',
    provider_label: 'LJ Hooker',
    sso_required: false
  },
  {
    domain: 'ljhookerredcliffe.com.au',
    provider: 'ljhooker',
    provider_label: 'LJ Hooker',
    sso_required: false
  },
  {
    domain: 'ljhookersgc.com.au',
    provider: 'ljhooker',
    provider_label: 'LJ Hooker',
    sso_required: false
  },
  {
    domain: 'ljhookerwestlakes.com.au',
    provider: 'ljhooker',
    provider_label: 'LJ Hooker',
    sso_required: false
  },
  {
    domain: 'ljhpb.com.au',
    provider: 'ljhooker',
    provider_label: 'LJ Hooker',
    sso_required: false
  },
  {
    domain: 'ljhpc.com.au',
    provider: 'ljhooker',
    provider_label: 'LJ Hooker',
    sso_required: false
  },
  {
    domain: 'ljhpmq.com.au',
    provider: 'ljhooker',
    provider_label: 'LJ Hooker',
    sso_required: false
  },
  {
    domain: 'ljhpp.com.au',
    provider: 'ljhooker',
    provider_label: 'LJ Hooker',
    sso_required: false
  },
  {
    domain: 'ljhps.com.au',
    provider: 'ljhooker',
    provider_label: 'LJ Hooker',
    sso_required: false
  },
  {
    domain: 'ljhsc.com.au',
    provider: 'ljhooker',
    provider_label: 'LJ Hooker',
    sso_required: false
  },
  {
    domain: 'ljhscarborough.com.au',
    provider: 'ljhooker',
    provider_label: 'LJ Hooker',
    sso_required: false
  },
  {
    domain: 'ljhseaforth.com.au',
    provider: 'ljhooker',
    provider_label: 'LJ Hooker',
    sso_required: false
  },
  {
    domain: 'ljhsouth.com.au',
    provider: 'ljhooker',
    provider_label: 'LJ Hooker',
    sso_required: false
  },
  {
    domain: 'ljhsouthwest.com.au',
    provider: 'ljhooker',
    provider_label: 'LJ Hooker',
    sso_required: false
  },
  {
    domain: 'ljhspringfield.com.au',
    provider: 'ljhooker',
    provider_label: 'LJ Hooker',
    sso_required: false
  },
  {
    domain: 'ljhstrathfield.com.au',
    provider: 'ljhooker',
    provider_label: 'LJ Hooker',
    sso_required: false
  },
  {
    domain: 'ljhsupport.com.au',
    provider: 'ljhooker',
    provider_label: 'LJ Hooker',
    sso_required: false
  },
  { domain: 'ljht.com.au', provider: 'ljhooker', provider_label: 'LJ Hooker', sso_required: false },
  { domain: 'ljhta.co.nz', provider: 'ljhooker', provider_label: 'LJ Hooker', sso_required: false },
  {
    domain: 'ljhtaree.com.au',
    provider: 'ljhooker',
    provider_label: 'LJ Hooker',
    sso_required: false
  },
  {
    domain: 'ljhteam.com.au',
    provider: 'ljhooker',
    provider_label: 'LJ Hooker',
    sso_required: false
  },
  {
    domain: 'ljhtghn.com.au',
    provider: 'ljhooker',
    provider_label: 'LJ Hooker',
    sso_required: false
  },
  {
    domain: 'ljhttr.co.nz',
    provider: 'ljhooker',
    provider_label: 'LJ Hooker',
    sso_required: false
  },
  {
    domain: 'ljhtully.com.au',
    provider: 'ljhooker',
    provider_label: 'LJ Hooker',
    sso_required: false
  },
  {
    domain: 'ljhvicpark.com.au',
    provider: 'ljhooker',
    provider_label: 'LJ Hooker',
    sso_required: false
  },
  { domain: 'ljhw.com.au', provider: 'ljhooker', provider_label: 'LJ Hooker', sso_required: false },
  {
    domain: 'ljhwanaka.co.nz',
    provider: 'ljhooker',
    provider_label: 'LJ Hooker',
    sso_required: false
  },
  {
    domain: 'ljhwingham.com.au',
    provider: 'ljhooker',
    provider_label: 'LJ Hooker',
    sso_required: false
  },
  {
    domain: 'ljhwodenweston.com.au',
    provider: 'ljhooker',
    provider_label: 'LJ Hooker',
    sso_required: false
  },
  {
    domain: 'ljplainland.com.au',
    provider: 'ljhooker',
    provider_label: 'LJ Hooker',
    sso_required: false
  }
];
