import React, { useCallback, useState } from 'react';

import { FormSpy } from 'react-final-form';

import {
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select as MuiSelect,
  Typography
} from '@material-ui/core';
import Pagination from '@material-ui/lab/Pagination';

import moment from 'moment';
import { useDebounce } from 'use-debounce';

import {
  useDistinctListingAgentsQuery,
  useListingsQuery,
  Workspace_Listing_Types_Enum,
  Workspace_Listings_Bool_Exp
} from 'generated/graphql';

import PaginationContainer from 'components/PaginationContainer';
import FinalFormListCheckboxes from 'components/forms/FinalFormListCheckboxes';
import SearchInput from 'components/forms/SearchInput';

import { buildThumbnailUrl } from 'lib/Cloudinary';
import { useHasuraRoleContext } from 'lib/HasuraRoleContext';
import { getFilePath } from 'lib/auth/hbp';
import { useAgentPermissions } from 'lib/hooks/useAgentPermissions';
import useUserContext from 'lib/hooks/useUserContext';

interface PropertySelectProps {
  name: string;
  multiple?: boolean;
  defaultStatus?: 'current' | 'sold' | 'withdrawn' | 'leased';
  defaultType?: Workspace_Listing_Types_Enum;
  variant?: 'ofi' | 'auction';
  showInspectionTimes?: boolean;
  showAuctionTimes?: boolean;
  showFilters?: boolean;
  dense?: boolean;
}

const PropertySelect: React.FC<PropertySelectProps> = ({
  name,
  multiple = false,
  defaultStatus = 'current',
  defaultType = Workspace_Listing_Types_Enum.Residential,
  variant,
  showInspectionTimes = false,
  showAuctionTimes = false,
  showFilters = false,
  dense = false
}) => {
  const { workspaceMemberContext } = useHasuraRoleContext();
  const { activeWorkspaceId, isWorkspaceAgent } = useUserContext();
  const agentPermissions = useAgentPermissions();

  const [pageSize] = useState(5);
  const [pagination, setPagination] = useState(1);
  const [searchQuery, setSearchQuery] = useState('');
  const [search] = useDebounce(searchQuery, 500);
  const [status, setStatus] = useState<string | 'all'>(defaultStatus);
  const [type, setType] = useState<Workspace_Listing_Types_Enum | 'all'>(defaultType);
  const [filterInspections, setFilterInspections] = useState<boolean>(showInspectionTimes);
  const [filterAuctions, setFilterAuctions] = useState<boolean>(showAuctionTimes);
  const [listingAgent, setListingAgent] = useState<string | 'all'>('');

  const pgSearch = search && search !== '' ? `%${search}%` : undefined;
  const pgAgentSearch = ['', 'all'].includes(listingAgent)
    ? undefined
    : `%${listingAgent.replace(/\s+/g, '%')}%`;

  const where: Workspace_Listings_Bool_Exp = {
    _and: [
      { status: { _eq: status === 'all' ? undefined : status } },
      { type: { _eq: type === 'all' ? undefined : type } },
      { inspection_times: filterInspections ? { start_time: { _gte: 'now()' } } : undefined },
      { auction_date: filterAuctions ? { _gte: 'now() ' } : undefined },
      { agents: pgAgentSearch ? { user: { display_name: { _ilike: pgAgentSearch } } } : undefined },
      { workspace_id: { _eq: activeWorkspaceId! } },
      {
        agents: isWorkspaceAgent
          ? { user: { display_name: { _in: agentPermissions.allowed_agents } } }
          : undefined
      },
      {
        _or: [
          { status: { _ilike: pgSearch } },
          { location: { address: { formatted_address: { _ilike: pgSearch } } } },
          { location: { address: { suburb: { _ilike: pgSearch } } } },
          { location: { address: { street: { _ilike: pgSearch } } } },
          { location: { address: { state: { _ilike: pgSearch } } } },
          { agents: { user: { display_name: { _ilike: pgSearch } } } },
          { headline: { _ilike: pgSearch } }
        ]
      }
    ]
  };

  const { data, loading, error, refetch } = useListingsQuery({
    variables: {
      limit: pageSize,
      offset: (pagination - 1) * pageSize,
      where: where
    },
    context: workspaceMemberContext
  });
  const { data: agentsData } = useDistinctListingAgentsQuery({
    variables: {
      workspace_id: activeWorkspaceId!
    },
    skip: !activeWorkspaceId,
    context: workspaceMemberContext
  });

  const handleChangePagination = useCallback(
    (_event: any, page: number) => {
      setPagination(page);
    },
    [setPagination]
  );

  const handleSearch = useCallback(() => {
    refetch();
  }, [refetch]);

  const handleSearchChange = useCallback(
    (event: any) => {
      setSearchQuery(event?.target.value);
      setPagination(1);
    },
    [setPagination, setSearchQuery]
  );

  const handleClearSearch = useCallback(() => {
    setSearchQuery('');
  }, [setSearchQuery]);

  const handleFilterChange = useCallback(
    (event: React.ChangeEvent<{ value: unknown }>) => {
      setStatus(event.target.value as string);
    },
    [setStatus]
  );

  const handleTypeChange = useCallback(
    (event: React.ChangeEvent<{ value: unknown }>) => {
      setType(event.target.value as Workspace_Listing_Types_Enum);
    },
    [setType]
  );

  const handleListingAgentChange = useCallback(
    (event: React.ChangeEvent<{ value: unknown }>) => {
      setListingAgent((event.target.value as string) ?? '');
    },
    [setListingAgent]
  );

  const handleToggleFilterInspectionTimes = useCallback(() => {
    setFilterInspections(!filterInspections);
  }, [filterInspections, setFilterInspections]);

  const handleToggleFilterAuctions = useCallback(() => {
    setFilterAuctions(!filterAuctions);
  }, [filterAuctions, setFilterAuctions]);

  const count = data?.count?.aggregate?.count ?? 0;
  const pages = Math.max(1, Math.ceil(count / pageSize));

  return (
    <div>
      <SearchInput
        name="property_search"
        label="Search Properties"
        value={searchQuery}
        style={{ width: '100%' }}
        onClickSearch={handleSearch}
        onClickClear={handleClearSearch}
        onChange={handleSearchChange}
      />
      <FormControl style={{ width: '100%' }}>
        <InputLabel>Listing Type</InputLabel>
        <MuiSelect variant="outlined" autoWidth value={type} onChange={handleTypeChange}>
          <MenuItem value={'all'}>
            <em>All</em>
          </MenuItem>
          <MenuItem value={Workspace_Listing_Types_Enum.Residential}>Residential</MenuItem>
          <MenuItem value={Workspace_Listing_Types_Enum.Rental}>Rental</MenuItem>
          <MenuItem value={Workspace_Listing_Types_Enum.Land}>Land</MenuItem>
          <MenuItem value={Workspace_Listing_Types_Enum.Rural}>Rural</MenuItem>
          <MenuItem value={Workspace_Listing_Types_Enum.Commercial}>Commercial</MenuItem>
          <MenuItem value={Workspace_Listing_Types_Enum.Business}>Business</MenuItem>
        </MuiSelect>
      </FormControl>
      <FormControl style={{ width: '100%' }}>
        <InputLabel>Filter Market Status</InputLabel>
        <MuiSelect variant="outlined" autoWidth value={status} onChange={handleFilterChange}>
          <MenuItem value={'all'}>
            <em>All</em>
          </MenuItem>
          <MenuItem value="current">On Market</MenuItem>
          <MenuItem value="offmarket">Off Market</MenuItem>
          <MenuItem value="sold">Sold</MenuItem>
          <MenuItem value="withdrawn">Withdrawn</MenuItem>
          <MenuItem value="leased">Leased</MenuItem>
        </MuiSelect>
      </FormControl>
      <FormControl style={{ width: '100%' }}>
        <InputLabel>Filter Listing Agent</InputLabel>
        <MuiSelect
          variant="outlined"
          autoWidth
          value={listingAgent}
          onChange={handleListingAgentChange}
        >
          <MenuItem value={'all'}>
            <em>All</em>
          </MenuItem>
          {agentsData?.agents
            ?.filter((agent) => !!agent.display_name)
            ?.filter(
              (agent) =>
                !isWorkspaceAgent || agentPermissions.allowed_agents.includes(agent.display_name!)
            )
            .map((agent) => (
              <MenuItem key={agent.id} value={agent.display_name!}>
                {agent.display_name}
              </MenuItem>
            ))}
        </MuiSelect>
      </FormControl>
      {(showInspectionTimes || showFilters) && (
        <FormControl>
          <FormControlLabel
            control={
              <Checkbox
                checked={filterInspections}
                name="inspection_times"
                onChange={handleToggleFilterInspectionTimes}
              />
            }
            label="Filter properties with future inspection times"
          />
        </FormControl>
      )}
      {(showAuctionTimes || showFilters) && (
        <FormControl>
          <FormControlLabel
            control={
              <Checkbox
                checked={filterAuctions}
                name="auctions"
                onChange={handleToggleFilterAuctions}
              />
            }
            label="Filter properties with upcoming auctions"
          />
        </FormControl>
      )}
      {error && (
        <Typography variant="caption" color="error" gutterBottom>
          Unable to get listings
        </Typography>
      )}
      <PaginationContainer>
        <Typography style={{ textAlign: 'center' }} variant="caption" gutterBottom>
          Showing {Math.min(pageSize, count)} of {count}
        </Typography>
      </PaginationContainer>

      <PaginationContainer>
        <FormSpy subscription={{ values: true }}>
          {(props) => {
            if (multiple) {
              return (
                <Typography style={{ textAlign: 'center' }} variant="caption" gutterBottom>
                  {props.values[name]?.length} Selected
                </Typography>
              );
            } else if (props.values[name]) {
              return (
                <Typography style={{ textAlign: 'center' }} variant="caption" gutterBottom>
                  1 Selected
                </Typography>
              );
            }
            return null;
          }}
        </FormSpy>
      </PaginationContainer>

      <FinalFormListCheckboxes
        name={name}
        multiple={multiple}
        loading={loading}
        dense={dense}
        options={data?.listings?.map((listing) => {
          let secondary: string | React.ReactNode | null | undefined = listing?.headline;

          if (variant === 'ofi' || showInspectionTimes) {
            const inspections = listing.inspection_times;
            const inspectionCounts = inspections.length;
            const hasInspection = inspectionCounts > 0;

            secondary = (
              <>
                <Typography component="span" variant="body2" gutterBottom>
                  {listing?.headline}
                </Typography>
                <br />
                {hasInspection &&
                  inspections.map((inspection) => {
                    const startTime = inspection.start_time;
                    const endTime = inspection.end_time;
                    const start = startTime ? moment(startTime) : null;
                    const end = endTime ? moment(endTime) : null;

                    return (
                      <Typography key={inspection.id} component="span" variant="body2" gutterBottom>
                        {start?.format('ddd Do MMM h:mm a')} to {end?.format('h:mm a')} <br />
                      </Typography>
                    );
                  })}
                {!hasInspection && (
                  <Typography component="span" variant="body2" color="error">
                    Inspection times not found
                  </Typography>
                )}
              </>
            );
          } else if (variant === 'auction' || showAuctionTimes) {
            const hasAuction = Boolean(listing?.auction_date);
            const auctionTime = hasAuction ? moment(listing?.auction_date) : undefined;

            secondary = (
              <>
                <Typography component="span" variant="body2" gutterBottom>
                  {listing?.headline}
                </Typography>
                <br />
                {hasAuction && (
                  <Typography component="span" variant="body2">
                    Auction {auctionTime?.format('ddd Do MMM h:mm a')}
                  </Typography>
                )}
                {!hasAuction && (
                  <Typography component="span" variant="body2" color="error">
                    Auction time not found
                  </Typography>
                )}
              </>
            );
          }

          const images = listing?.images ?? [];
          const mainImage = images[0];

          const imageUrl =
            mainImage?.image.url ?? getFilePath(mainImage?.image.path, mainImage?.image.token);
          const thumbnailUrl = imageUrl ? buildThumbnailUrl(imageUrl, 400, 400) : undefined;

          return {
            value: listing?.id,
            primary: listing.location.address.full_address ?? listing.headline,
            secondary: secondary,
            thumbnailUrl: thumbnailUrl
          };
        })}
      />
      <PaginationContainer>
        <Pagination count={pages} page={pagination} onChange={handleChangePagination} />
      </PaginationContainer>
    </div>
  );
};

export default PropertySelect;
