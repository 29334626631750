export enum FeatureFlags {
  advanced_dashboard = 'advanced_dashboard',
  api_access = 'api_access',
  audiences = 'audiences',
  automations = 'automations',
  automations_custom = 'automations_custom',
  campaigns = 'campaigns',
  campaign_templates = 'campaign_templates',
  content = 'content',
  creatives = 'creatives',
  create_creatives = 'create_creatives',
  customer_reviews = 'customer_reviews',
  lead_gen = 'lead_gen',
  leads = 'leads',
  listings = 'listings',
  posts = 'posts',
  posts_bulk = 'posts_bulk',
  realshortz = 'realshortz',
  short_links = 'short_links',
  simple_campaigns = 'simple_campaigns',
  workflows = 'workflows',
  campaign_promotions = 'campaign_promotions',
  canva_app = 'canva_app',
  market_data = 'market_data',

  // Preview features
  next_release = 'next_release'
}
