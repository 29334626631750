import { Link, useLocation } from 'react-router-dom';

import { Box, Button } from '@material-ui/core';
import BrokenImageOutlinedIcon from '@material-ui/icons/BrokenImageOutlined';

import logdna from '@logdna/browser';

import EmptyStatePage from 'components/EmptyStatePage';

const NotFoundPage = () => {
  const location = useLocation();
  logdna.warn('Page not found', {
    location
  });

  return (
    <Box>
      <EmptyStatePage
        title="Page Not Found"
        text="Whoops, this looks like a wrong link."
        icon={
          <BrokenImageOutlinedIcon
            color="secondary"
            fill="none"
            style={{ height: 64, width: 64 }}
          />
        }
        button={
          <Button
            variant="contained"
            color="secondary"
            component={Link}
            to="/dashboard"
            size="large"
            fullWidth
          >
            Go Home
          </Button>
        }
      />
    </Box>
  );
};

export default NotFoundPage;
