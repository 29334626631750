import { useCallback, useMemo } from 'react';

import { useNavigate } from 'react-router';

import { Box, Button, ListItem, ListItemText, Typography, useTheme } from '@material-ui/core';

import moment from 'moment';

import { IActionObject, IRemoteNotification, ISubtextObject } from './types';

interface NotificationListItemProps {
  notification: IRemoteNotification;
  markClicked: (notificationId: string) => void;
  closePopoverState: () => void;
}

export default function NotificationListItem({
  notification,
  markClicked,
  closePopoverState
}: NotificationListItemProps) {
  const navigate = useNavigate();
  const theme = useTheme();

  const hasSeenOrInteracted = useMemo(
    () => Boolean(notification.interacted_on) || Boolean(notification.seen_on),
    [notification.interacted_on, notification.seen_on]
  );

  const extraDataJson = useMemo(() => {
    if (notification.message.extra_data) {
      try {
        return JSON.parse(notification.message.extra_data);
      } catch (error: any) {
        return null;
      }
    }
    return null;
  }, [notification.message.extra_data]);

  const handleClickNotification = useCallback(() => {
    markClicked(notification.n_id);
    closePopoverState();

    if (!notification.message.url) {
      return;
    }

    if (
      notification.message.url.startsWith('http') ||
      notification.message.url.startsWith('mailto')
    ) {
      if (notification.message.open_in_new_tab) {
        window.open(notification.message.url, '_blank')?.focus();
      } else {
        window.location.href = notification.message.url;
      }
    } else {
      // Use React Navigation
      navigate(notification.message.url, { state: extraDataJson?.navigation_state ?? undefined });
    }
  }, [navigate, markClicked, notification, closePopoverState, extraDataJson]);

  const handleClickSubtextAction = useCallback(
    (subtext: ISubtextObject) => {
      markClicked(notification.n_id);
      closePopoverState();

      if (subtext.action_url) {
        if (subtext.action_url.startsWith('http') || subtext.action_url.startsWith('mailto')) {
          window.location.href = subtext.action_url;
        } else {
          // Use React Navigation
          navigate(subtext.action_url, { state: extraDataJson?.navigation_state ?? undefined });
        }
      }
    },
    [navigate, markClicked, notification.n_id, closePopoverState, extraDataJson]
  );

  const handleClickAction = useCallback(
    (action: IActionObject) => {
      markClicked(notification.n_id);
      closePopoverState();

      if (!action.url) {
        return;
      }

      if (action.url.startsWith('http') || action.url.startsWith('mailto')) {
        if (action.open_in_new_tab) {
          window.open(action.url, '_blank')?.focus();
        } else {
          window.location.href = action.url;
        }
      } else {
        // Use React Navigation
        navigate(action.url, { state: extraDataJson?.navigation_state ?? undefined });
      }
    },
    [navigate, markClicked, notification.n_id, closePopoverState, extraDataJson]
  );

  return (
    <>
      <ListItem
        style={{ zIndex: 8 }}
        selected={!hasSeenOrInteracted}
        button
        onClick={handleClickNotification}
        divider
      >
        <ListItemText
          disableTypography
          primary={
            <Box>
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <Typography variant="subtitle1" component="span" display="block" gutterBottom>
                  <strong>{notification.message.header}</strong>
                </Typography>
                <Typography variant="overline">
                  {moment(notification.created_on).fromNow()}
                  {!hasSeenOrInteracted && (
                    <span
                      style={{
                        display: 'inline-block',
                        borderRadius: '50%',
                        flexGrow: 0,
                        flexShrink: 0,
                        margin: theme.spacing(0.1, 0.75),
                        height: theme.spacing(0.9),
                        width: theme.spacing(0.9),
                        backgroundColor: theme.palette.primary.main
                      }}
                    />
                  )}
                </Typography>
              </Box>
              <Typography
                style={{ maxWidth: 800 }}
                variant="body2"
                component="p"
                display="block"
                gutterBottom
              >
                {notification.message.text}
              </Typography>
              {notification.message.subtext && (
                <Typography
                  onClick={(event) => {
                    event.preventDefault();
                    event.stopPropagation();
                    handleClickSubtextAction(notification.message.subtext!);
                  }}
                  variant="caption"
                  component="span"
                  display="block"
                >
                  {notification.message.subtext.text}
                </Typography>
              )}
            </Box>
          }
          secondary={
            <Box style={{ display: 'flex', gap: 10, marginTop: 8 }}>
              {notification.message.actions?.map((action, idx) => (
                <Button
                  key={`action-${idx}`}
                  variant="contained"
                  color={idx === 0 ? 'primary' : 'default'}
                  size="small"
                  onClick={(event) => {
                    event.preventDefault();
                    event.stopPropagation();
                    handleClickAction(action);
                  }}
                >
                  {action.name}
                </Button>
              ))}
            </Box>
          }
        />
      </ListItem>
    </>
  );
}
