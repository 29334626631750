import { useContext } from 'react';

import { FeatureFlagContext } from 'lib/feature-flags/FeatureFlagWorkspaceContext';

export function useFeatureFlags() {
  const featureFlagData = useContext(FeatureFlagContext);
  return featureFlagData;
}

export default useFeatureFlags;
