import { Loader } from '@googlemaps/js-api-loader';

export const GOOGLE_PLACES_API_KEY = process.env.REACT_APP_GOOGLE_PLACES_API_KEY;

export function initGoogleMaps() {
  if (!GOOGLE_PLACES_API_KEY) {
    console.error('Invalid Configuration: Missing GOOGLE_PLACES_API_KEY');
    return;
  }

  const loader = new Loader({
    apiKey: GOOGLE_PLACES_API_KEY!,
    version: 'quarterly',
    libraries: ['places']
  });

  loader.importLibrary('places');
}
