import { useCallback, useEffect, useMemo, useState } from 'react';

import { Link, useNavigate } from 'react-router-dom';

import { Link as MuiLink } from '@material-ui/core';

import MaterialTable from '@material-table/core';

import { DateTime } from 'luxon';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import { RRule, rrulestr } from 'rrule';

import {
  useAutomationRulesQuery,
  useDeleteAutomationRuleMutation,
  useUpdateAutomationRuleStatusMutation,
  Workspace_Automation_Rules_Bool_Exp,
  Workspace_Automation_Status_Enum,
  Workspace_Resource_Permission_Modes_Enum,
  Workspace_User_Roles_Enum
} from 'generated/graphql';

import StatusIndicator, { Status } from 'components/StatusIndicator';
import TableContainer from 'components/TableContainer';
import Toolbar from 'components/Toolbar';
import AssignAgentsAssetDialog from 'components/workflows/AssignAgentsAssetDialog';

import { useHasuraRoleContext } from 'lib/HasuraRoleContext';
import useAssignAgentsAsset from 'lib/hooks/useAssignAgentsAsset';
import {
  createFilter,
  createFilterBySearch,
  usePagination
} from 'lib/hooks/useFiltersAndPagination';
import { useTeamMemberList } from 'lib/hooks/useTeamMemberList';
import useUserContext from 'lib/hooks/useUserContext';

type GetNextAndTextResponse = { text: string; next: string | null };

const luxonDateFormat = "EEEE dd MMM 'at' hh:mm a";

// NOTE luxon doesn't support ordinals like moment does
// https://github.com/moment/luxon/issues/118#issuecomment-781192208
function ordinal(n: number) {
  var s = ['th', 'st', 'nd', 'rd'];
  var v = n % 100;
  return n + (s[(v - 20) % 10] || s[v] || s[0]);
}

// NOTE helper function to convert UTC RRule and get relevant details in local
// timezone
function getNextAndText(rRuleStr: string): GetNextAndTextResponse {
  // NOTE this RRule is in UTC
  const utcRRule = rrulestr(rRuleStr);

  const utcNow = DateTime.utc().toJSDate();

  const { freq, interval } = utcRRule.options;

  // NOTE Get the next UTC occurrence of the RRule
  const nextOccurrence = utcRRule.after(utcNow, true);

  let rrule: RRule;

  let response: GetNextAndTextResponse = {} as GetNextAndTextResponse;

  if (nextOccurrence) {
    // NOTE convert the UTC occurrence to local time using the system's timezone
    const local = DateTime.fromJSDate(nextOccurrence, { zone: 'utc' }).setZone();

    // NOTE Recreate RRule in current timezone
    rrule = new RRule({
      freq,
      interval,
      // NOTE Luxon defaults Monday to 1 and RRule defaults Monday to 0.
      byweekday: freq === RRule.WEEKLY ? [local.weekday - 1] : undefined,
      bymonthday: freq === RRule.MONTHLY ? [local.day] : undefined,
      byhour: [local.hour],
      byminute: [local.minute],
      bysecond: [0]
    });

    response.next = local.toFormat(luxonDateFormat.replace('dd', `'${ordinal(local.day)}'`));
  } else {
    //NOTE if there is no next occurrence, get the previous occurrence just to
    //generate the text (We want the text to be in local time)

    const previousOccurrence = utcRRule.before(utcNow, true);

    if (previousOccurrence) {
      const local = DateTime.fromJSDate(previousOccurrence, { zone: 'utc' }).setZone();

      rrule = new RRule({
        freq,
        interval,
        // NOTE Luxon defaults Monday to 1 and RRule defaults Monday to 0.
        byweekday: freq === RRule.WEEKLY ? [local.weekday - 1] : undefined,
        bymonthday: freq === RRule.MONTHLY ? [local.day] : undefined,
        byhour: [local.hour],
        byminute: [local.minute],
        bysecond: [0]
      });

      response.next = null;
    } else {
      // NOTE something isn't right. There is no next or previous occurrence,
      // lets show the UTC text and bail out.
      return { text: `${utcRRule.toText()} UTC`, next: null };
    }
  }

  response.text = rrule.toText();

  return response;
}

const useSearchFilter = createFilterBySearch<Workspace_Automation_Rules_Bool_Exp>([
  {
    name: {}
  }
]);

const useAgentFilter = createFilter<Workspace_Automation_Rules_Bool_Exp>({
  assigned_users: {
    user_id: {}
  }
});

const useStatusFilter = createFilter<Workspace_Automation_Rules_Bool_Exp>({
  status: {}
});

export default function AutomationRulesSchedule() {
  const { workspaceMemberContext } = useHasuraRoleContext();
  const {
    userId,
    activeWorkspaceId,
    isWorkspaceAnalyst,
    isWorkspaceCreator,
    isWorkspaceAgent,
    isWorkspaceAdmin
  } = useUserContext();

  const { limit, page, offset, setLimit, setPage } = usePagination();
  const [filtersOpen, setFiltersOpen] = useState(true);
  const [searchFilter, search, setSearch] = useSearchFilter();
  const [agentFilter, agentFilterValue, setAgentFilterValue] = useAgentFilter();
  const [statusFilter, statusFilterValue, setStatusFilterValue] = useStatusFilter();

  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const where = useMemo<Workspace_Automation_Rules_Bool_Exp>(() => {
    const newWhere: Workspace_Automation_Rules_Bool_Exp = {
      _and: [
        {
          workspace_id: { _eq: activeWorkspaceId }
        },
        { ...searchFilter },
        { ...agentFilter },
        { ...statusFilter }
      ]
    };

    if (isWorkspaceAgent) {
      newWhere._and!.push({
        _or: [
          { created_by_id: { _eq: userId } },
          {
            agent_permission_mode: { _eq: Workspace_Resource_Permission_Modes_Enum.All }
          },
          {
            assigned_users: {
              user_id: { _eq: userId }
            }
          }
        ]
      });
    }

    return newWhere;
  }, [activeWorkspaceId, searchFilter, agentFilter, statusFilter, isWorkspaceAgent, userId]);

  const { data, loading, refetch } = useAutomationRulesQuery({
    variables: {
      where: where,
      limit: limit,
      offset: offset
    },
    context: workspaceMemberContext
  });

  const [deleteRule] = useDeleteAutomationRuleMutation({
    context: workspaceMemberContext
  });
  const [updateRuleStatus] = useUpdateAutomationRuleStatusMutation({
    context: workspaceMemberContext
  });

  const {
    assignAssetId,
    assignAgentAssetDialogOpen,
    handleOpenAssignAgentsAssetDialog,
    handleCloseAssignAgentsAssetDialog
  } = useAssignAgentsAsset(refetch);
  const { teamMembers } = useTeamMemberList(Workspace_User_Roles_Enum.Agent, false);

  // Refetch data on SPA page navigation
  useEffect(() => {
    refetch();
  }, [refetch]);

  const handleDeleteRule = useCallback(
    (id: string) => {
      deleteRule({
        variables: {
          rule_id: id
        }
      })
        .then(() => {
          enqueueSnackbar('Automation rule deleted', { variant: 'success' });
          refetch();
        })
        .catch(() => {
          enqueueSnackbar('Unable to delete rule', { variant: 'error' });
        });
    },
    [deleteRule, enqueueSnackbar, refetch]
  );

  const handleUpdateRuleStatus = useCallback(
    async (id: string, status: Workspace_Automation_Status_Enum) => {
      try {
        await updateRuleStatus({ variables: { id, status } });
        enqueueSnackbar('Status updated', { variant: 'success' });
      } catch (error) {
        enqueueSnackbar('Unable to update rule status', { variant: 'error' });
      }
    },
    [updateRuleStatus, enqueueSnackbar]
  );

  const handleEditRule = useCallback(
    (id: string) => navigate(`create/schedule?edit_id=${id}`, { state: { edit_id: id } }),
    [navigate]
  );

  // https://github.com/mbrn/material-table/issues/1979
  const rules = useMemo(() => data?.rules?.map((r) => ({ ...r })) ?? [], [data?.rules]);
  const count = useMemo(() => data?.count.aggregate?.count ?? 0, [data?.count?.aggregate?.count]);

  return (
    <>
      <MaterialTable
        title="Automation Rules"
        columns={[
          {
            title: 'Name',
            field: 'name'
          },
          {
            title: 'Schedule',
            field: 'rrule',
            render: (data) => getNextAndText(data.rrule).text
          },
          {
            title: 'Next Event',
            field: 'rrule',
            render: (data) => {
              if (data.status === 'paused') {
                return 'Schedule paused';
              }

              const next = getNextAndText(data.rrule).next;

              return next || 'No event scheduled';
            }
          },
          {
            title: 'Last Event',
            field: 'last_invocation',
            render: (data) => {
              if (!data.last_invocation) {
                return 'Never';
              }
              const correctedDate = moment(data.last_invocation);
              return correctedDate.fromNow();
            }
          },
          {
            title: 'Invocations',
            field: 'invocations_aggregate.aggregate.count',
            render: (data) => {
              return (
                <MuiLink component={Link} to={`schedules/${data.id}/invocations`}>
                  {data?.invocations_aggregate?.aggregate?.count}
                </MuiLink>
              );
            }
          },
          {
            title: 'Live',
            field: 'status',
            render: (data) => {
              if (data.status === Workspace_Automation_Status_Enum.Paused) {
                return <StatusIndicator status={Status.PENDING} text="Paused" />;
              }
              return <StatusIndicator status={Status.ACTIVE} text="Active" pulse />;
            }
          }
        ]}
        data={rules}
        totalCount={count}
        isLoading={loading}
        actions={[
          (rowData) => ({
            icon: 'pause',
            tooltip: 'Pause rule',
            onClick: () =>
              handleUpdateRuleStatus(rowData.id, Workspace_Automation_Status_Enum.Paused),
            disabled: isWorkspaceAnalyst || isWorkspaceCreator,
            hidden: rowData.status !== Workspace_Automation_Status_Enum.Live
          }),
          (rowData) => ({
            icon: 'play_arrow',
            tooltip: 'Start rule',
            onClick: () =>
              handleUpdateRuleStatus(rowData.id, Workspace_Automation_Status_Enum.Live),
            disabled: isWorkspaceAnalyst || isWorkspaceCreator,
            hidden: rowData.status !== Workspace_Automation_Status_Enum.Paused
          }),
          (rowData) => ({
            icon: 'group',
            tooltip: 'Assign Agent(s)',
            onClick: () => handleOpenAssignAgentsAssetDialog(rowData.id),
            disabled: rowData.created_by_id !== userId && !isWorkspaceAdmin
          }),
          (rowData) => ({
            icon: 'edit',
            tooltip: 'Edit rule',
            onClick: () => handleEditRule(rowData.id),
            disabled: isWorkspaceAnalyst || isWorkspaceCreator
          })
        ]}
        options={{
          search: true,
          toolbar: true,
          draggable: false,
          showTitle: false,
          columnsButton: false,
          sorting: false,
          actionsColumnIndex: -1,
          pageSize: limit,
          pageSizeOptions: [10, 20, 50]
        }}
        editable={{
          isDeleteHidden: () => Boolean(isWorkspaceAnalyst) || Boolean(isWorkspaceCreator),
          isDeletable: () => !(Boolean(isWorkspaceAnalyst) || Boolean(isWorkspaceCreator)),
          onRowDelete: async (rowData) => {
            return handleDeleteRule(rowData.id);
          }
        }}
        onSearchChange={setSearch}
        onRowsPerPageChange={setLimit}
        onPageChange={setPage}
        page={page}
        components={{
          Container: TableContainer,
          Toolbar: (props) => (
            <Toolbar
              {...props}
              setSearch={setSearch}
              searchValue={search}
              onToggleFiltersOpen={() => setFiltersOpen((prev) => !prev)}
              filtersOpen={filtersOpen}
              menuItems={[
                {
                  name: 'Status',
                  options: [
                    {
                      value: Workspace_Automation_Status_Enum.Live,
                      text: 'Active'
                    },
                    {
                      value: Workspace_Automation_Status_Enum.Paused,
                      text: 'Paused'
                    }
                  ],
                  value: statusFilterValue,
                  setValue: setStatusFilterValue,
                  hasAll: true
                },

                {
                  name: 'Assigned Agent',
                  options: teamMembers.map((m) => ({
                    text: m.primary,
                    value: m.user_id
                  })),
                  value: agentFilterValue,
                  setValue: setAgentFilterValue,
                  hasAll: true,
                  hidden: isWorkspaceAgent
                }
              ]}
            />
          )
        }}
      />
      <AssignAgentsAssetDialog
        open={assignAgentAssetDialogOpen}
        onClose={handleCloseAssignAgentsAssetDialog}
        assetId={assignAssetId}
        assetType="automation-schedule"
      />
    </>
  );
}
