import { useState } from 'react';

import { IconButton, Tooltip } from '@material-ui/core';
import VisibilityIcon from '@material-ui/icons/Visibility';

import {
  AvailableTemplatesQuery,
  CreativeTypeEnum,
  TemplateTypeEnum,
  Workspace_Template_Creative_Type_Enum,
  Workspace_Template_Type_Enum
} from 'generated/graphql';

import TemplatePreview from 'components/creative/TemplatePreview';

interface PreviewIconProps {
  templateId:
    | AvailableTemplatesQuery['getWorkspaceTemplates'][number]['template_id']
    | undefined
    | null;
  templates:
    | {
        name: string;
        creative_type: CreativeTypeEnum | Workspace_Template_Creative_Type_Enum;
        template_type: TemplateTypeEnum | Workspace_Template_Type_Enum;
        template_id: string;
        preview_url?: string | null;
      }[]
    | undefined;
}

function PreviewIcon({ templateId, templates }: PreviewIconProps) {
  const [showPreview, setShowPreview] = useState(false);

  function closePreview() {
    setShowPreview(false);
  }

  function onPreviewClick() {
    setShowPreview(true);
  }

  const template =
    templates?.find?.((template) => template.template_id === templateId) ?? templates?.[0];

  if (!template) {
    return null;
  }

  const previewUrl = template.preview_url ?? null;

  return (
    <>
      <IconButton
        aria-label="show preview"
        onClick={onPreviewClick}
        edge="end"
        disabled={!previewUrl || !templateId}
      >
        <Tooltip title="Preview Template" arrow placement="top">
          <VisibilityIcon fontSize="large" />
        </Tooltip>
      </IconButton>

      <TemplatePreview
        open={showPreview}
        type={template.creative_type as CreativeTypeEnum}
        template_name={template.name}
        preview_url={previewUrl}
        onClose={closePreview}
      />
    </>
  );
}

export default PreviewIcon;
