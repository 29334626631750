import { useEffect, useState } from 'react';

import { Link } from 'react-router-dom';

import {
  Avatar,
  Badge,
  Button,
  Divider,
  FormControlLabel,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Switch
} from '@material-ui/core';
import SupportIcon from '@material-ui/icons/ContactSupportOutlined';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import LaunchIcon from '@material-ui/icons/Launch';
import NotificationsIcon from '@material-ui/icons/NotificationsOutlined';
import PersonIcon from '@material-ui/icons/Person';

import { useUnseenCount } from '@suprsend/react-inbox';

import { bindPopover, bindTrigger, usePopupState } from 'material-ui-popup-state/hooks';

import NotificationsPopover from 'components/Notifications/NotificationsPopover';
import WorkspaceTypeIcon from 'components/WorkspaceTypeIcon';
import RealShortzIcon from 'components/icons/RealShortzIcon';

import { useAdminModeContext } from 'lib/AdminModeContext';
import { useHeadOfficeModeContext } from 'lib/HeadOfficeModeContext';
import { auth, getFilePath } from 'lib/auth/hbp';
import useUserContext from 'lib/hooks/useUserContext';

import useHeaderStyles from './styles';

const HeaderComponent = () => {
  const { isAdminModeAvailable, setAdminMode, isAdminMode } = useAdminModeContext();
  const { info } = useUserContext();
  const classes = useHeaderStyles({ isAdminMode });
  const accountPopupState = usePopupState({
    variant: 'popover',
    popupId: 'menuPopover'
  });
  const helpPopupState = usePopupState({
    variant: 'popover',
    popupId: 'helpPopover'
  });
  const notificationsPopoverState = usePopupState({
    variant: 'popover',
    popupId: 'notificationsPopover'
  });
  const { isHeadOfficeMode, setHeadOfficeMode } = useHeadOfficeModeContext();

  const [avatarUrl, setAvatarUrl] = useState<string>();

  const newAvatarUrl = getFilePath(info?.avatar_url, info?.avatar_token);
  useEffect(() => {
    if (newAvatarUrl && (!avatarUrl || avatarUrl !== newAvatarUrl)) {
      setAvatarUrl(newAvatarUrl);
    }
  }, [avatarUrl, setAvatarUrl, newAvatarUrl]);

  const handleExitHeadOfficeMode = () => {
    setHeadOfficeMode(null);
    accountPopupState.close();
  };

  const { unSeenCount } = useUnseenCount();

  useEffect(() => {
    const baseTitle = process.env.REACT_APP_SITE_TITLE ?? 'Properti';
    if (unSeenCount > 9) {
      window.document.title = `${baseTitle} (9+)`;
    } else if (unSeenCount > 0) {
      window.document.title = `${baseTitle} (${unSeenCount})`;
    } else {
      window.document.title = `${baseTitle}`;
    }

    return () => {
      window.document.title = `${baseTitle}`;
    };
  }, [unSeenCount]);

  return (
    <>
      <div className={classes.grow} />
      {isAdminModeAvailable && (
        <FormControlLabel
          control={
            <Switch checked={isAdminMode} onChange={(_, checked) => setAdminMode(checked)} />
          }
          label="Admin Mode"
          classes={{ label: classes.adminSwitch }}
        />
      )}
      <IconButton color="inherit" {...bindTrigger(notificationsPopoverState)}>
        <Badge variant="dot" invisible={unSeenCount === 0} color="primary">
          <NotificationsIcon />
        </Badge>
      </IconButton>
      <Button color="inherit" {...bindTrigger(helpPopupState)} startIcon={<SupportIcon />}>
        Help
      </Button>
      <IconButton color="inherit" {...bindTrigger(accountPopupState)}>
        <Avatar src={avatarUrl} className={classes.avatar} classes={{ img: classes.avatarImg }} />
      </IconButton>
      <Menu
        {...bindPopover(helpPopupState)}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        <MenuItem component={Link} to="/support" onClick={helpPopupState.close}>
          Customer Support
        </MenuItem>
        <Divider />
        <MenuItem
          component="a"
          href="https://support.properti.ai"
          target="_blank"
          onClick={helpPopupState.close}
        >
          Help Articles
          <ListItemIcon>
            <LaunchIcon />
          </ListItemIcon>
        </MenuItem>
        <MenuItem
          component="a"
          href="https://support.properti.ai"
          target="_blank"
          onClick={helpPopupState.close}
        >
          Tutorials
          <ListItemIcon>
            <LaunchIcon />
          </ListItemIcon>
        </MenuItem>
        <MenuItem
          component="a"
          href="https://www.realshortz.com"
          target="_blank"
          onClick={helpPopupState.close}
        >
          RealShortz
          <ListItemIcon>
            <LaunchIcon />
          </ListItemIcon>
        </MenuItem>
      </Menu>
      <Menu
        {...bindPopover(accountPopupState)}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        <MenuItem component={Link} to="/profile" onClick={accountPopupState.close}>
          <ListItemIcon>
            <PersonIcon />
          </ListItemIcon>
          Account
        </MenuItem>
        {isHeadOfficeMode ? (
          <MenuItem onClick={handleExitHeadOfficeMode}>
            <ListItemIcon>
              <ExitToAppIcon />
            </ListItemIcon>
            Exit Head Office
          </MenuItem>
        ) : (
          <MenuItem component={Link} to="/workspaces" onClick={accountPopupState.close}>
            <ListItemIcon>
              <WorkspaceTypeIcon type="realestate" />
            </ListItemIcon>
            Workspaces
          </MenuItem>
        )}
        <MenuItem component={Link} to="/headoffices" onClick={accountPopupState.close}>
          <ListItemIcon>
            <WorkspaceTypeIcon type="headoffice" />
          </ListItemIcon>
          Head Offices
        </MenuItem>
        <MenuItem
          component={Link}
          to="/notifications#notifications"
          onClick={accountPopupState.close}
        >
          <ListItemIcon>
            <NotificationsIcon />
          </ListItemIcon>
          Notifications
        </MenuItem>
        <MenuItem component={Link} to="/me/realshortz" onClick={accountPopupState.close}>
          <ListItemIcon>
            <RealShortzIcon fontSize="small" />
          </ListItemIcon>
          My RealShortz
        </MenuItem>
        <MenuItem onClick={() => auth.logout()}>
          <ListItemIcon>
            <ExitToAppIcon />
          </ListItemIcon>
          Sign Out
        </MenuItem>
      </Menu>
      <NotificationsPopover popoverState={notificationsPopoverState} />
    </>
  );
};

export default HeaderComponent;
