import React, { createContext, ReactNode, useEffect, useState } from 'react';

import suprsend from '@suprsend/web-sdk';

import { auth } from '../auth/hbp';

export type UserContextType = {
  signedIn: boolean;
  authStatusReported: boolean;
  userId: null | string;
  activeWorkspaceId: null | string;
  activeAdminWorkspaceId: null | string;
  isWorkspaceAdmin?: boolean;
  isWorkspaceAdvertiser?: boolean;
  isWorkspaceCreator?: boolean;
  isWorkspaceAnalyst?: boolean;
  isWorkspaceAgent?: boolean;
  isAdminMode: boolean;
  isHeadOfficeMode: boolean;
  activeHeadOfficeId: string | null;
};

const defaultUserContext: UserContextType = {
  signedIn: false,
  authStatusReported: false,
  userId: null,
  activeWorkspaceId: null,
  activeAdminWorkspaceId: null,
  isWorkspaceAdmin: false,
  isWorkspaceAdvertiser: false,
  isWorkspaceCreator: false,
  isWorkspaceAnalyst: false,
  isWorkspaceAgent: false,
  isAdminMode: false,
  isHeadOfficeMode: false,
  activeHeadOfficeId: null
};

const UserContext = createContext(defaultUserContext);

const LOGGED_OUT: UserContextType = {
  signedIn: false,
  authStatusReported: true,
  userId: null,
  activeWorkspaceId: null,
  activeAdminWorkspaceId: null,
  isWorkspaceAdmin: false,
  isWorkspaceAdvertiser: false,
  isWorkspaceCreator: false,
  isWorkspaceAnalyst: false,
  isWorkspaceAgent: false,
  isAdminMode: false,
  isHeadOfficeMode: false,
  activeHeadOfficeId: null
};

const handleChange = (setState: React.Dispatch<React.SetStateAction<UserContextType>>) => {
  const userId = auth.getClaim('x-hasura-user-id');
  const activeWorkspaceIdString = auth.getClaim('x-hasura-active-workspace-id');
  const activeAdminWorkspaceIdString = auth.getClaim('x-hasura-active-admin-workspace-id');
  const activeWorkspaceId = activeWorkspaceIdString !== 'null' ? activeWorkspaceIdString : null;
  const activeAdminWorkspaceId =
    activeAdminWorkspaceIdString !== 'null' ? activeAdminWorkspaceIdString : null;
  const allowedWorkspaces = auth.getClaim('x-hasura-allowed-workspaces');
  const allowedWorkspacesAdmin = auth.getClaim('x-hasura-allowed-workspaces-admin');
  const allowedWorkspacesAdvertiser = auth.getClaim('x-hasura-allowed-workspaces-advertiser');
  const allowedWorkspacesCreator = auth.getClaim('x-hasura-allowed-workspaces-creator');
  const allowedWorkspacesAnalyst = auth.getClaim('x-hasura-allowed-workspaces-analyst');
  const allowedWorkspacesAgent = auth.getClaim('x-hasura-allowed-workspaces-agent');
  const activeHeadOfficeIdString = auth.getClaim('x-hasura-active-head-office-id');
  const activeHeadOfficeId =
    activeHeadOfficeIdString && activeHeadOfficeIdString !== 'null'
      ? activeHeadOfficeIdString
      : null;

  const isAdminMode = Boolean(activeAdminWorkspaceId);
  const isHeadOfficeMode = Boolean(activeHeadOfficeId);

  if (activeWorkspaceId) {
    // Check to see if active workspace is allowed, otherwise force switching workspace.
    if (!allowedWorkspaces?.includes(activeWorkspaceId)) {
      return setState({
        signedIn: true,
        authStatusReported: true,
        userId: userId,
        activeWorkspaceId: null,
        activeAdminWorkspaceId: null,
        isWorkspaceAdmin: false,
        isWorkspaceAdvertiser: false,
        isWorkspaceCreator: false,
        isWorkspaceAnalyst: false,
        isWorkspaceAgent: false,
        isAdminMode: false,
        isHeadOfficeMode: false,
        activeHeadOfficeId: null
      });
    }
  }

  const isWorkspaceAdmin =
    (activeWorkspaceId && allowedWorkspacesAdmin?.includes(activeWorkspaceId)) || false;

  const isWorkspaceAdvertiser =
    (!isWorkspaceAdmin &&
      activeWorkspaceId &&
      allowedWorkspacesAdvertiser?.includes(activeWorkspaceId)) ||
    false;

  const isWorkspaceAgent =
    (!isWorkspaceAdmin &&
      !isWorkspaceAdvertiser &&
      activeWorkspaceId &&
      allowedWorkspacesAgent?.includes(activeWorkspaceId)) ||
    false;

  const isWorkspaceCreator =
    (!isWorkspaceAdmin &&
      !isWorkspaceAdvertiser &&
      activeWorkspaceId &&
      allowedWorkspacesCreator?.includes(activeWorkspaceId)) ||
    false;

  const isWorkspaceAnalyst =
    (!isWorkspaceAdmin &&
      !isWorkspaceAdvertiser &&
      !isWorkspaceCreator &&
      activeWorkspaceId &&
      allowedWorkspacesAnalyst?.includes(activeWorkspaceId)) ||
    false;

  setState({
    signedIn: true,
    authStatusReported: true,
    userId,
    activeWorkspaceId: isAdminMode ? activeAdminWorkspaceId : activeWorkspaceId,
    activeAdminWorkspaceId,
    isWorkspaceAdmin,
    isWorkspaceAdvertiser,
    isWorkspaceCreator,
    isWorkspaceAnalyst,
    isWorkspaceAgent,
    isAdminMode,
    isHeadOfficeMode,
    activeHeadOfficeId
  });
};

const UserContextProvider = ({ children }: { children: ReactNode }) => {
  const [state, setState] = useState<UserContextType>(defaultUserContext);

  useEffect(() => {
    auth.onAuthStateChanged((state: boolean) => {
      if (!state) {
        suprsend.reset();
        return setState(LOGGED_OUT);
      }

      handleChange(setState);
    });
  }, [setState]);

  useEffect(() => {
    auth.onClaimsChanged(() => {
      handleChange(setState);
    });
  }, [setState]);

  useEffect(() => {
    if (auth.isAuthenticated() && !state.signedIn) {
      handleChange(setState);
    }
  }, [state.signedIn, setState]);

  useEffect(() => {
    const handleStorageChange = (ev: StorageEvent) => {
      if (ev.key === 'active_admin_workspace_id' || ev.key === 'active_workspace_id') {
        const isAdminMode = Boolean(state.activeAdminWorkspaceId);
        const lsawid = window.localStorage.getItem(
          isAdminMode ? 'active_admin_workspace_id' : 'active_workspace_id'
        );
        const cawid = state.activeWorkspaceId;

        if (lsawid && lsawid !== 'null' && lsawid !== cawid) {
          setState({
            ...state,
            isAdminMode: isAdminMode,
            activeWorkspaceId: lsawid
          });
        }
      }
    };

    window.addEventListener('storage', handleStorageChange);
    return () => window.removeEventListener('storage', handleStorageChange);
  }, [state, setState]);

  return <UserContext.Provider value={{ ...state }}>{children}</UserContext.Provider>;
};

export { UserContext, UserContextProvider };
