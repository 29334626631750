export interface IActionObject {
  name: string;
  url: string;
  open_in_new_tab?: boolean;
}

export interface ISubtextObject {
  action_url?: string;
  text: string;
}

export interface IRemoteNotificationMessage {
  schema: string;
  header: string;
  text: string;
  url: string;
  open_in_new_tab?: boolean;
  extra_data?: string;
  tags?: string[];
  actions?: IActionObject[];
  subtext?: ISubtextObject;
}

export interface IRemoteNotification {
  n_id: string;
  n_category: string;
  created_on: number;
  seen_on?: number;
  interacted_on?: number;
  message: IRemoteNotificationMessage;
}

export enum PreferenceOptions {
  OPT_IN = 'opt_in',
  OPT_OUT = 'opt_out'
}

export enum ChannelLevelPreferenceOptions {
  ALL = 'all',
  REQUIRED = 'required'
}

export type EmitterEvents = {
  preferences_updated: PreferenceData;
  preferences_error: PreferenceErrorData;
};

export interface CategoryChannel {
  channel: string;
  preference: PreferenceOptions;
  is_editable: boolean;
}

export interface Category {
  name: string;
  category: string;
  description?: string | null;
  preference: PreferenceOptions;
  is_editable: boolean;
  channels?: CategoryChannel[] | null;
}

export interface Section {
  name?: string | null;
  description?: string | null;
  subcategories?: Category[] | null;
}

export interface ChannelPreference {
  channel: string;
  is_restricted: boolean;
}

export interface PreferenceData {
  sections: Section[] | null;
  channel_preferences: ChannelPreference[] | null;
}

export interface PreferenceErrorData {
  error: boolean;
  api_error?: boolean;
  message: string;
  status_code?: number | null;
  error_obj?: Error | null;
}

export interface PreferencesResponse extends PreferenceData, PreferenceErrorData {}
