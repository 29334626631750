import React from 'react';

import { Step, StepButton, Stepper } from '@material-ui/core';

import StepLabel from './StepLabel';
import StepperConnector from './StepperConnector';

interface WizardStepperProps {
  activeStep: number;
  steps: string[];
  nonLinear?: boolean;
  onClickStep?: (index: number) => void;
}

export default function WizardStepper({
  activeStep,
  steps,
  nonLinear = false,
  onClickStep
}: WizardStepperProps) {
  const handleClickStep = (index: number) => () => {
    if (onClickStep) {
      onClickStep(index);
    }
  };

  return (
    <Stepper
      activeStep={activeStep}
      orientation="vertical"
      nonLinear={nonLinear}
      connector={<StepperConnector />}
    >
      {steps.map((step, index) => (
        <Step key={step}>
          {nonLinear ? (
            <StepButton onClick={handleClickStep(index)}>{step}</StepButton>
          ) : (
            <StepLabel label={step} />
          )}
        </Step>
      ))}
    </Stepper>
  );
}
