import { useEffect, useState } from 'react';

import { CircularProgress, Paper } from '@material-ui/core';

import suprsend from '@suprsend/web-sdk';

import ChannelLevelPreferences from 'components/Notifications/preferences/ChannelLevelPreferences';
import NotificationCategoryPreferences from 'components/Notifications/preferences/NotificationCategoryPreferences';
import { PreferenceData } from 'components/Notifications/types';

export default function NotificationsPreferencesTab() {
  const [preferenceData, setPreferenceData] = useState<PreferenceData | null>(null);

  useEffect(() => {
    const handleUpdatePreferences = (preferenceData: PreferenceData) => {
      setPreferenceData({ ...preferenceData });
    };

    suprsend.user.preferences.get_preferences().then((resp) => {
      if (resp.error) {
        console.warn(resp.message);
      } else {
        setPreferenceData(resp);
      }
    });

    // listen for update in preferences data
    suprsend.emitter.on('preferences_updated', handleUpdatePreferences);

    return () => {
      suprsend.emitter.off('preferences_updated', handleUpdatePreferences);
    };
  }, [setPreferenceData]);

  if (!preferenceData) {
    return <CircularProgress />;
  }

  return (
    <>
      <Paper style={{ padding: 8 }}>
        <NotificationCategoryPreferences
          preferenceData={preferenceData}
          setPreferenceData={setPreferenceData}
        />
      </Paper>
      <Paper style={{ padding: 8, marginTop: 16 }}>
        <ChannelLevelPreferences
          preferenceData={preferenceData}
          setPreferenceData={setPreferenceData}
        />
      </Paper>
    </>
  );
}
