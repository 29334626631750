import { useCallback, useEffect, useMemo, useState } from 'react';

import { Link, useNavigate } from 'react-router-dom';

import { Box, Button, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';

import MaterialTable from '@material-table/core';

import BullhornIcon from 'mdi-material-ui/BullhornOutline';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import numbro from 'numbro';

import {
  useDeleteCustomAudienceMutation,
  useWorkspaceAudiencesQuery,
  Workspace_Audience_Status_Enum,
  Workspace_Audiences_Bool_Exp,
  Workspace_Resource_Permission_Modes_Enum,
  WorkspaceAudiencesQuery
} from 'generated/graphql';

import EmptyStatePage from 'components/EmptyStatePage';
import PageHeader from 'components/PageHeader';
import StatusIndicator, { Status } from 'components/StatusIndicator';
import TableContainer from 'components/TableContainer';
import TooltipComponent from 'components/Tooltip';
import PresentationAudienceIcon from 'components/icons/PresentationAudience';
import AssignAgentsAssetDialog from 'components/workflows/AssignAgentsAssetDialog';

import { useHasuraRoleContext } from 'lib/HasuraRoleContext';
import useAssignAgentsAsset from 'lib/hooks/useAssignAgentsAsset';
import useUserContext from 'lib/hooks/useUserContext';

const AudiencesPage = () => {
  const { workspaceMemberContext } = useHasuraRoleContext();
  const {
    userId,
    activeWorkspaceId,
    isWorkspaceAnalyst,
    isWorkspaceCreator,
    isWorkspaceAgent,
    isWorkspaceAdmin
  } = useUserContext();

  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [limit, setLimit] = useState(5);
  const [page, setPage] = useState(0);

  const where: Workspace_Audiences_Bool_Exp = {
    _and: [{ workspace_id: { _eq: activeWorkspaceId! } }]
  };

  if (isWorkspaceAgent) {
    where._and!.push({
      _or: [
        { created_by_id: { _eq: userId } },
        { agent_permission_mode: { _eq: Workspace_Resource_Permission_Modes_Enum.All } },
        { assigned_users: { user_id: { _eq: userId } } }
      ]
    });
  }

  const { data, loading, refetch } = useWorkspaceAudiencesQuery({
    variables: {
      where: where,
      limit: limit,
      offset: page * limit
    },
    context: workspaceMemberContext,
    skip: !activeWorkspaceId
  });
  const [deleteAudience] = useDeleteCustomAudienceMutation({
    context: workspaceMemberContext
  });

  const {
    assignAssetId,
    assignAgentAssetDialogOpen,
    handleOpenAssignAgentsAssetDialog,
    handleCloseAssignAgentsAssetDialog
  } = useAssignAgentsAsset(refetch);

  // Reload when page enters view
  // As we have a SPA, pages enter and leave view without full reloads
  useEffect(() => {
    refetch();
  }, [refetch]);

  const handleOnRowDelete = useCallback(
    async (row: any) => {
      const id = row.id;
      try {
        const resp = await deleteAudience({
          variables: {
            id
          }
        });

        if (!resp.data?.deleteCustomAudience?.deleted) {
          throw new Error('Failed to delete audience');
        } else {
          refetch();
          enqueueSnackbar('Audience deleted', { variant: 'success' });
        }
      } catch (error) {
        enqueueSnackbar('Failed to delete audience', { variant: 'error' });
      }
    },
    [deleteAudience, refetch, enqueueSnackbar]
  );

  const count = useMemo(
    () => data?.aggregate?.aggregate?.count ?? 0,
    [data?.aggregate?.aggregate?.count]
  );

  // https://github.com/mbrn/material-table/issues/1979
  const audiences = useMemo(() => data?.audiences?.map((a) => ({ ...a })) ?? [], [data?.audiences]);

  if (!loading && count === 0) {
    return (
      <EmptyStatePage
        title="Audiences"
        icon={
          <PresentationAudienceIcon
            color="secondary"
            fill="none"
            style={{ height: 64, width: 64 }}
          />
        }
        text="Build custom audiences to use in your campaigns by segmenting you data sources."
        button={
          <Button
            component={Link}
            to="/audiences/create"
            variant="contained"
            color="secondary"
            size="large"
            disabled={isWorkspaceAnalyst}
            fullWidth
          >
            Create Audience
          </Button>
        }
      />
    );
  }

  return (
    <>
      <div>
        <PageHeader
          title="Audiences"
          rightComponent={
            <Button
              component={Link}
              to="/audiences/create"
              variant="contained"
              color="secondary"
              size="large"
              disabled={isWorkspaceAnalyst || isWorkspaceCreator}
              style={{ minWidth: 160 }}
            >
              Create Audience
            </Button>
          }
        />

        <MaterialTable
          title="Audiences"
          columns={[
            { title: 'Audience Name', field: 'name', editable: 'never' },
            {
              title: 'Source',
              field: 'source',
              editable: 'never',
              render: (data) => {
                if (data.source === 'app') {
                  if (data.app === 'agentbox') {
                    return 'Agentbox';
                  } else if (data.app === 'inhabit_move') {
                    return 'Inhabit Move';
                  }

                  return data.app ?? 'App';
                } else if (data.source === 'customer-list') {
                  return 'Customer List';
                } else if (data.source === 'website-traffic') {
                  return 'Website Traffic';
                } else if (data.source === 'import') {
                  return 'Imported';
                } else if (data.source === 'leads') {
                  return 'Properti Leads';
                } else {
                  return data.source;
                }
              }
            },
            {
              title: 'Status',
              field: 'status',
              editable: 'never',
              render: (data) => {
                if (data.status === Workspace_Audience_Status_Enum.Created) {
                  return <StatusIndicator status={Status.ACTIVE} text="Created" />;
                } else if (data.status === Workspace_Audience_Status_Enum.Creating) {
                  return <StatusIndicator status={Status.PENDING} text="Creating" />;
                } else if (data.status === Workspace_Audience_Status_Enum.Error) {
                  return <StatusIndicator status={Status.ERROR} text="Error" />;
                } else if (data.status === Workspace_Audience_Status_Enum.TooSmall) {
                  return <StatusIndicator status={Status.ERROR} text="Too Small" />;
                }
                return data.status;
              }
            },
            {
              title: 'Created',
              field: 'created_at',
              render: (data) => moment(data?.created_at).fromNow(),
              editable: 'never'
            }
          ]}
          detailPanel={AudienceDetailBox}
          actions={[
            (rowData) => ({
              icon: BullhornIcon,
              tooltip: 'Create Campaign',
              disabled: isWorkspaceAnalyst || isWorkspaceCreator,
              onClick: () => {
                navigate(`/campaigns/create`, {
                  state: {
                    from_audience_id: rowData.id
                  }
                });
              }
            }),
            (rowData) => ({
              icon: 'group_add',
              tooltip: 'Create Lookalike Audience',
              disabled: isWorkspaceAnalyst || isWorkspaceCreator,
              onClick: () => {
                navigate(`create/lookalike?id=${rowData.id}`);
              }
            }),
            (rowData) => ({
              icon: 'group',
              tooltip: 'Assign Agent(s)',
              onClick: () => handleOpenAssignAgentsAssetDialog(rowData.id),
              disabled: rowData.created_by_id !== userId && !isWorkspaceAdmin
            })
          ]}
          data={audiences}
          totalCount={count}
          page={page}
          isLoading={loading}
          onRowClick={(_event, _rowData, togglePanel) => togglePanel!()}
          options={{
            search: false,
            toolbar: false,
            draggable: false,
            showTitle: false,
            actionsColumnIndex: -1,
            pageSize: limit
          }}
          onRowsPerPageChange={setLimit}
          onPageChange={setPage}
          editable={{
            isDeletable: () => !isWorkspaceAnalyst && !isWorkspaceCreator,
            onRowDelete: handleOnRowDelete
          }}
          components={{
            Container: TableContainer
          }}
        />
      </div>
      <AssignAgentsAssetDialog
        open={assignAgentAssetDialogOpen}
        onClose={handleCloseAssignAgentsAssetDialog}
        assetId={assignAssetId}
        assetType="audience"
      />
    </>
  );
};

export default AudiencesPage;

interface AudienceDetailBoxProps {
  rowData: WorkspaceAudiencesQuery['audiences'][number];
}

const AUDIENCE_DETAIL_BOX_TOOLTIP_HEADING = 'Unavailable';
const AUDIENCE_DETAIL_BOX_TOOLTIP_TEXT =
  'Due to privacy controls, Facebook does not disclose custom audience sizes.';
const AUDIENCE_DETAIL_BOX_TOOLTIP_TEXT_PROPERTI_ESTIMATE =
  'Estimated based on the size of your contact list ingested.';

function AudienceDetailBox({ rowData }: AudienceDetailBoxProps) {
  const info = rowData.facebook?.info;
  const lookalikes = rowData?.facebook?.lookalikes ?? [];

  return (
    <Box margin={1}>
      <Table size="small" aria-label="lookalikes">
        <TableHead>
          <TableRow>
            <TableCell colSpan={1} />
            <TableCell component="th">Name</TableCell>
            <TableCell component="th">Type</TableCell>
            <TableCell component="th">Audience Size</TableCell>
            <TableCell component="th">Status</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell colSpan={1} />
            <TableCell component="th" scope="row">
              {rowData?.facebook?.name}
            </TableCell>
            <TableCell>Custom</TableCell>
            <TableCell>
              {info?.approximate_count_lower_bound === -1 ? (
                <TooltipComponent
                  heading={
                    rowData.estimated_size
                      ? `${numbro(rowData.estimated_size).format({
                          thousandSeparated: true
                        })}`
                      : AUDIENCE_DETAIL_BOX_TOOLTIP_HEADING
                  }
                  tooltip={
                    rowData.estimated_size
                      ? AUDIENCE_DETAIL_BOX_TOOLTIP_TEXT_PROPERTI_ESTIMATE
                      : AUDIENCE_DETAIL_BOX_TOOLTIP_TEXT
                  }
                />
              ) : (
                `${numbro(info?.approximate_count_lower_bound).format({
                  thousandSeparated: true
                })} to ${numbro(info?.approximate_count_upper_bound).format({
                  thousandSeparated: true
                })}`
              )}
            </TableCell>
            <TableCell>
              {info?.delivery_status.code === 200 ? (
                <StatusIndicator status={Status.ACTIVE} />
              ) : (
                info?.delivery_status.description
              )}
            </TableCell>
          </TableRow>
          {lookalikes.map((lookalike) => (
            <TableRow key={lookalike.id}>
              <TableCell colSpan={1} />
              <TableCell component="th" scope="row">
                {lookalike.name}
              </TableCell>
              <TableCell>Lookalike</TableCell>
              <TableCell>
                {numbro(lookalike.info?.approximate_count_lower_bound).format({
                  thousandSeparated: true
                })}{' '}
                to{' '}
                {numbro(lookalike.info?.approximate_count_upper_bound).format({
                  thousandSeparated: true
                })}
              </TableCell>
              <TableCell>
                {lookalike.info?.delivery_status.code === 200 ? (
                  <StatusIndicator status={Status.ACTIVE} />
                ) : (
                  lookalike.info?.delivery_status.description
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Box>
  );
}
