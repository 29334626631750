import { SvgIcon, SvgIconProps } from '@material-ui/core';

export default function LJHookerIcon(props: SvgIconProps) {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      xmlSpace="preserve"
      {...props}
      viewBox="0 0 49.7 50.5"
    >
      <path
        d="M0 0v46.6s-.2 3.9 3.9 3.9h12.7V0H0z"
        style={{
          fillRule: 'evenodd',
          clipRule: 'evenodd',
          fill: '#ffd33d'
        }}
      />
      <path
        d="M16.6 50.5h16.6V0H16.6v50.5z"
        style={{
          fillRule: 'evenodd',
          clipRule: 'evenodd',
          fill: '#faa83a'
        }}
      />
      <path
        d="M33.3 50.5h16.4V0H33l.3 50.5z"
        style={{
          fillRule: 'evenodd',
          clipRule: 'evenodd',
          fill: '#ef4046'
        }}
      />
    </SvgIcon>
  );
}
