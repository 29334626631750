import { makeStyles } from '@material-ui/core/styles';

const useAuthStyles = makeStyles((theme) => ({
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
    width: '100vw',
    backgroundColor: '#E5E5E5'
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1)
  },
  formWrapper: {
    [theme.breakpoints.only('md')]: {
      padding: theme.spacing(3, 6)
    },
    [theme.breakpoints.up('lg')]: {
      padding: theme.spacing(3, 12)
    },
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1, 4)
    }
  },
  box: {
    width: '100%',
    maxWidth: 680,
    minHeight: 680,
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(3, 1),
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      height: '100vh',
      padding: theme.spacing(1)
    }
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  },
  logo: {
    display: 'block',
    margin: theme.spacing(3, 'auto')
  },
  extraItem: {
    margin: theme.spacing(2, 0, 1, 0)
  },
  buttonCaption: {},
  googleButton: {
    backgroundColor: '#FFFFFFF',
    textTransform: 'none',
    font: '#1F1F1F | Roboto Medium | 14/20',
    '&:hover': {
      backgroundColor: '#F2F2F2'
    }
  },
  googleIcon: {
    marginRight: theme.spacing(1)
  },
  ssoPopover: {
    pointerEvents: 'none'
  },
  ssoPopoverPaper: {
    maxWidth: 680,
    padding: theme.spacing(3, 12)
  }
}));

export default useAuthStyles;
