import { useMemo } from 'react';

import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';

import { Select, TextField } from 'mui-rff';

import { AvailableTemplatesQuery } from 'generated/graphql';

import { renderDynamicFunctionName, renderDynamicParameterIdentifier } from './utils';

export const HEAD_OFFICE_DYNAMIC_PARAMETER_MERGE_TAGS = [
  '{{ WORKSPACE_OFFICE_SUBURB }}',
  '{{ WORKSPACE_OFFICE_POST_CODE }}',
  '{{ WORKSPACE_OFFICE_STATE_NAME }}',
  '{{ WORKSPACE_OFFICE_STATE_CODE }}',
  '{{ WORKSPACE_OFFICE_NAME }}'
];

export interface DynamicTemplateParameterInputProps {
  hasDynamicParameters: boolean;
  dynamicParameters: AvailableTemplatesQuery['getWorkspaceTemplates'][number]['dynamic_parameters'];

  /**
   * Form key prefix, including any "."s
   */
  formKeyPrefix?: string;

  /**
   * Optional boolean indicating if head office mode merge tags
   *  should be displayed
   */
  isHeadOfficeMode?: boolean;

  /**
   * Optional boolean to disable inputs
   */
  disabled?: boolean;

  groupByFunctionName?: boolean;
}

export function DynamicTemplateParameterInput({
  hasDynamicParameters,
  dynamicParameters,
  formKeyPrefix,
  isHeadOfficeMode = false,
  disabled = false
}: DynamicTemplateParameterInputProps) {
  if (!hasDynamicParameters) {
    return (
      <Alert variant="standard" severity="success">
        <AlertTitle>
          This template does not require any dynamic parameters, continue to create your creative
        </AlertTitle>
      </Alert>
    );
  }

  return (
    <div>
      {dynamicParameters.map((dn, idx) => (
        <Accordion key={idx} variant="outlined" defaultExpanded>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            Function: {renderDynamicFunctionName(dn.function.name)}
            <br />
            {dn.function.helperText}
            <br />
            {renderDynamicParameterIdentifier(dn.identifiers)}
          </AccordionSummary>
          <AccordionDetails>
            <DynamicFunctionParameterInputHolder
              key={idx}
              dynamicParameter={dn}
              functionIndex={idx}
              formKeyPrefix={formKeyPrefix}
              disabled={disabled}
              isHeadOfficeMode={isHeadOfficeMode}
            />
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  );
}

interface DynamicFunctionParameterInputHolderProps {
  dynamicParameter: DynamicTemplateParameterInputProps['dynamicParameters'][number];
  functionIndex: number;

  /**
   * Form key prefix, including any "."s
   */
  formKeyPrefix?: string;

  /**
   * Optional boolean indicating if head office mode merge tags
   *  should be displayed
   */
  isHeadOfficeMode?: boolean;

  /**
   * Optional boolean to disable inputs
   */
  disabled?: boolean;
}

function DynamicFunctionParameterInputHolder({
  dynamicParameter,
  functionIndex,
  formKeyPrefix,
  isHeadOfficeMode = false,
  disabled = false
}: DynamicFunctionParameterInputHolderProps) {
  const functionDynamicParameters = useMemo(
    () => dynamicParameter.function.dynamicParameters,
    [dynamicParameter.function.dynamicParameters]
  );

  if (!functionDynamicParameters?.length) {
    return (
      <Typography variant="caption">No dynamic parameters required for this function</Typography>
    );
  }

  return (
    <div>
      {functionDynamicParameters.map((fdp, idx) => (
        <DynamicParameterInput
          key={fdp.name}
          parameter={fdp}
          functionIndex={functionIndex}
          parameterIndex={idx}
          formKeyPrefix={formKeyPrefix}
          isHeadOfficeMode={isHeadOfficeMode}
          disabled={disabled}
        />
      ))}
    </div>
  );
}

interface DynamicParameterInputProps {
  parameter: Exclude<
    DynamicFunctionParameterInputHolderProps['dynamicParameter']['function']['dynamicParameters'],
    null | undefined
  >[number];

  functionIndex: number;
  parameterIndex: number;

  /**
   * Form key prefix, including any "."s
   */
  formKeyPrefix?: string;

  /**
   * Optional boolean indicating if head office mode merge tags
   *  should be displayed
   */
  isHeadOfficeMode?: boolean;

  /**
   * Optional boolean to disable inputs
   */
  disabled?: boolean;
}

export function DynamicParameterInput({
  parameter,
  functionIndex,
  parameterIndex,
  formKeyPrefix = '',
  isHeadOfficeMode = false,
  disabled = false
}: DynamicParameterInputProps) {
  const formKey = useMemo(
    () => `${formKeyPrefix}dynamic_parameters[${functionIndex}].parameters[${parameterIndex}]`,
    [functionIndex, parameterIndex, formKeyPrefix]
  );

  const options = useMemo(() => {
    if (parameter.type !== 'select') {
      return [];
    }

    const optionsBuilder = parameter.options ?? [];

    // Hacky solution to hide merge tags on some inputs
    if (['type', 'dwelling_type'].includes(parameter.name)) {
      return optionsBuilder;
    }

    if (isHeadOfficeMode) {
      const newOptions = HEAD_OFFICE_DYNAMIC_PARAMETER_MERGE_TAGS
        // Hacky solution to hide merge tags for state selections
        .filter((tag) => parameter.name !== 'state' || tag === '{{ WORKSPACE_OFFICE_STATE_CODE }}')
        // Required format for select input
        .map((tag) => ({
          label: tag,
          value: tag
        }));

      return optionsBuilder.concat(newOptions);
    }

    return optionsBuilder;
  }, [isHeadOfficeMode, parameter.type, parameter.name, parameter.options]);

  const helperText = useMemo(() => {
    if (!isHeadOfficeMode) {
      return parameter.helperText;
    }

    return `${parameter.helperText}. Use merge tags to customise for each of your workspaces`;
  }, [isHeadOfficeMode, parameter.helperText]);

  if (parameter.type === 'select') {
    return (
      <div>
        <Select
          name={`${formKey}.value`}
          label={parameter.label}
          helperText={parameter.helperText}
          placeholder={parameter.defaultValue}
          data={options}
          disabled={disabled}
          fullWidth
        />
      </div>
    );
  }

  return (
    <div>
      <TextField
        name={`${formKey}.value`}
        type={parameter.type === 'number' ? 'number' : undefined}
        label={parameter.label}
        helperText={helperText}
        placeholder={parameter.defaultValue}
        disabled={disabled}
        fullWidth
      />
      {isHeadOfficeMode && (
        <>
          <Typography variant="caption">
            Merge tags available (be sure to include both opening and closing brackets, and spaces
            in-between):
          </Typography>
          <ul>
            {HEAD_OFFICE_DYNAMIC_PARAMETER_MERGE_TAGS.map((tag) => (
              <Typography key={tag} variant="caption" component="li">
                <code>{tag}</code>
              </Typography>
            ))}
          </ul>
        </>
      )}
      <Typography variant="caption">Here are some example values:</Typography>
      <ul>
        {parameter.exampleValues.map((example) => (
          <Typography key={example} variant="caption" component="li">
            {example}
          </Typography>
        ))}
      </ul>
    </div>
  );
}
