import { useCallback, useEffect, useMemo, useState } from 'react';

import { Link, useNavigate } from 'react-router-dom';

import { Link as MuiLink } from '@material-ui/core';

import MaterialTable from '@material-table/core';

import moment from 'moment';
import { useSnackbar } from 'notistack';

import {
  useAutomationTriggersQuery,
  useDeleteAutomationTriggerMutation,
  useUpdateAutomationTriggerStatusMutation,
  Workspace_Automation_Actions_Enum,
  Workspace_Automation_Available_Triggers_Enum,
  Workspace_Automation_Status_Enum,
  Workspace_Automation_Triggers_Bool_Exp,
  Workspace_Resource_Permission_Modes_Enum,
  Workspace_User_Roles_Enum
} from 'generated/graphql';

import StatusIndicator, { Status } from 'components/StatusIndicator';
import TableContainer from 'components/TableContainer';
import Toolbar from 'components/Toolbar';
import AssignAgentsAssetDialog from 'components/workflows/AssignAgentsAssetDialog';

import { useHasuraRoleContext } from 'lib/HasuraRoleContext';
import useAssignAgentsAsset from 'lib/hooks/useAssignAgentsAsset';
import {
  createFilter,
  createFilterBySearch,
  usePagination
} from 'lib/hooks/useFiltersAndPagination';
import { useTeamMemberList } from 'lib/hooks/useTeamMemberList';
import useUserContext from 'lib/hooks/useUserContext';

const useSearchFilter = createFilterBySearch<Workspace_Automation_Triggers_Bool_Exp>([
  {
    name: {}
  }
]);

const useAgentFilter = createFilter<Workspace_Automation_Triggers_Bool_Exp>({
  assigned_users: {
    user_id: {}
  }
});

const useTriggerFilter = createFilter<Workspace_Automation_Triggers_Bool_Exp>({
  trigger: {}
});

const useStatusFilter = createFilter<Workspace_Automation_Triggers_Bool_Exp>({
  status: {}
});

const useActionFilter = createFilter<Workspace_Automation_Triggers_Bool_Exp>({
  action: {}
});

export default function AutomationRulesTriggers() {
  const { workspaceMemberContext } = useHasuraRoleContext();
  const {
    userId,
    activeWorkspaceId,
    isWorkspaceAnalyst,
    isWorkspaceCreator,
    isWorkspaceAgent,
    isWorkspaceAdmin
  } = useUserContext();

  const { limit, page, offset, setLimit, setPage } = usePagination();
  const [filtersOpen, setFiltersOpen] = useState(true);
  const [searchFilter, search, setSearch] = useSearchFilter();
  const [agentFilter, agentFilterValue, setAgentFilterValue] = useAgentFilter();
  const [triggerFilter, triggerFilterValue, setTriggerFilterValue] = useTriggerFilter();
  const [statusFilter, statusFilterValue, setStatusFilterValue] = useStatusFilter();
  const [actionFilter, actionFilterValue, setActionFilterValue] = useActionFilter();

  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const where = useMemo<Workspace_Automation_Triggers_Bool_Exp>(() => {
    const newWhere: Workspace_Automation_Triggers_Bool_Exp = {
      _and: [
        {
          workspace_id: { _eq: activeWorkspaceId }
        },
        { ...searchFilter },
        { ...agentFilter },
        { ...triggerFilter },
        { ...statusFilter },
        { ...actionFilter }
      ]
    };

    if (isWorkspaceAgent) {
      newWhere._and!.push({
        _or: [
          { created_by_id: { _eq: userId } },
          {
            agent_permission_mode: { _eq: Workspace_Resource_Permission_Modes_Enum.All }
          },
          {
            assigned_users: {
              user_id: { _eq: userId }
            }
          }
        ]
      });
    }

    return newWhere;
  }, [
    activeWorkspaceId,
    searchFilter,
    agentFilter,
    triggerFilter,
    statusFilter,
    actionFilter,
    isWorkspaceAgent,
    userId
  ]);

  const { data, loading, refetch } = useAutomationTriggersQuery({
    variables: {
      where: where,
      limit: limit,
      offset: offset
    },
    context: workspaceMemberContext
  });

  const {
    assignAssetId,
    assignAgentAssetDialogOpen,
    handleOpenAssignAgentsAssetDialog,
    handleCloseAssignAgentsAssetDialog
  } = useAssignAgentsAsset(refetch);
  const { teamMembers } = useTeamMemberList(Workspace_User_Roles_Enum.Agent, false);

  const [deleteTrigger] = useDeleteAutomationTriggerMutation({
    context: workspaceMemberContext
  });
  const [updateTriggerStatus] = useUpdateAutomationTriggerStatusMutation({
    context: workspaceMemberContext
  });

  // Refetch data on SPA page navigation
  useEffect(() => {
    refetch();
  }, [refetch]);

  const handleDeleteTrigger = useCallback(
    async (id: string) => {
      try {
        await deleteTrigger({ variables: { id } });
        await refetch();
        enqueueSnackbar('Automation trigger deleted', { variant: 'success' });
      } catch (error: any) {
        console.error('Error deleting trigger', error);
        enqueueSnackbar('Unable to delete trigger', { variant: 'error' });
      }
    },
    [deleteTrigger, enqueueSnackbar, refetch]
  );

  const handleEditTrigger = useCallback(
    (id: string) => {
      navigate(`create/trigger?edit_id=${id}`, { state: { edit_id: id } });
    },
    [navigate]
  );

  const handleUpdateTriggerStatus = useCallback(
    async (id: string, status: Workspace_Automation_Status_Enum) => {
      try {
        await updateTriggerStatus({ variables: { id, status } });
        enqueueSnackbar('Trigger status updated', { variant: 'success' });
      } catch (error) {
        enqueueSnackbar('Unable to update trigger status', { variant: 'error' });
      }
    },
    [updateTriggerStatus, enqueueSnackbar]
  );

  // https://github.com/mbrn/material-table/issues/1979
  const triggers = useMemo(() => data?.triggers?.map((t) => ({ ...t })) ?? [], [data?.triggers]);
  const count = useMemo(() => data?.count.aggregate?.count ?? 0, [data?.count?.aggregate?.count]);

  return (
    <>
      <MaterialTable
        title="Automation Triggers"
        columns={[
          {
            title: 'Name',
            field: 'name'
          },
          {
            title: 'Trigger Event',
            field: 'triggerByTrigger.description',
            render: (rowData) => {
              if (rowData.trigger === Workspace_Automation_Available_Triggers_Enum.InsertListing) {
                const listingStatus: string[] = rowData.params?.listing_status ?? [];
                if (listingStatus.length) {
                  return `${rowData.triggerByTrigger.description} (${listingStatus.join(', ')})`;
                }
              }

              return rowData.triggerByTrigger.description;
            }
          },
          {
            title: 'Action',
            field: 'actionByAction.description'
          },
          {
            title: 'Last Event',
            field: 'last_invocation',
            render: (data) => {
              if (!data.last_invocation) {
                return 'Never';
              }
              const correctedDate = moment(data.last_invocation);
              return correctedDate.fromNow();
            }
          },
          {
            title: 'Invocations',
            field: 'invocations_aggregate.aggregate.count',
            render: (data) => {
              return (
                <MuiLink component={Link} to={`triggers/${data.id}/invocations`}>
                  {data?.invocations_aggregate?.aggregate?.count}
                </MuiLink>
              );
            }
          },
          {
            title: 'Status',
            field: 'name',
            render: (data) => {
              if (data.status === Workspace_Automation_Status_Enum.Paused) {
                return <StatusIndicator status={Status.PENDING} text="Paused" />;
              }
              return <StatusIndicator status={Status.ACTIVE} text="Active" pulse />;
            }
          }
        ]}
        data={triggers}
        totalCount={count}
        isLoading={loading}
        actions={[
          (rowData) => ({
            icon: 'pause',
            tooltip: 'Pause trigger',
            onClick: () =>
              handleUpdateTriggerStatus(rowData.id, Workspace_Automation_Status_Enum.Paused),
            disabled: isWorkspaceAnalyst || isWorkspaceCreator,
            hidden: rowData.status !== Workspace_Automation_Status_Enum.Live
          }),
          (rowData) => ({
            icon: 'play_arrow',
            tooltip: 'Start trigger',
            onClick: () =>
              handleUpdateTriggerStatus(rowData.id, Workspace_Automation_Status_Enum.Live),
            disabled: isWorkspaceAnalyst || isWorkspaceCreator,
            hidden: rowData.status !== Workspace_Automation_Status_Enum.Paused
          }),
          (rowData) => ({
            icon: 'group',
            tooltip: 'Assign Agent(s)',
            onClick: () => handleOpenAssignAgentsAssetDialog(rowData.id),
            disabled: rowData.created_by_id !== userId && !isWorkspaceAdmin
          }),
          (rowData) => ({
            icon: 'edit',
            tooltip: 'Edit Trigger',
            onClick: () => handleEditTrigger(rowData.id),
            disabled: isWorkspaceAnalyst || isWorkspaceCreator
          })
        ]}
        editable={{
          isDeleteHidden: () => Boolean(isWorkspaceAnalyst) || Boolean(isWorkspaceCreator),
          isDeletable: () => !(Boolean(isWorkspaceAnalyst) || Boolean(isWorkspaceCreator)),
          onRowDelete: async (rowData) => {
            return handleDeleteTrigger(rowData.id);
          }
        }}
        options={{
          search: true,
          toolbar: true,
          draggable: false,
          showTitle: false,
          columnsButton: false,
          sorting: false,
          actionsColumnIndex: -1,
          pageSize: limit,
          pageSizeOptions: [10, 20, 50]
        }}
        onRowsPerPageChange={setLimit}
        onPageChange={setPage}
        page={page}
        onSearchChange={setSearch}
        components={{
          Container: TableContainer,
          Toolbar: (props) => (
            <Toolbar
              {...props}
              setSearch={setSearch}
              searchValue={search}
              onToggleFiltersOpen={() => setFiltersOpen((prev) => !prev)}
              filtersOpen={filtersOpen}
              menuItems={[
                {
                  name: 'Trigger',
                  options: [
                    {
                      value: Workspace_Automation_Available_Triggers_Enum.InsertListing,
                      text: 'New Listing'
                    },
                    {
                      value: Workspace_Automation_Available_Triggers_Enum.InsertReview,
                      text: 'New Review'
                    },
                    {
                      value: Workspace_Automation_Available_Triggers_Enum.InsertRssContentItem,
                      text: 'New News Content'
                    },
                    {
                      value: Workspace_Automation_Available_Triggers_Enum.PriceDecrease,
                      text: 'Price Decrease'
                    },
                    {
                      value: Workspace_Automation_Available_Triggers_Enum.PriceIncrease,
                      text: 'Price Increase'
                    },
                    {
                      value: Workspace_Automation_Available_Triggers_Enum.UpdateListing,
                      text: 'Listing Status Change'
                    },
                    {
                      value: Workspace_Automation_Available_Triggers_Enum.UpdateListingUnderOffer,
                      text: 'Listing Under Offer'
                    }
                  ],
                  value: triggerFilterValue,
                  setValue: setTriggerFilterValue,
                  hasAll: true
                },
                {
                  name: 'Status',
                  options: [
                    {
                      value: Workspace_Automation_Status_Enum.Live,
                      text: 'Active'
                    },
                    {
                      value: Workspace_Automation_Status_Enum.Paused,
                      text: 'Paused'
                    }
                  ],
                  value: statusFilterValue,
                  setValue: setStatusFilterValue,
                  hasAll: true
                },
                {
                  name: 'Action',
                  options: [
                    {
                      value: Workspace_Automation_Actions_Enum.CreatePost,
                      text: 'Create Post'
                    },
                    {
                      value: Workspace_Automation_Actions_Enum.CreateCampaign,
                      text: 'Create Campaign'
                    },
                    {
                      value: Workspace_Automation_Actions_Enum.PauseCampaign,
                      text: 'Pause Campaign'
                    }
                  ],
                  value: actionFilterValue,
                  setValue: setActionFilterValue,
                  hasAll: true
                },
                {
                  name: 'Assigned Agent',
                  options: teamMembers.map((m) => ({
                    text: m.primary,
                    value: m.user_id
                  })),
                  value: agentFilterValue,
                  setValue: setAgentFilterValue,
                  hasAll: true,
                  hidden: isWorkspaceAgent
                }
              ]}
            />
          )
        }}
      />
      <AssignAgentsAssetDialog
        open={assignAgentAssetDialogOpen}
        onClose={handleCloseAssignAgentsAssetDialog}
        assetId={assignAssetId}
        assetType="automation-trigger"
      />
    </>
  );
}
