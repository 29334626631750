import React from 'react';

import { makeStyles } from '@material-ui/core';

interface DividerWithTextProps {
  children: React.ReactNode;
}

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    margin: theme.spacing(1, 0)
  },
  border: {
    borderBottom: '1px solid #C2CFE0',
    width: '100%'
  },
  content: {
    padding: theme.spacing(0.5, 3),
    fontWeight: 500,
    fontSize: 18,
    color: '#C2CFE0',
    flexGrow: 1,
    whiteSpace: 'nowrap'
  }
}));

export default function DividerWithText({ children }: DividerWithTextProps) {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <div className={classes.border} />
      <span className={classes.content}>{children}</span>
      <div className={classes.border} />
    </div>
  );
}
