import React from 'react';

import { useMediaQuery } from '@material-ui/core';
import Drawer from '@material-ui/core/Drawer';
import { makeStyles, Theme } from '@material-ui/core/styles';

const useWizardDrawerStyles = makeStyles((theme: Theme) => ({
  drawer: {
    width: 300,
    background: theme.palette.common.white,
    boxShadow: '0px 6px 18px rgba(0, 0, 0, 0.06)',
    flexShrink: 0
  },
  drawerPaper: {
    width: 300,
    padding: theme.spacing(5, 1)
  }
}));

interface WizardDrawerProps {
  children: React.ReactNode;
}

export default function WizardDrawer({ children }: WizardDrawerProps) {
  const classes = useWizardDrawerStyles();
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  if (isMobile) {
    return null;
  }

  return (
    <Drawer
      className={classes.drawer}
      classes={{ paper: classes.drawerPaper }}
      open={true}
      variant="permanent"
      anchor="left"
    >
      {children}
    </Drawer>
  );
}
