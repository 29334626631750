import { ReactNode } from 'react';

import clsx from 'clsx';

import {
  CampaignStatus,
  FacebookEffectiveStatus,
  FacebookStatus,
  Workspace_Campaign_Status_Enum
} from 'generated/graphql';

import useStatusIndicatorStyles from './_styles';

export enum Status {
  READY = 'ready',
  ACTIVE = 'active',
  PAUSED = 'paused',
  FINISHED = 'finished',
  ERROR = 'error',
  DRAFT = 'draft',
  ARCHIVED = 'archived',
  DELETED = 'deleted',
  PENDING = 'pending'
}

export interface StatusIndicatorProps {
  status:
    | Status
    | Workspace_Campaign_Status_Enum
    | CampaignStatus
    | FacebookStatus
    | FacebookEffectiveStatus
    | string;

  // Default: medium
  size?: 'small' | 'medium' | 'large';

  // Default: left
  indicatorSide?: 'left' | 'right';

  // Text
  text?: string;

  // Pulse Colour
  pulse?: boolean;

  children?: ReactNode;
}

const StatusIndicator = ({
  status,
  size = 'small',
  indicatorSide = 'left',
  pulse = false,
  text,
  children
}: StatusIndicatorProps) => {
  const classes = useStatusIndicatorStyles();

  const stat: Status = status.toLowerCase() as Status;

  if (indicatorSide === 'right') {
    return (
      <span>
        <span>{text ?? children ?? status}</span>
        <span
          className={clsx(
            classes.root,
            classes[size],
            classes[stat],
            classes['@keyframes pulseDot'],
            pulse && classes.pulse
          )}
        />
      </span>
    );
  }

  return (
    <span>
      <span
        className={clsx(
          classes.root,
          classes[size],
          classes[stat],
          classes['@keyframes pulseDot'],
          pulse && classes.pulse
        )}
      />
      <span>{text ?? children ?? status.toLowerCase()}</span>
    </span>
  );
};

export default StatusIndicator;
