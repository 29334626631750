import Carousel from 'react-material-ui-carousel';

import { Dialog, DialogContent } from '@material-ui/core';

import DialogTitle from 'components/DialogTitle';

interface ImageCreativePreviewDialogProps {
  open: boolean;
  onClose: () => void;
  title: string;
  imageUrls: string[];
}

interface ImageCarouselProps extends Pick<ImageCreativePreviewDialogProps, 'imageUrls' | 'title'> {
  width?: number;
  minHeight?: number;
  maxHeight?: number;
}

export function ImageCarousel({
  imageUrls,
  title,
  minHeight = 480,
  maxHeight = 720,
  width = 720
}: ImageCarouselProps) {
  if (imageUrls.length === 0) {
    return null;
  }
  return (
    <Carousel animation="slide" navButtonsAlwaysVisible>
      {imageUrls.map((url) => (
        <div
          key={url}
          style={{ marginLeft: 'auto', marginRight: 'auto', width, minHeight, maxHeight }}
        >
          <img
            key={url}
            src={url}
            alt={title}
            width={width}
            style={{ maxHeight, objectFit: 'scale-down' }}
          />
        </div>
      ))}
    </Carousel>
  );
}

export default function ImageCreativePreviewDialog({
  open,
  title,
  onClose,
  imageUrls
}: ImageCreativePreviewDialogProps) {
  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
      <DialogTitle onClose={onClose}>{title}</DialogTitle>
      <DialogContent>
        <div style={{ marginLeft: 'auto', marginRight: 'auto', maxWidth: 720, maxHeight: 720 }}>
          <ImageCarousel imageUrls={imageUrls} title={title} />
        </div>
      </DialogContent>
    </Dialog>
  );
}
