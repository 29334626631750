import { Workspace_Subscription_Model_Enum } from 'generated/graphql';

const ORDERED_SUBSCRIPTION_LEVELS: Record<Workspace_Subscription_Model_Enum, number> = {
  [Workspace_Subscription_Model_Enum.Entry]: 0,
  [Workspace_Subscription_Model_Enum.Basic]: 1,
  [Workspace_Subscription_Model_Enum.Premium]: 2,
  [Workspace_Subscription_Model_Enum.PremiumPlus]: 3
};

export const MAX_SUBSCRIPTION_LEVEL_NUMBER = Math.max(
  ...Object.keys(ORDERED_SUBSCRIPTION_LEVELS).map(
    (level) => ORDERED_SUBSCRIPTION_LEVELS[level as Workspace_Subscription_Model_Enum]
  )
);

/**
 * Get a comparable number for a subscription level,
 * the higher the number the higher (more expensive) the plan.
 *
 * @param subscriptionLevel
 * @returns number (higher indicates higher level of subscription)
 */
export function getSubscriptionLevelComparableNumber(
  subscriptionLevel: Workspace_Subscription_Model_Enum
) {
  return ORDERED_SUBSCRIPTION_LEVELS[subscriptionLevel];
}

function getSubscriptionLevelByNumber(
  value: number
): Workspace_Subscription_Model_Enum | undefined {
  const entry = Object.entries(ORDERED_SUBSCRIPTION_LEVELS).find(([_, v]) => v === value);
  return entry ? (entry[0] as Workspace_Subscription_Model_Enum) : undefined;
}

export function getNextSubscriptionLevel(
  currentSubscriptionLevel: Workspace_Subscription_Model_Enum
): Workspace_Subscription_Model_Enum {
  const currentSubscriptionLevelNumber =
    getSubscriptionLevelComparableNumber(currentSubscriptionLevel);

  // Ensure we don't have overflow
  const nextSubscriptionLevelNumber = Math.min(
    currentSubscriptionLevelNumber + 1,
    MAX_SUBSCRIPTION_LEVEL_NUMBER
  );

  return getSubscriptionLevelByNumber(nextSubscriptionLevelNumber) ?? currentSubscriptionLevel;
}

export function isMaxSubscriptionLevel(
  currentSubscriptionLevel: Workspace_Subscription_Model_Enum
) {
  const currentSubscriptionLevelNumber =
    getSubscriptionLevelComparableNumber(currentSubscriptionLevel);

  return currentSubscriptionLevelNumber >= MAX_SUBSCRIPTION_LEVEL_NUMBER;
}
