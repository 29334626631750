import React from 'react';

import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

export default function StepperIcon(props: SvgIconProps) {
  return (
    <SvgIcon viewBox="0 0 8 8" xmlns="http://www.w3.org/2000/svg" {...props}>
      <circle cx="4" cy="4" r="4" fill="#F47D6F" />
      <circle cx="4" cy="4" r="2" fill="#FFFFFF" />
    </SvgIcon>
  );
}
