import { useCallback, useEffect, useMemo, useState } from 'react';

import { Link, useNavigate } from 'react-router-dom';

import { Box, Button, Grid, Theme, Typography, useMediaQuery } from '@material-ui/core';
import AssistantIcon from '@material-ui/icons/Assistant';
import RefreshIcon from '@material-ui/icons/Refresh';
import SentimentVeryDissatisfiedIcon from '@material-ui/icons/SentimentVeryDissatisfied';

import moment from 'moment';

import { useBasicDashboardAnalyticsQuery, useDashboardQuery } from 'generated/graphql';

import DashboardStatCard from 'components/DashboardStatCard';
import EmptyStatePage from 'components/EmptyStatePage';
import LoadingStatePage from 'components/LoadingStatePage';
import PropertiIcon from 'components/icons/PropertiIcon';

import { useHasuraRoleContext } from 'lib/HasuraRoleContext';
import { getFilePath } from 'lib/auth/hbp';
import { FeatureFlags, useIsFeatureFlagEnabled } from 'lib/feature-flags';
import useUserContext from 'lib/hooks/useUserContext';

import BenchmarkCPMBox from './components/BenchmarkCPMBox';
import BenchmarkSpendBox from './components/BenchmarkSpendBox';
import CategorySpendBox from './components/CategorySpendBox';
import { DashboardDatePresetContextProvider } from './components/DatePresetSelect';
import OrganicPostBox from './components/OrganicPostBox';
import PlatformBox from './components/PlatformBox';
import SpendBox from './components/SpendBox';
import WorkspaceOnboardingDialog from './components/WorkspaceOnboardingDialog';

function BasicDashboard() {
  const { workspaceMemberContext } = useHasuraRoleContext();
  const { activeWorkspaceId, workspace, isWorkspaceAdmin } = useUserContext();
  const start = useMemo(() => moment().subtract(30, 'days'), []);

  const [showOnboardingDialog, setShowOnboardingDialog] = useState<boolean>(false);
  const workspaceOnboardingHidden = useMemo(
    () => workspace?.hide_onboarding ?? true,
    [workspace?.hide_onboarding]
  );

  const { data, loading, error } = useBasicDashboardAnalyticsQuery({
    variables: {
      workspace_id: activeWorkspaceId!,
      start_date: start.format('YYYY-MM-DD'),
      start_time: start.toISOString()
    },
    skip: !activeWorkspaceId,
    context: workspaceMemberContext
  });

  useEffect(() => {
    if (!workspaceOnboardingHidden && isWorkspaceAdmin) {
      setShowOnboardingDialog(true);
    } else {
      setShowOnboardingDialog(false);
    }
  }, [workspaceOnboardingHidden, isWorkspaceAdmin, setShowOnboardingDialog]);

  const handleCloseOnboardingDialog = useCallback(() => {
    setShowOnboardingDialog(false);
  }, [setShowOnboardingDialog]);

  const brandLogoUrl = getFilePath(workspace?.branding?.logo_url, workspace?.branding?.logo_token);

  const campaignImpressions =
    data?.workspace_analytics_workspaces_aggregate?.aggregate?.sum?.impressions ?? 0;
  const postImpressions =
    data?.workspace_post_analytics_overall_aggregate?.aggregate?.sum?.total_impressions ?? 0;
  const newListings = data?.workspace_listings_aggregate?.aggregate?.count ?? 0;
  const newPosts = data?.workspace_posts_aggregate?.aggregate?.count ?? 0;
  const newCampaigns = data?.workspace_campaigns_aggregate?.aggregate?.count ?? 0;
  const draftPosts = data?.draft_posts?.aggregate?.count ?? 0;

  if (error) {
    return (
      <EmptyStatePage
        title="Whoops, something went wrong!"
        text="Your error has been logged with our tech team"
        icon={<SentimentVeryDissatisfiedIcon color="error" style={{ width: 64, height: 64 }} />}
        error
        button={
          <Button
            variant="contained"
            color="secondary"
            onClick={() => window?.location?.reload()}
            size="large"
            startIcon={<RefreshIcon />}
            fullWidth
          >
            Reload
          </Button>
        }
      />
    );
  }

  if (loading) {
    return <LoadingStatePage />;
  }

  return (
    <>
      <Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            gridGap: 12,
            marginTop: 80,
            marginBottom: 80
          }}
        >
          {brandLogoUrl && (
            <img
              src={brandLogoUrl}
              alt=""
              width={340}
              style={{ padding: 4 }}
              object-fit="contain"
            />
          )}
          <Typography variant="h3">Your social media dashboard</Typography>
          <Typography variant="subtitle1">Analytics and management powered by Properti</Typography>
        </Box>

        <Box
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            marginLeft: 'auto',
            marginRight: 'auto',
            gridGap: 16,
            maxWidth: 1200
          }}
        >
          <DashboardStatCard
            title="New listings"
            subtitle="Last 30 days"
            value={newListings}
            buttonLink="../properties"
            buttonText="View listings"
          />
          <DashboardStatCard
            title="Ad campaign impressions"
            subtitle="Last 30 days"
            value={campaignImpressions}
            buttonLink="../campaigns"
            buttonText="View ad campaigns"
          />
          <DashboardStatCard
            title="Published ad campaigns"
            subtitle="Last 30 days"
            value={newCampaigns}
            buttonLink="../campaigns"
            buttonText="View ad campaigns"
          />

          <DashboardStatCard
            title="Unscheduled draft posts"
            subtitle="Last 30 days"
            value={draftPosts}
            buttonLink="../posts"
            buttonText="View posts"
          />
          <DashboardStatCard
            title="Post impressions"
            subtitle="Last 30 days"
            value={postImpressions}
            buttonLink="../posts"
            buttonText="View posts"
          />
          <DashboardStatCard
            title="Published posts"
            subtitle="Last 30 days"
            value={newPosts}
            buttonLink="../posts"
            buttonText="View posts"
          />
        </Box>
      </Box>
      <WorkspaceOnboardingDialog
        open={showOnboardingDialog}
        onClose={handleCloseOnboardingDialog}
      />
    </>
  );
}

function AdvancedDashboard() {
  const navigate = useNavigate();

  const { workspaceMemberContext } = useHasuraRoleContext();
  const {
    activeWorkspaceId,
    isWorkspaceAgent,
    isWorkspaceAdmin,
    workspace,
    loading: loadingWorkspaceContext
  } = useUserContext();

  // use memo to avoid rerenders
  const aMonthAgo = useMemo(() => moment().subtract(30, 'days'), []);

  const [showOnboardingDialog, setShowOnboardingDialog] = useState<boolean>(false);
  const workspaceOnboardingHidden = useMemo(
    () => workspace?.hide_onboarding ?? true,
    [workspace?.hide_onboarding]
  );
  useEffect(() => {
    if (isWorkspaceAgent) {
      navigate('/posts');
    }
  }, [isWorkspaceAgent, navigate]);

  const { data, loading, error } = useDashboardQuery({
    variables: {
      workspace_id: activeWorkspaceId!,
      a_month_ago: aMonthAgo.toISOString(),
      a_month_ago_date: aMonthAgo.format('YYYY-MM-DD')
    },
    skip: !activeWorkspaceId || isWorkspaceAgent,
    context: workspaceMemberContext
  });

  useEffect(() => {
    if (!workspaceOnboardingHidden && isWorkspaceAdmin) {
      setShowOnboardingDialog(true);
    } else {
      setShowOnboardingDialog(false);
    }
  }, [workspaceOnboardingHidden, isWorkspaceAdmin, setShowOnboardingDialog]);

  const handleCloseOnboardingDialog = useCallback(() => {
    setShowOnboardingDialog(false);
  }, [setShowOnboardingDialog]);

  if (error) {
    return (
      <EmptyStatePage
        title="Whoops, something went wrong!"
        text="Your error has been logged with our tech team"
        icon={<SentimentVeryDissatisfiedIcon color="error" style={{ width: 64, height: 64 }} />}
        error
        button={
          <Button
            variant="contained"
            color="secondary"
            onClick={() => window?.location?.reload()}
            size="large"
            startIcon={<RefreshIcon />}
            fullWidth
          >
            Reload
          </Button>
        }
      />
    );
  }

  if (loading || loadingWorkspaceContext) {
    return <LoadingStatePage />;
  }

  const brandLogoUrl = getFilePath(workspace?.branding?.logo_url, workspace?.branding?.logo_token);

  const liveAdCampaigns = data?.live_campaigns?.aggregate?.count ?? 0;
  const campaignImpressions30Days = data?.campaigns_stats_30_days?.aggregate?.sum?.impressions ?? 0;
  const campaignsPublished30Days = data?.campaigns_30_days?.aggregate?.count ?? 0;

  const draftPosts = data?.draft_posts?.aggregate?.count ?? 0;
  const postImpressions30Days = data?.posts_stats_30_days?.aggregate?.sum?.total_impressions ?? 0;
  const postsPublished30Days = data?.posts_30_days?.aggregate?.count ?? 0;

  return (
    <>
      <DashboardDatePresetContextProvider>
        <div>
          {brandLogoUrl && (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                marginTop: 12,
                marginBottom: 24
              }}
            >
              <img
                src={brandLogoUrl}
                alt=""
                width={340}
                style={{ padding: 4 }}
                object-fit="contain"
              />
            </Box>
          )}

          <Box sx={{ marginTop: 8 }}>
            <Box
              sx={{
                display: 'flex',
                flexWrap: 'wrap',
                flexDirection: 'row',
                justifyContent: 'space-around',
                alignItems: 'center',
                gridGap: 12,
                marginBottom: 12
              }}
            >
              <DashboardStatCard
                title="Live ad campaigns"
                value={liveAdCampaigns}
                buttonLink="../campaigns"
                buttonText="View ad campaigns"
              />
              <DashboardStatCard
                title="Ad campaign impressions"
                subtitle="Last 30 days"
                value={campaignImpressions30Days}
                buttonLink="../campaigns"
                buttonText="View ad campaigns"
              />
              <DashboardStatCard
                title="Published ad campaigns"
                subtitle="Last 30 days"
                value={campaignsPublished30Days}
                buttonLink="../campaigns"
                buttonText="View ad campaigns"
              />
              <DashboardStatCard
                title="Unscheduled draft posts"
                subtitle="Last 30 days"
                value={draftPosts}
                buttonLink="../posts"
                buttonText="View posts"
              />
              <DashboardStatCard
                title="Post impressions"
                subtitle="Last 30 days"
                value={postImpressions30Days}
                buttonLink="../posts"
                buttonText="View posts"
              />
              <DashboardStatCard
                title="Published posts"
                subtitle="Last 30 days"
                value={postsPublished30Days}
                buttonLink="../posts"
                buttonText="View posts"
              />
            </Box>
          </Box>
          <Box sx={{ marginTop: 12 }}>
            <SpendBox workspaceId={activeWorkspaceId!} />
          </Box>

          <Grid container spacing={2} style={{ marginTop: 4 }}>
            <Grid item xs={12} sm={12} md={8} lg={5}>
              <CategorySpendBox workspaceId={activeWorkspaceId!} />
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={3}>
              <PlatformBox workspaceId={activeWorkspaceId!} />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={4}>
              <OrganicPostBox workspaceId={activeWorkspaceId!} />
            </Grid>
          </Grid>

          <Grid container spacing={5}>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <BenchmarkCPMBox workspaceId={activeWorkspaceId!} />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <BenchmarkSpendBox workspaceId={activeWorkspaceId!} />
            </Grid>
          </Grid>
        </div>
      </DashboardDatePresetContextProvider>
      <WorkspaceOnboardingDialog
        open={showOnboardingDialog}
        onClose={handleCloseOnboardingDialog}
      />
    </>
  );
}

const DashboardPage = () => {
  const navigate = useNavigate();
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  const { isWorkspaceAgent, activeHeadOfficeId } = useUserContext();
  const isAdvancedDashboardEnabled = useIsFeatureFlagEnabled(FeatureFlags.advanced_dashboard);

  useEffect(() => {
    if (isWorkspaceAgent) {
      navigate('/posts');
    }
  }, [isWorkspaceAgent, navigate]);

  // Fallback placeholder for Head Office Mode
  // We try to redirect /dashboard to /head-office/dashboard
  if (activeHeadOfficeId) {
    return (
      <EmptyStatePage
        title="Head Office Mode"
        text="Welcome to Head Office mode, from here you can manage all your offices at once"
        icon={<AssistantIcon style={{ width: 64, height: 64 }} color="secondary" />}
      />
    );
  }

  // Mobile access, only show manage account link
  if (isMobile) {
    return (
      <EmptyStatePage
        title="Welcome to Properti"
        text="Open Properti on your Desktop device for a more complete experience"
        icon={<PropertiIcon style={{ width: 64, height: 64 }} color="secondary" />}
        button={
          <Button component={Link} to="/profile" variant="contained" color="secondary">
            Manage Account
          </Button>
        }
      />
    );
  }

  if (isAdvancedDashboardEnabled) {
    return <AdvancedDashboard />;
  }

  return <BasicDashboard />;
};

export default DashboardPage;
