import { useMemo } from 'react';

import useFeatureFlags from './useFeatureFlagsContext';

export function useIsFeatureFlagEnabled(flagName: string): boolean {
  const { isFlagEnabled } = useFeatureFlags();
  const isEnabled = useMemo(() => isFlagEnabled(flagName), [isFlagEnabled, flagName]);

  return isEnabled;
}
