import { FormEvent, useCallback, useEffect, useMemo, useState } from 'react';

import { Box, Button, CircularProgress, LinearProgress, Link, Typography } from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';

import { PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';

import { useSnackbar } from 'notistack';

import {
  useCreateStripeSetupIntentMutation,
  useStripeCustomerPaymentMethodsQuery,
  useUpdateStripeCustomerDefaultPaymentMethodMutation
} from 'generated/graphql';

import { useHasuraRoleContext } from 'lib/HasuraRoleContext';
import useUserContext from 'lib/hooks/useUserContext';

interface AddPaymentMethodSectionProps {
  onPaymentMethodAdded: () => void;
}

export function AddPaymentMethodSection({ onPaymentMethodAdded }: AddPaymentMethodSectionProps) {
  const { activeWorkspaceId, workspace } = useUserContext();
  const { workspaceMemberContext } = useHasuraRoleContext();

  const { enqueueSnackbar } = useSnackbar();

  const stripeCustomerId = useMemo(
    () => workspace?.stripe_customer_id,
    [workspace?.stripe_customer_id]
  );

  const [savingCard, setSavingCard] = useState<boolean>(false);

  const { data, loading, refetch } = useStripeCustomerPaymentMethodsQuery({
    variables: {
      workspace_id: activeWorkspaceId!,
      customerId: stripeCustomerId!
    },
    skip: !stripeCustomerId,
    context: workspaceMemberContext
  });
  const [updateDefaultPaymentMethod] = useUpdateStripeCustomerDefaultPaymentMethodMutation({
    context: workspaceMemberContext
  });
  const [createSetupIntent] = useCreateStripeSetupIntentMutation({
    context: workspaceMemberContext
  });

  const stripe = useStripe();
  const elements = useElements();

  const handleSaveCard = useCallback(
    async (event: FormEvent<HTMLFormElement>) => {
      event.preventDefault();

      if (!stripe || !elements) {
        return;
      }

      setSavingCard(true);

      const { error: submitError } = await elements.submit();
      if (submitError) {
        console.error(submitError);
        setSavingCard(false);
        return;
      }

      // Create Intent
      const result = await createSetupIntent({
        variables: {
          customerId: stripeCustomerId!
        }
      });

      const clientSecret = result.data?.stripe_create_setup_intent?.client_secret;

      if (!clientSecret) {
        setSavingCard(false);
        return enqueueSnackbar('Unable to setup secure handshake', { variant: 'error' });
      }

      // Save customer payment method
      const { error, setupIntent } = await stripe.confirmSetup({
        elements,
        clientSecret,
        confirmParams: {
          return_url: 'https://dashboard.properti.ai/quick-start'
        },
        redirect: 'if_required'
      });

      if (error) {
        console.error(error);
        setSavingCard(false);
        return enqueueSnackbar(error.message ?? 'Unable to save card', { variant: 'error' });
      }

      if (setupIntent?.payment_method) {
        await updateDefaultPaymentMethod({
          variables: {
            customerId: stripeCustomerId!,
            paymentMethodId: setupIntent?.payment_method! as string
          }
        });
      }

      await refetch();
      setSavingCard(false);

      return enqueueSnackbar('Payment method setup', { variant: 'success' });
    },
    [
      stripe,
      elements,
      stripeCustomerId,
      createSetupIntent,
      enqueueSnackbar,
      updateDefaultPaymentMethod,
      setSavingCard,
      refetch
    ]
  );

  const totalMethods = useMemo(
    () => data?.stripe_customer_payment_methods?.length ?? 0,
    [data?.stripe_customer_payment_methods?.length]
  );

  useEffect(() => {
    if (totalMethods > 0) {
      onPaymentMethodAdded();
    }
  }, [totalMethods, onPaymentMethodAdded]);

  if (!stripe || !elements || loading) {
    return <CircularProgress />;
  }

  if (totalMethods > 0) {
    return (
      <Box maxWidth={1000}>
        <Typography variant="h6">Add payment method</Typography>
        <Alert severity="success">
          <AlertTitle>Payment method successfully attached</AlertTitle>
        </Alert>
      </Box>
    );
  }

  return (
    <Box maxWidth={1000}>
      <Typography variant="h6">Add payment method</Typography>
      <Box>
        <Alert severity="info">
          <AlertTitle>Why add a payment method?</AlertTitle>
          Properti requires a payment method to run{' '}
          <Link color="inherit" href="https://support.properti.ai/ad-campaigns" target="_blank">
            paid ad campaigns
          </Link>
          , and upgrade your subscription.
        </Alert>
      </Box>
      <Box mt={2} mb={4}>
        {savingCard && <LinearProgress variant="indeterminate" />}
        <form onSubmit={handleSaveCard}>
          <PaymentElement
            options={{
              layout: 'accordion',
              readOnly: savingCard
            }}
          />

          <Button
            type="submit"
            style={{ marginTop: 4 }}
            variant="contained"
            color="primary"
            size="large"
            disabled={savingCard}
          >
            {savingCard ? 'Loading ...' : 'Save card'}
          </Button>
        </form>
      </Box>
    </Box>
  );
}
