import { capitalize, Divider, MenuItem, Typography } from '@material-ui/core';

import { Autocomplete, Select, TextField } from 'mui-rff';

import { TextFieldArray } from './Responsive';

const CallToActionOptions = [
  'OPEN_LINK',
  'WATCH_VIDEO',
  'LEARN_MORE',
  'WATCH_MORE',
  'NO_BUTTON',
  'APPLY_NOW',
  'SELL_NOW',
  'GET_QUOTE',
  'CONTACT_US',
  'REQUEST_TIME',
  'SEE_MORE'
];

export default function ResponsiveAdDetails() {
  return (
    <>
      <TextField name="creative_name" label="Creative Name" />
      <Autocomplete
        name="tags"
        label="Creative Tags"
        placeholder="Start typing and press enter to select tags"
        multiple
        freeSolo
        options={[]}
        helperText="Start typing and press enter to select tags"
      />
      <Divider style={{ margin: '8px 0 16px' }} />
      <Typography variant="caption">Enter Ad Details</Typography>
      <TextField name="long_title" label="Long Title" />
      <TextFieldArray name="titles" label="Title" maxLength={5} />
      <TextFieldArray name="descriptions" label="Description" maxLength={5} />
      <Select name="cta" label="Call To Action">
        {CallToActionOptions.map((value, index) => (
          <MenuItem value={value} key={index}>
            {value.toLowerCase().split('_').map(capitalize).join(' ')}
          </MenuItem>
        ))}
      </Select>
      {/* <TextFieldArray name="videos" label="YouTube Video" maxLength={5} /> */}
      <TextField name="url" label="Landing Page URL" />
    </>
  );
}
