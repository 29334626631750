import React from 'react';

import Typography from '@material-ui/core/Typography';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

interface PageHeaderProps {
  title: string;
  subtitle?: string;
  rightComponent?: React.ReactNode;
  subtitleComponent?: React.ReactNode;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      minHeight: 42,
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: theme.spacing(2)
    }
  })
);

export default function PageHeader({
  title,
  subtitle,
  subtitleComponent,
  rightComponent
}: PageHeaderProps) {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div>
        <Typography variant="h5">{title}</Typography>
        <Typography variant="subtitle2">{subtitle}</Typography>
        {subtitleComponent}
      </div>
      {rightComponent}
    </div>
  );
}
