import React from 'react';

import { StepIconProps } from '@material-ui/core';

import StepperIcon from 'components/icons/StepperIcon';

const StepIcon: React.FC<StepIconProps> = ({ active }) => {
  const fontSize = active ? 24 : 16;
  const paddingLeft = active ? 0 : 4;
  const paddingTop = active ? 0 : 4;

  return (
    <div style={{ paddingLeft, paddingTop, width: 24, height: 24 }}>
      <StepperIcon style={{ fontSize }} />
    </div>
  );
};

export default StepIcon;
