import { useEffect } from 'react';

import { useNavigate } from 'react-router';

import { SSO_REDIRECT_PATH_LOCAL_STORAGE_KEY } from 'lib/auth/constants';

export default function SSORedirectSuccess() {
  const navigate = useNavigate();

  useEffect(() => {
    // Check stored redirect paths
    const redirectPath = localStorage.getItem(SSO_REDIRECT_PATH_LOCAL_STORAGE_KEY) ?? '/dashboard';

    // Clear stored path
    localStorage.removeItem(SSO_REDIRECT_PATH_LOCAL_STORAGE_KEY);

    // Redirect
    navigate(redirectPath, {
      replace: true
    });
  }, [navigate]);

  // Don't show anything as redirect will happen quickly,
  // This prevents "jitter" or "flashes"
  return null;
}
