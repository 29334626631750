import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Checkbox,
  Divider,
  FormControlLabel,
  Switch,
  Typography
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import suprsend, { PreferenceOptions } from '@suprsend/web-sdk';

import { Category, CategoryChannel, PreferenceData } from '../types';
import { getChannelName } from './utils';

const handleCategoryPreferenceChange = (
  subcategory: Category,
  setPreferenceData: (newData: PreferenceData) => void
) => {
  if (!subcategory.is_editable) {
    return;
  }

  const resp = suprsend.user.preferences.update_category_preference(
    subcategory.category,
    subcategory.preference === PreferenceOptions.OPT_IN
      ? PreferenceOptions.OPT_OUT
      : PreferenceOptions.OPT_IN
  );

  if (resp.error) {
    console.warn(resp.message);
  } else {
    setPreferenceData({ ...resp });
  }
};

const handleChannelPreferenceInCategoryChange = (
  channel: CategoryChannel,
  subcategory: Category,
  setPreferenceData: (newData: PreferenceData) => void
) => {
  if (!channel.is_editable) {
    return;
  }

  const resp = suprsend.user.preferences.update_channel_preference_in_category(
    channel.channel,
    channel.preference === PreferenceOptions.OPT_IN
      ? PreferenceOptions.OPT_OUT
      : PreferenceOptions.OPT_IN,
    subcategory.category
  );
  if (resp.error) {
    console.warn(resp.message);
  } else {
    setPreferenceData({ ...resp });
  }
};

interface NotificationCategoryPreferencesProps {
  preferenceData: PreferenceData;
  setPreferenceData: (newData: PreferenceData) => void;
}

export default function NotificationCategoryPreferences({
  preferenceData,
  setPreferenceData
}: NotificationCategoryPreferencesProps) {
  const sections = preferenceData.sections;

  if (!sections || !sections.length) {
    return null;
  }

  return (
    <Box style={{ paddingTop: 8, marginBottom: 24 }}>
      <Typography variant="h6" gutterBottom>
        Which notifications to allow for each category?
      </Typography>
      {sections.map((section, idx) => (
        <Accordion
          key={idx}
          variant="outlined"
          defaultExpanded={section.subcategories?.some((sc) => sc.is_editable)}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls={`${section.name}-content`}
            id={`${section.name}-header`}
          >
            <Box>
              <Typography variant="h6">{section.name}</Typography>
              <Typography variant="overline">{section.description}</Typography>
            </Box>
          </AccordionSummary>
          <AccordionDetails id={`${section.name}-content`}>
            <Box style={{ width: '100%' }}>
              {section.subcategories?.map((subcategory, idx) => (
                <Box key={idx}>
                  <Divider />
                  <SubCategoryPreferences
                    subcategory={subcategory}
                    setPreferenceData={setPreferenceData}
                  />
                  <Divider />
                </Box>
              ))}
            </Box>
          </AccordionDetails>
        </Accordion>
      ))}
    </Box>
  );
}

interface SubCategoryPreferencesProps {
  subcategory: Category;
  setPreferenceData: (newData: PreferenceData) => void;
}

function SubCategoryPreferences({ subcategory, setPreferenceData }: SubCategoryPreferencesProps) {
  const handleChange = () => {
    handleCategoryPreferenceChange(subcategory, setPreferenceData);
  };

  return (
    <Box style={{ marginTop: 8, marginBottom: 8 }}>
      <Box style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
        <Box style={{ display: 'flex', flexDirection: 'column' }}>
          <Typography>
            <strong>{subcategory.name}</strong>
          </Typography>
          <Typography>{subcategory.description}</Typography>
        </Box>
        <Box>
          <Switch
            disabled={!subcategory.is_editable}
            checked={subcategory.preference === PreferenceOptions.OPT_IN}
            onChange={handleChange}
            name={`${subcategory.name}-switch`}
            inputProps={{ 'aria-label': `${subcategory.name} switch` }}
            color="primary"
          />
        </Box>
      </Box>
      {subcategory.channels?.map((channel) => (
        <SubCategoryChannelCheckbox
          key={channel.channel}
          subcategory={subcategory}
          channel={channel}
          setPreferenceData={setPreferenceData}
        />
      ))}
    </Box>
  );
}

interface SubCategoryChannelCheckboxProps {
  subcategory: Category;
  channel: CategoryChannel;
  setPreferenceData: (newData: PreferenceData) => void;
}

function SubCategoryChannelCheckbox({
  subcategory,
  channel,
  setPreferenceData
}: SubCategoryChannelCheckboxProps) {
  const handleChange = () => {
    handleChannelPreferenceInCategoryChange(channel, subcategory, setPreferenceData);
  };

  return (
    <FormControlLabel
      control={
        <Checkbox
          checked={channel.preference === PreferenceOptions.OPT_IN}
          disabled={!channel.is_editable}
          onChange={handleChange}
          name={`${channel.channel}-${subcategory.name}`}
          color="primary"
        />
      }
      label={getChannelName(channel.channel)}
    />
  );
}
