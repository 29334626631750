import { Box, capitalize, ThemeProvider } from '@material-ui/core';
import { createTheme } from '@material-ui/core/styles';

import { sample } from 'lodash';

import { getFilePath } from 'lib/auth/hbp';

import { previewTypes } from './google';
import { calculateContrastColor, hexToRgb, useResponsiveContext } from './utils';

interface GoogleAdsResponsivePreviewProps {
  title: string;
  description: string;
  imageUrl?: string | null;
  cta: string;
  brandName?: string | null;
  logoUrl?: string | null;
  landingPageUrl: string;
  showButton: boolean;
}

export default function GoogleAdsResponsivePreview({ previewType }: { previewType: number }) {
  const Preview = previewTypes[previewType].component;
  const { creative, branding } = useResponsiveContext();
  const { responsive } = creative;

  if (!responsive || !Preview) {
    return <div>Could not generate preview</div>;
  }

  const images = responsive.images
    .map(({ selections }) =>
      selections
        .filter((selection) => selection.type === 'landscape')
        .map(({ attachment }) => attachment!)
        .filter(Boolean)
    )
    .flat();
  const image = sample(images);
  const logos = [
    { path: branding?.logo_url, token: branding?.logo_token },
    { path: branding?.logo_alt_url, token: branding?.logo_alt_token }
  ]
    .map(({ path, token }) => getFilePath(path, token)!)
    .filter(Boolean);

  const squareLogos = [
    { path: branding?.logo_square_url, token: branding?.logo_square_token },
    { path: branding?.logo_square_alt_url, token: branding?.logo_square_alt_token }
  ]
    .map(({ path, token }) => getFilePath(path, token)!)
    .filter(Boolean);

  const props: GoogleAdsResponsivePreviewProps = {
    cta: responsive.cta.toLowerCase().split('_').map(capitalize).join(' '),
    title: sample(responsive.titles ?? [])?.value ?? '',
    description: sample(responsive.descriptions ?? [])?.value ?? '',
    imageUrl: getFilePath(image?.path, image?.token),
    landingPageUrl: creative.default_url ?? '#',
    brandName: branding?.brand_name,
    logoUrl: squareLogos.length ? squareLogos[0] : logos[0],
    showButton: responsive.cta !== 'NO_BUTTON'
  };

  const primary = branding?.primary_color ?? '#111111';
  const secondary = branding?.secondary_color ?? '#F37D6F';
  const primaryContrast = calculateContrastColor(hexToRgb(primary));
  const secondaryContrast = calculateContrastColor(hexToRgb(secondary));

  const theme = createTheme({
    palette: {
      primary: {
        main: primary,
        contrastText: primaryContrast
      },
      secondary: {
        main: secondary,
        contrastText: secondaryContrast
      }
    }
  });

  return (
    <div>
      <ThemeProvider theme={theme}>
        <Box mt={4}>
          <Preview {...props} />
        </Box>
      </ThemeProvider>
    </div>
  );
}
