import Uppy from '@uppy/core';
import '@uppy/core/dist/style.css';
import '@uppy/dashboard/dist/style.css';
import '@uppy/image-editor/dist/style.css';
import { Dashboard } from '@uppy/react';
import '@uppy/url/dist/style.css';

interface InlineUploaderProps {
  uppyInstance: Uppy;
  height?: number;
  note?: string;
}

export default function InlineUploader({
  uppyInstance,
  height,
  note = 'Image max size 30MB. Video max size 1GB.'
}: InlineUploaderProps) {
  return (
    <Dashboard
      uppy={uppyInstance}
      plugins={['ImageEditor', 'Url', 'Dropbox', 'GoogleDrive', 'Canva']}
      showProgressDetails
      proudlyDisplayPoweredByUppy={false}
      showRemoveButtonAfterComplete
      doneButtonHandler={null as any}
      autoOpenFileEditor
      height={height}
      note={note}
    />
  );
}
