import React from 'react';

import { Form } from 'react-final-form';

import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Typography
} from '@material-ui/core';

import { FORM_ERROR } from 'final-form';
import { Autocomplete, makeValidate } from 'mui-rff';
import { useSnackbar } from 'notistack';
import * as Yup from 'yup';

import {
  useFacebookCampaignIdsQuery,
  useImportCampaignMutation,
  useRemoteCampaignsQuery
} from 'generated/graphql';

import DialogTitle from 'components/DialogTitle';

import { useHasuraRoleContext } from 'lib/HasuraRoleContext';
import useUserContext from 'lib/hooks/useUserContext';

interface ImportCampaignModalProps {
  open: boolean;
  onClose: () => void;
}

const schema = Yup.object({
  facebook_id: Yup.string().required()
}).required();

const validate = makeValidate(schema);

type FormSchema = Yup.InferType<typeof schema>;

const ImportCampaignModal: React.FC<ImportCampaignModalProps> = ({ open, onClose }) => {
  const { workspaceMemberContext } = useHasuraRoleContext();

  const { activeWorkspaceId } = useUserContext();
  const { enqueueSnackbar } = useSnackbar();
  const [importCampaign] = useImportCampaignMutation({
    context: workspaceMemberContext
  });
  const {
    data: currentData,
    loading: currentLoading,
    refetch
  } = useFacebookCampaignIdsQuery({
    variables: {
      workspace_id: activeWorkspaceId!
    },
    context: workspaceMemberContext
  });
  const { data, loading } = useRemoteCampaignsQuery({
    context: workspaceMemberContext
  });

  const handleSubmit = async (values: FormSchema) => {
    try {
      const resp = await importCampaign({
        variables: {
          facebook_id: values.facebook_id,
          workspace_id: activeWorkspaceId!
        }
      });

      refetch();
      enqueueSnackbar(`${resp.data?.importCampaign?.campaign?.name} imported!`, {
        variant: 'success'
      });
      onClose();
    } catch (error: any) {
      return {
        [FORM_ERROR]: error?.message ?? 'Unable to import campaign'
      };
    }
  };

  const isLoading = loading || currentLoading;

  const existing = currentData?.workspace_campaign_facebook?.map((x) => x.facebook_id) ?? [];
  const campaigns = data?.campaigns?.filter((value) => !existing.includes(value.id)) ?? [];

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle onClose={onClose}>Import Existing Campaign</DialogTitle>
      <DialogContent>
        <DialogContentText>Select your campaign to import into Properti.</DialogContentText>
        {isLoading && <CircularProgress />}
        {!isLoading && (
          <Form
            validate={validate}
            onSubmit={handleSubmit}
            render={({ handleSubmit, submitting, submitError }) => {
              if (submitting) {
                return <CircularProgress />;
              }

              return (
                <form onSubmit={handleSubmit}>
                  <Autocomplete
                    name="facebook_id"
                    label="Facebook Campaign"
                    options={campaigns}
                    getOptionLabel={(option: any) => option.name}
                    getOptionValue={(option: any) => option.id}
                  />
                  {submitError && (
                    <Box mt={1}>
                      <Typography variant="caption" color="error" gutterBottom>
                        {submitError}
                      </Typography>
                    </Box>
                  )}
                  <DialogActions>
                    <Button color="primary" onClick={onClose} disabled={submitting}>
                      Cancel
                    </Button>
                    <Button type="submit" color="primary" disabled={submitting}>
                      {submitting ? 'Loading ...' : 'Confirm'}
                    </Button>
                  </DialogActions>
                </form>
              );
            }}
          />
        )}
      </DialogContent>
    </Dialog>
  );
};

export default ImportCampaignModal;
