import { SSO_REDIRECT_PATH_LOCAL_STORAGE_KEY } from 'lib/auth/constants';
import { auth } from 'lib/auth/hbp';

import { SSO_EMAIL_DOMAINS } from '../config/sso';

export function handleSSORedirect(provider: string, redirectPath?: string) {
  if (redirectPath) {
    localStorage.setItem(SSO_REDIRECT_PATH_LOCAL_STORAGE_KEY, redirectPath);
  } else {
    localStorage.removeItem(SSO_REDIRECT_PATH_LOCAL_STORAGE_KEY);
  }

  auth.loginViaProvider(provider);
}

export function findSSODomain(domain: string) {
  const foundDomain = SSO_EMAIL_DOMAINS.find((s) => s.domain === domain);
  if (foundDomain) {
    return foundDomain;
  }

  return null;
}
