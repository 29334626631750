import { useState } from 'react';

import { useLocation, useNavigate } from 'react-router';

import { Box, Tab, Tabs } from '@material-ui/core';

import PageHeader from 'components/PageHeader';

import NotificationsTab from './tabs/Notifications';
import NotificationsPreferencesTab from './tabs/Preferences';

type NotificationTab = '#notifications' | '#preferences';

export default function AccountNotificationsPage() {
  const location = useLocation();
  const navigate = useNavigate();
  const hash = location.hash as NotificationTab;

  const [tab, setTab] = useState<NotificationTab>(hash || '#notifications');

  const handleChangeTab = (_: any, newTab: NotificationTab) => {
    setTab(newTab);
    navigate(newTab);
  };

  return (
    <div>
      <PageHeader title={'Notifications'} />
      <Tabs value={tab} onChange={handleChangeTab}>
        <Tab value="#notifications" label="Notifications" />
        <Tab value="#preferences" label="Preferences" />
      </Tabs>
      <Box>
        {tab === '#notifications' && <NotificationsTab />}
        {tab === '#preferences' && <NotificationsPreferencesTab />}
      </Box>
    </div>
  );
}
