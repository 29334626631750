import { useMemo, useState } from 'react';

import {
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
  IconButton,
  Tooltip,
  Typography
} from '@material-ui/core';
import GroupIcon from '@material-ui/icons/Group';
import ShareIcon from '@material-ui/icons/ShareOutlined';

import BullhornIcon from 'mdi-material-ui/BullhornOutline';
import CommentEditIcon from 'mdi-material-ui/CommentEditOutline';
import { useSnackbar } from 'notistack';

import {
  CreativeByPkQuery,
  useCreateCanvaDesignReturnRedirectUrlMutation,
  Workspace_Template_Engine_Providers_Enum
} from 'generated/graphql';

import ShareDrawer from 'components/ShareDrawer';
import CampaignFromListingOrCreativeDialog from 'components/campaigns/templates/CampaignFromListingOrCreativeDialog';
import CanvaIcon from 'components/icons/CanvaIcon';
import { ShareLogicVariants } from 'components/types';
import AssignAgentsAssetDialog from 'components/workflows/AssignAgentsAssetDialog';

import { useHasuraRoleContext } from 'lib/HasuraRoleContext';
import useAssignAgentsAsset from 'lib/hooks/useAssignAgentsAsset';
import useUserContext from 'lib/hooks/useUserContext';

import EditCreativeDrawer from '../EditCreativeDrawer';
import ResponsiveCreativePreviewDialog from '../ResponsiveCreativePreviewDialog';
import TagHolder, { Tag } from '../TagHolder';
import useImageCreativeCardStyles from './_styles';

interface ImageCreativeCardProps {
  id: string;
  title: string;
  date: string;
  tags?: Tag[];
  onRefetchRequired?: () => void;
  ready?: boolean;
  disableActionButtons?: boolean;
  images: Exclude<
    Exclude<CreativeByPkQuery['creative'], null | undefined>['images'],
    null | undefined
  >;
  listingId?: string;
  createdByUserId?: string | null;
}

export default function ImageCreativeCard({
  id,
  title,
  date,
  tags,
  ready,
  images,
  listingId,
  disableActionButtons,
  onRefetchRequired,
  createdByUserId
}: ImageCreativeCardProps) {
  const { userId, activeWorkspaceId, isWorkspaceAdmin } = useUserContext();
  const { workspaceMemberContext } = useHasuraRoleContext();
  const { enqueueSnackbar } = useSnackbar();

  const classes = useImageCreativeCardStyles();
  const [previewOpen, setPreviewOpen] = useState<boolean>(false);
  const [editOpen, setEditOpen] = useState<boolean>(false);
  const [shareOpen, setShareOpen] = useState<boolean>(false);

  const [boostModelOpen, setBoostModelOpen] = useState(false);

  const [createCanvaDesignRedirectUrl] = useCreateCanvaDesignReturnRedirectUrlMutation({
    context: workspaceMemberContext
  });

  const {
    assignAgentAssetDialogOpen,
    handleOpenAssignAgentsAssetDialog,
    handleCloseAssignAgentsAssetDialog
  } = useAssignAgentsAsset(onRefetchRequired);

  const handleClickCard = () => {
    setPreviewOpen(true);
  };

  const handleOpenEditDrawer = () => {
    setEditOpen(true);
  };

  const handleCloseEditDrawer = () => {
    setEditOpen(false);
    if (onRefetchRequired) {
      onRefetchRequired();
    }
  };

  const handleClosePreview = () => {
    setPreviewOpen(false);
  };

  const handleOpenShareDrawer = () => {
    setShareOpen(true);
  };

  const handleCloseShareDrawer = () => {
    setShareOpen(false);
  };

  const handleOpenCreateFromTemplateModal = () => {
    setBoostModelOpen(true);
  };

  const handleCloseCreateFromTemplateModal = () => {
    setBoostModelOpen(false);
  };

  const mainImage = images ? images[0] : null;
  const mainImageUrl = mainImage?.image_url_jpg ?? '/videos/loading.jpg';

  const currentWorkspaceUrn = useMemo(() => `workspace:${activeWorkspaceId}`, [activeWorkspaceId]);

  // Advanced images may have images rendered by different images.
  // Lets only enable return navigation on full Canva engine images.
  const isCanvaEditingAvailable = images.every(
    (image) =>
      image.engine === Workspace_Template_Engine_Providers_Enum.Canva &&
      // If multiple designs have been stitched together for a single creative
      // we cannot provide editing.
      // e.g. 1 design for each listing in an automation.
      image.engine_asset_id === images[0].engine_asset_id &&
      // Workspace must be using their own connection, not the head office connection
      image.engine_owner_urn === currentWorkspaceUrn
  );

  const handleCanvaReturnNavigation = async () => {
    if (!isCanvaEditingAvailable) {
      enqueueSnackbar('Canva editing not available on this creative', { variant: 'error' });
    }

    try {
      const { data } = await createCanvaDesignRedirectUrl({
        variables: {
          args: { creative_id: id }
        }
      });
      if (data?.createCanvaDesignReturnNavigationRedirectUrl?.design_redirect_url) {
        window.location.href =
          data?.createCanvaDesignReturnNavigationRedirectUrl?.design_redirect_url;
      } else {
        throw new Error('Unable to build edit URL');
      }
    } catch (error: any) {
      enqueueSnackbar(error?.message ?? 'Unable to build Canva edit URL, please try again', {
        variant: 'error'
      });
    }
  };

  return (
    <>
      <Card className={classes.root} elevation={2}>
        <CardActionArea disabled={!ready} onClick={handleClickCard}>
          <CardMedia className={classes.media} title={title} image={mainImageUrl}>
            <TagHolder tags={tags} />
          </CardMedia>
        </CardActionArea>
        <CardContent>
          <Typography variant="overline">{date}</Typography>
          <Typography variant="h6">{title}</Typography>
        </CardContent>
        <CardActions>
          <Tooltip title="Share Post">
            <IconButton
              aria-label="share post"
              onClick={handleOpenShareDrawer}
              disabled={disableActionButtons || !ready}
            >
              <ShareIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Boost Creative">
            <IconButton
              aria-label="boost creative"
              onClick={handleOpenCreateFromTemplateModal}
              disabled={disableActionButtons || !ready}
            >
              <BullhornIcon />
            </IconButton>
          </Tooltip>
          {isCanvaEditingAvailable && (
            <Tooltip title="Edit on Canva">
              <IconButton
                aria-label="Edit on Canva"
                disabled={disableActionButtons || !isCanvaEditingAvailable}
                onClick={handleCanvaReturnNavigation}
              >
                <CanvaIcon />
              </IconButton>
            </Tooltip>
          )}
          <Tooltip style={{ marginLeft: 'auto' }} title="Assign Agents">
            <IconButton
              aria-label="Assign Agents"
              onClick={() => handleOpenAssignAgentsAssetDialog(id)}
              disabled={disableActionButtons || (createdByUserId !== userId && !isWorkspaceAdmin)}
            >
              <GroupIcon />
            </IconButton>
          </Tooltip>

          <Tooltip title="Edit Ad Copy">
            <IconButton
              onClick={handleOpenEditDrawer}
              aria-label="Edit Ad Copy"
              disabled={disableActionButtons}
            >
              <CommentEditIcon />
            </IconButton>
          </Tooltip>
        </CardActions>
      </Card>
      <EditCreativeDrawer creativeId={id} open={editOpen} onClose={handleCloseEditDrawer} />
      <ResponsiveCreativePreviewDialog
        id={id}
        open={previewOpen}
        title={title}
        onClose={handleClosePreview}
        imageUrls={images.map((image) => image.image_url_jpg!)}
      />
      {!disableActionButtons && (
        <ShareDrawer
          open={shareOpen}
          onClose={handleCloseShareDrawer}
          title={title}
          variant={ShareLogicVariants.CREATIVE}
          creativeId={id}
          listingId={listingId}
          disabled={disableActionButtons}
        />
      )}
      <CampaignFromListingOrCreativeDialog
        open={boostModelOpen}
        onClose={handleCloseCreateFromTemplateModal}
        creativeId={id}
      />
      <AssignAgentsAssetDialog
        open={assignAgentAssetDialogOpen}
        onClose={handleCloseAssignAgentsAssetDialog}
        assetId={id}
        assetType="creative"
      />
    </>
  );
}
