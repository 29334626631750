import { Link } from 'react-router-dom';

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText
} from '@material-ui/core';

import { useHideOnboardingMutation } from 'generated/graphql';

import DialogTitle from 'components/DialogTitle';

import { useHasuraRoleContext } from 'lib/HasuraRoleContext';
import useUserContext from 'lib/hooks/useUserContext';

interface WorkspaceOnboardingDialogProps {
  open: boolean;
  onClose: () => void;
}

export default function WorkspaceOnboardingDialog({
  open,
  onClose
}: WorkspaceOnboardingDialogProps) {
  const { activeWorkspaceId } = useUserContext();
  const { workspaceMemberContext } = useHasuraRoleContext();

  const [hideOnboarding] = useHideOnboardingMutation({
    context: workspaceMemberContext!
  });

  const handleCloseWithDoNotShowAgain = async () => {
    try {
      await hideOnboarding({
        variables: {
          workspace_id: activeWorkspaceId!,
          hide_onboarding: true
        }
      });
    } catch (error: any) {
      console.warn(error);
    }
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle title="Welcome to Properti" onClose={onClose}>
        Get started with Properti
      </DialogTitle>

      <DialogContent>
        <DialogContentText>
          Welcome to Properti, we have a quick start guide to get you started quicker! This guide
          will walk you through:
          <ul>
            <li>Choosing your features</li>
            <li>Adding a payment method</li>
            <li>Connecting your Facebook page</li>
          </ul>
          <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 8 }}>
            <Button
              variant="contained"
              color="primary"
              size="large"
              fullWidth
              style={{ maxWidth: 240 }}
              component={Link}
              to="/quick-start"
            >
              Get started now
            </Button>
          </Box>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button color="secondary" onClick={handleCloseWithDoNotShowAgain}>
          Don't show again
        </Button>
      </DialogActions>
    </Dialog>
  );
}
