import { makeStyles } from '@material-ui/core/styles';

const useAccountConnectionStyles = makeStyles((theme) => ({
  container: {
    minHeight: 100,
    padding: theme.spacing(1.5)
  },
  mainContentBox: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  extraInfoBox: {
    marginTop: theme.spacing(1)
  }
}));

export default useAccountConnectionStyles;
