import EmailIcon from '@material-ui/icons/Email';
import NotificationsIcon from '@material-ui/icons/Notifications';

export default function NotificationChannelIcon({ channel }: { channel: string }) {
  if (channel === 'email') {
    return <EmailIcon />;
  } else if (channel === 'inbox') {
    return <NotificationsIcon />;
  }

  return null;
}
