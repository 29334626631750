import intersection from 'lodash/intersection';

import { Workspace_Creative_Types_Enum } from 'generated/graphql';

import { APP_CONFIG } from 'lib/app/config';
import { getFilePath } from 'lib/auth/hbp';

import { Objective } from './objective';

export enum CreativeSource {
  CUSTOM = 'custom',
  EXISTING_POST = 'existing_post',
  REALSHORTZ_VIDEO = 'realshortz_video'
}

type Platform = 'facebook' | 'googleads';

export const ALL_SOURCES = [
  CreativeSource.CUSTOM,
  CreativeSource.EXISTING_POST,
  CreativeSource.REALSHORTZ_VIDEO
];
export const CREATIVE_SOURCE = [CreativeSource.CUSTOM];

export const ANY_CREATIVE = [
  Workspace_Creative_Types_Enum.Image,
  Workspace_Creative_Types_Enum.Video,
  Workspace_Creative_Types_Enum.ManualVideo,
  Workspace_Creative_Types_Enum.Manual,
  Workspace_Creative_Types_Enum.Responsive,
  Workspace_Creative_Types_Enum.Carousel,
  Workspace_Creative_Types_Enum.Dynamic
];

export const STATIC_CREATIVE = [
  Workspace_Creative_Types_Enum.Video,
  Workspace_Creative_Types_Enum.ManualVideo,
  Workspace_Creative_Types_Enum.Image,
  Workspace_Creative_Types_Enum.Manual,
  Workspace_Creative_Types_Enum.Carousel
];

export const PLATFORM_SUPPORTED_CREATIVE = {
  facebook: {
    sources: [CreativeSource.CUSTOM, CreativeSource.EXISTING_POST, CreativeSource.REALSHORTZ_VIDEO],
    creativeTypes: ANY_CREATIVE
  },
  googleads: {
    sources: [CreativeSource.CUSTOM],
    creativeTypes: [
      Workspace_Creative_Types_Enum.Image,
      Workspace_Creative_Types_Enum.Manual,
      Workspace_Creative_Types_Enum.Responsive
    ]
  }
} as const;

export const SUPPORTED_SOURCES_BY_OBJECTIVE: {
  [key: string]: CreativeSource[];
} = {
  reach: ALL_SOURCES,
  brand_awareness: ALL_SOURCES,
  conversions: ALL_SOURCES,
  engagement: ALL_SOURCES,
  website_visits: ALL_SOURCES,
  video_views: ALL_SOURCES,
  messages: CREATIVE_SOURCE,
  leadgen: CREATIVE_SOURCE,
  phone_call: CREATIVE_SOURCE
};

export const SUPPORTED_CREATIVE_TYPES_BY_OBJECTIVE: {
  [key: string]: Workspace_Creative_Types_Enum[];
} = {
  reach: ANY_CREATIVE,
  brand_awareness: ANY_CREATIVE,
  conversions: ANY_CREATIVE,
  engagement: STATIC_CREATIVE.filter(
    (creative) => creative !== Workspace_Creative_Types_Enum.Carousel
  ),
  messages: STATIC_CREATIVE,
  website_visits: ANY_CREATIVE,
  video_views: [Workspace_Creative_Types_Enum.Video, Workspace_Creative_Types_Enum.ManualVideo],
  leadgen: ANY_CREATIVE,
  phone_call: [Workspace_Creative_Types_Enum.Carousel]
};

export const CREATIVE_FIELD_OPTIONS = {
  [CreativeSource.CUSTOM]: {
    label: `${APP_CONFIG.SITE_TITLE} Creative`,
    value: 'custom'
  },
  [CreativeSource.EXISTING_POST]: {
    label: 'Existing Page Post',
    value: 'existing_post'
  },
  [CreativeSource.REALSHORTZ_VIDEO]: {
    label: 'RealShortz Video',
    value: 'realshortz_video'
  }
};

export function getSupportCreativeSources(objective: Objective, platforms: Platform[]) {
  const supportedCreativeSources = intersection(
    SUPPORTED_SOURCES_BY_OBJECTIVE[objective],
    ...platforms.map((platform) => PLATFORM_SUPPORTED_CREATIVE[platform].sources)
  ).map((type) => CREATIVE_FIELD_OPTIONS[type]);

  return supportedCreativeSources;
}

export function getSupportCreativeTypes(objective: Objective, platforms: Platform[]) {
  const supportedCreativeTypes = intersection(
    SUPPORTED_CREATIVE_TYPES_BY_OBJECTIVE[objective],
    ...platforms.map((platform) => PLATFORM_SUPPORTED_CREATIVE[platform].creativeTypes)
  );

  return supportedCreativeTypes;
}

export function creativeTypeTextFromCreative(creative: any) {
  switch (creative.type) {
    case Workspace_Creative_Types_Enum.Video:
      return 'Video creative';
    case Workspace_Creative_Types_Enum.Image:
      return 'Image creative';
    case Workspace_Creative_Types_Enum.ManualVideo:
      return 'Video creative';
    case Workspace_Creative_Types_Enum.Manual:
      return 'Image creative';
    case Workspace_Creative_Types_Enum.Responsive:
      return 'Responsive creative';
    case Workspace_Creative_Types_Enum.Carousel:
      return 'Carousel creative';
    case Workspace_Creative_Types_Enum.Dynamic:
      return 'Dynamic creative';
    default:
      return 'Creative';
  }
}

const FALLBACK_THUMBNAIL_PATH = '/images/PropertiMasterLogo.png';

export function creativeThumbnailFromCreative(creative: any) {
  switch (creative.type) {
    case Workspace_Creative_Types_Enum.Video:
      return getFilePath(creative.video?.thumbnail_url);
    case Workspace_Creative_Types_Enum.Image:
      return creative.images[0].image_url_jpg as string;
    case Workspace_Creative_Types_Enum.ManualVideo:
      return creative.cloudinary_video?.thumbnail_url;
    case Workspace_Creative_Types_Enum.Dynamic: {
      const attachments = creative.attachments ?? [];
      const attachment = attachments[0];

      if (attachment) {
        if (attachment.content_type.includes('video/')) {
          return getFilePath(attachment.generated_thumbnail_path) ?? FALLBACK_THUMBNAIL_PATH;
        } else {
          return getFilePath(attachment.path);
        }
      }

      return FALLBACK_THUMBNAIL_PATH;
    }
    case Workspace_Creative_Types_Enum.Manual: {
      const attachments = creative.attachments ?? [];
      const attachment = attachments[0];
      if (attachment) {
        return getFilePath(attachment.path, attachment.token);
      }

      return FALLBACK_THUMBNAIL_PATH;
    }
    case Workspace_Creative_Types_Enum.Responsive: {
      const attachment = creative.responsive?.images[0].attachment;
      return (
        getFilePath(attachment?.path, attachment?.token) ??
        creative.responsive?.images[0].src ??
        void 0
      );
    }
    case Workspace_Creative_Types_Enum.Carousel: {
      const attachment = creative.carousel?.items[0].images[0].attachment;
      return (
        getFilePath(attachment?.path, attachment?.token) ??
        creative.carousel?.items[0].images[0].src ??
        void 0
      );
    }
    default:
      return void 0;
  }
}
