import React, { useState } from 'react';

import { Form } from 'react-final-form';

import {
  Button,
  ButtonGroup,
  CircularProgress,
  Drawer,
  IconButton,
  Typography
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Clear';

import { FORM_ERROR } from 'final-form';
import { Autocomplete, makeValidate, TextField } from 'mui-rff';
import { useSnackbar } from 'notistack';
import styled from 'styled-components';
import * as Yup from 'yup';

import { useCreateManualCreativeMutation } from 'generated/graphql';

import FinalFormShareInput from 'components/forms/FinalFormShareInput';
import { Attachment } from 'components/types';

import { useHasuraRoleContext } from 'lib/HasuraRoleContext';
import { uploadAttachments } from 'lib/Uppy';
import useUserContext from 'lib/hooks/useUserContext';

interface ManualCreativeDrawerProps {
  open: boolean;
  onClose: () => void;
  onSaved: () => void;
  defaults?: {
    title?: string;
    message?: string;
    url?: string;
    tags?: string[];
  };
  disableUrl?: boolean;
}

const schema = Yup.object({
  url: Yup.string().url().label('Landing Page URL').nullable(),
  post: Yup.object({
    title: Yup.string().required().label('Title'),
    message: Yup.string().max(13000).required().label('Message'),
    attachments: Yup.array().max(10).label('Attachments')
  }).required(),
  tags: Yup.array().of(Yup.string().required())
}).required();

const validate = makeValidate(schema);

type FormSchema = Yup.InferType<typeof schema>;

const ManualCreativeDrawer: React.FC<ManualCreativeDrawerProps> = ({
  open,
  onClose,
  onSaved,
  defaults,
  disableUrl = false
}) => {
  const { workspaceMemberContext } = useHasuraRoleContext();
  const { activeWorkspaceId } = useUserContext();

  const { enqueueSnackbar } = useSnackbar();
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [createCreative] = useCreateManualCreativeMutation({
    context: workspaceMemberContext
  });

  const handleSubmit = async (values: FormSchema) => {
    setSubmitting(true);
    try {
      if (!activeWorkspaceId) {
        throw new Error('Invalid Workspace ID');
      }

      const postAttachments: Attachment[] = (values.post.attachments as Attachment[]) ?? [];
      const [attachments] = await uploadAttachments(activeWorkspaceId, [postAttachments]);
      const uploadedAttachments = attachments.map((a, idx) => ({
        priority: idx,
        ...a.file
      }));

      await createCreative({
        variables: {
          args: {
            workspace_id: activeWorkspaceId! as string,
            title: values.post.title,
            message: values.post.message,
            url: values.url,
            tags: values.tags,
            attachments: uploadedAttachments
          }
        }
      });

      setSubmitting(false);
      enqueueSnackbar('Manual Creative Created', { variant: 'success' });
      onSaved();
    } catch (error: any) {
      setSubmitting(false);
      return { [FORM_ERROR]: error?.message ?? 'Error uploading creative' };
    }
  };

  let submit: any;
  const handleSubmitBtn = () => {
    if (submit) {
      submit();
    }
  };

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={onClose}
      elevation={2}
      ModalProps={{
        disableEnforceFocus: true
      }}
      BackdropProps={{
        style: {
          backgroundColor: '#333333',
          opacity: 0.9
        }
      }}
    >
      <Container>
        <Top>
          <div>
            <Typography variant="h5" gutterBottom>
              New Creative
            </Typography>
          </div>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Top>
        <Middle>
          <Form
            validate={validate}
            onSubmit={handleSubmit}
            initialValues={{
              url: defaults?.url,
              post: {
                title: defaults?.title,
                message: defaults?.message
              },
              tags: defaults?.tags ?? []
            }}
            render={({ handleSubmit, submitting, submitError }) => {
              submit = handleSubmit;
              if (submitting) {
                return <CircularProgress />;
              }

              return (
                <form onSubmit={handleSubmit}>
                  <div>
                    <FinalFormShareInput
                      workspaceId={activeWorkspaceId!}
                      baseFileUploadPath={`workspace/${activeWorkspaceId}/posts/uploads`}
                      name="post"
                      platform="generic"
                      label="Copy"
                      placeholderText="Start typing your copy here ..."
                      titlePlaceholderText="Name your creative"
                      titleLabel="Title"
                      uppyRestrictions={{
                        allowedFileTypes: ['image/*', 'video/*'],
                        maxFileSize: 1500000000
                      }}
                      uppyFileCheckOptions={{
                        maxVideoSize: 1500000000
                      }}
                      filesLimit={10}
                      uppyNote="Image max size 30MB. Video max size 1GB."
                      showTitleField
                      hideUrl
                      maxMessageLength={1300}
                      rows={10}
                    />
                    <TextField
                      name="url"
                      label="Ad Campaign Landing Page URL"
                      disabled={disableUrl}
                    />
                    <Autocomplete
                      name="tags"
                      label="Creative Tags"
                      placeholder="Enter your tags here"
                      multiple
                      freeSolo
                      options={[]}
                      helperText="Press enter to select tags"
                    />
                  </div>
                  {submitError && (
                    <Typography variant="caption" color="error">
                      {submitError}
                    </Typography>
                  )}
                </form>
              );
            }}
          />
        </Middle>
        <Bottom>
          <ButtonGroup>
            <Button
              variant="contained"
              color="secondary"
              size="large"
              onClick={handleSubmitBtn}
              disabled={submitting}
            >
              Save Ad Creative
            </Button>
          </ButtonGroup>
        </Bottom>
      </Container>
    </Drawer>
  );
};

export default ManualCreativeDrawer;

const Container = styled.div`
  min-width: 560px;
  width: 640px;
  max-width: 640px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const Top = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 16px;
`;

const Middle = styled.div`
  padding: 40px 16px 8px 19px;
  text-align: center;
  flex-grow: 1;
  background-color: #f5f6f8;
  overflow-y: auto;
`;

const Bottom = styled.div`
  padding: 16px;
  display: flex;
  flex-direction: row-reverse;
`;
