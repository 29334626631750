import { useEffect, useState } from 'react';

import { Link } from 'react-router-dom';

import { Button, ButtonGroup, Link as MuiLink } from '@material-ui/core';
import RecentActorsIcon from '@material-ui/icons/RecentActors';

import MaterialTable from '@material-table/core';
import { ExportCsv } from '@material-table/exporters/csv';

import moment from 'moment';
import { useSnackbar } from 'notistack';

import {
  useDeleteLeadMutation,
  useWorkspaceLeadsQuery,
  useWorkspaceTeamMembersQuery,
  Workspace_Leads_Bool_Exp,
  Workspace_Lead_Types_Enum,
  Workspace_Users_Bool_Exp,
  Workspace_Leads_Order_By,
  Order_By,
  WorkspaceLeadsQuery,
  Workspace_Resource_Permission_Modes_Enum
} from 'generated/graphql';

import EmptyStatePage from 'components/EmptyStatePage';
import PageHeader from 'components/PageHeader';
import TableContainer from 'components/TableContainer';
import Toolbar from 'components/Toolbar';
import LeadTabs from 'components/leads/LeadTabs';

import { useHasuraRoleContext } from 'lib/HasuraRoleContext';
import { useAgentPermissions } from 'lib/hooks/useAgentPermissions';
import {
  createFilter,
  createFilterBySearch,
  createOrderBy,
  usePagination
} from 'lib/hooks/useFiltersAndPagination';
import usePlatforms from 'lib/hooks/usePlatforms';
import useUserContext from 'lib/hooks/useUserContext';

const IS_DEV = process.env.NODE_ENV === 'development';
const DEFAULT_URL_PREFIX = 'move';

const useSearchFilter = createFilterBySearch<Workspace_Leads_Bool_Exp>(
  [{ name: {} }, { lead_source: { label: {} } }],
  {
    customReplacer: (str) => str.replace(/\s+/, '%')
  }
);

const useFilterBySource = createFilter<Workspace_Leads_Bool_Exp, string>({
  source: {}
});

const useFilterByType = createFilter<Workspace_Leads_Bool_Exp, Workspace_Lead_Types_Enum>({
  lead_type: {}
});

const useFilterByOwnerId = createFilter<Workspace_Leads_Bool_Exp, string>({
  owner_id: {}
});

const useFilterByPage = createFilter<Workspace_Leads_Bool_Exp, string>({
  facebook_page_id: {}
});

const useOrderBy = createOrderBy<Workspace_Leads_Order_By>(
  [
    {
      created_at: Order_By.Desc
    }
  ],
  [
    'name',
    'created_at',
    'lead_source.label',
    'type.label',
    'facebook_page_id',
    'facebook_page_id',
    'email',
    'number',
    'message',
    'multi_choice_questions',
    'listing.id'
  ]
);

const LeadsPage = () => {
  const { workspaceMemberContext } = useHasuraRoleContext();
  const {
    userId,
    activeWorkspaceId,
    isWorkspaceAdvertiser,
    isWorkspaceAdmin,
    isWorkspaceAgent,
    isAdminMode
  } = useUserContext();
  const agentPermissions = useAgentPermissions();
  const [, , meta] = usePlatforms();

  const { page, setPage, limit, setLimit, offset } = usePagination(10);
  const [filterSearch, search, setSearch] = useSearchFilter();
  const [filterSource, source, setSource] = useFilterBySource();
  const [filterType, type, setType] = useFilterByType();
  const [filterOwnerId, ownerId, setOwnerId] = useFilterByOwnerId();
  const [filterPage, pageId, setPageId] = useFilterByPage();
  const [filtersOpen, setFiltersOpen] = useState(true);

  const [orderBy, handleOnOrderChange] = useOrderBy();

  const { enqueueSnackbar } = useSnackbar();

  const where: Workspace_Leads_Bool_Exp = {
    workspace_id: { _eq: activeWorkspaceId },
    ...filterSearch,
    ...filterSource,
    ...filterType,
    ...filterOwnerId,
    ...filterPage
  };

  const teamWhere: Workspace_Users_Bool_Exp = {
    _and: [{ workspace_id: { _eq: activeWorkspaceId! } }]
  };

  if (isWorkspaceAgent) {
    where._or = [
      {
        listing: {
          agents: { user: { display_name: { _in: agentPermissions.allowed_agents } } }
        }
      },
      {
        campaign: { created_by_id: { _eq: userId } }
      },
      {
        owner_id: { _eq: userId }
      },
      {
        campaign: { facebook_page_id: { _in: agentPermissions.allowed_facebook_pages_leads } }
      },
      {
        leadgen_form: {
          _or: [
            {
              owner_id: { _eq: userId }
            },
            {
              created_by_id: { _eq: userId }
            },
            {
              facebook_page_id: { _in: agentPermissions.allowed_facebook_pages_leads }
            },
            {
              assigned_users: {
                user_id: { _eq: userId }
              }
            },
            {
              agent_permission_mode: { _eq: Workspace_Resource_Permission_Modes_Enum.All }
            }
          ]
        }
      }
    ];

    teamWhere._and!.push({
      _or: [
        {
          user: { display_name: { _in: agentPermissions.allowed_agents } }
        },
        {
          agent_workspace_mappings: {
            listing_agent_name: { _in: agentPermissions.allowed_agents }
          }
        },
        {
          user_id: { _eq: userId! }
        }
      ]
    });
  }

  const { data: teamData } = useWorkspaceTeamMembersQuery({
    variables: {
      where: teamWhere,
      workspace_id: activeWorkspaceId!
    },
    context: workspaceMemberContext
  });

  const { data, loading, refetch } = useWorkspaceLeadsQuery({
    variables: {
      where: where,
      workspace_id: activeWorkspaceId!,
      limit: limit,
      offset: offset,
      order_by: orderBy
    },
    context: workspaceMemberContext
  });
  const [deleteLead] = useDeleteLeadMutation({ context: workspaceMemberContext });

  // Reload when page enters view
  // As we have a SPA, pages enter and leave view without full reloads
  useEffect(() => {
    refetch();
  }, [refetch]);

  const count = data?.count?.aggregate?.count ?? 0;

  const urlPrefix = data?.workspace?.url_prefix ?? DEFAULT_URL_PREFIX;
  const urlPostfix = `p/appraisal`;

  const appraisalPageUrl = IS_DEV
    ? `http://localhost:3005/${urlPostfix}`
    : `https://${urlPrefix}.lp.properti.ai/${urlPostfix}`;

  if (!loading && count === 0 && !search && !source && !type && !ownerId && !pageId) {
    return (
      <EmptyStatePage
        title="Leads"
        icon={<RecentActorsIcon color="secondary" style={{ width: 64, height: 64 }} />}
        text="Leads collected from Properti landing pages and FB leadgen campaigns will appear here"
        button={
          <ButtonGroup variant="contained" color="secondary" size="large">
            <Button component={Link} to="/campaigns" fullWidth>
              Campaigns
            </Button>
            <Button component="a" href={appraisalPageUrl} target="_blank" fullWidth>
              Appraisal Page
            </Button>
          </ButtonGroup>
        }
      />
    );
  }

  const handleOnRowDelete = async (rowData: any) => {
    try {
      await deleteLead({ variables: { lead_id: rowData.id } });
      await refetch();
      enqueueSnackbar('Lead deleted', { variant: 'success' });
    } catch (error) {
      enqueueSnackbar('Unable to delete lead', { variant: 'error' });
    }
  };

  // https://github.com/mbrn/material-table/issues/1979
  const leads = data?.leads?.map((l) => ({ ...l }));

  return (
    <div>
      <PageHeader
        title="Leads"
        subtitle="Incoming leads collected by Properti"
        rightComponent={
          <Button
            component="a"
            href={appraisalPageUrl}
            target="_blank"
            color="secondary"
            variant="contained"
            size="large"
          >
            Appraisal Page
          </Button>
        }
      />

      <LeadTabs currentTab={0} />
      <MaterialTable
        title="Leads"
        columns={[
          {
            title: 'Name',
            field: 'name',
            editable: 'never',
            type: 'string',
            sorting: true
          },
          {
            title: 'Created At',
            field: 'created_at',
            type: 'datetime',
            render: (data) => {
              return (
                <span>
                  {moment(data.created_at).format('D MMM YYYY')}
                  <br />
                  {moment(data.created_at).format('h:mm A')}
                </span>
              );
            },
            sorting: true,
            editable: 'never'
          },
          {
            title: 'Source',
            field: 'source',
            editable: 'never',
            sorting: true,
            render: (data) => data.lead_source.label ?? data.source
          },
          {
            title: 'Type',
            field: 'lead_type',
            editable: 'never',
            sorting: true,
            render: (data) => data.type.label ?? data.lead_type
          },
          {
            title: 'Facebook Page ID',
            field: 'facebook_page_id',
            editable: 'never',
            hidden: true,
            sorting: false
          },
          {
            title: 'Facebook Page',
            field: 'facebook_page_name',
            editable: 'never',
            sorting: false,
            render: (data) => {
              const foundPage = meta.facebook?.allowedPages?.find(
                (page) => page.id === data.facebook_page_id
              );

              if (foundPage && foundPage.display_name) {
                return foundPage.display_name;
              }

              return data.facebook_page_id ?? '-';
            }
          },
          { title: 'Email', field: 'email', editable: 'never', sorting: true },
          {
            title: 'Number',
            field: 'number',
            sorting: true
          },
          {
            title: 'Message',
            field: 'message',
            sorting: true
          },
          {
            title: 'Multiple Choice',
            field: 'multi_choice_questions',
            sorting: false,
            render: (data) => {
              if (!data.multi_choice_questions) {
                return '-';
              }
              const multiChoiceQuestions = data.multi_choice_questions ?? [];

              return multiChoiceQuestions.map((question: any, idx: number) => (
                <p key={idx}>
                  {question.question}: <strong>{question.response}</strong>
                </p>
              ));
            }
          },
          {
            title: 'Listing',
            field: 'listing_id',
            sorting: false,
            render: (data) => {
              if (!data.listing) {
                return '-';
              }
              const address = data?.listing?.location?.address?.sub_number
                ? `${data?.listing?.location?.address?.sub_number}/${data?.listing?.location?.address?.street_number} ${data?.listing?.location?.address?.street}, ${data?.listing?.location?.address?.suburb}`
                : `${data?.listing?.location?.address?.street_number} ${data?.listing?.location?.address?.street}, ${data?.listing?.location?.address?.suburb}`;
              return (
                <MuiLink component={Link} to={`/properties/view/${data?.listing_id}`}>
                  {address}
                </MuiLink>
              );
            }
          }
        ]}
        data={leads ?? []}
        totalCount={count ?? 0}
        options={{
          search: true,
          debounceInterval: 500,
          toolbar: true,
          draggable: false,
          showTitle: false,
          filtering: false,
          actionsColumnIndex: -1,
          pageSize: limit,
          pageSizeOptions: [10, 25, 50, 100, 200, 1000],
          sorting: true,
          exportMenu: [
            {
              label: 'Export CSV',
              exportFunc: (cols, datas: WorkspaceLeadsQuery['leads']) => {
                const fixedDatas = datas.map((data) => {
                  // Facebook Page
                  const foundPage = meta.facebook?.allowedPages?.find(
                    (page) => page.id === data?.facebook_page_id
                  );
                  const foundPageDisplayName = foundPage?.display_name;
                  const facebookPageName = !data.facebook_page_id
                    ? '-'
                    : foundPageDisplayName ?? '-';

                  // Address
                  const address = !data.listing
                    ? '-'
                    : data?.listing?.location?.address?.sub_number
                    ? `${data?.listing?.location?.address?.sub_number}/${data?.listing?.location?.address?.street_number} ${data?.listing?.location?.address?.street}, ${data?.listing?.location?.address?.suburb}`
                    : `${data?.listing?.location?.address?.street_number} ${data?.listing?.location?.address?.street}, ${data?.listing?.location?.address?.suburb}`;

                  // Multi choice questions
                  const multiChoiceQuestions = !data.multi_choice_questions?.length
                    ? '-'
                    : data.multi_choice_questions
                        ?.map(
                          (question: any, idx: number) =>
                            `${question.question}: ${question.response}`
                        )
                        .join('\r\n');

                  return {
                    ...data,
                    facebook_page_name: facebookPageName,
                    multi_choice_questions: multiChoiceQuestions,
                    listing_id: address
                  };
                });

                return ExportCsv(cols, fixedDatas, 'properti-leads');
              }
            }
          ],
          exportAllData: false
        }}
        isLoading={loading}
        editable={{
          onRowDelete: handleOnRowDelete,
          isDeleteHidden: () => !isWorkspaceAdmin && !isWorkspaceAdvertiser && !isAdminMode
        }}
        onRowsPerPageChange={setLimit}
        onPageChange={setPage}
        onOrderChange={handleOnOrderChange}
        page={page}
        components={{
          Container: TableContainer,
          Toolbar: (props) => (
            <Toolbar
              {...props}
              searchValue={search}
              setSearch={setSearch}
              filtersOpen={filtersOpen}
              onToggleFiltersOpen={() => {
                setFiltersOpen(!filtersOpen);
              }}
              menuItems={[
                {
                  name: 'Filter Source',
                  value: source,
                  setValue: setSource,
                  options: [
                    { value: 'properti', text: 'Properti' },
                    { value: 'facebook', text: 'Facebook' },
                    // { value: 'domain', text: 'Domain' },
                    // { value: 'rea', text: 'REA' },
                    { value: 'crm', text: 'CRM' },
                    { value: 'other', text: 'Other' }
                  ],
                  hasAll: true
                },
                {
                  name: 'Filter Type',
                  value: type,
                  setValue: setType,
                  options: [
                    { value: Workspace_Lead_Types_Enum.Appraisal, text: 'Appraisal' },
                    { value: Workspace_Lead_Types_Enum.General, text: 'General' },
                    { value: Workspace_Lead_Types_Enum.Listing, text: 'Listing' },
                    { value: Workspace_Lead_Types_Enum.Promotion, text: 'Promotional' }
                  ],
                  hasAll: true
                },
                {
                  name: 'Filter Facebook Page',
                  value: pageId,
                  setValue: setPageId,
                  options:
                    meta.facebook?.allowedPages?.map((ap) => ({
                      value: ap.id,
                      text: ap.display_name
                    })) ?? [],
                  hasAll: true
                },
                {
                  name: 'Filter owner',
                  value: ownerId,
                  setValue: setOwnerId,
                  options:
                    teamData?.workspace_users?.map((user) => ({
                      value: user.user_id,
                      text: user.user.display_name
                    })) ?? [],
                  hasAll: true
                }
              ]}
            />
          )
        }}
      />
    </div>
  );
};

export default LeadsPage;
