import { CSSProperties } from 'react';

import LoadingIndicator from 'components/LoadingIndicator';

const DIV_STYLE: CSSProperties = {
  minHeight: '85vh',
  backgroundColor: '#fafafa',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
};

export default function LoadingStatePage() {
  return (
    <div style={DIV_STYLE}>
      <LoadingIndicator />
    </div>
  );
}
