import React from 'react';

import {
  Backdrop,
  Fade,
  IconButton,
  Dialog,
  DialogTitle,
  Typography,
  DialogContent,
  Slide,
  useTheme,
  useMediaQuery
} from '@material-ui/core';
import { TransitionProps } from '@material-ui/core/transitions';
import CloseIcon from '@material-ui/icons/Close';

import { CreativeTypeEnum } from 'generated/graphql';

import useTemplatePreviewStyles from './_styles';

export interface TemplatePreviewProps {
  preview_url?: string | null;
  template_name?: string | null;
  open: boolean;
  onClose: () => void;
  type?: CreativeTypeEnum;
}

const FadeTransition = React.forwardRef<any, TransitionProps>(function (props, ref) {
  // @ts-ignore
  return <Fade ref={ref} {...props} />;
});

const SlideTransition = React.forwardRef<any, TransitionProps>(function (props, ref) {
  // @ts-ignore
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function TemplatePreview({
  preview_url,
  template_name,
  open,
  onClose,
  type
}: TemplatePreviewProps) {
  const classes = useTemplatePreviewStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  if (!preview_url || !template_name || !type) {
    return null;
  }

  return (
    <Dialog
      open={open}
      fullScreen={fullScreen}
      TransitionComponent={fullScreen ? SlideTransition : FadeTransition}
      BackdropComponent={Backdrop}
      closeAfterTransition
      onClose={onClose}
      BackdropProps={{
        timeout: 250
      }}
    >
      <DialogTitle disableTypography className={classes.title}>
        <Typography variant="h6">{`${template_name ?? 'Template'} preview`}</Typography>
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent className={classes.content}>
        {type === CreativeTypeEnum.Image || type === CreativeTypeEnum.ImageSet ? (
          <img
            className={classes.media}
            src={preview_url}
            alt={`${template_name ?? 'Template'} preview`}
            style={{ maxHeight: 720 }}
          />
        ) : (
          <video
            autoPlay={true}
            controls
            className={classes.media}
            height="100%"
            style={{ maxHeight: 720 }}
          >
            <source src={preview_url} type="video/mp4" />
          </video>
        )}
      </DialogContent>
    </Dialog>
  );
}
