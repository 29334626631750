import { useState } from 'react';

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Typography
} from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

import DialogTitle from 'components/DialogTitle';
import DividerWithText from 'components/DividerWithText';
import GoogleGIcon from 'components/icons/GoogleGIcon';
import LJHookerIcon from 'components/icons/LJHookerIcon';

import useAuthStyles from '../_styles';
import { handleSSORedirect } from '../utils/sso';

interface SSOLoginOptionsProps {
  redirectPath?: string;
}

export default function SSOLoginOptions({ redirectPath }: SSOLoginOptionsProps) {
  const classes = useAuthStyles();

  const [extraOptionsOpen, setExtraOptionsOpen] = useState(false);

  const handleOpenExtraOptions = () => {
    setExtraOptionsOpen(true);
  };

  const handleCloseExtraOptions = () => {
    setExtraOptionsOpen(false);
  };

  const handleGoogleOAuthLogin = () => {
    handleSSORedirect('google', redirectPath);
  };

  const handleLJHookerAuthLogin = () => {
    handleSSORedirect('ljhooker', redirectPath);
  };

  return (
    <>
      <Box>
        <DividerWithText>
          <Typography variant="overline">Or</Typography>
        </DividerWithText>
        <Box>
          <Button
            size="large"
            variant="outlined"
            className={classes.googleButton}
            fullWidth
            onClick={handleGoogleOAuthLogin}
            startIcon={<GoogleGIcon className={classes.googleIcon} />}
          >
            Continue with Google
          </Button>
        </Box>
        <Box mt={2}>
          <Button
            size="large"
            variant="outlined"
            fullWidth
            endIcon={<ArrowDropDownIcon />}
            onClick={handleOpenExtraOptions}
          >
            Use Single Sign On
          </Button>
        </Box>
      </Box>
      <Dialog
        open={extraOptionsOpen}
        onClose={handleCloseExtraOptions}
        fullWidth
        maxWidth="sm"
        PaperProps={{
          style: { minHeight: 400 }
        }}
      >
        <DialogTitle onClose={handleCloseExtraOptions}>Enterprise SSO Options</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Choose you organization's identity provider below. If you are unsure whether your
            organization supports using single sign-on, reach out to your administrator.
          </DialogContentText>
          <Box mt={2}>
            <LJHookerSSOButton onClick={handleLJHookerAuthLogin} />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseExtraOptions}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

interface LJHookerSSOButtonProps {
  onClick: () => void;
  disabled?: boolean;
}

function LJHookerSSOButton({ onClick, disabled = false }: LJHookerSSOButtonProps) {
  const classes = useAuthStyles();

  return (
    <Button
      size="large"
      variant="outlined"
      fullWidth
      onClick={onClick}
      startIcon={<LJHookerIcon className={classes.googleIcon} />}
      disabled={disabled}
    >
      Continue with LJ Hooker SSO
    </Button>
  );
}

interface ContinueWithSSOButtonProps {
  provider: string;
  disabled?: boolean;
  redirectPath?: string;
  color?: 'secondary' | 'primary' | 'default' | 'inherit';
}

export function ContinueWithSSOButton({
  provider,
  disabled = false,
  color = 'secondary',
  redirectPath
}: ContinueWithSSOButtonProps) {
  const classes = useAuthStyles();

  if (provider === 'ljhooker') {
    return <LJHookerSSOButton onClick={() => handleSSORedirect(provider, redirectPath)} />;
  }

  return (
    <Button
      onClick={() => handleSSORedirect(provider, redirectPath)}
      fullWidth
      size="large"
      variant="contained"
      color={color}
      className={classes.submit}
      disabled={disabled}
    >
      Continue with Single Sign On
    </Button>
  );
}
