import { useCallback, useEffect, useState } from 'react';

import { Virtuoso } from 'react-virtuoso';

import { Box, Button, CircularProgress, List, Paper, Typography } from '@material-ui/core';

import { useNotifications, useUnseenCount } from '@suprsend/react-inbox';

import ListFooter from 'components/Notifications/NotificationListFooter';
import NotificationListItem from 'components/Notifications/NotificationListItem';
import NotificationsEmptyState from 'components/Notifications/NotificationsEmptyState';

export default function NotificationsTab() {
  const [loadingBuffer, setLoadingBuffer] = useState<boolean>(true);

  const { unSeenCount } = useUnseenCount();
  const {
    notifications,
    hasNext,
    markAllRead,
    markAllSeen,
    initialLoading,
    fetchPrevious,
    markClicked
  } = useNotifications();

  const handleOnEndReached = useCallback(() => {
    if (hasNext) {
      fetchPrevious();
    }
  }, [hasNext, fetchPrevious]);

  const handleClearNotifications = useCallback(() => {
    markAllRead();
    markAllSeen();
  }, [markAllRead, markAllSeen]);

  useEffect(() => {
    // SuprSend initialLoading starts as false, but needs to load
    // Lets add a one second automatic buffer to avoid showing
    // No notification message at the first render
    const to = setTimeout(() => {
      setLoadingBuffer(false);
    }, 250);

    return () => {
      clearTimeout(to);
    };
  }, [setLoadingBuffer]);

  if (initialLoading || loadingBuffer) {
    return <CircularProgress />;
  }

  return (
    <Paper>
      <Box pl={2} height={60} display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="h6">
          {unSeenCount === 0 ? 'No' : unSeenCount} unseen notifications
        </Typography>
        <Button variant="text" color="primary" onClick={handleClearNotifications}>
          Mark all as read
        </Button>
      </Box>
      <List>
        <Virtuoso
          style={{ height: '70vh' }}
          data={notifications}
          endReached={handleOnEndReached}
          overscan={200}
          itemContent={(_index, notification) => {
            return (
              <NotificationListItem
                key={notification.n_id}
                notification={notification}
                markClicked={markClicked}
                closePopoverState={() => {
                  // noop in full screen notifications
                }}
              />
            );
          }}
          components={{
            EmptyPlaceholder: NotificationsEmptyState,
            Footer: ListFooter
          }}
        />
      </List>
    </Paper>
  );
}
