import { useEffect, useState } from 'react';

import { Link, useNavigate } from 'react-router-dom';

import { Avatar, Link as MuiLink } from '@material-ui/core';
import HouseIcon from '@material-ui/icons/House';

import MaterialTable from '@material-table/core';

import BullhornIcon from 'mdi-material-ui/BullhornOutline';
import { useSnackbar } from 'notistack';
import numbro from 'numbro';

import {
  useDistinctListingAgentsQuery,
  useListingsQuery,
  Workspace_Listing_Types_Enum,
  Workspace_Listings_Bool_Exp,
  useDeleteListingMutation,
  Workspace_Listing_Commercial_Listing_Types_Enum_Comparison_Exp,
  Workspace_Listing_Commercial_Listing_Types_Enum
} from 'generated/graphql';

import ShareDrawer from 'components/ShareDrawer';
import StatusIndicator, { Status } from 'components/StatusIndicator';
import TableContainer from 'components/TableContainer';
import Toolbar from 'components/Toolbar';
import CampaignFromListingOrCreativeDialog from 'components/campaigns/templates/CampaignFromListingOrCreativeDialog';
import { ShareLogicVariants } from 'components/types';

import { buildThumbnailUrl } from 'lib/Cloudinary';
import { useHasuraRoleContext } from 'lib/HasuraRoleContext';
import { getFilePath } from 'lib/auth/hbp';
import { useAgentPermissions } from 'lib/hooks/useAgentPermissions';
import {
  createFilter,
  createFilterBySearch,
  usePagination
} from 'lib/hooks/useFiltersAndPagination';
import useLocalStorage from 'lib/hooks/useLocalStorage';
import useUserContext from 'lib/hooks/useUserContext';

const useFilterBySearch = createFilterBySearch<Workspace_Listings_Bool_Exp>(
  [
    { status: {} },
    { location: { address: { formatted_address: {} } } },
    { location: { address: { suburb: {} } } },
    { location: { address: { street: {} } } },
    { location: { address: { state: {} } } },
    { location: { address: { full_address: {} } } },
    { agents: { user: { display_name: {} } } },
    { headline: {} }
  ],
  { customReplacer: (str) => str.replace(/\s/g, '%') }
);

const useFilterByStatus = createFilter<Workspace_Listings_Bool_Exp>({
  status: {}
});

const useFilterByType = createFilter<Workspace_Listings_Bool_Exp, Workspace_Listing_Types_Enum>({
  type: {}
});

const useFilterBySource = createFilter<Workspace_Listings_Bool_Exp, string>({
  source: {}
});

const useFilterByUnderOffer = createFilter<Workspace_Listings_Bool_Exp, boolean>({
  under_offer: {}
});

const useFilterByCommercialListingType = createFilter<
  Workspace_Listings_Bool_Exp,
  Workspace_Listing_Commercial_Listing_Types_Enum_Comparison_Exp
>({
  commercial_listing_type: {}
});

const lookup = {
  current: { status: Status.ACTIVE, text: 'Current' },
  withdrawn: { status: Status.ARCHIVED, text: 'Withdrawn' },
  sold: { status: Status.FINISHED, text: 'Sold' },
  offmarket: { status: Status.DRAFT, text: 'Off Market' },
  deleted: { status: Status.FINISHED, text: 'Deleted' },
  leased: { status: Status.FINISHED, text: 'Leased' }
};

const ListingsTab = () => {
  const { workspaceMemberContext } = useHasuraRoleContext();
  const { isWorkspaceAnalyst, activeWorkspaceId, isWorkspaceAgent } = useUserContext();
  const agentPermissions = useAgentPermissions();
  const { page, offset, limit, setLimit, setPage } = usePagination();
  const [filterSearch, search, setSearch] = useFilterBySearch();

  const [listingId, setListingId] = useState<string>();
  const [shareOpen, setShareOpen] = useState(false);

  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  // Filters
  const [filterStatus, status, setStatus] = useFilterByStatus();
  const [filterType, type, setType] = useFilterByType();
  const [filterAgentName, setFilterAgentName] = useLocalStorage(
    `properti.${activeWorkspaceId}.listings.filter.agent`
  );
  const [filterUnderOffer, underOffer, setUnderOffer] = useFilterByUnderOffer();
  const [filterSource, source, setSource] = useFilterBySource();
  const [filterCommercialListingType, CommercialListingType, setCommercialListingType] =
    useFilterByCommercialListingType();
  const [filtersOpen, setFiltersOpen] = useState(Boolean(filterAgentName) ?? false);
  const [boostListingId, setBoostListingId] = useState<string>();
  const [boostModelOpen, setBoostModelOpen] = useState(false);

  useEffect(() => {
    if (CommercialListingType) {
      setType(Workspace_Listing_Types_Enum.Commercial);
    }
  }, [CommercialListingType, setType]);

  const filterAgents: Workspace_Listings_Bool_Exp = {
    agents: {
      _and: [{ user: { display_name: { _eq: filterAgentName } } }]
    }
  };

  if (isWorkspaceAgent) {
    filterAgents.agents?._and?.push({
      user: { display_name: { _in: agentPermissions?.allowed_agents } }
    });
  }

  const where: Workspace_Listings_Bool_Exp = {
    workspace_id: { _eq: activeWorkspaceId! },
    ...filterStatus,
    ...filterType,
    ...filterSource,
    ...filterAgents,
    ...filterUnderOffer,
    ...filterSearch,
    ...filterCommercialListingType
  };

  const { data: agentsData } = useDistinctListingAgentsQuery({
    variables: {
      workspace_id: activeWorkspaceId!
    },
    skip: !activeWorkspaceId,
    context: workspaceMemberContext
  });

  const { data, loading, refetch } = useListingsQuery({
    variables: {
      where,
      limit,
      offset
    },
    context: workspaceMemberContext
  });

  // Reload when page enters view
  // As we have a SPA, pages enter and leave view without full reloads
  useEffect(() => {
    refetch();
  }, [refetch]);

  const [deleteListing] = useDeleteListingMutation({
    context: workspaceMemberContext
  });

  const handleCloseShareDrawer = () => {
    setShareOpen(false);
  };

  const handleEdit = (rowData: { id: string }) => {
    navigate(`/properties/listing/edit/${rowData.id}`);
  };

  const handleDelete = async (id: string) => {
    try {
      await deleteListing({ variables: { id } });
      enqueueSnackbar('Listing deleted', { variant: 'success' });
    } catch (error) {
      enqueueSnackbar('Unable to delete listing', { variant: 'error' });
    }
    await refetch();
  };

  const handleOpenCreateFromTemplateModal = (listingId: string) => {
    setBoostListingId(listingId);
    setBoostModelOpen(true);
  };

  const handleCloseCreateFromTemplateModal = () => {
    refetch();
    setBoostModelOpen(false);
  };

  // https://github.com/mbrn/material-table/issues/1979
  const listings = data?.listings?.map((l) => ({ ...l }));

  return (
    <>
      <MaterialTable
        title="Properties"
        columns={[
          {
            title: 'Address',
            field: 'address.full_address',
            filtering: false,
            render: (rowData) => {
              const { sub_number, street_number, street, suburb } = rowData.location.address;
              let address = `${street_number ?? ''} ${street}, ${suburb}`;
              if (sub_number) {
                address = `${sub_number}/${street_number} ${street}, ${suburb}`;
              }

              const mainImage = rowData.images[0];

              const imageUrl =
                mainImage?.image?.url ??
                getFilePath(mainImage?.image?.path, mainImage?.image?.token);
              const thumbnailUrl = imageUrl ? buildThumbnailUrl(imageUrl, 200, 200) : undefined;

              return (
                <span
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'flex-start'
                  }}
                >
                  <Avatar variant="rounded" src={thumbnailUrl} style={{ width: 45, height: 45 }}>
                    <HouseIcon />
                  </Avatar>
                  <MuiLink style={{ marginLeft: 16 }} component={Link} to={`view/${rowData.id}`}>
                    {address}
                  </MuiLink>
                </span>
              );
            }
          },
          {
            title: 'Status',
            field: 'status',
            filterPlaceholder: 'Filter',
            render: (rowData) => {
              const status = rowData.status as
                | 'current'
                | 'withdrawn'
                | 'sold'
                | 'offmarket'
                | 'deleted'
                | 'leased';
              const found = lookup[status];

              if (!found) {
                return rowData.status;
              }

              return <StatusIndicator status={found.status} text={found.text} />;
            }
          },
          {
            title: 'Under Offer',
            field: 'under_offer',
            render: (rowData) => {
              return rowData?.under_offer ? 'Yes' : 'No';
            }
          },
          {
            title: 'Type',
            field: 'type',
            filterPlaceholder: 'Filter',
            lookup: {
              residential: 'Residential',
              rental: 'Rental',
              land: 'Land',
              rural: 'Rural',
              commercial: 'Commercial',
              business: 'Business'
            }
          },
          {
            title: 'Primary Agent',
            field: 'agents[0].user.display_name',
            filtering: false
          },
          {
            title: 'Price',
            field: 'price_view',
            filtering: false,
            render: (rowData) => {
              if (rowData.price_view) {
                return rowData.price_view;
              }

              if (rowData.type === Workspace_Listing_Types_Enum.Rental) {
                return `${numbro(rowData?.rent?.value).formatCurrency({
                  mantissa: 0,
                  thousandSeparated: true
                })} ${rowData?.rent?.period}`;
              }

              return '';
            }
          }
        ]}
        data={listings ?? []}
        totalCount={data?.count.aggregate?.count ?? 0}
        page={page}
        isLoading={loading}
        editable={{
          isEditable: () => false,
          isDeletable: (rowData) => rowData.source === 'manual' && !isWorkspaceAnalyst,
          onRowDelete: async (oldData) => {
            await handleDelete(oldData.id);
          }
        }}
        actions={[
          (rowData) => ({
            icon: 'share',
            tooltip: 'Share on Socials',
            disabled: isWorkspaceAnalyst,
            onClick: () => {
              setListingId(rowData.id);
              setShareOpen(true);
            }
          }),
          (rowData) => ({
            icon: BullhornIcon,
            tooltip: 'Boost Property',
            onClick: () => {
              handleOpenCreateFromTemplateModal(rowData.id);
            },
            disabled: isWorkspaceAnalyst
          }),
          (rowData) => ({
            icon: 'edit',
            tooltip: 'Edit',
            disabled: rowData.source !== 'manual' || isWorkspaceAnalyst,
            onClick: () => handleEdit(rowData)
          })
        ]}
        options={{
          search: true,
          debounceInterval: 500,
          toolbar: true,
          draggable: false,
          showTitle: false,
          filtering: false,
          actionsColumnIndex: -1,
          pageSize: limit,
          columnsButton: true,
          sorting: false
        }}
        localization={{
          body: {
            editRow: {
              deleteText: 'Are you sure you want to delete this listing?'
            }
          }
        }}
        onRowsPerPageChange={(rowsPerPage) => setLimit(rowsPerPage)}
        onPageChange={(newPage) => setPage(newPage)}
        components={{
          Container: TableContainer,
          Toolbar: (props: any) => (
            <Toolbar
              {...props}
              searchValue={search}
              setSearch={setSearch}
              filtersOpen={filtersOpen}
              onToggleFiltersOpen={() => {
                setFiltersOpen(!filtersOpen);
              }}
              menuItems={[
                {
                  name: 'Listing Type',
                  value: type,
                  setValue: setType,
                  options: [
                    { value: Workspace_Listing_Types_Enum.Residential, text: 'Residential' },
                    { value: Workspace_Listing_Types_Enum.Rental, text: 'Rental' },
                    { value: Workspace_Listing_Types_Enum.Land, text: 'Land' },
                    { value: Workspace_Listing_Types_Enum.Rural, text: 'Rural' },
                    { value: Workspace_Listing_Types_Enum.Commercial, text: 'Commercial' },
                    { value: Workspace_Listing_Types_Enum.Business, text: 'Business' }
                  ],
                  hasAll: true
                },
                {
                  name: 'Market Status',
                  value: status,
                  setValue: setStatus,
                  options: [
                    { value: 'current', text: 'Current' },
                    { value: 'offmarket', text: 'Off Market' },
                    { value: 'sold', text: 'Sold' },
                    { value: 'withdrawn', text: 'Withdrawn' },
                    { value: 'leased', text: 'Leased' }
                  ],
                  hasAll: true
                },
                {
                  name: 'Under Offer',
                  value: underOffer,
                  setValue: setUnderOffer,
                  options: [
                    { value: true, text: 'Under Offer' },
                    { value: false, text: 'Not Under Offer' }
                  ],
                  hasAll: true
                },
                {
                  name: 'Commercial Listing Type',
                  value: CommercialListingType,
                  setValue: setCommercialListingType,
                  options: [
                    {
                      value: Workspace_Listing_Commercial_Listing_Types_Enum.Both,
                      text: 'Combined (lease and sale)'
                    },
                    {
                      value: Workspace_Listing_Commercial_Listing_Types_Enum.Lease,
                      text: 'Lease'
                    },
                    {
                      value: Workspace_Listing_Commercial_Listing_Types_Enum.Sale,
                      text: 'Sale'
                    }
                  ],
                  hasAll: true
                },
                {
                  name: 'Agent',
                  value: filterAgentName,
                  setValue: setFilterAgentName,
                  options:
                    agentsData?.agents
                      ?.filter(
                        (agent) =>
                          !isWorkspaceAgent ||
                          agentPermissions?.allowed_agents?.includes(agent.display_name!)
                      )
                      .map((agent) => ({ value: agent.display_name, text: agent.display_name })) ??
                    [],
                  hasAll: true
                },
                {
                  name: 'Source',
                  value: source,
                  setValue: setSource,
                  options: [{ value: 'manual', text: 'Manual Listings' }],
                  hasAll: true,
                  hidden: true
                }
              ]}
            />
          )
        }}
      />
      <ShareDrawer
        open={shareOpen}
        onClose={handleCloseShareDrawer}
        title="Share Listing"
        variant={ShareLogicVariants.LISTING}
        listingId={listingId}
        disabled={isWorkspaceAnalyst}
      />
      <CampaignFromListingOrCreativeDialog
        open={boostModelOpen}
        onClose={handleCloseCreateFromTemplateModal}
        listingId={boostListingId}
      />
    </>
  );
};

export default ListingsTab;
