import React from 'react';

import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  IconButton,
  TextField
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Clear';

import { useSnackbar } from 'notistack';

import { useGenerateCampaignReportExportMutation } from 'generated/graphql';

import DialogTitle from 'components/DialogTitle';

import { useHasuraRoleContext } from 'lib/HasuraRoleContext';
import useUserContext from 'lib/hooks/useUserContext';

interface CampaignReportExportDialogProps {
  agentId?: string;
  open: boolean;
  onClose: () => void;
}

export default function CampaignReportExportDialog({
  agentId,
  open,
  onClose
}: CampaignReportExportDialogProps) {
  const { activeWorkspaceId, info } = useUserContext();
  const { workspaceMemberContext } = useHasuraRoleContext();

  const [generateReport, { loading }] = useGenerateCampaignReportExportMutation({
    context: workspaceMemberContext
  });
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const handleGenerateReport = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    try {
      await generateReport({
        variables: {
          args: {
            workspace_id: activeWorkspaceId!,
            agent_user_id: agentId ?? undefined
          }
        }
      });
      enqueueSnackbar('Report queued to be generated', {
        variant: 'success',
        autoHideDuration: 10000,
        disableWindowBlurListener: true,
        action: (snackbarId) => (
          <>
            <CircularProgress size={26} color="inherit" />
            <IconButton color="inherit" onClick={() => closeSnackbar(snackbarId)}>
              <CloseIcon />
            </IconButton>
          </>
        )
      });
      onClose();
    } catch (error: any) {
      console.error(error);
      enqueueSnackbar('Unable to generate report', { variant: 'error' });
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle onClose={onClose}>Export full campaign report CSV</DialogTitle>
      <DialogContent>
        <DialogContentText>We will email your export once it has been generated</DialogContentText>
        <form onSubmit={handleGenerateReport}>
          <TextField
            name="email"
            type="email"
            label="Your email"
            value={info?.account?.email!}
            disabled
            fullWidth
          />
          <DialogActions>
            <Button onClick={onClose}>Cancel</Button>
            <Button variant="contained" color="primary" type="submit" disabled={loading}>
              {loading ? 'Loading ...' : 'Generate Report'}
            </Button>
          </DialogActions>
        </form>
      </DialogContent>
    </Dialog>
  );
}
