import { useCallback, useEffect, useMemo, useState } from 'react';

import createDecorator from 'final-form-calculate';
import moment from 'moment';
import { makeValidate } from 'mui-rff';
import * as Yup from 'yup';

import {
  CreatePostInput,
  CreativeByPkQuery,
  useCreatePostMutation,
  useCreativeByPkQuery,
  useGetRsVideosByIdQuery,
  useListingByIdQuery,
  usePostByIdQuery,
  useSavePostDraftMutation,
  useUpdatePostDraftMutation,
  useWorkspaceRssContentItemQuery,
  Workspace_Content_Type_Enum,
  Workspace_Creative_Types_Enum,
  Workspace_Post_Page_Selection_Modes_Enum,
  Workspace_Resource_Permission_Modes_Enum
} from 'generated/graphql';

import { getHasuraContextFromSessionStorage, useHasuraRoleContext } from 'lib/HasuraRoleContext';
import { uploadAttachments } from 'lib/Uppy';
import { getFilePath } from 'lib/auth/hbp';
import { buildApolloClient } from 'lib/graphql/apollo';
import { RssContentItemByIdQuery } from 'lib/graphql/queries/workspaces/content';
import { ListingMessageQuery } from 'lib/graphql/queries/workspaces/listings';
import useUserContext from 'lib/hooks/useUserContext';
import {
  HTML5_DATETIME_INPUT_MOMENT_FORMAT,
  VALID_FACEBOOK_POST_TYPES,
  VALID_INSTAGRAM_POST_TYPES
} from 'lib/utils/constants';

import {
  Attachment,
  AttachmentEnum,
  Facebook,
  Maybe,
  Platforms,
  ShareLogicVariants,
  UploadAttachment
} from '../types';
import { listingAttachment } from './utils';

interface UseShareLogicProps {
  title: string;
  variant: ShareLogicVariants;
  listingId?: Maybe<string>;
  creativeId?: Maybe<string>;
  rssContentId?: Maybe<string>;
  realShortzId?: Maybe<string>;
  draftPostId?: Maybe<string>;
  disabled?: boolean;
}

const postingFromSchema = Yup.object({
  id: Yup.string().required(),
  name: Yup.string().nullable(),
  display_name: Yup.string().nullable(),
  avatar_url: Yup.string().nullable(),
  avatar_token: Yup.string().nullable(),
  access_token: Yup.string().nullable()
})
  .optional()
  .nullable();

const facebookSchema = Yup.object({
  posting_from: postingFromSchema.label('Posting from').default(null),
  url: Yup.string().url().nullable(),
  message: Yup.string().max(2000).required().label('Message'),
  attachments: Yup.array().max(10).label('Attachments'),
  post_type: Yup.string()
    .oneOf(VALID_FACEBOOK_POST_TYPES)
    .required()
    .default('feed')
    .label('Post Type')
});

const facebookCarouselSchema = Yup.object({
  posting_from: postingFromSchema.label('Posting from').default(null),
  url: Yup.string().url().nullable(),
  message: Yup.string().max(2000).required().label('Message'),
  attachments: Yup.array().min(2).max(10).label('Attachments')
});

const linkedinSchema = Yup.object({
  posting_from: postingFromSchema.label('Posting from').default(null),
  url: Yup.string().url().nullable(),
  message: Yup.string().max(3000).required().label('Message'),
  attachments: Yup.array().max(10).label('Attachments')
});

const youtubeSchema = Yup.object({
  posting_from: postingFromSchema.label('Posting from').default(null),
  title: Yup.string().max(200).required().label('Title'),
  privacy: Yup.string().oneOf(['public', 'unlisted', 'private']).required().label('Video Privacy'),
  message: Yup.string().max(1300).required().label('Message'),
  attachments: Yup.array().min(1).max(1).required().label('Attachments')
});

const instagramSchema = Yup.object({
  posting_from: postingFromSchema.label('Posting from').default(null),
  message: Yup.string().max(2000).required().label('Message'),
  attachments: Yup.array().min(1).max(10).required().label('Attachments'),
  post_type: Yup.string()
    .oneOf(VALID_INSTAGRAM_POST_TYPES)
    .required()
    .default('feed')
    .label('Post Type')
});

const gmbSchema = Yup.object({
  posting_from: postingFromSchema.label('Posting from').default(null),
  url: Yup.string().url().nullable(),
  message: Yup.string().max(1300).required().label('Message'),
  attachments: Yup.array().max(1).label('Attachments')
});

const schema = Yup.object({
  creative_id: Yup.string()
    .optional()
    .nullable()
    .when('variant', {
      is: (val) => ['creative', 'carousel'].includes(val),
      then: Yup.string()
    }),
  listing_id: Yup.string().optional().nullable().when('variant', {
    is: 'listing',
    then: Yup.string()
  }),
  rss_content_item_id: Yup.string().optional().nullable().when('variant', {
    is: 'content_rss',
    then: Yup.string()
  }),
  realshortz_video_id: Yup.string().optional().nullable().when('variant', {
    is: 'realshortz_video',
    then: Yup.string()
  }),
  facebook: facebookSchema.when('platforms', {
    is: (val) => val.includes('facebook'),
    then: facebookSchema.required().when('variant', {
      is: (val) => ['carousel'].includes(val),
      then: facebookCarouselSchema.required().label('Build your Facebook Post'),
      otherwise: facebookSchema.required()
    }),
    otherwise: Yup.object().nullable(true)
  }),
  linkedin: linkedinSchema.nullable(true).when('platforms', {
    is: (val) => val.includes('linkedin'),
    then: linkedinSchema.required().label('Build your LinkedIn Post'),
    otherwise: Yup.object().nullable(true)
  }),
  linkedin_personal: linkedinSchema.nullable(true).when('platforms', {
    is: (val) => val.includes('linkedin_personal'),
    then: linkedinSchema.required().label('Build your LinkedIn Post'),
    otherwise: Yup.object().nullable(true)
  }),
  instagram: instagramSchema.nullable(true).when('platforms', {
    is: (val) => val.includes('instagram'),
    then: instagramSchema.required('Build your Instagram Post'),
    otherwise: Yup.object().nullable(true)
  }),
  youtube: youtubeSchema.nullable(true).when('platforms', {
    is: (val) => val.includes('youtube'),
    then: youtubeSchema.required().label('Build your YouTube Post'),
    otherwise: Yup.object().nullable(true)
  }),
  gmb: gmbSchema.nullable(true).when('platforms', {
    is: (val) => val.includes('gmb'),
    then: gmbSchema.required().label('Build you Google My Business Post'),
    otherwise: Yup.object().nullable(true)
  }),
  defaults: facebookSchema.nullable(true).label('Defaults'),
  schedule: Yup.string().oneOf(['schedule']).required(),
  schedule_timestamp: Yup.date()
    .min(moment().subtract(10, 'minutes').toDate())
    .required()
    .label('Schedule time'),
  platforms: Yup.array()
    .of(
      Yup.string().oneOf([
        'facebook',
        'youtube',
        'linkedin',
        'linkedin_personal',
        'instagram',
        'gmb'
      ])
    )
    .required(),
  variant: Yup.string()
    .oneOf(['upload', 'creative', 'listing', 'content_rss', 'carousel', 'realshortz'])
    .required(),
  post_name: Yup.string().nullable(),
  content_type: Yup.string()
    .oneOf(Object.values(Workspace_Content_Type_Enum))
    .required('Please select a content category')
    .label('Content Category')
}).required();

export const validate = makeValidate(schema);

export type FormSchema = Yup.InferType<typeof schema>;

const DEFAULT_BULK_PAGE_MULTISELECT = {
  facebook: [] as string[],
  instagram: [] as string[],
  linkedin: [] as string[],
  linkedin_personal: [] as string[],
  gmb: [] as string[],
  youtube: [] as string[]
};

function buildNewPlatformValues(platforms: Platforms, allValues: any) {
  const { defaults, facebook, gmb, linkedin, linkedin_personal, instagram, youtube, variant } =
    allValues;
  const isRSSContent = variant === 'content_rss';

  const useDefaults = isRSSContent ? defaults : null;

  let newFacebook = null;
  if (platforms.includes('facebook')) {
    if (facebook) {
      newFacebook = facebook;
    } else {
      const newFacebookValues = linkedin ?? instagram ?? linkedin_personal ?? gmb ?? youtube;
      newFacebook = {
        message: newFacebookValues?.message ?? useDefaults?.message,
        url: newFacebookValues?.url ?? useDefaults?.url,
        attachments: !isRSSContent ? newFacebookValues?.attachments : undefined,
        post_type: VALID_FACEBOOK_POST_TYPES.includes(newFacebookValues?.post_type)
          ? newFacebookValues.post_type
          : undefined,
        posting_from: undefined
      };
    }
  }

  let newLinkedIn = null;
  if (platforms.includes('linkedin')) {
    if (linkedin) {
      newLinkedIn = linkedin;
    } else {
      const newLinkedInValues = facebook ?? linkedin_personal ?? instagram ?? gmb ?? youtube;
      newLinkedIn = {
        message: newLinkedInValues?.message ?? useDefaults?.message,
        url: newLinkedInValues?.url ?? useDefaults?.url,
        attachments: !isRSSContent ? newLinkedInValues?.attachments : undefined,
        posting_from: undefined
      };
    }
  }

  let newLinkedInPersonal = null;
  if (platforms.includes('linkedin_personal')) {
    if (linkedin_personal) {
      newLinkedInPersonal = linkedin_personal;
    } else {
      const newLinkedInPersonalValues = facebook ?? linkedin ?? instagram ?? gmb ?? youtube;
      newLinkedInPersonal = {
        message: newLinkedInPersonalValues?.message ?? useDefaults?.message,
        url: newLinkedInPersonalValues?.url ?? useDefaults?.url,
        attachments: !isRSSContent ? newLinkedInPersonalValues?.attachments : undefined,
        posting_from: undefined
      };
    }
  }

  let newInstagram = null;
  if (platforms.includes('instagram')) {
    if (instagram) {
      newInstagram = instagram;
    } else {
      const newInstagramValues = facebook ?? linkedin ?? linkedin_personal ?? gmb ?? youtube;
      newInstagram = {
        message: newInstagramValues?.message ?? useDefaults?.message,
        attachments:
          newInstagramValues?.attachments?.slice(0, 10) ?? useDefaults?.attachments?.slice(0, 10),
        post_type: VALID_INSTAGRAM_POST_TYPES.includes(newInstagramValues?.post_type)
          ? newInstagramValues.post_type
          : undefined,
        posting_from: undefined
      };
    }
  }

  let newYouTube = null;
  if (platforms.includes('youtube')) {
    if (youtube) {
      newYouTube = youtube;
    } else {
      const newYouTubeValues = facebook ?? instagram ?? linkedin ?? linkedin_personal;
      newYouTube = {
        message: newYouTubeValues?.message ?? useDefaults?.message,
        title: newYouTubeValues?.title ?? useDefaults?.title,
        privacy: newYouTubeValues?.privacy,
        attachments: newYouTubeValues?.attachments?.slice(0, 1),
        posting_from: undefined
      };
    }
  }

  let newGMB = null;
  if (platforms.includes('gmb')) {
    if (gmb) {
      newGMB = gmb;
    } else {
      const newGMBValues = facebook ?? linkedin ?? linkedin_personal ?? instagram;
      newGMB = {
        message: newGMBValues?.message ?? useDefaults?.message,
        url: newGMBValues?.url ?? useDefaults?.url,
        attachments:
          newGMBValues?.attachments?.slice(0, 1) ?? useDefaults?.attachments?.slice(0, 1),
        posting_from: undefined
      };
    }
  }

  return {
    facebook: newFacebook,
    instagram: newInstagram,
    linkedin: newLinkedIn,
    linkedin_personal: newLinkedInPersonal,
    youtube: newYouTube,
    gmb: newGMB
  };
}

export const shareDrawerDecorator = createDecorator({
  field: 'platforms',
  updates: (platforms: Platforms, _name, allValues: any) => {
    // No changes
    if (platforms.length === 0) {
      return {
        defaults: allValues?.defaults,
        facebook: allValues?.facebook,
        instagram: allValues?.instagram,
        linkedin: allValues?.linkedin,
        linkedin_personal: allValues?.linkedin_personal,
        youtube: allValues?.youtube,
        gmb: allValues?.gmb
      };
    }

    const { defaults } = allValues;

    const { facebook, instagram, linkedin, linkedin_personal, youtube, gmb } =
      buildNewPlatformValues(platforms, allValues);

    return {
      defaults,
      facebook,
      instagram,
      linkedin,
      linkedin_personal,
      youtube,
      gmb
    };
  }
});

export const downloadAttachments = async (attachment: UploadAttachment) => {
  const { type, file, image, video, entry } = attachment;

  if (type === AttachmentEnum.FILE && file) {
    return {
      type: 'UPLOADED_FILE',
      uploaded_file: file
    };
  } else if (
    (type === AttachmentEnum.LISTING_IMAGE || type === AttachmentEnum.CREATIVE_IMAGE) &&
    image
  ) {
    return {
      type: type,
      image: {
        id: image.id,
        url: image.url
      }
    };
  } else if (
    (type === AttachmentEnum.CREATIVE_VIDEO || type === AttachmentEnum.REALSHORTZ_VIDEO) &&
    video
  ) {
    return { type, video };
  } else if (
    (type === AttachmentEnum.CREATIVE_FILE || type === AttachmentEnum.CAROUSEL_ITEM) &&
    entry
  ) {
    return { type, entry };
  }
};

export const buildDraftInitialValues = async (args: any): Promise<any> => {
  const attachmentPromises: Promise<Attachment>[] = args?.attachments?.map(downloadAttachments);

  let attachments;
  if (attachmentPromises) {
    try {
      attachments = await Promise.all(attachmentPromises);
    } catch (error) {
      // DO something?
    }
  }

  const values = {
    posting_from: args?.posting_from,
    title: args?.title,
    message: args?.message,
    attachments: attachments,
    url: args?.url,
    post_type: args?.post_type
  };

  return values;
};

type InitialValues = {
  facebook: Facebook | null;
  linkedin: any;
  linkedin_personal: any;
  youtube: any;
  instagram: any;
  gmb: any;
  defaults: any;
  platforms: string[];
  listingId: any;
  creativeId: any;
  post_name?: Maybe<string>;
  schedule_timestamp: string;
  content_type?: Maybe<Workspace_Content_Type_Enum>;
  page_selection_mode?: Maybe<Workspace_Post_Page_Selection_Modes_Enum>;
  bulk_page_multiselect?: {
    facebook?: string[];
    instagram?: string[];
    linkedin?: string[];
    linkedin_personal?: string[];
    gmb?: string[];
    youtube?: string[];
  };
};

async function handleUpload(
  values: FormSchema,
  draftPostId?: string | null,
  userId?: string | null,
  activeWorkspaceId?: string | null
): Promise<CreatePostInput> {
  if (!activeWorkspaceId || !userId) {
    throw new Error('No active workspace');
  }
  const platforms = values.platforms;

  const [
    fbAttachments,
    liAttachments,
    lipAttachments,
    ytAttachments,
    igAttachments,
    gmbAttachments
  ] = await uploadAttachments(activeWorkspaceId, [
    values?.facebook?.attachments as Attachment[],
    values?.linkedin?.attachments as Attachment[],
    values?.linkedin_personal?.attachments as Attachment[],
    values?.youtube?.attachments as Attachment[],
    values?.instagram?.attachments as Attachment[],
    values?.gmb?.attachments as Attachment[]
  ]);

  const facebook: any = {
    posting_from: values.facebook?.posting_from,
    message: values.facebook?.message,
    attachments: fbAttachments,
    url: values.facebook?.url,
    post_type: values.facebook?.post_type
  };

  const linkedin: any = {
    posting_from: values.linkedin?.posting_from,
    message: values.linkedin?.message,
    attachments: liAttachments,
    url: values.linkedin?.url
  };

  const linkedinPersonal: any = {
    posting_from: values.linkedin_personal?.posting_from,
    message: values.linkedin_personal?.message,
    attachments: lipAttachments,
    url: values.linkedin_personal?.url
  };

  const youtube: any = {
    posting_from: values.youtube?.posting_from,
    title: values?.youtube?.title,
    message: values?.youtube?.message,
    privacy: values?.youtube?.privacy,
    attachments: ytAttachments
  };

  const instagram: any = {
    posting_from: values.instagram?.posting_from,
    message: values.instagram?.message,
    attachments: igAttachments,
    post_type: values.instagram?.post_type
  };

  const gmb: any = {
    posting_from: values.gmb?.posting_from,
    message: values.gmb?.message,
    attachments: gmbAttachments,
    url: values.gmb?.url
  };

  const defaults: any = values.defaults;

  let scheduleTimestamp = moment(values.schedule_timestamp);
  if (scheduleTimestamp.isBefore(moment())) {
    scheduleTimestamp = moment();
  }

  if (platforms.includes('youtube') && (!youtube.attachments || youtube.attachments.length !== 1)) {
    throw new Error('YouTube is missing video attachment');
  }

  if (
    platforms.includes('instagram') &&
    (!instagram.attachments || instagram.attachments.length === 0)
  ) {
    throw new Error('Instagram must have attachments');
  }

  return {
    user_id: userId,
    workspace_id: activeWorkspaceId,
    defaults: defaults ?? undefined,
    facebook: platforms.includes('facebook') ? facebook : undefined,
    instagram: platforms.includes('instagram') ? instagram : undefined,
    linkedin: platforms.includes('linkedin') ? linkedin : undefined,
    linkedin_personal: platforms.includes('linkedin_personal') ? linkedinPersonal : undefined,
    youtube: platforms.includes('youtube') ? youtube : undefined,
    gmb: platforms.includes('gmb') ? gmb : undefined,
    schedule: values.schedule,
    schedule_timestamp: scheduleTimestamp.toISOString(),
    variant: values.variant as string,
    listing_id: values.listing_id,
    creative_id: values.creative_id,
    rss_content_item_id: values.rss_content_item_id,
    name: values.post_name,
    draft_id: draftPostId,
    content_type: values.content_type,
    page_selection_mode: values.page_selection_mode,
    bulk_page_multiselect: values.bulk_page_multiselect
  };
}

async function handleUploadCarousel(
  values: FormSchema,
  draftPostId?: string | null,
  userId?: string | null,
  activeWorkspaceId?: string | null,
  creativeData?: CreativeByPkQuery
): Promise<CreatePostInput> {
  const creative = creativeData?.creative;
  const carousel = creative?.carousel;
  if (!creative || !carousel) {
    throw new Error('Cannot find carousel details');
  }

  const args = await handleUpload(values, draftPostId, userId, activeWorkspaceId);

  const fb: keyof CreatePostInput = 'facebook';
  const li: keyof CreatePostInput = 'linkedin';
  const lip: keyof CreatePostInput = 'linkedin_personal';

  const carouselPlatform = [fb, li, lip];
  for (const cp of carouselPlatform) {
    if (args[cp]) {
      args[cp]!.carousel = {
        creative_id: creative!.id,
        caption: creative!.carousel!.caption!,
        multi_share_optimized: carousel?.multi_share_optimized ?? true,
        multi_share_end_card: carousel?.multi_share_end_card ?? true,
        images: carousel?.items.map((item) => {
          const image = item.images[0];

          const imageSrc = image.src ?? image.selections[0]?.facebook_image?.url;
          if (!imageSrc) {
            console.error('Carousel Image missing image source url', imageSrc);
            throw new Error('Carousel Image missing image source url');
          }

          return {
            // REVIEW maybe better error handling?
            // Assert because if values are undefined it should throw
            image_path: imageSrc,
            call_to_action: item.cta,
            link: item.link,
            description: item.description,
            name: item.name
          };
        })
      };

      args[cp]!.url = args[cp]?.url ?? carousel.link;
      args[cp]!.message = args[cp]?.message ?? carousel.message;
    }
  }

  return args;
}

const useShareLogic = ({
  title,
  variant,
  listingId,
  creativeId,
  rssContentId,
  realShortzId,
  draftPostId,
  disabled = false
}: UseShareLogicProps) => {
  const { workspaceMemberContext } = useHasuraRoleContext();
  const { activeWorkspaceId, userId } = useUserContext();
  const [savingDraft, setSavingDraft] = useState(false);
  const [loadingInitialValues, setLoadingInitialValues] = useState(true);
  const [initialValues, setInitialValues] = useState<InitialValues>({} as InitialValues);

  const { data: draftData, loading: draftLoading } = usePostByIdQuery({
    variables: {
      id: draftPostId!
    },
    context: workspaceMemberContext,
    skip: !draftPostId || disabled
  });

  const draft = useMemo(() => draftData?.workspace_posts_by_pk, [draftData?.workspace_posts_by_pk]);
  const draftArgs = useMemo(() => draft?.args, [draft?.args]);
  const shareVariant = useMemo(() => draftArgs?.variant ?? variant, [draftArgs?.variant, variant]);
  const shareCreativeId = useMemo(
    () => draft?.creative_id ?? creativeId,
    [draft?.creative_id, creativeId]
  );
  const shareListingId = useMemo(
    () => draft?.listing_id ?? listingId,
    [draft?.listing_id, listingId]
  );
  const shareRssContentId = useMemo(
    () => draft?.rss_content_item_id ?? rssContentId,
    [draft?.rss_content_item_id, rssContentId]
  );

  const { data: creativeData, loading: creativeLoading } = useCreativeByPkQuery({
    variables: {
      id: shareCreativeId!
    },
    context: workspaceMemberContext,
    skip: !['creative', 'carousel'].includes(shareVariant) || !shareCreativeId || disabled
  });

  const skipRsVideoIsQuery = useMemo(() => !realShortzId || disabled, [realShortzId, disabled]);
  const { data: realshortzVideoData } = useGetRsVideosByIdQuery({
    context: workspaceMemberContext,
    variables: {
      where: {
        id: { _eq: realShortzId! }
      }
    },
    skip: skipRsVideoIsQuery
  });

  const skipListingByIdQuery = useMemo(
    () => shareVariant !== 'listing' || !shareListingId || disabled,
    [shareVariant, shareListingId, disabled]
  );
  const { data: listingData, loading: listingLoading } = useListingByIdQuery({
    variables: {
      id: shareListingId!
    },
    context: workspaceMemberContext,
    skip: skipListingByIdQuery
  });

  const skipRssContentItemQuery = useMemo(
    () => shareVariant !== 'content_rss' || !shareRssContentId || disabled,
    [shareVariant, shareRssContentId, disabled]
  );
  const { data: rssContentData, loading: rssContentLoading } = useWorkspaceRssContentItemQuery({
    variables: {
      id: shareRssContentId!
    },
    context: workspaceMemberContext,
    skip: skipRssContentItemQuery
  });

  const [createPost] = useCreatePostMutation({ context: workspaceMemberContext });
  const [saveDraftPost] = useSavePostDraftMutation({ context: workspaceMemberContext });
  const [updateDraftPost] = useUpdatePostDraftMutation({ context: workspaceMemberContext });

  const handleFormSubmit = useCallback(
    async (values: FormSchema) => {
      if (!activeWorkspaceId) {
        throw new Error('No active workspace');
      }

      let args: CreatePostInput;
      if (['upload', 'listing', 'creative', 'content_rss', 'realshortz'].includes(values.variant)) {
        args = await handleUpload(values, draftPostId, userId, activeWorkspaceId);
      } else if (values.variant === 'carousel') {
        args = await handleUploadCarousel(
          values,
          draftPostId,
          userId,
          activeWorkspaceId,
          creativeData
        );
      } else {
        throw new Error(`Invalid variant "${values.variant}"`);
      }
      await createPost({ variables: { args: args } });
    },
    [activeWorkspaceId, createPost, creativeData, userId, draftPostId]
  );

  const handleSaveDraft = useCallback(
    async (values: FormSchema) => {
      if (!activeWorkspaceId) {
        throw new Error('No active workspace');
      }

      setSavingDraft(true);

      try {
        let scheduleTimestamp = moment(values.schedule_timestamp);
        if (scheduleTimestamp.isBefore(moment())) {
          scheduleTimestamp = moment();
        }
        let args;
        if (
          ['upload', 'listing', 'creative', 'content_rss', 'realshortz'].includes(values.variant)
        ) {
          args = await handleUpload(values, draftPostId, userId, activeWorkspaceId);
        } else if (values.variant === 'carousel') {
          args = await handleUploadCarousel(
            values,
            draftPostId,
            userId,
            activeWorkspaceId,
            creativeData
          );
        } else {
          throw new Error(`Invalid variant "${values.variant}`);
        }

        const message =
          args.facebook?.message ??
          args.linkedin?.message ??
          args.linkedin_personal?.message ??
          args.youtube?.message ??
          'Draft';

        const listings = values.listing_id
          ? {
              data: [
                {
                  listing_id: values.listing_id
                }
              ]
            }
          : undefined;

        if (draftPostId) {
          // Update Existing Draft
          await updateDraftPost({
            variables: {
              id: draftPostId,
              _set: {
                message: message,
                args: args,
                scheduled_at: scheduleTimestamp.toISOString(),
                listing_id: values.listing_id,
                creative_id: values.creative_id,
                rss_content_item_id: values.rss_content_item_id,
                name: values.post_name,
                content_type: values.content_type as Workspace_Content_Type_Enum,
                agent_permission_mode:
                  values.agent_permission_mode ??
                  Workspace_Resource_Permission_Modes_Enum.SelectUsers,
                page_selection_mode:
                  values.page_selection_mode as Workspace_Post_Page_Selection_Modes_Enum,
                bulk_page_multiselect: values.bulk_page_multiselect
              }
            }
          });
        } else {
          // Create New Draft
          await saveDraftPost({
            variables: {
              workspace_id: activeWorkspaceId,
              message: message,
              args: args,
              scheduled_at: scheduleTimestamp.toISOString(),
              listing_id: values.listing_id,
              listings: listings,
              creative_id: values.creative_id,
              rss_content_item_id: values.rss_content_item_id,
              name: values.post_name,
              content_type: values.content_type as Workspace_Content_Type_Enum,
              agent_permission_mode:
                values.agent_permission_mode ??
                Workspace_Resource_Permission_Modes_Enum.SelectUsers,
              page_selection_mode:
                values.page_selection_mode as Workspace_Post_Page_Selection_Modes_Enum,
              bulk_page_multiselect: values.bulk_page_multiselect
            }
          });
        }
        setSavingDraft(false);
      } catch (error) {
        setSavingDraft(false);
        throw error;
      }
    },
    [
      activeWorkspaceId,
      setSavingDraft,
      draftPostId,
      userId,
      creativeData,
      saveDraftPost,
      updateDraftPost
    ]
  );

  const creative = useMemo(() => creativeData?.creative, [creativeData?.creative]);
  const listing = useMemo(() => listingData?.listing, [listingData?.listing]);
  const rssContentItem = useMemo(() => rssContentData?.item, [rssContentData?.item]);
  const realShortzVideo = useMemo(
    () => realshortzVideoData?.realshortz_videos_for_properti_sync?.[0],
    [realshortzVideoData?.realshortz_videos_for_properti_sync]
  );

  const draftVariant = useMemo(
    () => (draftPostId ? draftArgs?.variant : null),
    [draftPostId, draftArgs?.variant]
  );

  useEffect(() => {
    async function fetchInitialValues() {
      setLoadingInitialValues(true);

      // default values
      let platforms: string[] = [];
      let facebook: Facebook | null = null;
      let linkedin = null;
      let linkedin_personal = null;
      let youtube = null;
      let instagram = null;
      let gmb = null;
      let defaults = null;
      let postName = null;
      let contentType = Workspace_Content_Type_Enum.Other;
      let pageSelectionMode = Workspace_Post_Page_Selection_Modes_Enum.Page;
      let bulkPageMultiselect = DEFAULT_BULK_PAGE_MULTISELECT;

      if (draftPostId) {
        postName = draft?.name;
        contentType = draft?.content_type ?? Workspace_Content_Type_Enum.Other;
        pageSelectionMode =
          draft?.page_selection_mode ?? Workspace_Post_Page_Selection_Modes_Enum.Page;
        bulkPageMultiselect = draft?.bulk_page_multiselect ?? DEFAULT_BULK_PAGE_MULTISELECT;

        if (draftArgs?.facebook) {
          platforms.push('facebook');
          facebook = await buildDraftInitialValues(draftArgs.facebook);
        }

        if (draftArgs?.linkedin) {
          platforms.push('linkedin');
          linkedin = await buildDraftInitialValues(draftArgs.linkedin);
        }

        if (draftArgs?.linkedin_personal) {
          platforms.push('linkedin_personal');
          linkedin_personal = await buildDraftInitialValues(draftArgs.linkedin_personal);
        }

        if (draftArgs?.youtube) {
          platforms.push('youtube');
          youtube = await buildDraftInitialValues(draftArgs.youtube);
        }

        if (draftArgs?.instagram) {
          platforms.push('instagram');
          instagram = await buildDraftInitialValues(draftArgs.instagram);
        }

        if (draftArgs?.gmb) {
          platforms.push('gmb');
          gmb = await buildDraftInitialValues(draftArgs.gmb);
        }

        if (draftArgs?.defaults) {
          defaults = await buildDraftInitialValues(draftArgs.defaults);
        }
      } else if (variant === 'realshortz') {
        postName = `RealShortz: ${title}`;
        if (realShortzVideo) {
          const message = realShortzVideo.caption ?? '';
          const thumbnail =
            getFilePath(
              realShortzVideo.custom_thumbnail_s3_path,
              realShortzVideo.custom_thumbnail_s3_token
            ) ?? realShortzVideo.cloudinary_thumbnail_url;
          const attachments: Attachment[] = [
            {
              type: AttachmentEnum.REALSHORTZ_VIDEO,
              video: {
                id: realShortzVideo?.id!,
                thumbnail_url: thumbnail!
              }
            }
          ];

          facebook = {
            title,
            message,
            attachments
          };
        }
      } else if (['creative', 'carousel'].includes(variant)) {
        let attachments: Attachment[] | undefined = undefined;
        if (creative?.type === Workspace_Creative_Types_Enum.Image) {
          attachments = creative.images?.slice(0, 10).map((image) => ({
            type: AttachmentEnum.CREATIVE_IMAGE,
            image: {
              id: image.id!,
              url: image.image_url_jpg!
            }
          }));
        } else if (creative?.type === Workspace_Creative_Types_Enum.Video) {
          const thumbnail = getFilePath(
            creative.video_thumbnail?.s3_path ?? creative?.video?.thumbnail_url
          );

          attachments = [
            {
              type: AttachmentEnum.CREATIVE_VIDEO,
              video: {
                id: creative?.id,
                thumbnail_url: thumbnail
              }
            }
          ];
        } else if (creative?.type === Workspace_Creative_Types_Enum.ManualVideo) {
          const thumbnail =
            getFilePath(creative.video_thumbnail?.s3_path) ??
            creative.cloudinary_video?.thumbnail_url!;
          attachments = [
            {
              type: AttachmentEnum.CREATIVE_VIDEO,
              video: {
                id: creative?.id,
                thumbnail_url: thumbnail
              }
            }
          ];
        } else if (creative?.type === Workspace_Creative_Types_Enum.Manual) {
          attachments = creative?.attachments?.map((att) => ({
            type: AttachmentEnum.CREATIVE_FILE,
            entry: {
              id: att.id,
              path: att.path,
              token: att.token,
              content_type: att.content_type!,
              filename: att.filename
            }
          }));
        } else if (creative?.type === Workspace_Creative_Types_Enum.Carousel) {
          attachments = creative?.attachments?.map((att) => ({
            type: AttachmentEnum.CAROUSEL_ITEM,
            entry: {
              id: att?.id,
              path: att?.path,
              token: att?.token,
              content_type: att?.content_type!,
              filename: att?.filename
            }
          }));
        }
        postName = title;
        facebook = {
          title: title,
          message: creative?.default_message ?? creative?.carousel?.message,
          attachments
        };
      } else if (variant === 'listing') {
        const { title, message } = await getDefaultMessage(
          shareListingId as string,
          activeWorkspaceId as string
        );
        postName = title;

        const attachments: Attachment[] | undefined = listing?.images
          ?.slice(0, 5)
          .map(listingAttachment);

        facebook = {
          title,
          message,
          attachments
        };
      } else if (variant === 'content_rss') {
        const { title, message, url, image } = await getRssContentItem(shareRssContentId!);
        postName = title;

        const attachments: Attachment[] | undefined = image
          ? [
              {
                type: AttachmentEnum.LISTING_IMAGE,
                image: {
                  id: shareRssContentId!,
                  url: image
                }
              }
            ]
          : undefined;

        defaults = {
          title,
          message,
          url,
          attachments
        };
      }

      const newScheduleTimestamp =
        draft?.scheduled_at && moment(draft?.scheduled_at).isAfter(moment())
          ? moment(draft.scheduled_at).format(HTML5_DATETIME_INPUT_MOMENT_FORMAT)
          : moment().add(15, 'minutes').format(HTML5_DATETIME_INPUT_MOMENT_FORMAT);

      const newInitialValues = {
        facebook,
        linkedin,
        linkedin_personal,
        youtube,
        instagram,
        gmb,
        defaults,
        platforms,
        listingId: shareListingId,
        creativeId: shareCreativeId,
        post_name: postName,
        schedule_timestamp: newScheduleTimestamp,
        content_type: contentType,
        page_selection_mode: pageSelectionMode,
        bulk_page_multiselect: bulkPageMultiselect
      };

      setInitialValues(newInitialValues);
      setLoadingInitialValues(false);
    }

    fetchInitialValues();
  }, [
    creative,
    listing,
    rssContentItem,
    draftPostId,
    draftArgs,
    setInitialValues,
    shareListingId,
    shareCreativeId,
    realShortzId,
    realShortzVideo,
    shareRssContentId,
    setLoadingInitialValues,
    variant,
    title,
    draft,
    activeWorkspaceId
  ]);

  const loading = useMemo(
    () =>
      (creativeLoading ||
        listingLoading ||
        draftLoading ||
        loadingInitialValues ||
        rssContentLoading) ??
      false,
    [creativeLoading, listingLoading, draftLoading, loadingInitialValues, rssContentLoading]
  );

  return {
    activeWorkspaceId,
    userId,
    loading,
    handleFormSubmit,
    handleSaveDraft,
    creative: creative,
    listing: listing,
    rssContentItem: rssContentItem,
    initialValues,
    draftVariant,
    savingDraft
  };
};

export default useShareLogic;

const getDefaultMessage = async (listingId: string, workspaceId: string) => {
  const workspaceMemberContext = getHasuraContextFromSessionStorage();
  const client = buildApolloClient();

  if (!listingId || !workspaceId) {
    return {};
  }

  const resp = await client
    .query({
      query: ListingMessageQuery,
      variables: {
        args: {
          workspace_id: workspaceId,
          listing_id: listingId
        }
      },
      context: workspaceMemberContext
    })
    .catch((error) => {
      console.debug(error);
    });

  if (!resp) {
    return {};
  }

  const message: string = resp.data?.getListingMessage?.message;
  const title: string = resp.data?.getListingMessage?.title;
  const url: string | null | undefined = resp.data?.getListingMessage?.url;

  return { title, message, url };
};

const getRssContentItem = async (contentId: string) => {
  const client = buildApolloClient();
  const workspaceMemberContext = getHasuraContextFromSessionStorage();

  if (!contentId) {
    return {};
  }

  const resp = await client.query({
    query: RssContentItemByIdQuery,
    variables: {
      id: contentId
    },
    context: workspaceMemberContext
  });

  const message: string = resp.data?.item?.description;
  const title: string = resp.data?.item?.title;
  const url: string = resp.data?.item?.link;
  const image: string | null = resp.data?.item?.image;

  return {
    title,
    message,
    url,
    image
  };
};
