import React, { useMemo } from 'react';

import { Typography } from '@material-ui/core';

import { Autocomplete } from 'mui-rff';

import { Workspace_Users_Bool_Exp, useWorkspaceTeamMembersQuery } from 'generated/graphql';

import { useHasuraRoleContext } from 'lib/HasuraRoleContext';
import { useAgentPermissions } from 'lib/hooks/useAgentPermissions';
import useUserContext from 'lib/hooks/useUserContext';

interface AgentAutocompleteProps {
  name: string;
  label?: string;
  multiple?: boolean;
  limitAgentUsers?: boolean;
}

const AgentAutocomplete: React.FC<AgentAutocompleteProps> = ({
  name,
  label = 'Select Listing Agent',
  multiple = false,
  limitAgentUsers = true
}) => {
  const { workspaceMemberContext } = useHasuraRoleContext();
  const { userId, activeWorkspaceId, isWorkspaceAgent } = useUserContext();
  const agentPermissions = useAgentPermissions();

  const where = useMemo(() => {
    const where: Workspace_Users_Bool_Exp = {
      _and: [{ workspace_id: { _eq: activeWorkspaceId! } }]
    };

    if (isWorkspaceAgent && limitAgentUsers) {
      where._and!.push({
        _or: [
          {
            user: { display_name: { _in: agentPermissions.allowed_agents } }
          },
          {
            agent_workspace_mappings: {
              listing_agent_name: { _in: agentPermissions.allowed_agents }
            }
          },
          {
            user_id: { _eq: userId! }
          }
        ]
      });
    }

    return where;
  }, [
    activeWorkspaceId,
    isWorkspaceAgent,
    limitAgentUsers,
    agentPermissions.allowed_agents,
    userId
  ]);

  const { data, loading, error } = useWorkspaceTeamMembersQuery({
    variables: {
      where: where,
      workspace_id: activeWorkspaceId!
    },
    context: workspaceMemberContext
  });

  const options = useMemo(
    () =>
      data?.workspace_users?.map((user) => ({
        id: user.user_id,
        name: user.user.display_name
      })) ?? [],
    [data?.workspace_users]
  );

  if (error) {
    return (
      <div>
        <Typography variant="caption" color="error">
          Unable to load agents
        </Typography>
      </div>
    );
  }

  return (
    <div>
      <Autocomplete
        name={name}
        label={label}
        multiple={multiple}
        loading={loading}
        options={options}
        getOptionValue={(option: any) => option.id}
        getOptionLabel={(option: any) => option.name}
      />
    </div>
  );
};

export default AgentAutocomplete;
