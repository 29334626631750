import { Box, Typography } from '@material-ui/core';

export default function ListFooter() {
  return (
    <Box
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        margin: 2,
        height: 60
      }}
    >
      <Typography variant="caption">
        <strong>End of notifications 🎉</strong>
      </Typography>
    </Box>
  );
}
