import { useCallback, useEffect, useState } from 'react';

import { Link as RouterLink } from 'react-router-dom';

import { Tab, Tabs } from '@material-ui/core';

import { FeatureFlags, useIsFeatureFlagEnabled } from 'lib/feature-flags';

interface LeadTabsProps {
  currentTab: number;
}

export default function LeadTabs({ currentTab }: LeadTabsProps) {
  const [tab, setTab] = useState<number>(currentTab);

  const isLeadGenEnabled = useIsFeatureFlagEnabled(FeatureFlags.lead_gen);

  useEffect(() => {
    setTab(currentTab);
  }, [setTab, currentTab]);

  const handleTabChange = useCallback(
    (_e: any, newTab: number) => {
      setTab(newTab);
    },
    [setTab]
  );

  return (
    <Tabs textColor="secondary" value={tab} onChange={handleTabChange}>
      <Tab component={RouterLink} to="/leads" label="Leads" />
      <Tab
        component={RouterLink}
        to="/leads/forms"
        label="Leadgen Forms"
        disabled={!isLeadGenEnabled}
      />
    </Tabs>
  );
}
