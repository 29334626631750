import { IconButton, InputAdornment, TextField, TextFieldProps } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import SearchIcon from '@material-ui/icons/Search';

type SearchInputProps = TextFieldProps & {
  // Show Clear Search Button
  onClickClear: () => void;

  // Function called when IconButton is clicked
  onClickSearch: () => void;
};

interface IconButtonProps {
  position: 'start' | 'end';
  onClick: () => void;
}

const SearchIconButton = ({ position, onClick }: IconButtonProps) => (
  <IconButton edge={position} onClick={onClick}>
    <SearchIcon />
  </IconButton>
);

const ClearIconButton = ({ position, onClick }: IconButtonProps) => (
  <IconButton edge={position} onClick={onClick}>
    <ClearIcon />
  </IconButton>
);

const SearchInput = ({ onClickSearch, onClickClear, InputProps, ...other }: SearchInputProps) => {
  const startAdornment = (
    <InputAdornment position="start">
      <SearchIconButton position={'start'} onClick={onClickSearch} />
    </InputAdornment>
  );

  const endAdornment = (
    <InputAdornment position="end">
      <ClearIconButton position={'end'} onClick={onClickClear} />
    </InputAdornment>
  );

  return (
    <TextField
      InputProps={{
        startAdornment,
        endAdornment,
        autoCapitalize: 'off',
        autoCorrect: 'off',
        autoComplete: 'off',
        ...InputProps
      }}
      {...other}
    />
  );
};

export default SearchInput;
