import {
  AppBar,
  Box,
  Button,
  Container,
  DialogActions,
  DialogContent,
  Drawer,
  IconButton,
  Tab,
  Tabs,
  Typography
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Clear';
import { Alert, AlertTitle, TabContext, TabPanel } from '@material-ui/lab';

import { SelectPreview } from 'pages/workspace/Campaigns/Create/AdPreview';

interface DynamicCreativePreviewProps {
  open: boolean;
  onClose: () => void;
  creativeId: string;
  title: string;
  allAssetsReady: boolean;
}

export default function DynamicCreativePreview({
  open,
  onClose,
  title,
  creativeId,
  allAssetsReady
}: DynamicCreativePreviewProps) {
  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={onClose}
      elevation={2}
      ModalProps={{
        disableEnforceFocus: true
      }}
      BackdropProps={{
        style: {
          backgroundColor: '#333333',
          opacity: 0.9
        }
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          paddingX: '16px',
          paddingY: '8px'
        }}
      >
        <Typography variant="h5" gutterBottom>
          Previewing {title}
        </Typography>
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </Box>
      <Container
        style={{
          width: '48rem',
          flexGrow: 1,
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between'
        }}
      >
        <DialogContent>
          <TabContext value="meta">
            <AppBar position="static">
              <Tabs value="meta">
                <Tab label="Meta" value={'meta'} />
              </Tabs>
            </AppBar>
            <TabPanel value="meta">
              {allAssetsReady ? (
                <SelectPreview
                  preview={{
                    platform: 'facebook',
                    creativeIds: [creativeId],
                    videoIds: []
                  }}
                />
              ) : (
                <Alert severity="info">
                  <AlertTitle>Video assets being prepared</AlertTitle>
                  We are still preparing your videos assets, please check back shortly. This
                  normally only takes a few minutes.
                </Alert>
              )}
            </TabPanel>
          </TabContext>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="secondary" size="large" onClick={onClose}>
            Close
          </Button>
        </DialogActions>
      </Container>
    </Drawer>
  );
}
