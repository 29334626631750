import { useState } from 'react';

import { Box, Button, Grid, LinearProgress } from '@material-ui/core';
import Pagination from '@material-ui/lab/Pagination';

import moment from 'moment';

import {
  Realshortz_Videos_Bool_Exp,
  Realshortz_Video_Statuses_Enum,
  useGetRsDraftVideosQuery,
  useGetRsWorkspaceSyncSelectionQuery
} from 'generated/graphql';

import EmptyStatePage from 'components/EmptyStatePage';
import InstallRealShortzModal from 'components/InstallRealShortzModal';
import PageHeader from 'components/PageHeader';
import PaginationContainer from 'components/PaginationContainer';
import DraftVideoCard from 'components/RealShortz/DraftVideoCard';
import RealShortzIcon from 'components/icons/RealShortzIcon';
import MultiFilterDropdown from 'components/multiFilterDropdown';

import { useHasuraRoleContext } from 'lib/HasuraRoleContext';
import { createFilterBySearch } from 'lib/hooks/useFiltersAndPagination';
import useLocalStorage from 'lib/hooks/useLocalStorage';
import useUserContext from 'lib/hooks/useUserContext';

import RealShortzTabs from '../components/RealShortzTabs';

const useSearchFilter = createFilterBySearch<Realshortz_Videos_Bool_Exp>([{ title: {} }]);

const DraftVideoPage = () => {
  const { userId, isWorkspaceAgent } = useUserContext();
  const { workspaceMemberContext } = useHasuraRoleContext();
  const { activeWorkspaceId } = useUserContext();

  const [page, setPage] = useState(1);
  const [limit] = useState(8);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [filtersOpen, setFiltersOpen] = useState(false);

  const { data: workspaceSyncData } = useGetRsWorkspaceSyncSelectionQuery({
    variables: { userId: userId!, workspaceId: activeWorkspaceId! },
    context: workspaceMemberContext
  });

  const workspaceUserDataWithSyncSelected = workspaceSyncData?.workspace_users.map((user) => ({
    userId: user.user_id,
    displayName: user.user.display_name
  }));

  const workspaceUserIdsWithSyncSelected = workspaceUserDataWithSyncSelected?.map(
    (data) => data.userId
  );
  const workspaceUserNamesWithSyncSelected =
    workspaceUserDataWithSyncSelected?.map((data) => data.displayName) || [];

  console.debug(
    'workspace users names with workspace sync selected',
    workspaceUserNamesWithSyncSelected
  );

  // Filters
  const [filterAgentName, setFilterAgentName] = useLocalStorage(
    `properti.${activeWorkspaceId}.realshortzVideos.filter.agent`
  );
  const [searchFilter, search, setSearch] = useSearchFilter();

  const where: Realshortz_Videos_Bool_Exp = {
    user_id: { _in: workspaceUserIdsWithSyncSelected },
    status: { _eq: Realshortz_Video_Statuses_Enum.Draft },
    _and: [{ ...searchFilter }]
  };

  if (isWorkspaceAgent) {
    where._and = [
      { user_id: { _eq: userId! } },
      { user_id: { _in: workspaceUserIdsWithSyncSelected } }
    ];
  }

  if (filterAgentName) {
    if (!where._and) {
      where._and = [];
    }
    where._and.push({
      _or: [
        {
          user: { display_name: { _eq: filterAgentName } }
        }
      ]
    });
  }

  const { data, loading } = useGetRsDraftVideosQuery({
    variables: {
      where,
      offset: (page - 1) * limit,
      limit
    },
    context: workspaceMemberContext,
    pollInterval: 300000
  });

  const agentNames =
    data?.realshortz_videos
      ?.filter((video) => video.status === Realshortz_Video_Statuses_Enum.Draft)
      .map((video) => video.user.display_name)
      .filter((name): name is string => Boolean(name) && typeof name === 'string')
      .filter((name, index, self) => self.indexOf(name) === index) || ([] as string[]);

  const agentNameOptions = agentNames
    ?.filter(Boolean)
    .map((agent) => ({ value: agent, text: agent }));

  const realshortzDraftVideos = data?.realshortz_videos;
  const totalVideoCount = data?.realshortz_videos_aggregate.aggregate?.count ?? 0;
  const pageCount = Math.ceil(totalVideoCount / limit);

  const handleToggleModal = () => setModalOpen((open) => !open);
  return (
    <>
      <div>
        <PageHeader
          title="Your RealShortz Videos"
          subtitle="Film it, share it, boost it."
          rightComponent={
            <Button variant="contained" color="secondary" size="large" onClick={handleToggleModal}>
              <RealShortzIcon fontSize="inherit" style={{ marginRight: 8 }} />
              Install RealShortz
            </Button>
          }
        />
        <RealShortzTabs value={1} />
        <MultiFilterDropdown
          onToggleFiltersOpen={() => setFiltersOpen((prev) => !prev)}
          filtersOpen={filtersOpen}
          search={true}
          setSearch={setSearch}
          searchValue={search}
          menuItems={[
            {
              name: 'Agent',
              value: filterAgentName,
              setValue: setFilterAgentName,
              options: agentNameOptions,
              hasAll: true
            }
          ]}
        />
        <Box mb={0.5} height={2}>
          {loading && Boolean(data) && <LinearProgress />}
        </Box>

        {!loading && !realshortzDraftVideos?.length ? (
          <EmptyStatePage
            title={'No RealShortz Draft Videos'}
            text={'Download RealShortz on your phone to get started'}
            icon={<RealShortzIcon style={{ height: 64, width: 64 }} />}
            button={
              <Button
                variant="contained"
                color="secondary"
                size="large"
                onClick={handleToggleModal}
                fullWidth
              >
                <RealShortzIcon fontSize="inherit" style={{ marginRight: 8 }} />
                Install RealShortz
              </Button>
            }
          />
        ) : (
          <>
            <Grid container spacing={2}>
              {realshortzDraftVideos?.map((video) => {
                const thumbnailUrl =
                  video.sections[0].sync?.cloudinary_thumbnail_url ?? '/videos/LoadingVideo.jpg';
                return (
                  <Grid
                    key={video.id}
                    item
                    xs={12}
                    sm={6}
                    md={4}
                    lg={3}
                    xl={3}
                    style={{
                      paddingTop: 2
                    }}
                  >
                    <DraftVideoCard
                      id={video.id!}
                      title={video.title!}
                      subtitle={`${moment(video.created_at).fromNow()} by ${
                        video.user?.display_name
                      }`}
                      thumbnailUrl={thumbnailUrl!}
                      cloudinaryPlaybackUrl={video.sections[0].sync?.cloudinary_playback_url!}
                      disableActionButtons={true}
                      isUploading={!Boolean(video.sections[0].sync?.s3_path)}
                      sections={video.sections}
                    />
                  </Grid>
                );
              })}
            </Grid>
            <PaginationContainer
              style={{
                marginTop: 16
              }}
            >
              <Pagination
                count={pageCount}
                page={page}
                onChange={(_, page) => {
                  setPage(page);
                }}
                showFirstButton={pageCount >= 5}
                showLastButton={pageCount >= 5}
              />
            </PaginationContainer>
          </>
        )}
      </div>

      <InstallRealShortzModal open={modalOpen} onClose={handleToggleModal} />
    </>
  );
};

export default DraftVideoPage;
