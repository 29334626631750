import { useMemo } from 'react';

import { Link, useParams } from 'react-router-dom';

import { Link as MuiLink } from '@material-ui/core';

import MaterialTable from '@material-table/core';
import { ExportCsv } from '@material-table/exporters/csv';

import moment from 'moment';

import { WorkspaceLeadsQuery, useWorkspaceLeadsByFormQuery } from 'generated/graphql';

import PageHeader from 'components/PageHeader';
import TableContainer from 'components/TableContainer';
import Toolbar from 'components/Toolbar';

import { useHasuraRoleContext } from 'lib/HasuraRoleContext';
import { usePagination } from 'lib/hooks/useFiltersAndPagination';
import usePlatforms from 'lib/hooks/usePlatforms';
import useUserContext from 'lib/hooks/useUserContext';

export default function LeadsByFormPage() {
  const { workspaceMemberContext } = useHasuraRoleContext();
  const { activeWorkspaceId } = useUserContext();
  const { id } = useParams();
  const [, , meta] = usePlatforms();

  const { limit, page, offset, setPage, setLimit } = usePagination(10);

  const { data, loading } = useWorkspaceLeadsByFormQuery({
    variables: {
      workspace_id: activeWorkspaceId!,
      form_id: id!,
      limit: limit,
      offset: offset
    },
    context: workspaceMemberContext
  });
  const count = useMemo(() => data?.count?.aggregate?.count ?? 0, [data?.count?.aggregate?.count]);

  // https://github.com/mbrn/material-table/issues/1979
  const leads = useMemo(() => data?.leads?.map((l) => ({ ...l })) ?? [], [data?.leads]);
  const form = useMemo(() => data?.form, [data?.form]);

  return (
    <div>
      <PageHeader
        title={`Leads from ${form?.name ?? '...'}`}
        subtitle="Incoming leads collected by Properti"
      />

      <MaterialTable
        title="Leads"
        columns={[
          {
            title: 'Name',
            field: 'name',
            editable: 'never',
            type: 'string'
          },
          {
            title: 'Created At',
            field: 'created_at',
            type: 'datetime',
            render: (data) => {
              return (
                <span>
                  {moment(data.created_at).format('D MMM YYYY')}
                  <br />
                  {moment(data.created_at).format('h:mm A')}
                </span>
              );
            },
            sorting: true,
            editable: 'never'
          },
          {
            title: 'Source',
            field: 'source',
            editable: 'never',
            render: (data) => data.lead_source.label ?? data.source
          },
          {
            title: 'Type',
            field: 'lead_type',
            editable: 'never',
            sorting: true,
            render: (data) => data.type.label ?? data.lead_type
          },
          {
            title: 'Facebook Page ID',
            field: 'facebook_page_id',
            editable: 'never',
            hidden: true,
            sorting: false
          },
          {
            title: 'Facebook Page',
            field: 'facebook_page_name',
            editable: 'never',
            sorting: false,
            render: (data) => {
              const foundPage = meta.facebook?.allowedPages?.find(
                (page) => page.id === data.facebook_page_id
              );

              if (foundPage && foundPage.display_name) {
                return foundPage.display_name;
              }

              return data.facebook_page_id ?? '-';
            }
          },
          { title: 'Email', field: 'email', editable: 'never' },
          {
            title: 'Number',
            field: 'number'
          },
          {
            title: 'Message',
            field: 'message'
          },
          {
            title: 'Multiple Choice',
            field: 'multi_choice_questions',
            render: (data) => {
              if (!data.multi_choice_questions) {
                return '-';
              }
              const multiChoiceQuestions = data.multi_choice_questions ?? [];

              return multiChoiceQuestions.map((question: any, idx: number) => (
                <p key={idx}>
                  {question.question}: <strong>{question.response}</strong>
                </p>
              ));
            }
          },
          {
            title: 'Listing',
            field: 'listing_id',
            render: (data) => {
              if (!data.listing) {
                return '-';
              }
              const address = data?.listing?.location?.address?.sub_number
                ? `${data?.listing?.location?.address?.sub_number}/${data?.listing?.location?.address?.street_number} ${data?.listing?.location?.address?.street}, ${data?.listing?.location?.address?.suburb}`
                : `${data?.listing?.location?.address?.street_number} ${data?.listing?.location?.address?.street}, ${data?.listing?.location?.address?.suburb}`;
              return (
                <MuiLink component={Link} to={`/properties/view/${data?.listing_id}`}>
                  {address}
                </MuiLink>
              );
            }
          }
        ]}
        data={leads}
        totalCount={count}
        options={{
          search: false,
          draggable: false,
          showTitle: false,
          toolbar: true,
          pageSizeOptions: [10, 25, 50, 100, 200, 1000],
          exportMenu: [
            {
              label: 'Export CSV',
              exportFunc: (cols, datas: WorkspaceLeadsQuery['leads']) => {
                const fixedDatas = datas.map((data) => {
                  // Facebook Page
                  const foundPage = meta.facebook?.allowedPages?.find(
                    (page) => page.id === data?.facebook_page_id
                  );
                  const foundPageDisplayName = foundPage?.display_name;
                  const facebookPageName = !data.facebook_page_id
                    ? '-'
                    : foundPageDisplayName ?? '-';

                  // Address
                  const address = !data.listing
                    ? '-'
                    : data?.listing?.location?.address?.sub_number
                    ? `${data?.listing?.location?.address?.sub_number}/${data?.listing?.location?.address?.street_number} ${data?.listing?.location?.address?.street}, ${data?.listing?.location?.address?.suburb}`
                    : `${data?.listing?.location?.address?.street_number} ${data?.listing?.location?.address?.street}, ${data?.listing?.location?.address?.suburb}`;

                  // Multi choice questions
                  const multiChoiceQuestions = !data.multi_choice_questions?.length
                    ? '-'
                    : data.multi_choice_questions
                        ?.map(
                          (question: any, idx: number) =>
                            `${question.question}: ${question.response}`
                        )
                        .join('\r\n');

                  return {
                    ...data,
                    facebook_page_name: facebookPageName,
                    multi_choice_questions: multiChoiceQuestions,
                    listing_id: address
                  };
                });

                return ExportCsv(cols, fixedDatas, 'properti-leads');
              }
            }
          ],
          exportAllData: false,
          actionsColumnIndex: -1,
          pageSize: limit
        }}
        isLoading={loading}
        onRowsPerPageChange={setLimit}
        onPageChange={setPage}
        page={page}
        components={{
          Container: TableContainer,
          Toolbar: (props) => <Toolbar {...props} />
        }}
      />
    </div>
  );
}
