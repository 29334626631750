import { useEffect, useMemo } from 'react';

import { Box, Checkbox, CircularProgress, FormControlLabel, FormGroup } from '@material-ui/core';

import { FormApi } from 'final-form';

import { useTemplateDynamicParameters } from 'lib/hooks/useTemplateDynamicParameters';
import { getPossibleNestedValue } from 'lib/utils/getPossibleNestedValue';

import { DynamicTemplateParameterInput } from './DynamicParametersInput';

export interface DynamicFunctionParametersInputWrapperTemplateProps {
  /**
   * Properti custom template Id
   */
  templateId?: string | null;

  /**
   * React Final Form Form API object
   */
  form: FormApi<Record<string, any>, Partial<Record<string, any>>>;

  /**
   * Form key prefix, including any "."s
   */
  formKeyPrefix?: string;

  /**
   * Optional boolean to disable all dynamic parameter inputs
   */
  disabled?: boolean;
}

export function DynamicFunctionParametersInputWrapperTemplate({
  templateId,
  form,
  formKeyPrefix = '',
  disabled = false
}: DynamicFunctionParametersInputWrapperTemplateProps) {
  const {
    hasDynamicParameters,
    loadingDynamicParameters,
    dynamicParameters,
    initialFormValuesForDynamicParameters,
    isValidDynamicParameterFormValue,
    groupByFunctionName,
    setGroupByFunctionName,
    isHeadOfficeMode
  } = useTemplateDynamicParameters(templateId);

  const changeForm = useMemo(() => form.change, [form]);
  const getFormState = useMemo(() => form.getState, [form]);

  const formKey = useMemo(() => `${formKeyPrefix}dynamic_parameters`, [formKeyPrefix]);

  useEffect(() => {
    // Wait until dynamic parameters are loaded
    if (loadingDynamicParameters) {
      return;
    }

    // Check if dynamic parameters are passed in during initial values
    const fieldState = getFormState();
    const currentInitialValue = getPossibleNestedValue(fieldState.values, formKey);
    if (currentInitialValue && isValidDynamicParameterFormValue(currentInitialValue)) {
      // No need to init the form key
      return;
    }

    if (initialFormValuesForDynamicParameters) {
      changeForm(formKey, initialFormValuesForDynamicParameters);
    } else {
      changeForm(formKey, null);
    }
  }, [
    changeForm,
    getFormState,
    formKey,
    initialFormValuesForDynamicParameters,
    isValidDynamicParameterFormValue,
    loadingDynamicParameters
  ]);

  if (loadingDynamicParameters) {
    return <CircularProgress />;
  }

  if (!hasDynamicParameters) {
    return null;
  }

  return (
    <Box>
      <FormGroup>
        <FormControlLabel
          control={
            <Checkbox
              value={groupByFunctionName}
              checked={groupByFunctionName}
              onChange={(_, checked) => setGroupByFunctionName(checked)}
            />
          }
          label="Group dynamic parameters by function"
        />
      </FormGroup>
      <DynamicTemplateParameterInput
        hasDynamicParameters={hasDynamicParameters}
        dynamicParameters={dynamicParameters}
        formKeyPrefix={formKeyPrefix}
        isHeadOfficeMode={isHeadOfficeMode}
        disabled={disabled}
        groupByFunctionName={groupByFunctionName}
      />
    </Box>
  );
}
